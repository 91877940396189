import React from 'react';

import classes from 'classnames';
import BitSizeForm from 'components/BitSize/BitSizeForm';
import BitSizeClipboard from 'components/BitSizeClipboard';
import Modal from 'react-modal';

import removeIcon from '../../assets/close.svg';
import { modalStyleWellPhasesModal } from '../../utils';

const Title = ({ title, className, classTitle }) => (
  <h3 className={classes(
    'title-row',
    {
      [className]: !!className,
      [classTitle]: !!classTitle
    }
  )}
  >
    {title}
  </h3>
);

Title.defaultProps = {
  classTitle: 'modal-scale__title'
};

export const WellPhasesModal = ({ 
  isOpen,
  onCloseModal,
  rangeTop,
  rangeBottom,
  bottom,
  top,
  submitBitSize,
  current,
  processing,
  goPrev,
  closeWell,
  steps,
  initialBitSize,
  removeWellPhase,
  wellphases,
  loadingWellPhases,
  currentWell,
  loading
}) => {
  const rangeTopMin = currentWell && currentWell.depth && currentWell.depth.length ? currentWell.depth[0] : undefined;
  const rangeTopMax = currentWell && currentWell.depth && currentWell.depth.length ? currentWell.depth.slice(-1)[0] : undefined;
  return (
    <Modal
      isOpen={isOpen}
      style={modalStyleWellPhasesModal}
      onRequestClose={onCloseModal}
    >
      <div style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <img
          onClick={onCloseModal}
          src={removeIcon}
          className="modal-scale__close"
          data-cy="close-modal"
          alt="close modal"
        />
      </div>
      <div
        className="modal-scale__form"
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: '#fff', fontSize: 30 }}>Well Phases</h1>   
        </div>
        <div>
        <div className="bit-size__description">
          <span className="bit-size__title">
            Copy the values of bit size and then enter in the Top and Bottom inputs:
          </span>
          <BitSizeClipboard
            rangeTop={rangeTop || rangeTopMin}
            rangeBottom={rangeBottom || rangeTopMax}
            bottom={bottom}
            top={top}
          />
          </div>
          <BitSizeForm
            onSubmit={submitBitSize}
            current={current}
            processing={processing}
            goPrev={goPrev}
            closeWell={closeWell}
            steps={steps}
            loading={loading}
            loadingWellPhases={loadingWellPhases}
            initialBitSize={initialBitSize}
            removeWellPhase={removeWellPhase}
            currentWell={currentWell}
            wellphases={wellphases}
          />
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 90 }}>
          <Button style={{ background: '#333', color: '#fff', width: 120, height: 40 }}>Cancel</Button>
          <Button type="primary" style={{ width: 120, height: 40 }}>Next</Button>
        </div> */}
      </div>
    </Modal>
  );
};

export default WellPhasesModal;
