import styled, { css } from "styled-components"
import { Box } from "../../components"

export const Content = styled(Box)`
background: rgb(29, 28, 28);
border-radius: 15px;

transition: all ease-in-out 0.3s;
  &:hover {
    background: rgba(33, 31, 30, 0.955);
  }
`

export const StatusBadgeGreen = styled.span`
  ${({ status }) => css`
    position: relative;
    background-color: #54b82a22;
    color: #54b82a;
    margin-left: 5px;
    padding: 5px;
    padding-left: 20px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    border-radius: 35px;
    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 7px;
      left: 7px;
      border-radius: 100%;
      background: #54b82a;
    }
  `}
`

export const StatusBadgeRed = styled.span`
  ${({ status }) => css`
    position: relative;
    background-color: #520505;
    color: #cc441b;
    margin-left: 5px;
    padding: 5px;
    padding-left: 20px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    border-radius: 35px;
    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 7px;
      left: 7px;
      border-radius: 100%;
      background: #cc441b;
    }
  `}
`

export const StatusBadgeGray = styled.span`
  ${({ status }) => css`
    position: relative;
    background-color: #646464;
    color: #afacbd;
    margin-left: 5px;
    padding: 5px;
    padding-left: 20px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    border-radius: 35px;
    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 7px;
      left: 7px;
      border-radius: 100%;
      background: #afacbd;
    }
  `}
`

export const GridContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-template-columns: minmax(200px, 1fr);
  gap: 8px;
`

export const ItemContainer = styled(Box)`
  border-bottom: 1px solid #dbdbdb;
`

export const HeaderContainer = styled(Box)`
  &[aria-busy=false] {
    cursor: pointer;
  }
`
export const MetricsItem = styled(Box)`
    display: flex;
    align-items: center;

    padding: 10px;
    margin-bottom: 1rem;
    flex-grow: 1;
    flex-direction: column;

    border-radius: 5px;
    border: 1px dashed #323232;
    transition: all ease-in-out .3s;

    &:hover{
      background: #323232;
      border: 1px solid #323232;
    }
  
`
