import React, { useState, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import {
  nFormatter, toggleMseCurve,
  toggleWobCurve, toggleRopCurve
} from 'utils';
import { DEFAULT_SCALES } from 'utils/constants';

const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  // mse: 0,
  wob: 0,
  rop: 0
};

const initialHeader = {
  // mse: 0,
  wob: 0,
  rop: 0
};

const WOB_CURVE = 'wob';
const ROP_CURVE = 'rop';

const ChartHeader = () => {
  const wells = useSelector(state => state.wells);

  // --- Intital State
  const [headerState, setHeaderState] = useState({
    ...initialHeader,
    scales: store.get('configScales') || { ...DEFAULT_SCALES }
  });

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    // mse: true,`
    wob: true,
    rop: true
  });

  const tooggleCallbacks = {
    // mse: toggleMseCurve,
    wob: toggleWobCurve,
    rop: toggleRopCurve
  };

  const toggleTrackCurrentTrack = event => {
    const {
      dataset: { name, index }
    } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  const dispatchModal = typeOfTitle => event => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const index = wells.selectedDepthIndex || 0;

      const wobValue = wells.currentWell && wells.currentWell.wob
        ? wells.currentWell.wob[index] : 0;

      const ropValue = wells.currentWell && wells.currentWell.rop ?
        wells.currentWell.rop[index] : 0;
  
      // const mseValue = wells.currentWell && wells.currentWell.mse ?
      //   wells.currentWell.mse[index] : 0;

      setHeaderState({
        ...headerState,
        // mse: mseValue.toFixed(2),
        wob: wobValue ? wobValue.toFixed(2) : 0,
        rop: ropValue ? ropValue.toFixed(2) : 0
      });
    }
  }, [wells ]);

  useEffect(() => {
    // Scales listener
    const configListener = ({ detail }) => {
      updatedHeader.scales.mse = detail;
      headerState.scales.mse = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('mse', configListener);

    const ropConfigListener = ({ detail }) => {
      updatedHeader.scales.rop = detail;
      headerState.scales.rop = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(ROP_CURVE, ropConfigListener);

    const wobConfigListener = ({ detail }) => {
      updatedHeader.scales.wob = detail;
      headerState.scales.wob = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(WOB_CURVE, wobConfigListener);

    // ---- Remove listeners
    return () => {
      document.removeEventListener('mse', configListener);
      document.removeEventListener(ROP_CURVE, ropConfigListener);
      document.removeEventListener(WOB_CURVE, wobConfigListener);
    };
  }, []);

  const { mse, wob, rop, scales } = headerState;

  const defaultMse = scales && scales.mse ? scales.mse : {
    min: 0,
    max: 60,
    color: '#020202',
    line: '#020202',
    title: 'MSE',
    titleModal: 'MSE'
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '132px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative'
      }}
    >
      {/* <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: defaultMse.color
        }}
        onClick={dispatchModal('mse')}
        data-cy="open-config-total-gas"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
        >
          {mse ? `MSE ${mse}` : 'MSE'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.mse}
            data-name="mse"
            data-index="1"
            data-cy="checkbox-total-gas"
          />
          <span
            className="checkmark"
            style={{ backgroundColor: defaultMse.color }}
          />
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: defaultMse.color
          }}
        >
          <span data-cy="scales-totalGas-min">
            {nFormatter(defaultMse.min)}
          </span>
          <span data-cy="scales-totalGas-max">
            {nFormatter(defaultMse.max)}
          </span>
        </div>
      </div> */}
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: scales.wob.color
        }}
        onClick={dispatchModal(WOB_CURVE)}
        data-cy="open-config-total-carbon"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
          style={{
            color: scales.wob.color
          }}
        >
          {wob ? `WOB ${wob}` : 'WOB'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.wob}
            data-name="wob"
            data-index="2"
            data-cy="checkbox-total-carbon"
          />
          <span
            className="checkmark"
            style={{ backgroundColor: scales.wob.color }}
          />
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: scales.wob.color
          }}
        >
          <span data-cy="scales-total-carbon-min">
            {nFormatter(scales.wob.min)}
          </span>
          <span data-cy="scales-total-carbon-max">
            {nFormatter(scales.wob.max)}
          </span>
        </div>
      </div>
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: scales.rop.color
        }}
        onClick={dispatchModal(ROP_CURVE)}
        data-cy="open-config-c1-normalized"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
        >
          {rop ? `ROP ${rop}` : 'ROP'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.rop}
            data-name="rop"
            data-index="3"
            data-cy="checkbox-c1-normalized"
          />
          <span
            className="checkmark"
            style={{ backgroundColor: scales.rop.color }}
          />
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: scales.rop.color,
            color: scales.rop.color
          }}
        >
          <span data-cy="scales-c1-normalized-min">
            {nFormatter(scales.rop.min)}
          </span>
          <span data-cy="scales-c1-normalized-max">
            {nFormatter(scales.rop.max)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(ChartHeader);
