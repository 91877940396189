import React, { useState } from 'react';
import store from 'store';
import { useFormik } from 'formik';
import classes from 'classnames';
import Modal from 'react-modal';
import { CompactPicker } from 'react-color';
import { message } from 'antd';
import { DEFAULT_SCALES } from 'utils/constants';
import Wrapper from '../Wrapper';
import { modalStyleConfirmSettings } from 'utils';
import warningIcon from 'assets/atention.svg';

export const updateChart = (event, data) => {
  const updateScales = new CustomEvent(event, {
    detail: data
  });
  document.dispatchEvent(updateScales);
};

export const updateStorage = (curveName, newValues) => {
  const currentConfig = store.get('configScales');
  const oldConfig = currentConfig[curveName];
  store.set('configScales', {
    ...currentConfig,
    [curveName]: { ...oldConfig, ...newValues }
  });
};

export const checkGasComposition = values => {
  const errors = {};

  if (values.min < 0 || values.min > 100) {
    errors.min = true;
    message.error('Invalid value');
    return errors;
  }
  if (values.max > 100 || values.max < 0) {
    errors.max = true;
    message.error('Invalid value');
    return errors;
  }
  return errors;
};

export const validate = (typeOfCurve, values, curvesTocheck) => {
  const errors = {};
  const messageError = 'Empty value';

  if (values.min === undefined) {
    errors.min = true;
    message.error(messageError);
    return errors;
  }
  if (values.max === undefined) {
    errors.max = true;
    message.error(messageError);
    return errors;
  }
  return errors;
};

export const updateOnLocalStorage = (
  typeOfCurve,
  onClose,
  validateInputs,
  updateLocalStorage,
  updateScalesChart
) => (values, { setErrors }) => {
  const errors = validateInputs(typeOfCurve, values);
  if (!Object.keys(errors).length) {
    updateLocalStorage(typeOfCurve, values);
    updateScalesChart(typeOfCurve, values);
    onClose();
    message.success('New scale settings successfully applied!');
  } else {
    setErrors(errors);
  }
};

export const restoreData = (
  curveName,
  defaultConfig,
  setFieldValue,
  updateLocalStorage,
  updateScalesChart
) => () => {
  const scales = DEFAULT_SCALES.gradient;
  setFieldValue('min', defaultConfig.min);
  setFieldValue('max', defaultConfig.max);

  setFieldValue('pp', scales.pp);
  setFieldValue('mw', scales.mw);
  setFieldValue('ecd', scales.ecd);
  setFieldValue('fg', scales.fg);
  setFieldValue('obg', scales.obg);

  updateScalesChart('gradient', scales);
  updateLocalStorage('gradient', scales);

  message.success('Scale settings successfully restored!');
};

const Field = ({ children }) => (
  <div className="form-scale__wrapper-input">
    <div className="form-scale__box-input">{children}</div>
  </div>
);

const Label = ({ id, text, className }) => (
  <label
    htmlFor={id}
    className={classes('form-scale__label', { [className]: !!className })}
  >
    {text}
  </label>
);

const InputConfig = ({ name, onChange, value, className }) => (
  <input
    className={classes('form-scale__input', {
      [className]: !!className
    })}
    type="number"
    name={name}
    id={name}
    onChange={onChange}
    value={value}
    data-cy={`modal-input-${name}`}
  />
);

const popover = {
  position: 'absolute',
  top: '20px',
  zIndex: '2',
};
const cover = {
  position: 'relative',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
};

const ColorSelector = ({
  handleClickColor,
  handleBlurColor,
  handleChangeColor,
  color,
  activeColor
}) => (
  <div style={popover}>
    <div style={cover}>
      <div
        onClick={handleClickColor}
        className="form-scale__wrapper-color"
        style={{ backgroundColor: color }}
        data-cy="scale-color"
      />
      {activeColor ? (
        <div>
          <div
            className="form-scale__close-tooltip"
            onClick={handleBlurColor}
          />
            <CompactPicker
              color={color}
              onChange={handleChangeColor}
              onBlur={handleChangeColor}
            />
        </div>
      ) : null}
    </div>
  </div>
);

const WrapperActions = Wrapper('form-scale__group-buttons');
const WrapperSaveButtons = Wrapper('form-scale__group-save-buttons');

const ConfigActions = ({ onReset, onClose, openModal }) => (
  <WrapperActions>
    <button
      onClick={onReset}
      type="button"
      className="form-scale__restore-button"
      data-cy="reset-config"
    >
      Restore Defaults
    </button>
    <WrapperSaveButtons>
      <button onClick={onClose} type="button" className="form-scale__ok-button">
        ok
      </button>
      <button
        onClick={openModal}
        type="button"
        className="form-scale__save-button"
        data-cy="apply-config"
      >
        Apply
      </button>
    </WrapperSaveButtons>
  </WrapperActions>
);

const WrapperInfoModal = Wrapper('form-scale__info-modal');
const WrapperImage = Wrapper('form-scale__warning-image');
const WrapperButtonsConfirmModal = Wrapper('form-scale__wrapper-btn-confirm');

const ConfirmActions = ({ modalState, confirmSettings, closeModal }) => (
  <Modal
    isOpen={modalState}
    style={confirmSettings}
    onRequestClose={closeModal}
  >
    <form>
      <WrapperInfoModal>
        <WrapperImage>
          <img src={warningIcon} alt="warning" />
        </WrapperImage>
        <div>
          <h4 className="form-scale__title-confirm">
            Do you want to make these changes?
          </h4>
          <span className="form-scale__text-confirm">
            <label className="form-scale__weight">All scales </label>
            on the chromatography charts will be changed.
          </span>
        </div>
      </WrapperInfoModal>
      <WrapperButtonsConfirmModal>
        <button
          onClick={closeModal}
          type="button"
          className="form-scale__cancel-button"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="form-scale__ok-button"
          data-cy="modal-button-ok"
        >
          ok
        </button>
      </WrapperButtonsConfirmModal>
    </form>
  </Modal>
);

const Title = ({ title, className }) => (
  <h3 className={classes('title-row', { [className]: !!className })}>
    {title}
  </h3>
);

const SubTitle = ({ title }) => (
  <Title title={title} className="form-scale__subtitle" />
);

const GeopressureForm = ({ typeOfTitle, onClose }) => {
  const scales = { ...store.get('configScales') } || {
    ...DEFAULT_SCALES[typeOfTitle]
  };
  const currentCurve = scales[typeOfTitle];

  const [modalState, setModal] = useState(false);
  const [activeColor1, setActiveColor1] = useState(false);
  const [activeColor2, setActiveColor2] = useState(false);
  const [activeColor3, setActiveColor3] = useState(false);
  const [activeColor4, setActiveColor4] = useState(false);
  const [activeColor5, setActiveColor5] = useState(false);

  const closeModal = () => setModal(false);
  const openModal = () => setModal(true);

  const onSubmit = updateOnLocalStorage(
    'gradient',
    onClose,
    validate,
    updateStorage,
    updateChart
  );

  const formik = useFormik({
    initialValues: {
      min: currentCurve.min,
      max: currentCurve.max,
      pp: scales.gradient.pp,
      mw: scales.gradient.mw,
      fg: scales.gradient.fg,
      ecd: scales.gradient.ecd,
      obg: scales.gradient.obg
    },
    enableReinitialize: true,
    onSubmit
  });

  const onReset = restoreData(
    typeOfTitle,
    DEFAULT_SCALES[typeOfTitle],
    formik.setFieldValue,
    updateStorage,
    updateChart
  );

  const { handleSubmit, handleChange, values } = formik;

  const handleClickColor1 = () => setActiveColor1(true);
  const handleClickColor2 = () => setActiveColor2(true);
  const handleClickColor3 = () => setActiveColor3(true);
  const handleClickColor4 = () => setActiveColor4(true);
  const handleClickColor5 = () => setActiveColor5(true);
  
  const handleBlurColor1 = () => setActiveColor1(false);
  const handleBlurColor2 = () => setActiveColor2(false);
  const handleBlurColor3 = () => setActiveColor3(false);
  const handleBlurColor4 = () => setActiveColor4(false);
  const handleBlurColor5 = () => setActiveColor5(false);

  const handleChangeColor = (name) => ({ hex }) => {
    handleChange({
      target: {
        value: hex,
        name: name
      }
    });
  };

  return (
    <div className="form-scale form-scale__geopressure">
      <form onSubmit={handleSubmit}>
        <SubTitle title={DEFAULT_SCALES[typeOfTitle].titleModal} />
        {
          typeOfTitle === "gradient"
          ? <div className="form-scale__wrapper-inputs form-scale-settings__wrapper" style={{ minHeight: '220px' }}>
            <div className="form-scale__inputs">
              <Field>
                <Label id="min" text="Minimum" />
                <InputConfig
                  name="min"
                  id="min"
                  onChange={handleChange}
                  value={values.min}
                />
              </Field>
              <Field>
                <Label id="max" text="Maximum" />
                <InputConfig
                  name="max"
                  id="max"
                  onChange={handleChange}
                  value={values.max}
                />
              </Field>
            </div>
              <div className="scale-settings__field-color" style={{ position: 'relative' }}>
                <Field>
                  <Label id="color" text="PP" />
                  <ColorSelector
                    handleClickColor={handleClickColor1}
                    handleBlurColor={handleBlurColor1}
                    handleChangeColor={handleChangeColor('pp')}
                    color={values.pp}
                    activeColor={activeColor1}
                  />
                </Field>
                <Field>
                  <Label id="color" text="MW" />
                  <div className="wrapper-field-color">
                    <ColorSelector
                      handleClickColor={handleClickColor2}
                      handleBlurColor={handleBlurColor2}
                      handleChangeColor={handleChangeColor('mw')}
                      color={values.mw}
                      className="color-field"
                      activeColor={activeColor2}
                    />
                  </div>
                </Field>
                <Field>
                  <Label id="color" text="ECD" />
                  <ColorSelector
                    handleClickColor={handleClickColor3}
                    handleBlurColor={handleBlurColor3}
                    handleChangeColor={handleChangeColor('ecd')}
                    color={values.ecd}
                    activeColor={activeColor3}
                  />
                </Field>
                <Field>
                  <Label id="color" text="FG" />
                  <ColorSelector
                    handleClickColor={handleClickColor4}
                    handleBlurColor={handleBlurColor4}
                    handleChangeColor={handleChangeColor('fg')}
                    color={values.fg}
                    activeColor={activeColor4}
                  />
                </Field>
                <Field>
                  <Label id="color" text="OBG" />
                    <ColorSelector
                      handleClickColor={handleClickColor5}
                      handleBlurColor={handleBlurColor5}
                      handleChangeColor={handleChangeColor('obg')}
                      color={values.obg}
                      activeColor={activeColor5}
                    />
                </Field>
              </div>
            </div>
          : <div className="form-scale__wrapper-inputs">
              <Field>
                <Label id="min" text="Minimum" />
                <InputConfig
                  name="min"
                  id="min"
                  onChange={handleChange}
                  value={values.min}
                />
              </Field>
              <Field>
                <Label id="max" text="Maximum" />
                <InputConfig
                  name="max"
                  id="max"
                  onChange={handleChange}
                  value={values.max}
                />
              </Field>
              {values.color && (
                <Field>
                  <Label id="color" text="Color" />
                  <ColorSelector
                    handleClickColor={handleClickColor}
                    handleBlurColor={handleBlurColor}
                    handleChangeColor={handleChangeColor}
                    color={values.color}
                    activeColor={activeColor}
                  />
                </Field>
              )}
             </div>
            }
        
        <ConfigActions
          onReset={onReset}
          onClose={onClose}
          openModal={openModal}
        />
        <ConfirmActions
          modalState={modalState}
          confirmSettings={modalStyleConfirmSettings}
          closeModal={closeModal}
        />
      </form>
    </div>
  );
};

export default GeopressureForm;
