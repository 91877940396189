import React, { useState } from 'react';
import { Form, Input, Button, Alert, Spin } from 'antd';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import ForgotPassword from "../ForgotPassword";


const REQUIRED_MESSAGE = 'This field is required';
const TOOSHORT_MESSAGE = 'Too Short!';
const TOOLONG_MESSAGE = 'Too Long!';

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, TOOSHORT_MESSAGE)
    .max(70, TOOLONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  password: Yup.string()
    .min(2, TOOSHORT_MESSAGE)
    .max(50, TOOLONG_MESSAGE)
    .required(REQUIRED_MESSAGE)
});


const InputPlugged = (
  {
    field,
    type,
    placeholder,
    label,
    dataCy,
    form: { touched, errors }
  }
) => {
  return (
    <Form.Item 
      name={field.name}
      label={label}
      hasFeedback
      validateStatus={touched[field.name] && errors[field.name] ? 'error' : null}
    >
      {type && 'password' ? 
        <Input.Password
          { ...field }
          size="large"
          placeholder={placeholder}
          name={field.name}
          data-cy={dataCy}
        />
        :   
        <Input
          { ...field }
          type={type}
          size="large"
          placeholder={placeholder}
          name={field.name}
          data-cy={dataCy}
        /> 
      }
        
      <div className="ant-form-explain" data-cy="error-message">
        {touched[field.name] && errors[field.name] ? errors[field.name] : null}
      </div> 
    </Form.Item>
  )
};

const LoginForm = ({
  onSubmitLogin, onSubmitForgot, errorLogin,
  errorForgot, isSubmited, loading
}) => {
  const [currentForm, setForm] = useState(true);

  const toggleForm = () => {
    setForm(!currentForm);
  };

  return(
    <div 
      className="login-box"
      data-aos="fade-up"
      data-aos-delay="450"
      data-aos-duration="650"
      data-aos-easing="ease"
    >
      <Spin spinning={loading}>
        {currentForm ? (
          <Formik
            validationSchema={LoginSchema}
            initialValues = {{
              username: '',
              password: '',
              error: ''
            }}
            onSubmit={onSubmitLogin}
            render={({
              handleSubmit,
              isSubmitting
            }) => (
              <Form 
                onSubmit={handleSubmit}
                className="form-login"
              >
                {errorLogin && (
                  <Alert 
                    message={errorLogin} 
                    className="form-login__error"
                    type="error" 
                    showIcon />
                )}
                
                <Field 
                  component={InputPlugged}
                  label="Username"
                  name="username"
                  placeholder="Username"
                  dataCy="email-input"
                />

                <Field
                  component={InputPlugged}
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="Password"
                  dataCy="password-input"
                />
                
                <div className="form-login__btn-wrapper">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-submit cy-login-button"
                    disabled={isSubmitting}
                    data-cy="login-button"
                  >
                    LOG IN
                  </Button>
                </div>
              </Form>
            )}
          />
        ):(
          <ForgotPassword
            onSubmitForgot={onSubmitForgot}
            errorForgot={errorForgot}
            backToLogin={toggleForm}
            isSubmited={isSubmited}
          />
        )}
      </Spin>
    </div>
  );
}

export default LoginForm;
