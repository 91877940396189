import React from 'react'

import Loading from './Loading'
import { Box, Text, Button } from '..'

import ExperimentItem from '../../Models/Register/ExperimentItem';
import usePredictedExperiments from '../../hooks/usePredictedExperiments'

const ListExperiments = ({ onBackHome }) => {
  const { experiments, isLoading } = usePredictedExperiments()

  if (isLoading) return <Loading />

  if (!experiments || !Object.keys(experiments).length) return <p>sem resultados para exibir.</p>

  return (
    <Box className='experiments-container' padding="10px" >
      <Box display="flex" justifyContent="space-between" mt="1rem" mb="1rem">
        <Text variant="title">Your Experiments</Text>
        <Button onClick={onBackHome}>Return</Button>
        {/* <Button variant="outlined" onClick={openTrainModel}>+ Train New Model</Button> */}
      </Box>
      <Box>
        {Object.keys(experiments).map((experimentName) => (
          <ExperimentItem
            name={experimentName}
            experimentData={experiments[experimentName]}
          />
        ))}
      </Box>
    </Box>
  )
}

export default ListExperiments
