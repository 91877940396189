import {
  ADD_ZONE, DELETE_ZONE, UPDATE_ZONE,
  RECEIVE_ZONES, DELETE_ALL,
} from '../constants/zone';

export const createZone = zone => ({
  type: ADD_ZONE,
  zone,
});

export const deleteZone = zoneId => ({
  type: DELETE_ZONE,
  zoneId,
});

export const deleteAll = () => ({
  type: DELETE_ALL,
});

export const updateZone = zone => ({
  type: UPDATE_ZONE,
  zone,
});

export const receiveZones = zones => ({
  type: RECEIVE_ZONES,
  zones,
});
