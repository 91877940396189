import React from 'react';
import classNames from 'classnames';
import {
  Button, Steps, Input, Switch
} from 'antd';
import {
  defaultMnemonicsGeopress, defaultMnemonicsDensity
} from 'utils/constants';
import BitSizeClipboard from 'components/BitSizeClipboard';
import BitSizeForm from 'components/BitSize/BitSizeForm';
import SelectMnemonicSensorType from 'components/SelectMnemonic/SelectMnemonicSensorType';
import SelectMnemonic from 'components/SelectMnemonic';
import Spinner from 'components/Spinner';

const geopressionsSteps = [
  {
    title: 'Well Phases',
    content: ({
      curvesKeys,
      processing,
      current,
      submitBitSize,
      goPrevBitSizes,
      closeWell,
      steps, 
      bitSizes, 
      rangeTop,
      rangeBottom,
      top,
      bottom,
      selectMnemnonics,
      errorCurves,
      onChangeOverburden,
      activeOverburden,
      sensorOptions
    }) => {
      return (
        <div className="home__modal-container">
          <div className="wrapper-header-modal">
            <h1 className="well-phase__title" data-cy="well-phases-title">
              Well Phases
            </h1>
            <div className="bit-size__description">
              <span className="bit-size__title">
                Copy the values of bit size and then enter in the Top and Bottom inputs:
              </span>
              <BitSizeClipboard
                rangeTop={rangeTop}
                rangeBottom={rangeBottom}
                bottom={bottom}
                top={top}
              />
            </div>
          </div>
          <BitSizeForm
            onSubmit={submitBitSize}
            current={current}
            processing={processing}
            goPrev={goPrevBitSizes}
            closeWell={closeWell}
            steps={steps}
            initialBitSize={bitSizes}
            isGeopressConfig={true}
          />
        </div>
      );
    }
  },
  {
    title: 'Match Curves',
    content: ({
      curvesKeys,
      processing,
      current,
      submitBitSize,
      goPrevBitSizes,
      closeWell,
      steps, 
      bitSizes, 
      rangeTop,
      rangeBottom,
      top,
      bottom,
      selectMnemnonics,
      errorCurves,
      onChangeOverburden,
      activeOverburden,
      refactorConstants,
      sensorOptions
    }) => {
      return (
        <div>
          <div className="home__modal-content">
            {Object.keys(defaultMnemonicsGeopress).map((field, index) => {
              return (
                <div className={classNames({
                  'geopressure-field': errorCurves[field]
                })}>
                  <SelectMnemonic
                    key={index}
                    value={curvesKeys[field]}
                    label="Select a mnemonic"
                    name={field}
                    className={field}
                    onChange={selectMnemnonics(field)}
                    label={refactorConstants(field)}
                    id={field}
                    options={sensorOptions}
                  />
                  {errorCurves[field] && (
                    <div
                      className="error-geopressure-curves"
                      data-cy={`${field}-error`}
                    >
                      {errorCurves[field]}
                    </div>
                  )}
                </div>
              );
            })}
            <div className="wrapper-inputs-match-curves">
              <div className="wrapper-pressure">
                <label>N. PRESSURE GRADIENT</label>
                <Input
                  type="number"
                  className="pressure__input"
                  value={curvesKeys.normalPressureGradient}
                  onChange={(event) => {
                    const { value } = event.target;
                    selectMnemnonics('normalPressureGradient')(value);
                  }}
                />
              </div>
              {errorCurves.normalPressureGradient && (
                <div
                  className="error-geopressure-curves"
                >
                  {errorCurves.normalPressureGradient}
                </div>
              )}
            </div>
            <div className="wrapper-overburden">
              <label
                className="label-overburden"
                onClick={onChangeOverburden}
              >
                Don't have Overburden gradient input?
              </label>
            </div>
            {activeOverburden &&
              <div className="wrapper-options-overburden">
                <label>
                  First option - Calculating from the density curve
                </label>  
                <div className="wrapper-select-density">                 
                  {Object.keys(defaultMnemonicsDensity).map((field, index) => {
                    return (
                      <>
                        <SelectMnemonicSensorType
                          key={index}
                          field={field}
                          value={curvesKeys[field]}
                          label="Select a mnemonic"
                          className={field}
                          onChange={selectMnemnonics(field)}
                          label={field}
                          id={field}
                          options={sensorOptions}
                        />
                        {errorCurves[field] && (
                          <span
                            className="error-curves"
                            data-cy={`${field}-error`}
                          >
                            {errorCurves[field]}
                          </span>
                        )}
                      </>
                    )
                  })}
                  <div className="wrapper-water-depth">
                    <label>Water Depth</label>
                    <Input
                      type="number"
                      className="water-depth__input pressure__input"
                      value={curvesKeys.waterDepth}
                      onChange={(event) => {
                        const { value } = event.target;
                        selectMnemnonics('waterDepth')(value);
                      }}
                    />
                  </div>
                  {errorCurves.waterDepth && (
                    <span
                      className="error-curves"
                    >
                      {errorCurves.waterDepth}
                    </span>
                  )}
                </div>
                {/*
                <label>
                  Second option - Loading a default curve for OBG from LAPA data
                </label>
                <div className="wrapper-default-curve">
                  <label className="label-default-curve">Do you want to use a default curve?</label>
                  <div className="switch-component">
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      defaultChecked={false}
                    />
                  </div>
                </div>*/}
              </div>
            }
          </div>
          {processing && (
            <p className="geopressure-process-message">Calculating and saving geopressure data...</p>
          )}
          {processing && (
            <div className="charts-container__spinner">
              <Spinner />
            </div>
          )}
        </div>
      );
    }
  },
];

const { Step } = Steps;

const CreateGeopressions = ({
  current, closeWell,
  curvesKeys, processing, submitBitSize,
  goPrevBitSizes, currentWell, prev,
  startProcess, rangeTop, rangeBottom,
  top, bottom,
  selectMnemnonics,
  errorCurves,
  onChangeOverburden,
  activeOverburden,
  refactorConstants,
  sensorOptions
}) => {
  return (
    <>
      <div>
        <Steps current={current} data-cy="home__steps">
          {geopressionsSteps.map(item => (
            <Step
              key={item.title}
              title={item.title}
            />
          ))}
        </Steps>
      </div>
      <div className="steps-content">
        {current < geopressionsSteps.length && geopressionsSteps[current].content({
          curvesKeys,
          processing,
          current,
          submitBitSize,
          goPrevBitSizes,
          closeWell,
          steps: geopressionsSteps,
          bitSizes: currentWell ? currentWell.geopressureBitSize : null,
          rangeTop,
          rangeBottom,
          top,
          bottom,
          selectMnemnonics,
          errorCurves,
          onChangeOverburden,
          activeOverburden,
          refactorConstants,
          sensorOptions
        })}
        {geopressionsSteps.length - 1 && (
          <div>
            {processing && (
              <div className="charts-container__spinner">
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="steps-action">
        {current < geopressionsSteps.length && current === 1 && (
          <Button
            onClick={closeWell}
            className="cy-btn-cancel"
          >
            Cancel
          </Button>
        )}
        {current !== 0 && !processing && current === 1 && (
          <Button
            onClick={prev}
            className="cy-btn-previous btn-step-geopress"
          >
            Previous
          </Button>
        )}
        {current === 1 && current !== 0 && (
          <Button
            type="primary"
            onClick={startProcess}
            className="cy-btn-apply btn-step-geopress"
            disabled={processing}
          >
            Apply
          </Button>
        )}
      </div>
    </>
  );
};

export default CreateGeopressions;
