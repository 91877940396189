import React, {
  memo,
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import { realTimeLithoDataFormatter } from 'utils';

import { ABBREVS_ROCKS } from '../../../../utils/constants';

const ChartHeader = ({ lithoData, title, depth }) => {
  const wells = useSelector(state => state.wells);
  const [rockValues, setRockValues] = useState([]);
  const newLitho = realTimeLithoDataFormatter(lithoData, depth);
  
  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0 && wells.selectedDepthIndex != null) {
      const rockNames = Object.keys(newLitho);
      const currentDepth = depth[wells.selectedDepthIndex];
      const lithologyData = rockNames.map(rockName => {
        const currentRockData = lithoData[ABBREVS_ROCKS[rockName]];
        const indexDepthLitho = depth.findIndex((element) => element === currentDepth);
        const valueByDepth = indexDepthLitho != -1 ? currentRockData[indexDepthLitho] : 0;
        
        return {
          name: rockName,
          value: valueByDepth ? valueByDepth : 0,
          abbreviation: ABBREVS_ROCKS[rockName]
        };
      });
      setRockValues(lithologyData);
    }
  }, [wells]);


  const getTooltipValues = (tooltipValues) => {
    return tooltipValues.reduce((acc, rockValue) => {
      if (rockValue.value) { 
        acc.push(
          <div className="real-time-litho-tooltip">
            {rockValue.abbreviation}: {rockValue.value}%
          </div>
        );
      }
      return acc;
    }, [])
  };

  const rockTooltipValues = getTooltipValues(rockValues);
  return (
    <div
      className="real-time-litho-header"
    >
      {!wells.selectedDepthIndex || !rockTooltipValues.length ? (
        <div className="real-time-litho-title">
          {title}
        </div>
      ) : rockTooltipValues}
      <div className="real-time-litho-scales">
        <div className='real-time-litho-scales-numbers'>
          <span>0</span>
          <span>100</span>
        </div>
      </div>
    </div>
  );
}

export default memo(ChartHeader);
