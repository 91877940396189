import React, { useContext, useEffect, useState } from "react";
import Fieldset from "../../Fieldset";
import InputNumber from "../../InputNumber";
import InputRow from "../../InputRow";
import { Button, Icon, Switch, Tooltip } from "antd";

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { wellPhasesInitialValues, wellPhasesSchema } from "./payload";
import { SettingsFormContext } from "..";
import useHydraulicsContext from "../../../../new/Hydraulics/useHydraulics";
import InputDrillString from "./InputDrillString";

const WellPhasesForm = ({ nextStep, dataPhases, setDataPhases }) => {
    const methods = useForm({
        defaultValues: dataPhases ? dataPhases : wellPhasesInitialValues,
        resolver: yupResolver(wellPhasesSchema),
    });
    const { handleSubmit, setValue, getValues, control, formState: { errors, isSubmitSuccessful } } = methods
    const { onCancel, wellPhasesDetails, setWellPhasesDetails } = useContext(SettingsFormContext);
    const { unitsConfigDetails } = useHydraulicsContext()

    function goToNextStep(data) {
        setDataPhases(data)
        setWellPhasesDetails(data)
        if (data != null) {
            nextStep(1)
        }
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(goToNextStep)}>
                    <Fieldset label={'Drill String'}>
                          <InputDrillString name={'pipes'} control={control} errors={errors.pipes} />
                    </Fieldset>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            htmlType={'button'}
                            onClick={onCancel}
                            style={{ color: 'red' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType={'submit'}
                        >Next</Button>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default WellPhasesForm;