import store from 'store';
import { disableDots } from 'utils';

export const createDefaultConfig = (
  scales,
  displayZoomBtn,
  propagateZoom,
  getToolTipValues,
  getDefaultSeries,
  defaultCrossHair,
  mlPorosity,
  depth
) => ({
  chart: {
    inverted: true,
    width: 160,
    marginTop: 0,
    margin: 0,
    zoomType: 'x',
    events: {
      selection: event => {
        displayZoomBtn();
        propagateZoom(event, depth);
      }
    },
    animation: false,
    shadow: false
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    allowDecimals: false,
    labels: {
      enabled: false
    },
    tickPosition: 'inside',
    tickColor: '#fff',
    gridLineColor: '#a4a4a4',
    gridLineWidth: 1,
    minorTickInterval: 1,
    zIndex: 800
  },
  yAxis: [
    {
      title: {
        text: ''
      },
      padding: 0,
      margin: 0,
      minorGridLineWidth: 1,
      minorGridLineColor: '#E0E0E0',
      tickStart: 0,
      type: 'linear',
      minorTickInterval: 10,
      tickInterval: 10,
      min: scales && scales.mlPorosity ? scales.mlPorosity.min : 0.1,
      max: scales && scales.mlPorosity ? scales.mlPorosity.max : 100,
      color: 'rgba(0, 0, 0, 0)',
      index: 0,
      gridLineWidth: 1,
      zIndex: 1,
      step: true,
      allowDecimals: true,
      labels: {
        enabled: false
      }
    },
    {
      title: {
        text: ''
      },
      type: 'linear',
      minorTickInterval: 10,
      tickInterval: 10,
      min: scales.mlPorosity.min || 0.1,
      max: scales.mlPorosity.max || 100,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      color: scales.mlPorosity.color || '#272BFF',
      index: 1,
      allowDecimals: true,
      labels: {
        enabled: false
      },
      gridLineWidth: 1,
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      zIndex: 10,
      step: true
    }
  ],
  plotOptions: {
    series: {
      marker: {
        enabled: false
      },
      enableMouseTracking: false,
      showInLegend: false,
      hover: false
    }
  },
  exporting: { enabled: false },
  tooltip: {
    shared: true,
    ...defaultCrossHair,
    formatter: getToolTipValues('mlPorosity')
  },
  series: getDefaultSeries(mlPorosity)
});

export const getDefaultSeries = (grPredictionData) => {
 const { mlPorosity } = store.get('configScales');
  return [
    {
      type: 'spline',
      ...disableDots,
      name: 'grPlaceholder',
      data: grPredictionData,
      color: 'rgba(0, 0, 0, 0)',
      min: mlPorosity.min,
      max: mlPorosity.max,
      index: 0,
      yAxis: 0,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'mlPorosity',
      data: grPredictionData,
      color: mlPorosity.color,
      lineColor: mlPorosity.color,
      lineWidth: 1.3,
      min: mlPorosity.min,
      max: mlPorosity.max,
      index: 1,
      yAxis: 1,
      allowDecimals: true
    },
  ];
};
