import React, { useState } from 'react'

import { Box } from '..'
import Image from '../Image'
import Label from '../Text'
import Options from './Options/Options'
import { Value, ValueWrapper } from './Select.styles'

const Select = ({ id, options, onSelect, label, disabled }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [selected, setSelected] = useState('')

	const handleSelect = (selectedId) => {
		const selectedValue = options.find((opt) => opt.id === selectedId)

		setSelected(selectedValue.label)
		onSelect(selectedValue)
		setIsOpen(false)
	}

	return (
		<Box flex="1" position="relative" id={id}>
      {label && <Label as="label" variant="label">{label}</Label>}
			<ValueWrapper disabled={disabled} onClick={!disabled ? () => setIsOpen(!isOpen) : null}>
				<Value>{selected}</Value>
				<Image
					name={isOpen ? 'arrowUp' : 'arrowDown'}
					width={13}
					height={13}
					alt="arrow-down"
				/>
			</ValueWrapper>
			{options.length ? (
				<Options data={options} isOpen={isOpen} onSelect={handleSelect} />
			) : null}
		</Box>
	)
}

export default Select
