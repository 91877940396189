import styled from 'styled-components'

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  color: ${({ theme}) => theme.colors.dark['500']};
  border-radius: 8px;
  cursor: pointer;
  border: none;
  background-color: #646464;
  padding-right: .5rem;

  span {
    padding: 0 .2rem;
  }

  &:not(:first-child) {
    margin-left: .5rem;
  }
`