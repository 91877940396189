import React, {
  memo,
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import store from 'store';
import {
  nFormatter,
  toggleAiGrCurve,
  toggleGrCurve
} from 'utils';
import { DEFAULT_SCALES } from 'utils/constants';

const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  aiGrPrediction: 0,
  gammaRay: 0
};

const initialHeader = {
  aiGrPrediction: 0,
  gammaRay: 0
};

const AI_GR_PREDICTION = 'aiGrPrediction';
const GAMMA_RAY = 'gammaRay';

const ChartHeader = () => {
  const wells = useSelector(state => state.wells);

  // --- Intital State
  const [headerState, setHeaderState] = useState({
    ...initialHeader,
    scales: store.get('configScales') || { ...DEFAULT_SCALES }
  });

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    aiGrPrediction: true,
    gammaRay: true
  });

  const tooggleCallbacks = {
    aiGrPrediction: toggleAiGrCurve,
    gammaRay: toggleGrCurve
  };

  const toggleTrackCurrentTrack = event => {
    const {
      dataset: { name, index }
    } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  const dispatchModal = typeOfTitle => event => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const index = wells.selectedDepthIndex || 0;

      const aiGrValue = wells.currentWell
        && wells.currentWell.aiGammaRay  
        ? wells.currentWell.aiGammaRay[index] : 0;

      const gammaRayValue = wells.currentWell && wells.currentWell.gamma_ray
        ? wells.currentWell.gamma_ray[index] : 0;

      setHeaderState({
        ...headerState,
        aiGrPrediction: aiGrValue > 0 ? aiGrValue.toFixed(2) : aiGrValue,
        gammaRay: gammaRayValue > 0 ? gammaRayValue.toFixed(2) : gammaRayValue,
      });
    }
  }, [wells]);

  useEffect(() => {
    // Scales listener
    const gammaRayConfigListener = ({ detail }) => {
      updatedHeader.scales.gammaRay = detail;
      headerState.scales.gammaRay = detail;

      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(GAMMA_RAY, gammaRayConfigListener);

    const aiGrConfigListener = ({ detail }) => {
      updatedHeader.scales.aiGrPrediction = detail;
      headerState.scales.aiGrPrediction = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener(AI_GR_PREDICTION, aiGrConfigListener);

    // ---- Remove listeners
    return () => {
      document.removeEventListener(GAMMA_RAY, gammaRayConfigListener);
      document.removeEventListener(AI_GR_PREDICTION, aiGrConfigListener);
    };
  }, []);

  const { aiGr, gammaRay, scales } = headerState;

  return (
    <div
      style={{
        display: 'flex',
        height: '132px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative'
      }}
    >
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: scales.aiGrPrediction.color
        }}
        onClick={dispatchModal(AI_GR_PREDICTION)}
        data-cy="open-config-total-carbon"
      >
        <div
          className="total-gas-header__title"
          data-name="aiGr"
          data-cmd="openConfig"
          style={{
            color: scales.aiGrPrediction.color
          }}
        >
          {aiGr ? `AI GR Prediction ${aiGr}` : 'AI GR Prediction'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.aiGr}
            data-name="aiGr"
            data-index="1"
            data-cy="checkbox-total-carbon"
          />
          <span
            className="checkmark"
            style={{ backgroundColor: scales.aiGrPrediction.color }}
          />
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: scales.aiGrPrediction.color
          }}
        >
          <span data-cy="scales-total-carbon-min">
            {nFormatter(scales.aiGrPrediction.min)}
          </span>
          <span data-cy="scales-total-carbon-max">
            {nFormatter(scales.aiGrPrediction.max)}
          </span>
        </div>
      </div>
      <div
        className="total-gas-header"
        data-name="gammaRay"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: scales.gammaRay.color
        }}
        onClick={dispatchModal(GAMMA_RAY)}
        data-cy="open-config-c1-normalized"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
        >
          {gammaRay ? `GR ${gammaRay}` : 'GR'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.gammaRay}
            data-name="gammaRay"
            data-index="2"
            data-cy="checkbox-c1-normalized"
          />
          <span
            className="checkmark"
            style={{ backgroundColor: scales.gammaRay.color }}
          />
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: scales.gammaRay.color,
            color: scales.gammaRay.color
          }}
        >
          <span data-cy="scales-c1-normalized-min">
            {nFormatter(scales.gammaRay.min)}
          </span>
          <span data-cy="scales-c1-normalized-max">
            {nFormatter(scales.gammaRay.max)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(ChartHeader);
