/* eslint-disable */
import React, { useState, useEffect, memo } from 'react';
import uuid from 'uuid/v1';
import store from 'store';
import NewWindow from 'react-new-window';
import {
  defaultCrossPlotScales, calculateGeochemistryChart2
} from '../utils';

// TODO create unit tests to these functions
/*
*  BalanceChart2
*  y: (C4 + C5) / (C1 + C2)
*  x: (C1 + C2) / C3
*/
export const calculateBalanceChart2 = (c1, c2, c3, c4, c5) => {
  const x = (c1 + c2) / c3;
  const y = (c4 + c5) / (c1 + c2);
  return [
    isNaN(x) ? 0 : x,
    isNaN(y) ? 0 : y
  ];
};

/*
*  BalanceChart4
*  y: (C1 / C3)
*  x: C1
*/
export const calculateBalanceChart4 = (c1, c3) => {
  return [
    c1,
    c1 === 0 || c3 === 0 ? 0 : (c1 / c3)
  ];
};

/*
*  BalanceChart5
*  y: (C1 / C3)
*  x: C1 / (C4 + C5) */
export const calculateBalanceChart5 = (c1, c3, c4, c5) => {
  return [
    c1 === 0 || c3 === 0 || c4 === 0 || c5 === 0 ? 0 : c1 / (c4 + c5),
    c1 === 0 || c3 === 0 ? 0 : (c1 / c3)
  ];
};


export const calculateBioDegradationChart1 = (c1, c2, depth) => {
  const x = isNaN(c1 / c2) ? 0 : c1 / c2;
  return [x, depth];
};

export const calculateC1C2ByC3Bio = (c1, c2, c3, defaultNull = 0.0001) => {
  const x = isNaN(c2 / c3) ? defaultNull : c2 / c3;
  const y = isNaN(c1 / c2) ? defaultNull : c1 / c2;

  return [x, y];
};

function calculatePPMToCarbonComposition(valuePPM, totalCarbon) {
  return valuePPM === 0 || totalCarbon === 0 ? 0 : (valuePPM / totalCarbon) * 100;
}

function calculateC2Fid(sensorType, c2s, c2PPM, c2H4, c2H6) {
  const isAValidC2s = (c2s - c2PPM) > -10 && c2PPM !== 0;
  if (sensorType) {
    return c2H6 / (c2H6 + c2H4) * c2s;
  } if (c2s - c2PPM > -10 && c2PPM !== 0) {
    return c2PPM;
  } if (isAValidC2s) {
    return c2PPM;
  }
  // when there is no fid use c2PPM
  return c2PPM;
}

// TODO: improve the variables names
export const generateGeochemistryCharts = (defaultValue = -1, min, max, data, curvesKeys, sensorType) => {
  const {
    c1Composition, c2Composition, c3Composition,
    nc4Composition, nc5Composition, c1, c2, c3, ic4, ic5, ic4Normal, ic5Normal, nc4, nc5,
    ic5BynC5, ic4BynC4, depth, totalCarbon, c2fid, totalCarbonOnly, c2h6, c2h4, c2s, c1Byc2,
    slowFactor
  } = data;

  const c1PPM = c1 ? c1.slice(min, max) : [];
  const c2PPM = c2 && c2.length > 0 ? c2.slice(min, max) : [];
  const c3PPM = c3 && c3.length > 0 ? c3.slice(min, max) : [];
  const ic4PPM = ic4 && ic4.length > 0 ? ic4.slice(min, max) : [];
  const ic5PMM = ic5 && ic5.length > 0 ? ic5.slice(min, max) : [];
  const ic4NormalPPM = ic4Normal && ic4Normal.length > 0 ? ic4Normal.slice(min, max) : [];
  const ic5NormalPPM = ic5Normal && ic5Normal.length > 0 ? ic5Normal.slice(min, max) : [];
  const nc4Value = nc4 && nc4.length > 0 ? nc4.slice(min, max) : [];
  const nc5Value = nc5 && nc5.length > 0 ? nc5.slice(min, max) : [];

  const c1CompByC2Comp = c1Byc2 && c1Byc2.length ? c1Byc2.slice(min, max) : [];

  const c3Comp = c3Composition ? c3Composition.slice(min, max) : [];
  const c4Comp = nc4Composition ? nc4Composition.slice(min, max) : [];
  const c5Comp = nc5Composition ? nc5Composition.slice(min, max) : [];

  const iC5Data = ic5BynC5 && ic5BynC5.length > 0 ? ic5BynC5.slice(min, max) : [];
  const iC4Data = ic4BynC4 && ic4BynC4.length > 0 ? ic4BynC4.slice(min, max) : [];
  const totalDepth = depth ? depth.slice(min, max) : [];

  const chart1 = c1PPM.reduce((acc, currentC1, index) => {
    const currentDepth = totalDepth[index];
    const currentC2 = c2PPM[index];
    const c1ByC2 = isNaN(currentC1 / currentC2) || currentC2 === 0
      ? defaultValue : (currentC1 / currentC2);
    const y = currentDepth;
    const x = c1ByC2;
    acc.items.push([x, y]);
    acc.itemsWithDepth.push([x, y]);
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart2 = c1PPM.reduce((acc, currentC1, index) => {
    const currentDepth = totalDepth[index];
    const currentC2 = c2PPM[index];
    const currentC3 = c3PPM[index];
    const currentIc4 = ic4NormalPPM[index];
    const currentIc5 = ic5NormalPPM[index];
    const currentNc5 = nc5Value[index];
    const currentNc4 = nc4Value[index];
    const slopeFactor = slowFactor[index];
    acc.items.push([slopeFactor, currentDepth]);
    acc.itemsWithDepth.push([slopeFactor, currentDepth]);
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart3 = totalDepth.reduce((acc, currentDepth, index) => {
    const c1Byc2Value = c1CompByC2Comp[index] ? c1CompByC2Comp[index] : 0;
    const currentC1 = c1PPM[index];
    const currentC2 = c2PPM[index];
    const currentC3 = c3PPM[index];
    const currentIc4 = ic4NormalPPM[index];
    const currentIc5 = ic5NormalPPM[index];
    const currentNc5 = nc5Value[index];
    const currentNc4 = nc4Value[index];

    // slope factor
    const [slopeFactor] = calculateGeochemistryChart2(
      currentC1, currentC2, currentC3, currentIc4,
      currentIc5, currentNc4, currentNc5, currentDepth
    );

    const x = c1Byc2Value; 
    const y = slopeFactor;

    acc.items.push([x, y]);
    acc.itemsWithDepth.push([currentDepth]);
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart7 = c1.reduce((acc, currentC1, index) => {
    let item = [];
    const currentDepth = totalDepth[index];
    const currentC2 = c2PPM[index];
    const currentC3 = c3[index];
    item = calculateC1C2ByC3Bio(
      currentC1, currentC2, currentC3, 0
    );
    acc.items.push(item);
    acc.itemsWithDepth.push(item);
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart8 = c1PPM.reduce((acc, currentC1, index) => {
    const currentDepth = totalDepth[index];
    const currentC3PPM = c3PPM && c3PPM[index] ? c3PPM[index] : 0;
    let currentIc4PPM = 0;
    if (ic4PPM && ic4PPM[index] && ic4PPM[index].length) {
      currentIc4PPM = ic4PPM[index][1][0];
    } else if (!ic4PPM[index].length && ic4PPM) {
      currentIc4PPM = ic4PPM[index];
    }

    const currentNc4PPM = nc4Value && nc4Value[index] ? nc4Value[index] : 0;
    let currentIc5PPM = 0;
    if (ic5PMM && ic5PMM[index] && ic5PMM[index].length) {
      currentIc5PPM = ic5PMM[index][1][0];
    } else if (!ic5PMM[index].length && ic5PMM) {
      currentIc5PPM = ic5PMM[index];
    }

    const currentNc5PPM = nc5Value && nc5Value[index] ? nc5Value[index] : 0;
    const currentTotalCarbon = totalCarbonOnly && totalCarbonOnly[index] ? totalCarbonOnly[index] : 0;
    const currentC2s = c2s && c2s[index] ? c2s[index] : 0;
    const currentC2PPM = c2PPM && c2PPM[index] ? c2PPM[index] : 0;
    const currentC2H4 = c2h4 && c2h4[index] ? c2h4[index] : 0;
    const currentC2H6 = c2h6 && c2h6[index] ? c2h6[index] : 0;

    const c2Fid = calculateC2Fid(sensorType, currentC2s, currentC2PPM, currentC2H4, currentC2H6);

    const c1Carbon = calculatePPMToCarbonComposition(currentC1, currentTotalCarbon);
    const c2Carbon = calculatePPMToCarbonComposition(c2Fid, currentTotalCarbon);
    const c3Carbon = calculatePPMToCarbonComposition(currentC3PPM, currentTotalCarbon);
    const ic4Carbon = calculatePPMToCarbonComposition(currentIc4PPM, currentTotalCarbon);
    const nc4Carbon = calculatePPMToCarbonComposition(currentNc4PPM, currentTotalCarbon);
    const ic5Carbon = calculatePPMToCarbonComposition(currentIc5PPM, currentTotalCarbon);
    const nc5Carbon = calculatePPMToCarbonComposition(currentNc5PPM, currentTotalCarbon);

    const c2CompToBiodegradation = (c2Carbon * 100) / (c1Carbon + c2Carbon + c3Carbon + ic4Carbon + nc4Carbon + ic5Carbon + nc5Carbon);
    const c3CompToBiodegradation = (c3Carbon * 100) / (c1Carbon + c2Carbon + c3Carbon + ic4Carbon + nc4Carbon + ic5Carbon + nc5Carbon);

    const y = c2CompToBiodegradation / c3CompToBiodegradation;

    const x = c2Carbon / ic4Carbon;

    acc.items.push([x, y]);
    acc.itemsWithDepth.push([x, y]);

    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart9 = totalDepth.reduce((acc, currentDepth, index) => {
    const currentIc5 = ic5NormalPPM[index] ? ic5NormalPPM[index] : 0
    const currentNc5 = nc5Value[index] ? nc5Value[index] : 0;

    const currentC5 = currentIc5 === 0 || currentNc5 === 0 ? 0 : currentIc5 / currentNc5;

    acc.items.push([currentC5, currentDepth]);
    acc.itemsWithDepth.push([currentC5, currentDepth]);
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart10 = totalDepth.reduce((acc, currentDepth, index) => {
    const ic4 = ic4NormalPPM[index] ? ic4NormalPPM[index] : 0;
    const nc4 = nc4Value[index] ? nc4Value[index] : 0;
    
    const currentC4 = ic4 === 0 || nc4 === 0 ? 0 : ic4 / nc4;

    acc.items.push([currentC4, currentDepth]);
    acc.itemsWithDepth.push([currentC4, currentDepth]);
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart11 = c1.reduce((acc, currentC1, index) => {
    acc.items.push(currentC1);
    const currentDepth = totalDepth[index];
    acc.itemsWithDepth.push([currentC1, currentDepth]);
    return acc;
  }, { items: [], itemsWithDepth: [] });

  return {
    chart1,
    chart2,
    chart3,
    chart4: { items: [], itemsWithDepth: [] },
    chart5: { items: [], itemsWithDepth: [] },
    chart6: [],
    chart7,
    chart8,
    chart9,
    chart10,
    chart11
  };
};

export const generateBalanceCrossplots = (type, min, max, data, curvesKeys) => {
  const {
    c1Composition, c2Composition, c3Composition,
    nc4Composition, nc5Composition, balanceRatioData,
    wetnessRatioData
  } = data;

  const c1 = c1Composition ? c1Composition.slice(min, max) : [];
  const c2 = c2Composition ? c2Composition.slice(min, max) : [];
  const c3 = c3Composition ? c3Composition.slice(min, max) : [];
  const c3PPM = data.c3 ? data.c3.slice(min, max) : [];
  const c4 = nc4Composition ? nc4Composition.slice(min, max) : [];
  const c5 = nc5Composition ? nc5Composition.slice(min, max) : [];

  const balance = balanceRatioData ? balanceRatioData.slice(min, max) : [];
  const wetness = wetnessRatioData ? wetnessRatioData.slice(min, max) : [];

  // TODO refactor to use just one reduce loop
  // const chart2 = calculateChart2(c1, c2, c3, c4, c5);
  const chart2 = c1.reduce((acc, currentC1, index) => {
    const {depth} = data;
    const totalDepth = depth ? depth.slice(min, max) : [];

    let item = [];
    const currentC2 = c2[index];
    const currentC3 = c3[index];
    const currentC4 = c4[index];
    const currentC5 = c5[index];

    item = calculateBalanceChart2(
      currentC1, currentC2, currentC3, currentC4, currentC5
    );

    if (item[0] && item[1]) {
      acc.items.push(item);
      acc.itemsWithDepth.push({
        depth: totalDepth[index],
        item,
        c1: currentC1,
        c2: currentC2,
        c3: currentC3,
        c4: currentC4,
        c5: currentC5
      });
    }

    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart3 = balance.reduce((acc, currentBalance, index) => {
    const currentWetness = wetness[index];
    const {depth} = data;
    const totalDepth = depth ? depth.slice(min, max) : [];

    const item = [
      currentBalance,
      currentWetness
    ];

    if (item[0] && item[1]) {
      acc.items.push(item);
      acc.itemsWithDepth.push({
        depth: totalDepth[index],
        item,
        balance: item[0],
        wetness: item[1]
      });
    }
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart4 = c1.reduce((acc, currentC1, index) => {
    let item = [];
    const currentC3 = c3[index];
    const {depth} = data;
    const totalDepth = depth ? depth.slice(min, max) : [];

    item = calculateBalanceChart4(
      currentC1, currentC3
    );

    if (item[0] && item[1]) {
      acc.items.push(item);
      acc.itemsWithDepth.push({
        depth: totalDepth[index],
        item,
        c1: item[0],
        c1c3: item[1]
      });
    }
    return acc;
  }, { items: [], itemsWithDepth: [] });

  // ScatterPlotC1C45
  const chart5 = c1.reduce((acc, currentC1, index) => {
    let item = [];
    const currentC3 = c3[index];
    const currentC4 = c4[index];
    const currentC5 = c5[index];
    const {depth} = data;
    const totalDepth = depth ? depth.slice(min, max) : [];
    item = calculateBalanceChart5(
      currentC1, currentC3, currentC4, currentC5
    );

    if (item[0] && item[1]) {
      acc.items.push(item);
      acc.itemsWithDepth.push({
        depth: totalDepth[index],
        item,
        c1: currentC1,
        c1c45: item[0],
        c1c3: item[1]
      });
    }

    return acc;
  }, { items: [], itemsWithDepth: [] });
  return {
    chart2,
    chart3,
    chart4,
    chart5
  };
};

/*
* 
* (Pipeline)
*
* 1. Chart- When select a area in reason or balance chart the event is
*    dispatched with the min and the max ranges selected.
*    
* 2. PlotBox - Access the data, cut the array, apply reasons for while
*    on localStorage for this time.
*
* 3. I will redirect to the crossplots page with the current selection id
*
* 3. The pop up will open with a loader that relies of this flag
*    be finished when proccessed.
*
* 4. The new window will get the value on localStorage.
*
* 5. Hide the loader and display the charts according with the type.
*
*/
function PlotBox({ selectedWell, curvesKeys, data, well, sensorType }) {

  const [display, setToggle] = useState(false);
  const [currentChart, setChart] = useState(null);
  const [stateType, setStateType] = useState(null);
  const [depth, setDepth] = useState([]);

  const handleToggle = () => {
    // Undo the selection after close
    if (display) {
      const unselectChart = new CustomEvent(currentChart);
      document.dispatchEvent(unselectChart);
    }
  };

  const [count, setCount] = useState(0);
  const [windows, setUpdate] = useState({
    0: null, 1: null, 2: null,
    3: null, 4: null, 5: null,
    6: null, 7: null, 8: null
  });
  const toggleWindow = () => {
    setUpdate({ ...windows, [count]: null });
    setCount((count - 1));
  };

  useEffect(() => {
    if (well && well.curvesKeys && well.curvesKeys.depth) {
      const depthKey = well.curvesKeys.depth;
      setDepth(well.data[depthKey]);
    }
    const toggleWindows = event => {
      const { detail: { type, min, max, minLabel, maxLabel, name } } = event;
      setStateType(type);
      const id = uuid();
      const newWindow = {
        id,
        type
      };
      setUpdate({ ...windows, [count]: newWindow });
      setCount((count + 1));

      // TODO after proccess set data here
      const currentScales = defaultCrossPlotScales[type];
      let crossplotsData = {};

      // C1C2SF or balanceRatio Chart
      // Calculating Data
      const normalizedMin = min > 13 ? min - 13 : min;
      const normalizedMax = max > 13 ? max - 13 : max;
      if (type === 'balanceRatioToggle') {
        crossplotsData = generateBalanceCrossplots(
          type, normalizedMin, normalizedMax, data, curvesKeys
        );
      }

      if (type === 'C1C2SF') {
        crossplotsData = generateGeochemistryCharts(
          type, min, max, data, curvesKeys, sensorType
        );
      }

      store.set('querys', {
        ...store.get('querys'),
        [id]: {
          sensorType,
          type,
          data: crossplotsData,
          depth,
          min,
          max,
          minLabel,
          maxLabel,
          name,
          scales: currentScales
        }
      });

      if (!display) {
        handleToggle();
      }
    };

    document.addEventListener('showPlotBox', toggleWindows);
    return () => {
      document.removeEventListener('showPlotBox', toggleWindows);
    };
  }, [count, windows, data, well]);
  return (
    <div className="scatters-plot">
      {Object.keys(windows).length && Object.keys(windows).map((key, index) => {
        const currentWindow = windows[key];
        if (!currentWindow) {
          return null;
        }
        return (
          <NewWindow
            key={index}
            url={`/crossplots/${currentWindow.id}--${currentWindow.type}`}
            features={{ left: 200, top: 200, width: 1600, height: 900 }}
            onUnload={() => {
              const queryData = store.get('querys') || {};
              const { [currentWindow.id]: _, ...result } = queryData;
              store.set('querys', result);
            }}
          >
            <h2>-</h2>
          </NewWindow>
        )
      })}
    </div>
  );
}

export default memo(PlotBox);
