import React from 'react'
import { Popover } from 'antd';
import './popover.scss'

const ErrorPopover = ({ children, ...props }) => {
    return (
        <>
            <Popover className='error-popover' {...props}>{children}</Popover>
        </>
    )
}
export default ErrorPopover;