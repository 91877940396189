import styled, { css } from 'styled-components'

export const Figure = styled.figure`
  display: flex;
  align-items: center;
  margin: 0;

  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
`

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ borderRadius }) => css`
    border-radius: ${borderRadius ? `${borderRadius}px` : `${0}px`};
  `}
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`