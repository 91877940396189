import { Button, Input } from 'antd'
import styled from 'styled-components'

export const StyledInputRow = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: '16px',
});

export const StyledDisabledInput = styled(Input)`
    border: none;
    &.ant-input{
        background-color: #211F2B;
        cursor: not-allowed;
        color: #fff;
        max-width: 100px;
    }
`;

export const DeleteRow = styled.button({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#441D24',
    width: '25%',
    height: '32px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all ease-in-out .3s',
    '&:hover': {
        backgroundColor: '#6E2E3C'
    }
});

export const AddButton = styled(Button)`
    background: #2B2936;
    border: none;
    color: #fff;
`;

