import styled, { css } from 'styled-components'
import Text from '../Text'
import { space } from 'styled-system'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled(Text).attrs({
  as: 'label'
})``

export const InputRAW = styled.input(({ theme }) => `
  font-size: 1rem;
  ${space}
  ${({ width }) => css`
    width: ${width}px;
  `}
  width: 100%;
  border: none;
  outline: none;
  color: ${theme.colors.support.white};
  background: transparent;

  ::placeholder {
    color: ${theme.colors.dark['500']}
  }
`)

export const InputWrapper = styled.div(({ theme }) => `
  display: flex;
  align-items: center;
  background-color: ${theme.colors.dark['950']};
  padding: .5rem;
  border-radius: 5px;
`)
