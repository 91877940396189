import 'aos/dist/aos.css';

import React from 'react';

import ReactSvg from 'react-svg';

import LogoGeowellex from '../../images/logo-geowellex.svg';

const Copyright = () => (
  <div
    className="copyright"
    data-aos="fade-up"
    data-aos-delay="650"
    data-aos-duration="550"
    data-aos-easing="ease"
  >
    <div className="copyright__logo">
      <ReactSvg
        src={LogoGeowellex}
        className="copyright__logo-image"
      />
    </div>
    <div className="copyright__info">
      <span className="copyright__info-text">Version 1.21.21 - 2020 - 2023 - All Rights Reserved</span>
    </div>
  </div>
);

export default Copyright;
