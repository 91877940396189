import {
  defaultCrossHair,
  disableDefaultZoom,
  displayZoomBtn,
  getToolTipValues,
  propagateZoom
} from 'utils';

import { rocks } from '../../../utils/constants';

const getPattern = (id, height = 54, width = 54) => ({
  id,
  image: require(`images/patterns/${id}.png`),
  width,
  height,
});

export const defaultSeries = (defaultPlaceholderData) => ({
  crosshair: true,
  fillOpacity: 0.5,
  name: 'litho-placeholder',
  type: 'line',
  lineColor: '#fff',
  color: '#fff',
  yAxis: 0,
  zIndex: 101,
  index: 0,
  step: true,
  lineWidth: 1,
  shadow: false,
  data: defaultPlaceholderData
});

export const createDefaultConfig = ({
  width, customProps, id, depth
}, series) => ({
  chart: {
    type: 'area',
    zoomType: 'x',
    inverted: true,
    width,
    margin: 0,
    padding: 0,
    backgroundColor: '#fff',
    ...disableDefaultZoom,
    events: {
      selection: event => {
        displayZoomBtn();
        propagateZoom(event, depth);
      }
    },
    animation: false,
    shadow: false
  },
  defs: {
    patterns: rocks.map(rock => getPattern(rock)),
  },
  plotOptions: {
    series: {
      stacking: 'percent',
      marker: {
        enabled: false
      },
      enableMouseTracking: false,
      showInLegend: false,
      hover: false
    }
  },
  exporting: { enabled: false },
  tooltip: {
    shared: true,
    ...defaultCrossHair,
    formatter: getToolTipValues(id)
  },
  title: ' ',
  subtitle: {
    text: null,
  },
  credits: {
    enabled: false
  },
  xAxis: {
    showLabel: false,
    showFirstLabel: false,
    showLastLabel: false,
    minPadding: 0.0,
    maxPadding: 0.0,
    visible: false,
  },
  yAxis: [
    {
      index: 0,
      labels: {
        format: '-',
        enabled: false,
        style: {
          color: '#fff',
        }
      },
      title: {
        y: 0,
        x: 0,
        useHTML: false,
        color: '#fff',
        text: '',
      },
      allowDecimals: true,
      minorGridLineColor: '#E0E0E0',
      tickInterval: 0.1,
      startOnTick: false,
      endOnTick: false,
      showFirstLabel: true,
      showLastLabel: true,
      opposite: true,
      inverted: true,
      reversedStacks: false,
      reversed: true
    },
  ],
  series,
  ...customProps,
});

export const generateSerie = (rockName, rockData, index = 0) => {
  return {
    index,
    fillOpacity: 1,
    name: rockName,
    type: 'area',
    crosshair: true,
    tooltip: {
      valueSuffix: ' ',
      enabled: false,
    },
    stacking: 'percent',
    step: true,
    fillColor: `url(#${rockName})`,
    data: rockData,
    yAxis: 0,
    lineWidth: 0,
    showInLegend: false,
    shadow: false,
  };
};
