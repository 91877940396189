import * as ACTIONS from '../constants/crossplots';
import { createReducer } from './createReducer';

const initialState = {
  points: []
};

function receiveFluidValues(state, action) {
  return {
    points: [ ...state.points, ...action.values]
  };
}

export default createReducer(initialState, {
  [ACTIONS.RECEIVE_FLUID_TYPE_POINTS]: receiveFluidValues
});
