import styled, { css } from 'styled-components'
export const Figure = styled.figure`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
    margin: 0 0 .5em;
  `}
`
export const StyledIcons = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${({ borderRadius }) => css`
    border-radius: ${borderRadius ? `${borderRadius}px` : `${0}px`};
  `}
`