import React, { Component } from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import {
  maxCurveLithology,
  realTimeLithoDataFormatter,
  syncronizeTooltip
} from 'utils';

import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  defaultSeries,
  generateSerie
} from './config';

class Cuttings extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      data: [],
      cuttings: undefined,
    };
  }

  componentDidMount() {
    this.createRealTimeLithoChart([], []);
  }

  shouldComponentUpdate(nextProps) {
    const { selectedWell, currentWell } = nextProps;
    if (
      selectedWell !== this.props.selectedWell ||
      nextProps.currentWell.cuttings && !this.state.cuttings
    ) {
      this.setState({ cuttings: currentWell.cuttings });

      const currentDepth = 
        nextProps.currentWell && nextProps.currentWell.curves && nextProps.currentWell.curves.depth
        ? nextProps.currentWell.curves.depth : [];
      const data = realTimeLithoDataFormatter(currentWell.cuttings || {}, currentDepth);
      const series = Object.keys(data).map((key, index) => (
        generateSerie(key, data[key], index + 1)));

      const { depth } = this.props;

      let min = depth && depth.slice(0) ? depth.slice(0)[0] : 0;
      let max = depth && depth.slice(-1) ? depth.slice(-1)[0] : 0;

      this.chart.destroy();
      this.createRealTimeLithoChart(data, series); 
      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(min, max);
      }, 300);
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  createRealTimeLithoChart = (data, series) => {
    const { id } = this.props;

    let defaultPlaceholderData = [];

    if (Object.keys(data).length) {
      defaultPlaceholderData = maxCurveLithology(data);
    }

    // This map will generate each serie that we are passing data via props
    // NOTE: This will generate data just relyng from the serie that we are receiveing
    // const series = Object.keys(data).map(
    //   (key, index) => generateSerie(key, data[key], index + 1)
    // );
    series.push(defaultSeries(defaultPlaceholderData));
    this.chart = Highcharts.chart(id, createDefaultConfig(this.props, series));

    document.addEventListener('sincronize-selected', event => {
      const {
        detail: { min, max }
      } = event;

      const { depth } = this.props;
      let minValue = depth[min];
      let maxValue = depth[max];

      this.chart.xAxis[0].setExtremes(minValue, maxValue, true, true);
      this.chart.redraw();
    });

    syncronizeTooltip(`#${this.props.id}`);
  }

  render() {
    const { id, currentWell, title, depth } = this.props;
    return (
      <ChartContainer>
        <ChartHeader lithoData={currentWell.cuttings || []} title={title} depth={depth}/>
        <div
          id={id}
          style={{
            marginLeft: '10px',
            height: 'calc(100% - 128px)'
          }}
          data-cy="chart-cuttings"
        />
      </ChartContainer>
    );
  }
}

Cuttings.defaultProps = {
  width: 150,
  type: 'area',
  bgColor: '#fff',
  inverted: true,
  animation: false,
  customProps: {},
  id: 'cuttings',
  title: <span>Cuttings (%)</span>
};

export default Cuttings;
