import get from 'lodash.get';
import { defaultMnemonics, defaultMnemonicsGeopress } from '../utils/constants';
import { arrayFlipToObject, calculateSteps } from '../utils';
import LasConverter from './LasConverter';

export const processingLas = (
  lines, file, thereIsConflict, scope
) => {
  // Inject top / bottom
  // TODO refactor the validation with promises to avoid this if
  const JSONData = new LasConverter({
    lines,
    name: file.name,
    size: file.size
  }).getJSON() || false;

  console.log('LOG the .las to JSON: ---> ', JSONData);
  if (Object.keys(JSONData) && JSONData.curve === undefined ) {
    alert('Something is wrong.\nTry to check if the .las file is valid.\nContact the support: support@geowellex.com.');
    return;
  }
  if (JSONData.version.VERS.value === '3.0') {
    alert('This version .las is not supported.');
    return;
  }
  const curves = Object.keys(JSONData.curve);
  // TODO move it to a function try recognize the mnemonic 
  const tmpCurvesKeys = {};
  Object.keys(defaultMnemonics).forEach(curve => {
    const currentOptions = defaultMnemonics[curve];
    const mnem = currentOptions.find(opt => curves.includes(opt));
    if (mnem) {
      tmpCurvesKeys[curve] = mnem;
    }
  });

  // Applying aliases to recognize the keys
  JSONData.totalGas = JSONData.data[tmpCurvesKeys.total_gas] || [];
  JSONData.depth = JSONData.data[tmpCurvesKeys.depth] || [];
  JSONData.rop = JSONData.data[tmpCurvesKeys.rop] || [];
  const swappedKeyValues = arrayFlipToObject(JSONData.depth);

  const { depth } = JSONData;

  const topWell = depth[0];
  const bottomValue = depth.slice(-1)[0];

  const firstDepthValues = depth.slice(0, 100);
  const step = calculateSteps(firstDepthValues);

  const wellName = get(JSONData, 'well.WELL.value', null);
  let hasConflictData = false;
  const conflictedWell = thereIsConflict(wellName);
  let curveKeys = {};
  let hasNewWell = true;
  if (conflictedWell) {
    hasConflictData = true;
    hasNewWell = false;
    curveKeys = conflictedWell.curvesKeys;
  }
  scope.setState({
    curves,
    curve: JSONData.curve,
    well: JSONData.well,
    stepInterval: JSONData.well && JSONData.well.STEP ? JSONData.well.STEP : 1,
    version: JSONData.version,
    currentWell: {
      well: wellName,
      version: JSONData.version && JSONData.version.VERS ? JSONData.version.VERS.value : null
    },
    JSONData,
    swappedKeyValues,
    top: topWell,
    bottom: bottomValue,
    ...JSONData,
    ...JSONData.data,
    step,
    hasConflictData,
    hasNewWell,
    isCSVUpload: true,
    curvesKeys: {
      ...scope.state.curvesKeys,
      ...tmpCurvesKeys,
      ...curveKeys
    }
  }, () => {
    scope.openModal();
  });
};

export const processingGeopressLas = (
  lines, file, scope, geopressIsOpen
) => {
  // Inject top / bottom
  // TODO refactor the validation with promises to avoid this if
  const JSONData = new LasConverter({
    lines,
    name: file.name,
    size: file.size
  }).getJSON() || false;

  console.log('LOG the .las to JSON: ---> ', JSONData);
  if (!JSONData && !JSONData.curve) {
    alert('Something is wrong.\nTry to check if the .las file is valid.\nContact the support: support@geowellex.com.');
    return;
  }
  const curves = Object.keys(JSONData).filter(key => Array.isArray(JSONData[key]));
  // TODO move it to a function try recognize the mnemonic 
  const tmpCurvesKeys = {};
  Object.keys(defaultMnemonicsGeopress).forEach(curve => {
    const currentOptions = defaultMnemonicsGeopress[curve];
    const mnem = currentOptions.find(opt => curves.includes(opt));
    if (mnem) {
      tmpCurvesKeys[curve] = mnem;
    }
  });
    
  // Applying aliases to recognize the keys
  JSONData.totalGas = JSONData.data[tmpCurvesKeys.total_gas] || [];
  JSONData.depth = JSONData.data[tmpCurvesKeys.depth] || [];
  JSONData.rop = JSONData.data[tmpCurvesKeys.rop] || [];

  const swappedKeyValues = arrayFlipToObject(JSONData.depth);

  const {depth} = JSONData;

  const topWell = depth[0];
  const bottomValue = depth.slice(-1)[0];
  
  const firstDepthValues = depth.slice(0, 100);
  const step = calculateSteps(firstDepthValues);

  const wellName = get(JSONData, 'well.WELL.value', null);
  let hasConflictData = false;
  let curveKeys = {};
  let hasNewWell = true;
  scope.setState({
    curves,
    curve: JSONData.curve,
    well: JSONData.well,
    version: JSONData.version,
    currentWell: {
      well: wellName,
      version: JSONData.version && JSONData.version.VERS ? JSONData.version.VERS.value : null
    },
    JSONData,
    swappedKeyValues,
    top: topWell,
    bottom: bottomValue,
    ...JSONData,
    ...JSONData.data,
    step,
    hasConflictData,
    geopressIsOpen,
    hasNewWell,
    isCSVUpload: true,
    geopressureCurveKeys: {
      ...scope.state.geopressureCurveKeys,
      ...tmpCurvesKeys,
      ...curveKeys
    }
  }, () => {
    scope.openModalGeopress();
  });
};

export const formatParsedArrayToWellObject = (
  csvData, thereIsConflict = false, scope
) => {
  const [header] = csvData;
  const headers = header.map(str => str.trim().toLowerCase());
  const tmpCurvesKeys = {};
  Object.keys(defaultMnemonics).forEach(curve => {
    const currentOptions = defaultMnemonics[curve];
    const mnem = currentOptions.find(opt => headers.includes(opt));
    if (mnem) {
      tmpCurvesKeys[curve] = mnem;
    }
  });


  // --------- Transform arrays in JSON data -------
  // Creating Placehoders
  const JSONData = headers.reduce((acc, headerKey) => {
    acc[headerKey] = [];
    return acc;
  }, {});

  // {0: "md", 1: "tvd", 2: "c1"}
  const JSONHeaderIndexes = headers.reduce((acc, headerKey, index) => {
    acc[index] = headerKey;
    return acc;
  }, {});

  /*
   * transform from array
   * [
   *   0: ["tvd", "c1", "rop"],
   *   1: [2200, 1000, 22],
   *   2: [2201, 900, 18],
   * ]
   *
   * to object
   * {
   *   tvd: [2200, 2201],
   *   c1: [1000, 900],
   *   rop: [22, 18],
   * }
   */
  for (let i = 1; i < csvData.length - 1; i++) {
    const rowLength = csvData[i].length;
   
    for (let colKey = 0; colKey <= rowLength - 1; colKey++) {
      
      const value = csvData[i][colKey];

      const currentValue = value.replace(",", ".");
      // eslint-disable-next-line no-restricted-globals
      const formatedValue = isNaN(currentValue) ? 0 : Number(currentValue);
      const columnName = JSONHeaderIndexes[colKey];
      JSONData[columnName].push(formatedValue);
    }
  }

  // Applying aliases to recognize the keys
  JSONData.totalGas = JSONData[tmpCurvesKeys.total_gas] || [];
  JSONData.depth = JSONData[tmpCurvesKeys.depth] || [];
  JSONData.rop = JSONData[tmpCurvesKeys.rop] || [];
  JSONData.c2fid = JSONData[tmpCurvesKeys.c2fid] || [];

  const swappedKeyValues = arrayFlipToObject(JSONData.depth);

  const {depth} = JSONData;

  const topWell = depth[0];
  const bottomValue = depth.slice(-1)[0];

  const firstDepthValues = depth.slice(0, 100);
  const step = calculateSteps(firstDepthValues);

  const wellName = get(JSONData.data, 'well.WELL.value', null);
  let hasConflictData = false;
  const conflictedWell = thereIsConflict(wellName);
  let curveKeys = {};
  let hasNewWell = true;

  if (conflictedWell) {
    hasConflictData = true;
    hasNewWell = false;
    curveKeys = conflictedWell.curvesKeys;
  }

  scope.setState({
    curve: headers.reduce((acc, curve) => {
      acc[curve] = {};
      return acc;
    }, {}),
    currentWell: {
      well: wellName,
      version: null
    },
    swappedKeyValues,
    top: topWell,
    bottom: bottomValue,
    step,
    hasConflictData,
    hasNewWell,
    ...JSONData,
    curves: headers,
    JSONData,
    curvesKeys: { ...scope.state.curvesKeys, ...tmpCurvesKeys, ...curveKeys }
  }, () => {
    scope.openModal();
  });
};


export const processingInWell = (
  JSONData, file, thereIsConflict, scope, afterProccess
) => {
  try {
    const curves = Object.keys(JSONData).filter(key => Array.isArray(JSONData[key]));
    const tmpCurvesKeys = {};
    Object.keys(defaultMnemonics).forEach(curve => {
      const currentOptions = defaultMnemonics[curve];
      const mnem = currentOptions.find(opt => curves.includes(opt));
      if (mnem) {
        tmpCurvesKeys[curve] = mnem;
      }
    });

    const swappedKeyValues = arrayFlipToObject(JSONData.depth);

    const {depth} = JSONData;

    const topWell = depth[0];
    const bottomValue = depth.slice(-1)[0];

    const firstDepthValues = depth.slice(0, 100);
    const step = calculateSteps(firstDepthValues);

    const wellName = file.name;
    let hasConflictData = false;
    const conflictedWell = thereIsConflict(wellName);
    let curveKeys = {};
    let hasNewWell = true;
    if (conflictedWell) {
      hasConflictData = true;
      hasNewWell = false;
      curveKeys = conflictedWell.curvesKeys;
    }
    const currentWell = {
      curves,
      curve: JSONData,
      well: JSONData,
      version: JSONData.version,
      currentWell: {
        well: wellName,
        version: JSONData.version && JSONData.version.VERS ? JSONData.version.VERS.value : null
      },
      JSONData,
      swappedKeyValues,
      top: topWell,
      bottom: bottomValue,
      ...JSONData,
      step,
      hasConflictData,
      hasNewWell,
      isCSVUpload: true,
      curvesKeys: {
        ...scope.state.curvesKeys,
        ...tmpCurvesKeys,
        ...curveKeys
      }
    };

    scope.setState(currentWell, () => {
      afterProccess();
    });
  } catch(e) {
    console.log('error', e);
  }
};
