import React from 'react';

const RenderChart = ({
  id, styles, className
}) => (
  <div
    id={id}
    style={styles}
    className={className}
  />
);

RenderChart.defaultProps = {
  styles: {
    marginLeft: '4px',
    overflow: 'auto'
  },
  className: ''
};

export default RenderChart;
