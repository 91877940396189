import React, { useEffect, useState, useRef, createContext, useContext } from 'react'
import {
    // useDispatch,
    useSelector
} from 'react-redux';

const initialState = {}

import get from 'lodash.get';

export const ModelsContext = createContext(initialState)

export const ModelsProvider = ({ children, modelName }) => {

    const wells = useSelector(state => state.wells) || {};
    const currentWell = get(wells, 'currentWell', null);
    const models = useSelector(state => state.models) || {};

    const initialCurveMapping = (inputSchema) => {
        if (!inputSchema) {
            return {};
        }
        return inputSchema.reduce((acc, curveName) => {
            if (currentWell && currentWell[curveName]) {
                const isAllNull = currentWell[curveName].every((value) => value === null);
                const isArrayOfNumbersOrNull = currentWell[curveName].every((value) => typeof value === 'number' || value === null);
                const isEqualOrBiggerThanDepth = currentWell[curveName].length >= currentWell['depth'].length;
                if (!isAllNull && isArrayOfNumbersOrNull && isEqualOrBiggerThanDepth) {
                    acc[curveName] = curveName;
                } else {
                    acc[curveName] = null;
                }
            } else {
                acc[curveName] = null;
            }
            return acc;
        }, {});
    };

    const [selectedModel, setSelectedModel] = useState(null);
    const [curveMapping, setCurveMapping] = useState({});
    const [curvesNames, setCurvesNames] = useState([]);

    useEffect(() => {
        //  if curveMapping is not empty, then we can set the initial curve mapping
        if (models && currentWell && modelName && models.items[modelName] && models.items[modelName].input_schema) {
            setCurveMapping(initialCurveMapping(models.items[modelName].input_schema));
        }
    }, [models, currentWell, modelName]);

    useEffect(() => {
        if (currentWell) {
            const depthKey = 'depth'
            // find object which are arrays and not null
            const curves = Object.keys(currentWell).reduce((acc, curveKey) => {
                if (Array.isArray(currentWell[curveKey]) && currentWell[curveKey].length) {
                    // if all values are not null
                    const isAllNull = currentWell[curveKey].every((value) => value === null);
                    const isArrayOfNumbersOrNull = currentWell[curveKey].every((value) => typeof value === 'number' || value === null);
                    const isEqualOrBiggerThanDepth = currentWell[curveKey].length >= currentWell[depthKey].length;
                    if (!isAllNull && isArrayOfNumbersOrNull && isEqualOrBiggerThanDepth) {
                        acc.push(curveKey);
                    }
                }
                return acc;
            }, []);
            // this is hardcoded for now
            const curvesToExclude = [
                'ic4Normal', 'ic5Normal', 'c1Byc2', 'ic4BynC4', 'ic5BynC5', 'wetnessRatioData',
                'balanceRatioData', 'characterRatioData', 'c1Composition', 'c2Composition',
                'c3Composition', 'nc4Composition', 'nc5Composition', 'slowFactor', 'gammaRay',
                'totalCarbonOnly', 'totalGas'
            ]
            // if the curve is not in the curvesToExclude array, then we can add it to the curvesNames
            const curvesToInclude = curves.filter((curve) => !curvesToExclude.includes(curve));
            curvesToInclude.push('')
            setCurvesNames(curvesToInclude);
        }
    }, [currentWell]);


    return (
        <ModelsContext.Provider value={{
            curveMapping,
            setCurveMapping,
            initialCurveMapping,
            selectedModel,
            setSelectedModel,
            curvesNames,
            setCurvesNames,
        }}>
            {children}
        </ModelsContext.Provider>
    )
}

export const useModelsContext = () => {
    const context = useContext(ModelsContext)
    if (context === undefined) {
        throw new Error('useModels must be used within a ModelsProvider')
    }
    return context
}

export default useModelsContext