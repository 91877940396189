import styled from 'styled-components'

export const Wrapper = styled.div(({ theme }) => `
	max-height: 200px;
	border-radius: 8px;
	overflow: hidden;
	width: 100%;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	background: ${theme.colors.dark['800']};
	border: 2px solid ${theme.colors.dark['900']};
`)

export const Scrolling = styled.div(({ theme }) => `
	overflow: auto;
	max-height: 200px;
`)

export const List = styled.ul`
	list-style: none;
	margin-bottom: 0;
`

export const ListItem = styled.li(({ theme }) => `
	color: ${theme.colors.dark['500']};
	padding: 8px;
	cursor: pointer;

	&:hover {
		background: ${theme.colors.dark['900']};
	}
`)
