import React, { useState, useCallback } from 'react'

import useExperiments from '../../../hooks/useExperiments'

import { Container } from './styles.js'
import { TextArea, Combobox, Button, Box, Text, SelectedItems, Select } from '../../../components'

const parseData = (data) => {
  const fields = Object.keys(data)

  return fields.map(field => ({ label: data[field].display_name, id: data[field].experiment_id }))
}

const Experiment = () => {
  const {
    experiments: experimentsRAW,
    wells,
    logName,
    setLogName,
    curve,
    curves,
    setCurve,
    selectedCurves,
    availableLogs,
    selectedWells,
    handleConfirm,
    handleTrainingModel,
    handleChange,
    handleDescription,
    handleSelectedWells,
    addSelectedCurve,
    removeSelectedCurve
  } = useExperiments()
  const [currentSelectedWell, setCurrentSelectedWell] = useState(null)

  const experiments = useCallback(parseData(experimentsRAW), [experimentsRAW])

  const parseWellsData = useCallback(() => {
    const notSelectedWells = wells.filter((well) => !selectedWells.find((selectedWell) => well.uidWell === selectedWell.uidWell))

    return notSelectedWells.map((well) => ({ id: well.uidWell, label: well.nameWell }))
  }, [wells, selectedWells])

  const handleSelectedWellAndCleanState = () => {
    handleSelectedWells(currentSelectedWell)
    setCurrentSelectedWell(null)
  }

  const parseShowCurves = () => {
    const availableCurves = Object.keys(selectedCurves).filter((logName) => !selectedCurves[logName].find((item) => {
      (item === curve.label)
    }))

    const parseCurve = availableCurves.map((logName) => {
      return (selectedCurves[logName]).map((curves) => (
        curves
      ))
    })

    return parseCurve
  }

  return (
    <Container>
      <Combobox
        label="Experiment Name"
        options={experiments}
        onSelect={(value) => handleTrainingModel('experiment_name', value)}
        onChange={(e) => handleChange('experiment_name', e)}
        placeholder="Type a new experiment or select from one that exists"
      />
      <Box mt="1.5rem">
        <Text variant="subtitle">Description</Text>
        <TextArea onChange={(item) => handleDescription(item)} placeholder="e.g. 'Gamma Ray predictor trained with wells X, Y and Z'" />
      </Box>
      <Box mt="1.5rem">
        <Select
          label="Model Type"
          options={[{ label: 'Gamma Ray', id: 1 }, { label: 'Interpreted Lithology', id: 2 }, { label: 'Porosity', id: 3 }]}
          onSelect={(value) => handleTrainingModel('target_column', value)}
        />
      </Box>
      <Box display="flex" alignItems="flex-end" mt="1.5rem" mb="1.5rem">
        <Box flex="1" mr="1rem">
          <Select
            label="Select Wells"
            options={parseWellsData()}
            onSelect={(item) => setCurrentSelectedWell(item)}
            value={currentSelectedWell && currentSelectedWell.label}
            disabled={!wells.length}
          />
        </Box>
        <Button
          variant="outlined"
          onClick={handleSelectedWellAndCleanState}
          disabled={!currentSelectedWell}
        >Add</Button>
      </Box>
      <SelectedItems
        title="Selected Wells:"
        data={selectedWells
          .map(({ uidWell, nameWell }) =>
            ({ id: uidWell, field: nameWell })
          )
        }
        onRemove={handleSelectedWells}
      />
      <Box display="flex" mt="0.5rem">
        <Button
          flex="1"
          variant="primary"
          onClick={handleConfirm}
          disabled={!selectedWells.length}
        >Confirm</Button>
      </Box>
      <Box mt="1.5rem" mb="1.5rem">
        <Text variant="subtitle">Input Schema Definition</Text>
        <Box
          display="flex"
          alignItems="flex-end"
          mt=".5rem"
          mb="1rem"
        >
          <Box mr="1rem">
            <Select
              label='Log Name'
              options={availableLogs.logsList}
              onSelect={setLogName}
              disabled={!availableLogs.logsList.length}
            />
          </Box>
          <Box mr="1rem">
            <Select
              label='Select Curve'
              options={availableLogs.currentLogCurves}
              onSelect={setCurve}
              disabled={!availableLogs.currentLogCurves.length}
            />
          </Box>
          <Button
            variant="outlined"
            onClick={() => addSelectedCurve(logName.label, curve.label)}
            disabled={!logName || !curve}
          >Add</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          <SelectedItems
            title="Selected curves:"
            data={curves}
            onRemove={removeSelectedCurve}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default Experiment