import React from 'react';
import classNames from 'classnames';

const ChartContainer = ({
  children,
  defaultClassName,
  className,
  ...props
}) => (
  <div
    className={classNames(
      defaultClassName, {
        [className]: !!className
      }
    )}
    {...props}
  >
    {children}
  </div>
);

ChartContainer.defaultProps = {
  defaultClassName: 'chart__box-container'
};

export default ChartContainer;
