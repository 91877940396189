import React, { useState, useEffect } from 'react'

import useExperiments, { ExperimentProvider } from '../../hooks/useExperiments'
import { trainModel } from "api/train";

import { successNotification, errorNotification } from '../../utils';

import { Container, Content, GlobalStyle } from './styles'
import { Button, Tabs, Box } from '../../components'
import Experiment from './Experiment'
import Training from './Training'
import Header from './Header'

const tabs = [
  'Experiment Settings',
  'Training Settings'
]

const Models = ({ closeModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { trainingData, curves, hyperparameterTuning } = useExperiments()
  const [step, setStep] = useState(0)

  const sendData = async () => {
    setIsLoading(true)
    try {
      const response = await trainModel(trainingData)

      successNotification(response.data.message)
    } catch (err) {
      if (err instanceof Error) {
        errorNotification(err.message)
      }
    }
    setIsLoading(false)
  }

  const handleAction = () => {
    if (step === 0) {
      // iterate over curves.field, check if there is a field that includes the string depth
      // if there is, proceed setStep(1), else show errorNotification
      let hasDepth = false;
      curves.map((curve) => {
        if (curve.field.includes('depth')) {
          hasDepth = true;
        }
      })
      if (hasDepth) {
        setStep(1)
      } else {
        errorNotification('Please, select also the depth curve')
      }
    } else {
      sendData()
    }
  }

  const validateAction = () => {
    if (isLoading) return true

    if (step === 0) {
      return curves && !curves.length
    }

    return hyperparameterTuning ? !hyperparameterTuning.length : false
  }

  const renderButtonText = () => {
    if (isLoading) return 'Loading...'
    if (step === (tabs.length-1)) {
      return 'Train'
    }

    return 'Next'
  }

  return (
      <Container className='models-container'>
        <Content>
          <Header onClose={closeModal} />
          <Tabs
            className="tabs-container"
            selectedIndex={step}
            tabs={tabs}
          >
            <Experiment />
            <Training />
          </Tabs>

          <Box display="flex" justifyContent="flex-end">
            <Button onClick={closeModal} mr="1rem" variant="link">Cancel</Button>
            <Button onClick={handleAction} disabled={validateAction()}>{renderButtonText()}</Button>
          </Box>
        </Content>
      </Container>
  )
}

const ModelsWithProvider = (props) => (
  <ExperimentProvider>
    <GlobalStyle />
    <Models {...props} />
  </ExperimentProvider>
)

export default ModelsWithProvider
