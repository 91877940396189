import styled from 'styled-components'

export const Wrapper = styled.div`
  border-radius: 8px;
`;

export const Header = styled.ul`
  display: flex;
  position: relative;
  border-bottom: 3px solid ${({ theme }) => theme.colors.dark[600]};
  list-style: none;
`;

export const HeaderItem = styled.li`
  text-align: center;
  ${({ headerAlign }) => (headerAlign ? '' : `flex: 1;`)}
  color: ${({ theme }) => theme.colors.dark[600]};
  padding: 1rem;
  cursor: default;

  &[disabled=false] {
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.support.white};
    }
  }

  &[aria-selected=true] {
    color: ${({ theme }) => theme.colors.support.white};
  }    
`;

export const Indicator = styled.div`
  width: ${({ headerAlign, itemsqnt, width }) =>
    headerAlign ? `${width}px` : `calc(100% / ${itemsqnt})`};
  height: 2px;
  background: white;
  bottom: -2px;
  position: absolute;
  left: ${({ headerAlign, selected, itemsqnt, left }) =>
    headerAlign ? `${left}px` : `calc((100% / ${itemsqnt}) * ${selected})`};
  transition: all 300ms ease-in-out;
`;

export const Body = styled.div``;

export const Content = styled.div``;