import axios from 'axios';

export const URL_API = 'https://api.geowellex.com';

const Api = axios.create({
  baseURL: URL_API,
  withCredentials: true,
});

const API_URL_AUTH = false
  ? 'http://localhost:5000/login'
  : 'https://auth.geowellex.com/auth/realms/Geowellex/protocol/openid-connect/token';

const IN_WELL_URL_API = 'https://prod.gateway.geowellex.com/inwell-data/well';
const API_URL = 'https://wellex-gold-api-oofp55ubjq-uc.a.run.app';
const resourceWell = 'well';

export const doAuthenticate = async (data) => {
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: API_URL_AUTH,
    withCredentials: false,
  };
  const response = await axios(options);
  return response;
};

export const doLogout = async () => {
  let response = await Api.get(`/index.php?action=logout`);
  return response;
};

export const listWells = async () => {
  // TODO put the token here when receive from auth
  const options = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    url: `${URL_API}/wellsites?search=lapa`,
  };
  return axios(options);
};

export const listWell293 = async () => {
  // TODO put the token here when receive from auth
  const options = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    url: `${URL_API}/wellsites/293`,
  };
  return axios(options);
};

export const listOneWell = async (id) => {
  const options = {
    method: 'get',
    url:  `${URL_API}/wellsites/${id}/depth?interval=-10000`,
  };
  return axios(options);
};

export function fetchInWell(token, uid, uidWell) {
  const resource = `${IN_WELL_URL_API}/${uid}/${uidWell}/data-las-string`;
  return axios.get(
    resource,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export const fetchLithology = async (id) => {
  const options = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    url: `${URL_API}/wellsites/${id}/litho?interval=-10000`
  }
  return axios(options);
}

export function fetchInWellSite(uid, uidWell, token) {
  return axios.get(`${IN_WELL_URL_API}/${uid}/${uidWell}/data`, {
    headers: {
      'access-token': token,
      'Content-type': 'application/json'
    }
  });
}

export const fetchLithologyAutomud = async (id) => {
  const options = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    url: `${URL_API}/wellsites/${id}/automud?interval=-10000`
    
  }
  return axios(options);
}

export const fetchModel = async (id, model) => {
  const options = {
    method: 'post',
    url: `${URL_API}/wellsites/${id}/automud/${model}`
  }

  return axios(options);
}

export default Api;
