import React, { useEffect, useState, createContext, useContext } from 'react'
import store from 'store'
import axios from 'axios'
import { testConvertDataPressure, testConvertDataFlow } from './hydraulicsUtils'

export const API_BASE_URL = `https://prod.gateway.geowellex.com/hydraulics`

const initialState = {
    isLoading: false,
    setIsLoading: () => undefined,
    model: {
        "Total Anular": 0.00,
        "Total Pipe": 0.00,
        "Total Pipe TJ": 0.00,
        "Total Anular TJ": 0.00,
        "Total Surface": 0.00,
        "Total Bit": 0.00,
        "Total Pressure Loss": 0.00,
    },
    correlation: {
        "Lag Time (min)": 0.00,
        "Fluid Lag Time (min)": 0.00,
        "Geral Transport Ratio (%)": 0.00,
        "ECD at Casing Shoe": 0.00,
        "ECD on Bottom": 0.00,
    },
    unitParameters: {
        depth_unit: 'ft',
        pressure_unit: 'psi',
        radius_unit: 'inch',
        fluidDensity_unit: 'ppg',
        solidsDensity_unit: 'g/cm³',
        jetDiameter_unit: 'inch/32',
        sphericity_unit: '%'
    }
}

export const HydraulicsContext = createContext(initialState)

export const HydraulicsProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [wellInfo, setWellInfo] = useState();
    const [model, setModel] = useState(initialState.model)
    const [correlation, setCorrelation] = useState(initialState.correlation)
    const [cuttings, setCuttings] = useState()
    const [payload, setPayload] = useState()
    const [response, setResponse] = useState()
    const [unitsConfigDetails, setUnitsConfigDetails] = useState(initialState.unitParameters)

    const accessToken = store.get('automud-token');

    useEffect(() => {
        const selectedWellInfo = store.get('selectedWellInfo');
        if (selectedWellInfo && !wellInfo) {
            setWellInfo(selectedWellInfo);
        }
    }, [store.get('selectedWellInfo')])

    const fetchHydraulics = async (payload) => {
        setIsLoading(true);

        if (accessToken && accessToken != null) {
            const url = `${API_BASE_URL}/compute`


            const config = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': accessToken,
                    'Access-Control-Allow-Origin': '*'
                },
                url: url,
                data: payload,

            };

            axios(config)
                .then(function (response) {
                    const data = response.data
                    //console.log(response)
                    setResponse({ type: 'success', message: response.message })
                    setModel(testConvertDataPressure(data.Model))
                    setCorrelation(testConvertDataFlow(data.Model, data.Correlation))
                    setCuttings(data.Cuttings);

                    setIsLoading(false)

                })
                .catch(function (error) {
                    setResponse({ type: 'error', message: error.message })
                })
        }


    }

    const REQ_TIME_INTERVAL = 1000 * 60;

    useEffect(() => {
        let intervalId;

        const fetchDataWithInterval = async () => {

            if (payload) {
                await fetchHydraulics(payload);
                intervalId = setTimeout(fetchDataWithInterval, REQ_TIME_INTERVAL);
            }
        };

        fetchDataWithInterval();
    }, [payload])

    return (
        <HydraulicsContext.Provider value={{
            isLoading,
            model,
            correlation,
            cuttings,
            payload,
            setPayload,
            wellInfo,
            setWellInfo,
            initialState,
            fetchHydraulics,
            unitsConfigDetails,
            setUnitsConfigDetails,
            response
        }}>
            {children}
        </HydraulicsContext.Provider>
    )
}

const useHydraulicsContext = () => {
    const context = useContext(HydraulicsContext)

    if (context === undefined) {
        throw new Error('useHydraulics must be used within a HydraulicsProvider')
    }

    return context
}

export default useHydraulicsContext

