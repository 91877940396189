import './style.scss';

import React, {
  useEffect,
  useState
} from 'react';

import {
  listModels,
  listOneModel,
  listRunOneMetadata
} from 'actions/models';
import {
  receiveAiGrPrediction,
  receiveLithologyScore,
  receiveRealtimeLithology,
  receiveMLPorosity,
} from 'actions/wells';
import {
  Button,
  Collapse,
  Select,
  Popconfirm
} from 'antd';
import { successNotification, errorNotification } from '../../utils';
import { useFormik } from 'formik';
import get from 'lodash.get';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { format } from 'date-fns';

import { handleData } from "../../new/Hydraulics/hydraulicsUtils";

import Loading from './Loading';
import { predictLithology, deleteModel } from '../../api/models';
import useModelsContext, { ModelsProvider } from '../../hooks/useModels';
import Text from '../Text';
import Box from '../Box';

const { Panel } = Collapse;

const RUN_ALL_EVENT = 'RUN_ALL_EVENT';

const ModelsModal = ({ onClose, openModalWellPhases, closeModalWellPhases }) => {
  const models = useSelector(state => state.models) || {};
  const wells = useSelector(state => state.wells) || {};
  const dispatch = useDispatch();

  const currentWell = get(wells, 'currentWell', null);
  const [curvesNames, setCurvesNames] = useState(null);

  const [tabActive, setActive] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingOneModel, setLoadingOneModel] = useState(false);
  const [modelName, setModelName] = useState('');

  useEffect(() => {
    if (!curvesNames) {
      const removedCurves = [
        'endIndex',
        'isActive',
        'isVisible',
        'logName',
        'nameWell',
        'indexUnit',
        'startIndex',
        'uid',
        'uidWell',
        'uidWellbore',
      ];
      if (currentWell && currentWell.curves) {
        const well = Object.keys(currentWell.curves);
        const curvesNames = well.reduce((acc, curveKey) => {
          if (!removedCurves.includes(curveKey)) {
            // if (!removedCurves.includes(curveKey) && currentWell.curves[curveKey]) {
            acc.push(curveKey);
          }
          return acc;
        }, []);
        setCurvesNames(curvesNames);
      }
    }
  }, [currentWell]);

  const afterSuccess = () => {
    setLoadingOneModel(false);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(listModels(afterSuccess));
  }, []);

  const onClickPanel = (item) => {
    setActive('');
    if (tabActive !== item) {
      setActive(item);
    } else {
      return;
    }
  };

  const getOneModel = (name) => {
    if (name) {
      setModelName(name);
      dispatch(listOneModel(name, afterSuccess));
    }
  };

  useEffect(() => {
    getOneModel(tabActive);
  }, [tabActive]);

  const runId = models && models.items && models.items[modelName] && models.items[modelName].run_id;
  useEffect(() => {
    if (tabActive && runId !== undefined) {
      dispatch(listRunOneMetadata(runId, tabActive));
    }
  }, [tabActive, runId]);

  // const runAllEvent = () => {
  //   const action = new CustomEvent(RUN_ALL_EVENT, {
  //     detail: {
  //       type: 'runAll'
  //     }
  //   });
  //   document.dispatchEvent(action);
  // };

  const parsedModels = Object.keys(models.items)

  return (
    <ModelsProvider modelName={modelName} >
      <Text variant="title">Registered Models</Text>
      <Box className="modal-scale__form" mt="1rem">
        {loading && <Loading />}
        {!loading && parsedModels.length && parsedModels.map((item, index) => {
          const model = models.items[item];
          return (
            <ListModels
              item={item}
              openModalWellPhases={openModalWellPhases}
              closeModalWellPhases={closeModalWellPhases}
              onChange={() => { }}
              index={index}
              onClick={onClickPanel}
              modelData={model}
              loadingOneModel={loadingOneModel}
              data={models}
              loading={loading}
              modelName={modelName}
              onClose={onClose}
            />
          )
        })}
      </Box>
    </ModelsProvider>
  );
};

const ItemModel = ({ title, description }) => (
  <div className="box-model-text">
    <h3 className="detail-model-title">{title}</h3>
    <span className="detail-model-description">{description}</span>
  </div>
);

const SelectSettings = ({
  inputSchema, modelName, displaySuccessModel, displayDrift,
  models, tags, openModalWellPhases, closeModalWellPhases
}) => {
  const { curveMapping, curvesNames } = useModelsContext();

  const wells = useSelector(state => state.wells) || {};
  const [isLoading, setLoading] = useState(false);
  const defaultCurrentWell = get(wells, 'currentWell');
  const dispatch = useDispatch();

  const [modifiedSchema, setModifiedSchema] = useState({});

  // TODO create action
  const onSubmitForm = async () => {
    const currentWell = defaultCurrentWell && defaultCurrentWell
      ? { ...defaultCurrentWell } : {};

    // normalize some keynames and fill the array using null
    if (wells && wells.currentWell) {
      const data = inputSchema.reduce((acc, curveName) => {
        if (
          currentWell
          // && currentWell[curveName]
          && inputSchema.includes(curveName)
        ) {

          // map the curve to the right key considering modifiedSchema
          try {
            if (modifiedSchema[curveName]) {
              acc[curveName] = (currentWell[modifiedSchema[curveName]] || [])
            }
            else if (curveMapping[curveName]) {
              acc[curveName] = (currentWell[curveName] || [])
            }
          } catch (error) {
            console.log('error when trying to map curve', error)
          }
        }
        return acc;
      }, {});

      const validateSchema = (data, schema) => {
        // schema is an array of strings
        const dataKeys = Object.keys(data);
        const isValid = schema.every((key) => dataKeys.includes(key) && data[key] !== null && data[key].length > 0)
        return isValid;
      };

      // validate schema, else raise notification
      const isValid = validateSchema(data, inputSchema);

      const depthKey = Object.keys(data).find((key) => key.includes('depth'));
      const depthLength = data[depthKey].length;
      const formattedDataToHaveSameLength = Object.keys(data).reduce((acc, curveName) => {
        if (inputSchema.includes(curveName)) {
          const isArrayOfNumbersOrNull = data[curveName].every((value) => typeof value === 'number' || value === null);
          if (isArrayOfNumbersOrNull) {
            acc[curveName] = data[curveName].slice(0, depthLength);
          }
          else {
            // the actual value is inside the first array, second element
            acc[curveName] = data[curveName].map((array) => array[1][0]).slice(0, depthLength);
          }
        }
        return acc;
      }, {});

      const validateLength = (data, schema) => {
        // validate if all arrays inside data have the same length
        const dataKeys = Object.keys(data);
        let isValid = true;
        schema.forEach((key) => {
          try {
            // if lengths are different or if there is a missing key
            if (data[key].length !== data[dataKeys[0]].length || !dataKeys.includes(key)) {
              isValid = false;
            }
          } catch (error) {
            console.log('error validating length', error)
            isValid = false;
          }
        })
        return isValid;
      };

      const isValidLength = validateLength(formattedDataToHaveSameLength, inputSchema);

      if (isValid && isValidLength) {
        setLoading(true);
        try {
          const curveType = modelName ? modelName.toLowerCase() : null;
          if (curveType.includes('gamma ray')) {
            const gammaRayResponse = await predictLithology(modelName, formattedDataToHaveSameLength);
            dispatch(receiveAiGrPrediction(gammaRayResponse.data.results.predictions));
            displayDrift(gammaRayResponse.data.drift_message);
            displaySuccessModel();
          } else if (curveType.includes('interpreted lithology')) {
            const responseRealtimeLithology = await predictLithology(modelName, formattedDataToHaveSameLength);
            dispatch(receiveRealtimeLithology(responseRealtimeLithology.data.results.predictions));
            dispatch(receiveLithologyScore(responseRealtimeLithology.data.results.probabilities));
            displayDrift(responseRealtimeLithology.data.drift_message);
            displaySuccessModel();
          } else if (curveType.includes('porosity')) {
            // TODO rename it to be only predict
            const mlPorosityResponse = await predictLithology(modelName, formattedDataToHaveSameLength);
            const porosityPredictionData = mlPorosityResponse && mlPorosityResponse.data && mlPorosityResponse.data.results
              && mlPorosityResponse.data.results.predictions ? mlPorosityResponse.data.results.predictions : [];
            const formattedPorosity = porosityPredictionData.map(v => v * 100);
            dispatch(receiveMLPorosity(formattedPorosity));
            displaySuccessModel();
          }
        } catch (e) {
          console.log('error listing predictLithology', e);
          errorNotification('Error while running prediction. Please contact support.');
        } finally {
          setLoading(false);
        }
      } else {
        if (!isValid) {
          errorNotification('Please fill all the required curves for this model.');
        }
        else if (!isValidLength) {
          errorNotification('All curves must have the same length. Please check your data.');
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      curve: curvesNames,
    },
    enableReinitialize: true,
    onSubmit: onSubmitForm,
  });

  const { handleSubmit, errors } = formik;

  const handleChangeInput = (schemaKey) => (value) => {
    const currentModifiedSchema = { ...modifiedSchema };
    currentModifiedSchema[schemaKey] = value;
    setModifiedSchema(currentModifiedSchema);
  };

  const deleteCurrentModel = async () => {
    try {
      const response = await deleteModel(modelName);
      // after response
      if (response.status === 200) {
        // TODO: if model is deleted, remove it from redux immediately?
        // try {
        //   const updatedModels = models
        //   delete updatedModels[modelName];
        //   dispatch(receiveModels(updatedModels));
        // } catch (e) {
        //   console.log('error updating models after deleting one', e);
        // }        
        successNotification('Model deleted successfully.');
      }
    }
    catch (e) {
      console.error('error deleting model', e);
      errorNotification('Error while deleting model. Please contact support.');
    }
  };

  return (
    <div style={{ display: 'flex', width: 360 }}>
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
          {inputSchema === undefined || isLoading ? (
            <Loading customStyles={{ width: 330, height: 200 }} />
          ) : null}
          {inputSchema && !isLoading && inputSchema.map((schemaKey) => {
            return (
              <div style={{ marginBottom: 10 }}>
                <label style={{ color: '#fff' }}>{schemaKey.replaceAll('_', ' ').toUpperCase()}</label><br />
                <Select
                  // default value is schemaKey if it exists on currentWell and is not empty else empty string
                  defaultValue={
                    defaultCurrentWell[schemaKey]
                      && !defaultCurrentWell[schemaKey].every((value) => value === null) // is not full of nulls
                      && defaultCurrentWell[schemaKey].every((value) => typeof value === 'number' || value === null) // made of numbers or nulls
                      && defaultCurrentWell[schemaKey].length >= defaultCurrentWell['depth'].length // equal or bigger than depth
                      ? schemaKey : ''}
                  className="select-settings"
                  style={{ width: 210 }}
                  dropdownClassName="select-dropdown-settings-models"
                  onChange={(value) => {
                    handleChangeInput(schemaKey)(value);
                  }}>
                  {curvesNames.map(item => (
                    <Select.Option key={item} style={{ background: '#1e1e1e' }} value={item} name={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
                {schemaKey === 'bit_size' && curveMapping && curveMapping.bit_size === null ? (
                  <Button
                    type="primary"
                    className="run-model"
                    htmlType="button"
                    onClick={openModalWellPhases}
                    style={{ marginLeft: 10 }}
                  >
                    + Bit Size
                  </Button>
                ) : null}
              </div>
            )
          })
          }
          {inputSchema && !isLoading ? (
            <div className="btn-continue">
              <Button
                type="primary"
                className="run-model"
                htmlType="submit"
              >
                Run
              </Button>
              <Button>
                Back
              </Button>
              <Popconfirm
                title="Are you sure you want to proceed?"
                onConfirm={deleteCurrentModel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>
            </div>
          ) : null}
        </div>
      </form>
    </div>
  )
};

const ListModels = ({
  openModalWellPhases, closeModalWellPhases, item, onChange,
  onClick, modelName, loadingOneModel, data, loading, onClose
}) => {
  const models = useSelector(state => state.models) || {};
  const [successModel, setSuccessModel] = useState(false);
  const [driftMessage, setDriftMessage] = useState('');

  const displaySuccessModel = () => {
    setSuccessModel(true);
  };
  const displayDriftMessage = (msg) => {
    setDriftMessage(msg);
  };

  const inputSchema = data && data.items && data.items[modelName] && data.items[modelName].input_schema;
  let metrics = models.metrics || {};
  if (!Object.keys(metrics).length) {
    const versions = data.items && data.items[modelName] && data.items[modelName].versions;
    if (versions && versions.length) {
      const productionVersion = versions.reduce((acc, version) => {
        Object.keys(version) && Object.keys(version).find((versionKey) => {
          if (version[versionKey].current_stage === 'Production') {
            acc.push(version[versionKey]);
          }
        });
        return acc;
      }, []);
    }
  }

  const metricsData = Object.keys(models.metrics).reduce((acc, metricKey, index) => {
    if (index === 0) {
      const metricsData = models.metrics[metricKey][0].metrics;
      {
        Object.keys(metricsData).map((metricValueKey) => {
          acc.push(
            <div style={{ display: 'flex', color: '#ffffff' }}>
              <b>{metricValueKey.replaceAll('_', ' ')}</b>: {metricsData[metricValueKey]}
            </div>
          );
        })
      }

      return acc;
    }
  }, []);

  const dateCreated = Object.keys(models.metrics).map((metricKey, index) => {
    if (index === 0) {
      return models && models.metrics && models.metrics[metricKey] && models.metrics[metricKey];
    }
  });

  const date = dateCreated && dateCreated[0] && dateCreated[0].utc_time_created;
  const formatDateCreated = date && format(date, "DD-MM-YYYY hh:mm");

  const tags = data.items && data.items[modelName] && data.items[modelName].tags;
  const description = data.items && data.items[modelName] && data.items[modelName].description;
  return (
    <div onClick={() => onClick(item)} className="models">
      <Collapse
        defaultActiveKey={['1']} onChange={onChange}
        expandIconPosition="right"
        forceRender={true}
      >
        <Panel forceRender={true} header={
          <div>
            <b>{item}</b>
          </div>
        }>
          <div>
            <div style={{ display: 'flex' }}>
              <h2 className="title-model-lithology">{item}</h2>
              <h2 className="title-model-settings">Settings</h2>
            </div>
            <div className="container-card-model">
              <div className="wrapper-card-model">
                {loadingOneModel ? (
                  <Loading customStyles={{ height: 200 }} />
                ) : (
                  <>
                    <ItemModel title="Description" description={description || '-'} />
                    <ItemModel title="Training Metrics" description={metricsData || '-'} />
                    <ItemModel title="Date created" description={formatDateCreated || '-'} />
                    {/* <ItemModel title="Date Registered" description={startEndTime[0] && startEndTime[0].start_time || '-'} /> */}
                  </>
                )
                }
              </div>
              <div>
                <div className="wrapper-card-settings">
                  {successModel ?
                    <div className="wrapper-message-results">
                      <div className="text-model-msg-success">
                        <h2 className="model-result-msg-success">Completed processing.</h2>
                        <h3 className="model-result-msg-applied">See the results by clicking below.</h3>
                      </div>
                      <Button className="btn-see-results" onClick={onClose}>
                        See Results
                      </Button>
                      <p className="model-result-msg-drift">{driftMessage}</p>
                    </div>
                    : <SelectSettings
                      inputSchema={inputSchema}
                      loading={loading}
                      modelName={modelName}
                      models={models}
                      tags={tags}
                      closeModalWellPhases={closeModalWellPhases}
                      openModalWellPhases={openModalWellPhases}
                      displaySuccessModel={displaySuccessModel}
                      displayDrift={displayDriftMessage}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ModelsModal;
