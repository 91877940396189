import store from 'store';
import {
  defaultValuesToCrosshair,
  disableDots
} from 'utils';

export const createDefaultConfig = (
  scales,
  displayZoomBtn,
  propagateZoom,
  getToolTipValues,
  getDefaultSeries,
  defaultCrossHair,
  totalGasData,
  totalCarbonData,
  c1NormalizedData
) => ({
  chart: {
    inverted: true,
    width: 220,
    marginTop: 0,
    margin: 0,
    marginLeft: 4,
    zoomType: 'x',
    events: {
      selection: (event) => {
        displayZoomBtn();
        propagateZoom(event);
      }
    },
    animation: false,
    shadow: false
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    allowDecimals: true,
    tickPosition: 'inside',
    tickColor: '#fff',
    gridLineColor: '#a4a4a4',
    gridLineWidth: 1,
    minorTickInterval: 1,
    zIndex: 800,
    opposite: true,
    tickLength: 0,
    labels: {
      enabled: true,
      y: -300
    }
  },
  yAxis: [
    {
      title: {
        text: ''
      },
      padding: 0,
      margin: 0,
      minorGridLineWidth: 1,
      minorGridLineColor: '#E0E0E0',
      tickStart: 0.1,
      type: 'logarithmic',
      minorTickInterval: 0.1,
      min: scales.totalGas.min || 0.1,
      max: scales.totalGas.max || 100,
      color: 'rgba(0, 0, 0, 0)',
      index: 0,
      gridLineWidth: 1,
      tickInterval: 1,
      zIndex: 10,
      step: true,
      allowDecimals: true,
      labels: {
        enabled: true,
        y: -2000
      }
    },
    {
      title: {
        text: ''
      },
      type: 'logarithmic',
      minorTickInterval: 0.1,
      min: scales.totalGas.min || 0.1,
      max: scales.totalGas.max || 100,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      color: scales.totalGas.color,
      index: 1,
      allowDecimals: true,
      labels: {
        enabled: true,
        y: -10000
      },
      gridLineWidth: 1,
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      tickInterval: 1,
      zIndex: 10,
      step: true
    },
    {
      type: 'logarithmic',
      minorTickInterval: 0.1,
      title: {
        text: ''
      },
      min: scales.totalCarbon.min || 0.1,
      max: scales.totalCarbon.max || 100,
      color: scales.totalCarbon.color,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      index: 2,
      allowDecimals: true,
      labels: {
        enabled: true,
        y: -10000
      },
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      tickInterval: 1,
      zIndex: 10,
      step: true
    },
    {
      type: 'logarithmic',
      minorTickInterval: 0.1,
      title: {
        text: ''
      },
      min: scales.c1Normalized.min || 0.1,
      max: scales.c1Normalized.max || 100,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      color: scales.c1Normalized.color,
      index: 3,
      allowDecimals: true,
      labels: {
        enabled: false
      },
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      tickInterval: 1,
      zIndex: 10,
      step: true
    }
  ],
  plotOptions: {
    series: {
      marker: {
        enabled: false
      },
      enableMouseTracking: false,
      showInLegend: false,
      hover: false
    }
  },
  exporting: { enabled: false },
  tooltip: {
    shared: true,
    ...defaultCrossHair,
    formatter: getToolTipValues('total-gas')
  },
  series: getDefaultSeries(
    totalGasData,
    totalCarbonData,
    c1NormalizedData
  )
});

export const getDefaultSeries = (
  totalGasData, totalCarbonData, c1NormalizedData
) => {
  const { totalGas, totalCarbon, c1Normalized } = store.get('configScales');
  const totalGasPlaceholderData = defaultValuesToCrosshair(totalGasData, 0.1);
  return [
    {
      type: 'area',
      ...disableDots,
      name: 'totalPlaceholder',
      data: totalGasPlaceholderData,
      color: '#fff',
      min: totalGas.min,
      max: totalGas.max,
      index: 0,
      yAxis: 0,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'totalGas',
      data: totalGasData,
      color: totalGas.color,
      lineColor: totalGas.color,
      min: totalGas.min,
      max: totalGas.max,
      index: 1,
      yAxis: 1,
      fillOpacity: 0.1,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'totalCarbon',
      data: totalCarbonData,
      color: totalCarbon.color,
      lineColor: totalCarbon.color,
      min: totalCarbon.min,
      max: totalCarbon.max,
      index: 2,
      yAxis: 2,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'c1Normalized',
      data: c1NormalizedData,
      color: c1Normalized.color,
      lineColor: c1Normalized.color,
      min: c1Normalized.min,
      max: c1Normalized.max,
      index: 3,
      yAxis: 3,
      allowDecimals: true
    }
  ];
};

