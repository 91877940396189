export const defaultConfigAxis = {
  yMin: 0,
  yMax: 100,
  xMin: 6000,
  xMax: 7000
};

export const relativeEtheneChart = (data) => ({
  chart: {
    type: 'scatter',
    height: 425,
    width: 921,
    marginBottom: 90,
    marginTop: 30
  },
  title: {
    text: ''
  },
  xAxis: {
    tickInterval: 100,
    tickLength: 10,
    tickColor: '#CCCCCC',
    minorGridLineWidth: 0,
    minorTickInterval: 20,
    minorTickWidth: 1,
    minorTickLength: 5,
    minorTickColor: '#CCCCCC',
    plotLines: [
      /* {
        color: '#229E00',
        width: 2,
        value: 6600,
        dashStyle: 'LongDash',
        zIndex: 1,
        } */
    ],
    title: {
      text: 'Depth MD (m)',
      margin: 10,
      style: {
        color: '#000000',
        fontWeight: 'bold'
      }
    }
  },
  yAxis: {
    min: 0,
    max: 100,
    tickInterval: 10,
    title: {
      text: 'Relative Ethane and Ethene (%)',
      margin: 30,
      style: {
        color: '#000000',
        fontWeight: 'bold'
      }
    }
  },
  legend: {
    align: 'right',
    layout: 'vertical',
    floating: true,
    symbolWidth: 40
  },
  tooltip: {
    enabled: false
  },
  series: [{
    name: 'Ethene (Relative %)',
    lineWidth: 0,
    color: '#FF8519',
    opacity: 0.9,
    marker: {
      symbol: 'circle',
      width: '5px' 
    },
    data
  } 
    /* {
      name: 'Bit Change',
      type: 'spline',
      color: '#229E00',
      dashStyle: 'longdash',
      marker: {
        enabled: false
      }
    } */
  ], 
  credits: {
    enabled: false
  }
  // {
  //   name: 'Average Filtered Ethene',
  //   color: '#2B2B2B',
  //   lineWidth: 0,
  //   marker: {
  //     symbol: 'square'
  //   },
  //   data: [
  //     [6100, 29.9],
  //     [6300, 90],
  //     [6600, 50],
  //     [6300, 71.5],
  //   ]
  // }],
});

