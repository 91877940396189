export const calculateEthane = (isFid, c2, c2s, c2h6, c2h4) => {
  // When FID for C2 determination
  if (isFid) {
    if (c2 < c2s && c2s > 0) {
      return Math.round(c2 / c2s * 100);
    }
    return -1;
  // When MS - mass for C2 determination
  }
  if (c2h6 > 0 && c2h4 > 0) {
    return Math.round((c2h6 / (c2h6 + c2h4)) * 100);
  }
  return -1;
};

export const calculateEthene = (isFID, c2, c2s, c2H6, c2H4) => {
  const currentEthane = calculateEthane(isFID, c2, c2s, c2H6, c2H4);
  if (currentEthane === -1) {
    return -1;
  }
  return 100 - currentEthane;
};

