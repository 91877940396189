import styled, { css } from 'styled-components'
import { variant } from 'styled-system'

const Text = styled.span`
  color: #181C1C;
  ${({ theme }) => css`
      ${variant({
        variants: {
          title: {
            fontSize: 19,
            fontWeight: 600,
            color: theme.colors.dark[100]
          },
          subtitle: {
            fontSize: 16,
            fontWeight: 500,
            color: theme.colors.support.white,
            dark: {
              color: theme.colors.dark[100]
            }
          },
          paragraph: {
            fontSize: 12,
            fontWeight: 600,
            color: '#D5D5D7'
          },
          able: {
            fontSize: 17,
            fontWeight: 400,
            color: '#FFFFFF'
          },
          disable: {
            fontSize: 17,
            fontWeight: 400,
            color: theme.colors.dark[600]
          },
          label: {
            fontSize: 17,
            fontWeight: 500,
            color: '#FFFFFF'
          }
        }
      })}
  `}
`

export default Text
