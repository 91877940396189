import React from "react"
import { CardTitle, CardValue, CardUnit, StyledCard } from "./styles"

const Indicator = ({ id, title, value, unit, ...props }) => {
    return (
        <StyledCard>
            <CardTitle>{title}</CardTitle>
            <div>
                <CardValue>{value}</CardValue>
                <CardUnit>{unit}</CardUnit>
            </div>
        </StyledCard>
    )
}

export default Indicator
