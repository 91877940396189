import React from 'react';
import closeIcon from 'images/cancel.png';
import './style.scss';

const ButtonCloseModal = ({closeWell}) => (
  <button
    type="button"
    className="btn-close-modal" 
    onClick={closeWell}
  >
    <img src={closeIcon} alt="close modal"/>
  </button>
);

export default ButtonCloseModal;