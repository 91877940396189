/* eslint-disable */
import React, {
  Component,
  useEffect,
  useState
} from 'react';

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import store from 'store';

import {
  defaultCrossHair,
  defaultValuesToCrosshair,
  disableDefaultZoom,
  disableLabels,
  displayZoomBtn,
  getToolTipValues,
  propagateZoom,
  syncronizeTooltip,
  toggleCromaChart,
  toggleTrack
} from '../../../utils';
import {
  CHROMA_C1,
  CHROMA_C2,
  CHROMA_C3,
  CHROMA_IC4,
  CHROMA_IC5,
  CHROMA_NC4,
  CHROMA_NC5,
  DEFAULT_SCALES
} from '../../../utils/constants';
import GlobalCutOff from '../../../utils/GlobalCutOff';

Exporting(Highcharts);

export const chromatographyTracks = [
  CHROMA_C1,
  CHROMA_C2,
  CHROMA_C3,
  CHROMA_IC4,
  CHROMA_NC4,
  CHROMA_IC5,
  CHROMA_NC5
];

const scales = store.get('configScales') || { ...DEFAULT_SCALES };

const updatedHeader = {
  c1: 0,
  c2: 0,
  c3: 0,
  ic4: 0,
  nc4: 0,
  ic5: 0,
  nc5: 0,
  scales
};

const initialState = {
  c1: 0,
  c2: 0,
  c3: 0,
  ic4: 0,
  nc4: 0,
  ic5: 0,
  nc5: 0,
  scales
};

const ChartHeader = React.memo(({ rangeValues, positionsLeft }) => {
  const [headerState, setHeaderState] = useState({
    ...initialState,
    scales: store.get('configScales')
  });

  const wells = useSelector(state => state.wells);

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    chromaPlace: true,
    [CHROMA_C1]: true,
    [CHROMA_C2]: true,
    [CHROMA_C3]: true,
    [CHROMA_IC4]: true,
    [CHROMA_NC4]: true,
    [CHROMA_IC5]: true,
    [CHROMA_NC5]: true
  });

  const tooggleCallbacks = {
    chromaPlace: toggleCromaChart,
    [CHROMA_C1]: toggleCromaChart,
    [CHROMA_C2]: toggleCromaChart,
    [CHROMA_C3]: toggleCromaChart,
    [CHROMA_IC4]: toggleCromaChart,
    [CHROMA_NC4]: toggleCromaChart,
    [CHROMA_IC5]: toggleCromaChart,
    [CHROMA_NC5]: toggleCromaChart
  };

  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
      callback(parseInt(index))(event);
    }
  };

  // --- Open modal with config
  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  // should receive the index and display the current data
  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const { c1, c2, c3, c4, c5, ic4Normal, ic5Normal } = wells.currentWell;
      const index = wells.selectedDepthIndex;
      const c1Value = c1 && c1[index] ? c1[index] : 0;
      const c2Value = c2 && c2[index] ? c2[index] : 0;
      const c3Value = c3 && c3[index] ? c3[index] : 0;
      const nc4Value = c4 && c4[index] ? c4[index] : 0;
      const ic4Value = ic4Normal && ic4Normal[index] && ic4Normal ? ic4Normal[index] : 0;
      const nc5Value = c5 && c5[index] ? c5[index] : 0;
      const ic5Value = ic5Normal && ic5Normal[index] ? ic5Normal[index] : 0;
      setHeaderState({
        ...headerState,
        c1: c1Value > 0 ? c1Value.toFixed(2) : c1Value,
        c2: c2Value > 0 ? c2Value.toFixed(2) : c2Value,
        c3: c3Value > 0 ? c3Value.toFixed(2) : c3Value,
        ic4: ic4Value > 0 ? ic4Value.toFixed(2) : ic4Value,
        nc4: nc4Value > 0 ? nc4Value.toFixed(2) : nc4Value,
        ic5: ic5Value > 0 ? ic5Value.toFixed(2) : ic5Value,
        nc5: nc5Value > 0 ? nc5Value.toFixed(2) : nc5Value,
      });
    }
  }, [wells]);

  // Should receive all scales update
  useEffect(() => {
    // Chromatography
    document.addEventListener(CHROMA_C1, ({ detail }) => {
      updatedHeader.scales[CHROMA_C1] = detail;
      headerState.scales[CHROMA_C1] = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    });
    document.addEventListener(CHROMA_C2, ({ detail }) => {
      updatedHeader.scales[CHROMA_C2] = detail;
      headerState.scales[CHROMA_C2] = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    });
    document.addEventListener(CHROMA_C3, ({ detail }) => {
      updatedHeader.scales[CHROMA_C3] = detail;
      headerState.scales[CHROMA_C3] = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    });
    document.addEventListener(CHROMA_IC4, ({ detail }) => {
      updatedHeader.scales[CHROMA_IC4] = detail;
      headerState.scales[CHROMA_IC4] = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    });
    document.addEventListener(CHROMA_NC4, ({ detail }) => {
      updatedHeader.scales[CHROMA_NC4] = detail;
      headerState.scales[CHROMA_NC4] = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    });
    document.addEventListener(CHROMA_IC5, ({ detail }) => {
      updatedHeader.scales[CHROMA_IC5] = detail;
      headerState.scales[CHROMA_IC5] = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    });
    document.addEventListener(CHROMA_NC5, ({ detail }) => {
      updatedHeader.scales[CHROMA_NC5] = detail;
      headerState.scales[CHROMA_NC5] = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    });

    return () => {
    };
  }, [rangeValues]);

  const { scales, c1, c2, c3, ic4, nc4, ic5, nc5 } = headerState;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #333',
        alignItems: 'flex-start',
        marginLeft: '7px',
        alignSelf: 'flex-start'
      }}
    >
      <div
        className="chromatography-title-container"
        style={{
          height: '131px',
          width: '280px'
        }}
      >
        <div className="chromatography-title-box">
          <div className="chromatography-column">
            <div
              className="c-c1"
              key="c-c1"
              style={{
                color: scales[CHROMA_C1].color,
                borderColor: scales[CHROMA_C1].color,
                display: 'flex',
                justifyContent: 'center'
              }}
              onClick={dispatchModal(CHROMA_C1)}
              data-cmd="openConfig"
              data-cy="open-config-chroma-c1"
            >
              <div className="chromatography-column__target" style={{ width: '100px' }}>
                <span
                  className="chromatography-column__title"
                  data-cmd="openConfig"
                >
                  C1
                  <span data-cmd="openConfig">
                    {c1 ? `(${c1})` : ''}
                  </span>
                </span>
                <label
                  className="chromatography-column__show-checkbox container-checkbox"
                  style={{
                    marginLeft: 0
                  }}
                >
                  <input
                    type="checkbox"
                    checked={tracks[CHROMA_C1]}
                    className="checkbox-input"
                    data-name="chromatographyC1"
                    data-cmd="toggle"
                    data-index="1"
                    onChange={toggleTrack}
                    data-cy="checkbox-c1"
                  />
                  <span
                    className="checkmark"
                    style={{
                      backgroundColor: scales[CHROMA_C1].color
                    }}
                  />
                </label>
              </div>
            </div>
            <div
              className="c-c2"
              key="c-c2"
              style={{
                color: scales[CHROMA_C2].color,
                borderColor: scales[CHROMA_C2].color,
                display: 'flex',
                justifyContent: 'center'
              }}
              data-cmd="openConfig"
              onClick={dispatchModal(CHROMA_C2)}
              data-cy="open-config-chroma-c2"
            >
              <div
                className="chromatography-column__target"
                style={{
                  width: '100px',
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <span
                  className="chromatography-column__title"
                  data-cmd="openConfig"
                >
                  C2 
                  <span data-cmd="openConfig">
                    {c2 ? `(${c2})` : ''}
                  </span>
                </span>
                <label
                  className="chromatography-column__show-checkbox container-checkbox"
                  style={{ marginLeft: 0 }}
                >
                  <input
                    type="checkbox"
                    checked={tracks[CHROMA_C2]}
                    className="checkbox-input"
                    data-name={CHROMA_C2}
                    data-index="2"
                    onChange={toggleTrack}
                    data-cmd="toggle"
                    data-cy="checkbox-c2"
                  />
                  <span
                    className="checkmark"
                    style={{backgroundColor: scales[CHROMA_C2].color}}
                  />
                </label>
              </div>
            </div>
            <div
              className="c-c3"
              key="c-c3"
              style={{
                color: scales[CHROMA_C3].color,
                borderColor: scales[CHROMA_C3].color,
                display: 'flex',
                justifyContent: 'center'
              }}
              data-cmd="openConfig"
              onClick={dispatchModal(CHROMA_C3)}
              data-cy="open-config-chroma-c3"
            >
              <div className="chromatography-column__target" style={{width: '100px', display: 'flex', flexDirection: 'row'}}>
                <span
                  className="chromatography-column__title"
                  data-cmd="openConfig"
                >
                  C3 
                  <span data-cmd="openConfig">
                    {c3 ? `(${c3})` : ''}
                  </span>
                </span>
                <label className="chromatography-column__show-checkbox container-checkbox" style={{marginLeft: 0 }}>
                  <input
                    type="checkbox"
                    checked={tracks[CHROMA_C3]}
                    className="checkbox-input"
                    data-name={CHROMA_C3}
                    data-index="3"
                    onChange={toggleTrack}
                    data-cmd="toggle"
                    data-cy="checkbox-c3"
                  />
                  <span className="checkmark" style={{backgroundColor: scales[CHROMA_C3].color}}/>
                </label>
              </div>
            </div>
          </div>
          <div className="chromatography-column">
            <div
              className="c-ic4"
              key="c-ic4"
              style={{
                color: scales[CHROMA_IC4].color,
                borderColor: scales[CHROMA_IC4].color,
                display: 'flex',
                justifyContent: 'center'
              }}
              data-cmd="openConfig"
              onClick={dispatchModal(CHROMA_IC4)}
              data-cy="open-config-chroma-ic4"
            >
              <div className="target-ic4" style={{ width: '100px', display: 'flex', flexDirection: 'row' }}>
                <span
                  className="title-ic4"
                  data-cmd="openConfig"
                >
                iC4 {ic4 ? `(${ic4})` : ''}
                </span>
                <label className="show-checkbox-ic4 container-checkbox" style={{ marginLeft: '5px' }}>
                  <input
                    type="checkbox"
                    checked={tracks[CHROMA_IC4]}
                    className="checkbox-input"
                    data-name={CHROMA_IC4}
                    data-index="4"
                    onChange={toggleTrack}
                    data-cmd="toggle"
                    data-cy="checkbox-ic4"
                  />
                  <span className="checkmark" style={{backgroundColor: scales[CHROMA_IC4].color}}/>
                </label> 
              </div>
            </div>
            <div
              className="c-nc4"
              key="c-nc4"
              style={{
                color: scales[CHROMA_NC4].color,
                borderColor: scales[CHROMA_NC4].color,
                display: 'flex',
                justifyContent: 'center'
              }}
              data-cmd="openConfig"
              onClick={dispatchModal(CHROMA_NC4)}
              data-cy="open-config-chroma-nc4"
            >
              <div className="target-nc4" style={{width: '100px', display: 'flex', flexDirection: 'row'}}>
                <span className="title-nc4" data-cmd="openConfig">nC4 {nc4 ? `(${nc4})` : ''}</span>
                <label className="show-checkbox-nc4 container-checkbox" style={{ marginLeft: '5px' }}>
                  <input
                    type="checkbox"
                    checked={tracks[CHROMA_NC4]}
                    className="checkbox-input"
                    data-name={CHROMA_NC4}
                    data-index="5"
                    onChange={toggleTrack}
                    data-cmd="toggle"
                    data-cy="checkbox-nc4"
                  />
                  <span className="checkmark" style={{backgroundColor: scales[CHROMA_NC4].color}}/>
                </label>
              </div>
            </div>
            <div
              className="c-ic5"
              key="c-ic5"
              data-cmd="openConfig"
              style={{
                color: scales[CHROMA_IC5].color,
                borderColor: scales[CHROMA_IC5].color,
                display: 'flex',
                justifyContent: 'center'
              }}
              onClick={dispatchModal(CHROMA_IC5)}
              data-cy="open-config-chroma-ic5"
            >
              <div className="target-iC5" style={{width: '100px', display: 'flex', flexDirection: 'row'}}>
                <span
                  className="title-iC5"
                  data-cmd="openConfig"
                >
                iC5 {ic5 ? `(${ic5})` : ''}
                </span>
                <label className="show-checkbox-iC5 container-checkbox" style={{marginLeft: '5px' }}>
                  <input
                    type="checkbox"
                    checked={tracks[CHROMA_IC5]}
                    className="checkbox-input"
                    data-name={CHROMA_IC5}
                    data-index="6"
                    onChange={toggleTrack}
                    data-cmd="toggle"
                    data-cy="checkbox-ic5"
                  />
                  <span className="checkmark" style={{backgroundColor: scales[CHROMA_IC5].color}}/>
                </label>
              </div>
            </div>
            <div
              className="c-nc5"
              key="c-nc5"
              data-cmd="openConfig"
              style={{
                color: scales[CHROMA_NC5].color,
                borderColor: scales[CHROMA_NC5].color,
                display: 'flex',
                justifyContent: 'center'
              }}
              onClick={dispatchModal(CHROMA_NC5)}
              data-cy="open-config-chroma-nc5"
            >
              <div className="target-nc5" style={{width: '100px', display: 'flex', flexDirection: 'row'}}>
                <span
                  className="title-nc5"
                  data-cmd="openConfig"
                >
                nC5 {nc5 ? `(${nc5})` : ''}
                </span>
                <label className="show-checkbox-nc5 container-checkbox" style={{marginLeft: '5px' }}>
                  <input
                    type="checkbox"
                    checked={tracks[CHROMA_NC5]}
                    className="checkbox-input"
                    data-name={CHROMA_NC5}
                    data-index="7"
                    onChange={toggleTrack}
                    data-cmd="toggle"
                    data-cy="checkbox-nc5"
                  />
                  <span className="checkmark" style={{backgroundColor: scales[CHROMA_NC5].color}}/>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="chromatography-labels">
          {
            rangeValues.map((label, index) => (
              <span
                className={`chromatography-label-${(index)}`}
                style={{ left: positionsLeft[index] }}
                index={label.toString()}
              >
                {label}
              </span>
            ))
          }
        </div>
      </div>
    </div>
  );
});

const creatingyAxis = (index, withLabel = true) => {
  const scalesConfig = store.get('configScales') || DEFAULT_SCALES;
  const min = Number(scalesConfig[CHROMA_C1].min);
  const max = Number(scalesConfig[CHROMA_C1].max);

  const labels = withLabel ? {
    enabled: true,
    style: {
      color: '#333'
    },
    y: -800,
    x: -15,
    zIndex: 10
  } : { enabled: false };
  return {
    title: {
      text: ''
    },
    min,
    max,
    minorGridLineWidth: 1,
    minorGridLineColor: '#E0E0E0',
    tickStart: 0.1,
    type: 'logarithmic',
    minorTickInterval: 0.1,
    tickInterval: 1,
    index,
    zIndex: 10,
    step: true
  };
};

const createSerie = (color, index, data, zIndex = 10) => ({
  fillOpacity: 0.1,
  name: ' ',
  type: 'spline',
  data,
  lineColor: color,
  yAxis: index,
  zIndex,
  lineWidth: 1.2
});

const getDefaultSeries = (
  c1, c2, c3,
  ic4, nc4, ic5, nc5
) => {
  const {
    chromatographyC1,
    chromatographyC2,
    chromatographyC3,
    chromatographyIc4,
    chromatographyNc4,
    chromatographyIc5,
    chromatographyNc5
  } = store.get('configScales');
  const c1PlaceHolder = defaultValuesToCrosshair(c1, Number(chromatographyC1.max));
  return [
    createSerie('rgba(0, 0, 0, 0)', 0, c1PlaceHolder),
    // c1
    createSerie(chromatographyC1.color, 0, c1),
    // c2
    createSerie(chromatographyC2.color, 0, c2),
    // c3
    createSerie(chromatographyC3.color, 0, c3),
    // ic4
    createSerie(chromatographyIc4.color, 0, ic4),
    // nc4
    createSerie(chromatographyNc4.color, 0, nc4),
    // ic5
    createSerie(chromatographyIc5.color, 0, ic5),
    // nc5
    createSerie(chromatographyNc5.color, 0, nc5)
  ];
};

// TODO leo should create a hook
const createEventListener = (chart, positioningTheLabels) => (curveName, index) => {
  document.addEventListener(curveName, ({ detail }) => {
    if (chart && chart.yAxis) {
      // Ensure to apply min and max always to the first placeholder series
      chart.yAxis[0].update({
        min: Number(detail.min),
        max: Number(detail.max)
      });
      chart.yAxis[0].redraw();

      chart.series[0].update({
        min: Number(detail.min),
        max: Number(detail.max)
      });
      chart.series[0].redraw();

      positioningTheLabels();
      const serie = chart.series[index];
      serie.update({
        color: detail.color,
        lineColor: detail.color
      });
      serie.redraw();
    }
  });
};

class Chromatography extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      c1: [],
      c2: [],
      c3: [],
      ic4: [],
      nc4: [],
      ic5: [],
      nc5: [],
      series: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true
      },
      rangeValues: [],
      positionsLeft: []
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      c1, c2, c3, nc4,
      ic4, nc5, ic5, selectedWell,
      currentWell
    } = nextProps;

    if (
      selectedWell !== this.props.selectedWell
      || nextProps.selectedWell !== this.props.selectedWell
      || (this.props.beforeWell
        && this.props.beforeWell
        && this.props.beforeWell.depth
        && this.props.beforeWell.depth.length) !== 
        (currentWell && currentWell.depth && currentWell.depth.length)
    ) {
      this.chart.update({
        series: getDefaultSeries(c1, c2, c3, ic4, nc4, ic5, nc5),
        yAxis: {
          labels: {
            enabled: true,
            y: -100000,
            zIndex: 800
          }
        }
      });

      const min = currentWell.depth && currentWell.depth.length ? currentWell.depth.length - 200 : 0;
      const max = currentWell.depth && currentWell.depth.length ? currentWell.depth.length: 0;

      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(min, max);
      }, 200);
      return true;
    }

    // update the rage values
    if (nextState.rangeValues.length !== this.state.rangeValues) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  componentDidMount() {
    const { parentContainer } = this.props;
    this.vpHeight = document.querySelector(parentContainer).clientHeight + 20 || 0;

    this.createChart();
    // Dispatch the labels to the header
    this.positioningTheLabels();

    GlobalCutOff.registerInstances(
      'chromatography',
      this.chart
    );

    syncronizeTooltip('#chromatography');
    document.addEventListener('toggle-chroma-chart', ({ detail: { index } }) => {
      toggleTrack(this)(index);
    });

    const addSerieConfigListener = createEventListener(this.chart, this.positioningTheLabels);
    addSerieConfigListener(CHROMA_C1, 1);
    addSerieConfigListener(CHROMA_C2, 2);
    addSerieConfigListener(CHROMA_C3, 3);
    addSerieConfigListener(CHROMA_IC4, 4);
    addSerieConfigListener(CHROMA_NC4, 5);
    addSerieConfigListener(CHROMA_IC5, 6);
    addSerieConfigListener(CHROMA_NC5, 7);
  }

  // TODO create a hook to this method
  positioningTheLabels = () => {
    const {childNodes} = this.chart.axes[1].labelGroup.element;
    const labels = [...childNodes].map(element => {
      if (element.childNodes.length === 2) {
        return element.childNodes[1].innerHTML;
      }
      return element.innerHTML;
    }).filter(x => x);
    const positionsLeft = [...childNodes].map(element => `calc(${element.getAttribute('x')}px - 24px)`);
    this.setState({
      rangeValues: labels,
      positionsLeft
    });
  }

  createChart = () => {
    const { id } = this.props;
    const { c1, c2, c3, ic4, nc4, ic5, nc5 } = this.state;
    this.chart = Highcharts.chart(id, {
      chart: {
        zoomType: 'x',
        inverted: true,
        backgroundColor: '#fff',
        width: 290,
        margin: 0,
        marginLeft: 10,
        ...disableDefaultZoom,
        events: {
          selection: (event) => {
            event.preventDefault();
            displayZoomBtn();
            propagateZoom(event);
          }
        },
        animation: false,
        shadow: false
      },
      boost: {
        useGPUTranslations: true,
        usePreAllocated: true
      },
      plotOptions: {
        series: {
          boostThreshold: 2,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
          enableMouseTracking: false,
          showInLegend: false
        }
      },
      exporting: { enabled: false },
      tooltip: {
        ...defaultCrossHair,
        formatter: getToolTipValues('chromatography')
      },
      title: ' ',
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: [
        {
          minorGridLineWidth: 1,
          minorGridLineColor: '#E0E0E0',
          offset: 15,
          opposite: true,
          tickLength: 0,
          lineColor: '#fff',
          ...disableLabels,
          gridLineColor: '#a4a4a4',
          gridLineWidth: 1,
          labels: {
            enabled: true,
            y: -10000
          }
        }
      ],
      yAxis: [
        creatingyAxis(0, true)
      ],
      series: getDefaultSeries(
        c1, c2, c3, ic4, nc4, ic5, nc5
      )
    });
  }

  render() {
    const { id } = this.props;
    const { rangeValues, positionsLeft } = this.state;
    return (
      <div
        className="chart__box-container"
        style={{
          marginLeft: '20px',
          marginRight: '5px',
          width: '289px'
        }}
      >
        <ChartHeader
          rangeValues={rangeValues}
          positionsLeft={positionsLeft}
        />
        <div
          id={id}
          style={{
            height: 'calc(100% - 132px)'
          }}
          data-cy="chart-chromatography"
        />
      </div>
    );
  }
}

Chromatography.defaultProps = {
  id: 'chromatography',
  parentContainer: '.charts-container',
  c1: [],
  c2: [],
  c3: [],
  ic5: [],
  ic4: [],
  nc4: [],
  nc5: []
};

export default React.memo(Chromatography);
