import React from 'react';

const ChartHeader = ({
  title, unit, className
}) => (
  <div className={className}>
    <span>{title}</span>
    <span>({unit})</span>
  </div>
);

ChartHeader.defaultProps = {
  title: 'Total Depth',
  unit: null,
  className: 'charts-header__total-depth'
};

export default ChartHeader;
