import React from 'react';
import logo from '../../images/logogeowellex.svg';
import { Alert, Icon } from 'antd';
import * as Yup from 'yup';
import Input from '../Input';
import Button from '../Button';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import './style.scss';


const ForgotSchema = Yup.object().shape({
   email: Yup.string()
    .min(2, 'Too Short!')
    .required('Required')
    .email()
 });

const ForgotPassword = ({onSubmitForgot, errorForgot, backToLogin, isSubmited}) => {
  return(
    <div className="container-forgot">
    <Formik
      validationSchema={ForgotSchema}
      initialValues = {{
        email: '',
      }}
      onSubmit={onSubmitForgot} 
      render={({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        
        <Form onSubmit={handleSubmit} className="form-forgot">
          <h1 className="title-forgot">Forgot your password?</h1>
          {errorForgot && (
            <p className="msg-error-forgot">
              {errorForgot}
            </p>
          )}
          <p>{isSubmitting}</p>
          {isSubmited && (
              <Alert message="If you have an account, we'll email you a reset link."
               type="success" className="alert"
               showIcon
              />
          )}
          <Input 
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            text="text"
            name="email"
            placeholder="Email"
          />
          <ErrorMessage name="email" component="span" className="error-style" />
          <Button
            type="submit"
            className="btn btn__forgot"
            disabled={isSubmitting}
          >
            <span className="msg-reset-password">RESET PASSWORD</span>
          </Button>
          <a
            onClick={backToLogin}
            className="msg-back-sing-in"
          >
            BACK TO LOG IN
          </a>
          <hr/>
        </Form>
      )}
    />
    </div>
  );
}

export default ForgotPassword;
