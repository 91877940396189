import React, { useContext, useEffect, useState } from "react";
import Fieldset from "../../Fieldset";
import List from "../../List";

import { Button, Icon, Switch, Tooltip } from "antd";

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { UnitsConfigContext } from "..";
import { UnitParametersInitalValues, depthUnits, pressureUnits, radiusUnits } from "./utils";
import useHydraulicsContext from "../../../../new/Hydraulics/useHydraulics";

function UnitsConfig({ onCancel, ...props }) {
    const { unitsConfigDetails, setUnitsConfigDetails } = useHydraulicsContext();

    const methods = useForm({
        defaultValues: unitsConfigDetails ? unitsConfigDetails : UnitParametersInitalValues,
    });

    const { handleSubmit, setValue, getValues, control, formState: { errors, isSubmitSuccessful } } = methods;

    function updateUnitParameters(data) {
        setUnitsConfigDetails(data)
        onCancel()
    }

    return (

        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(updateUnitParameters)}>
                    <Fieldset>
                        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', gap: '8px' }}>
                            <List
                                label={'Depth Unit'}
                                name={'depth_unit'}
                                control={control}
                                options={depthUnits}
                                errors={errors.depth_unit} >
                                {depthUnits && depthUnits.map((value) => <Option key={value} value={value}>{value}</Option>)}
                            </List>
                            <List
                                label={'Pressure Unit'}
                                name={'pressure_unit'}
                                control={control}
                                options={pressureUnits}
                                errors={errors.pressure_unit} >
                                {pressureUnits && pressureUnits.map((value) => <Option key={value} value={value}>{value}</Option>)}
                            </List>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', gap: '8px' }}>
                            <List
                                label={'Bit Unit'}
                                name={'radius_unit'}
                                control={control}
                                options={radiusUnits}
                                errors={errors.radius_unit} >
                                {radiusUnits && radiusUnits.map((value) => <Option key={value} value={value}>{value}</Option>)}
                            </List>
                        </div>

                    </Fieldset>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            htmlType={'button'}
                            onClick={onCancel}
                            style={{ color: 'red' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType={'submit'}
                        >Save</Button>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}
export default UnitsConfig;