import React, { createContext, useState, useEffect } from 'react';
import { Button, Tooltip, Modal, notification } from 'antd';
import SettingsForm from './SettingsForm';
import styled from "styled-components";
import { modalStyleSelectInWell } from '../../../utils/index';
import useHydraulicsContext from '../../../new/Hydraulics/useHydraulics';
import { CustomizeButton } from '../HeaderHydraulics/styles.js'

export const StyledModal = styled(Modal)`
    &.ant-modal > .ant-modal-content{
       
        width: 875px;
        color: #fff;
        border: none;
        background-color: #16151F;
        max-height: 90%;
        overflow-y:scroll;

        & .ant-modal-header{
            color: #fff;
            border: none;
            background: #16151F;
            padding-top: 30px;
        }
        & .ant-modal-title{
            color: #fff;
            font-size: 36px;
        }
        & .ant-modal-close{
            top: 5px;
            color: #fff;
 
        }

    }
    
`

export const SettingsFormContext = createContext({})


function SettingsFormModal({ onCancel, ...props }) {

    const [wellPhasesDetails, setWellPhasesDetails] = useState()
    const [fluidParametersDetails, setFluidParametersDetails] = useState()
    const [bitParametersDetails, setBitParametersDetails] = useState()

    const { response } = useHydraulicsContext()

    const responseNotification = () => {
        const openResponseNotification = () => {
            if (response.type == 'success') {
                notification.success({
                    duration: 6,
                    message: 'Request attempt sucessful!',
                    description: response.message,
                    className: 'bit-size-notification'
                });
            }
            notification.error({
                duration: 6,
                message: 'Request attempt has failed!',
                description: response.message,
                className: 'bit-size-notification'
            });

        };
        if (response != null) {
            openResponseNotification();
            return;
        }
    };


    useEffect(() => {
        responseNotification()
    }, [response]);

    return (
        <SettingsFormContext.Provider value={{
            onCancel,
            wellPhasesDetails,
            setWellPhasesDetails,
            fluidParametersDetails,
            setFluidParametersDetails,
            bitParametersDetails,
            setBitParametersDetails,
        }}>
            <StyledModal {...props}
                title="Hydraulics"
                style={{
                    width: '875px',
                }}
                footer={''}
                onCancel={onCancel}
            >
                <SettingsForm onCancel={onCancel} />
            </StyledModal >
        </SettingsFormContext.Provider>
    )
}


export default SettingsFormModal;