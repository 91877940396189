function pascalToPsi(val) {
    return Number(val * 0.000145038);
}

function secondToMin(val) {
    return Number(val / 60);
}

export function testConvertDataPressure(data) {
    // Rename the keys
    const convertedData = {
        "Total Anular": pascalToPsi(data['total_anu_press_loss']),
        "Total Pipe": pascalToPsi(data['total_pipe_press_loss']),
        "Total Pipe TJ": pascalToPsi(data['total_pipe_tj_press_loss']),
        "Total Anular TJ": pascalToPsi(data['total_anu_tj_press_loss']),
        "Total Surface": pascalToPsi(data['surface_pressure_loss']),
        "Total Bit": pascalToPsi(data['bit_pressure_loss']),
        "Total Pressure Losses": pascalToPsi(data['total_press_loss'])
    };

    return convertedData;
}

export function testConvertDataFlow(model, correlation) {
    // Rename the keys
    const convertedData = {
        "Lag Time (min)": secondToMin(correlation['lag_time']),
        "Fluid Lag Time (min)": secondToMin(correlation['flu_lag_time']),
        "Geral Transport Ratio (%)": correlation['geral_trans_ratio'] * 100,
        "ECD at Casing Shoe": model['sapata_ecd'],
        "ECD on Bottom": model['bottom_ecd'],

    };

    return convertedData;
}

export const handleData = (data) => {

    const keys = Object.keys(data)
    const newData = []

    keys.map((field) => {
        if (!Array.isArray(data[field])) {
            newData.push({ field, value: data[field] })
        }
    })

    return newData

}

export const hydraulicsPayload = {
    "hydraulic_model": "Bingham",
    "correlation": "Moore",
    "user_settings": {
        "bit_diam": 8.5,
        "case_surf_press_loss": 2,
        "ch_press_loss": 0,
        "cs_press_loss": 0,
        "flu_dens": 0.0784470816,
        "list_hole_diam": [
            8.75,
            8.5,
            8.5,
            8.5
        ],
        "list_inner_pipe": [
            3.8,
            3.8,
            3,
            3
        ],
        "list_jet_diam": [
            13,
            13,
            13
        ],
        "list_outside_pipe": [
            4.5,
            4.5,
            5,
            6.5
        ],
        "list_pipe_ref": [
            "D",
            "D",
            "H",
            "C"
        ],
        "list_sec_length": [
            3280,
            4200,
            393,
            328
        ],
        "r100": 22,
        "r200": 29,
        "r3": 5,
        "r300": 35,
        "r6": 10,
        "r600": 50,
        "sapata_md": 3280,
        "sapata_tvd": 3280,
        "solids_dens": 1.1383517,
        "tool_press_loss": 0,
        "solids_diam": 0.00787401575,
        "spher": 0.78
    },
    "well_info": {
        "uid": "us_27983541_wb1_log_2",
        "uid_well": "US0100025055",
        "uid_wellbore": "US010002505500"
    }
}
