import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import ClipboardJS from 'clipboard';
import clippyIcon from '../../assets/copy.svg';

const clipboardJS = new ClipboardJS('.clipboard__btn');

const Clipboard = ({ value, defaultValue, clipboardTarget }) => {
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [showClass, setShowClass] = useState(false);
  let timerId = useRef(null);     
  
  const displayTooltipClipboard = () => {
    setVisibleTooltip(!visibleTooltip);
    setShowClass(!visibleTooltip);
    timerId = setTimeout(() => {
      setVisibleTooltip(false);
      setShowClass(false);
    }, 1000);
  };

  useEffect(() => {
    return () => clearInterval(timerId);
  }, []);

  return (
    <>
      <input 
        id={clipboardTarget}
        className="clipboard__input"
        value={value ? value.toFixed(2) : defaultValue}
      />
      <Tooltip 
        title="copied!"
        trigger="click"
        visible={showClass}
      >
        <button
          type="button"
          className="clipboard__btn"
          data-clipboard-target={`#${clipboardTarget}`}
          onClick={displayTooltipClipboard}
          aria-label="This is the tooltip on the North West side.">
          <img src={clippyIcon} width="15" height="15" alt="Copy top range" />
        </button>
      </Tooltip>
    </>
  );
}

export default Clipboard;
