import React, { useEffect, useState } from "react";
import { Typography } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { StyledSectionRow, HydraulicsPressure, CuttingsBox, PressureGridItems } from '../styles';
import MeanIndicator from "../../../components/Hydraulics/MeanIndicator";
import Indicator from "../../../components/Hydraulics/Indicator";
import TotalDepthCuttings from "../../../components/Hydraulics/TotalDepthCuttings";
import TotalDepth from "../../../components/Charts/TotalDepth";
import RealTimeLithology from "../../../components/Charts/RealTimeLithology";
import LithoScore from "components/Charts/LithoScore";
import useHydraulicsContext from "../useHydraulics";
import { AggregatedDataProvider } from "./useAggregatedData";
import { handleData } from "../hydraulicsUtils";


export const Cuttings = ({ ...props }) => {
    const { isLoading, cuttings } = useHydraulicsContext()
    const initialCutting = {
        'Original Depth': {
            "mean": 0,
            "std": 0,
            "min": 0,
            "max": 0
        },
        'Current Depth': {
            "mean": 0,
            "std": 0,
            "min": 0,
            "max": 0
        },
    };

    const [currentCutting, setCurrentCutting] = useState(initialCutting);
    const [cuttingIndex, setCuttingIndex] = useState(1);
    const wells = useSelector(state => state.wells);
    const { selectedWell } = wells;
    return (
        <AggregatedDataProvider>
            <StyledSectionRow >
                <CuttingsBox>
                    <div className="charts-wrapper" style={{ height: 'calc(100vh - 300px)', width: '100%' }}>
                        <div className="charts-container">
                            {<>
                                <TotalDepth
                                    data={wells.currentWell && wells.currentWell.depth || []}
                                    afterSelect={props.afterSelect}
                                    unit={props.unit}
                                    min={props.min}
                                    max={props.maxDepth}
                                    selectedWell={selectedWell}
                                    onAfter={props.onAfter}
                                />

                                <TotalDepthCuttings
                                    style={{ marginLeft: '10px' }}
                                    setCurrentCutting={setCurrentCutting}
                                    currentCutting={currentCutting}
                                    setCuttingIndex={setCuttingIndex}
                                    max={props.depth}
                                />
                                <RealTimeLithology
                                    beforeWell={wells.beforeWell || defaultWell}
                                    currentWell={wells.currentWell || defaultWell}
                                    lithoAutomudData={props.lithoAutomudData}
                                    selectedWell={selectedWell}
                                    depth={props.depth}
                                />
                                <LithoScore
                                    beforeWell={wells.beforeWell || defaultWell}
                                    currentWell={wells.currentWell || defaultWell}
                                    lithoAutomudData={wells.currentWell && wells.currentWell.lithologyScore}
                                    selectedWell={selectedWell}
                                    depth={props.depth}
                                />
                            </>
                            }
                        </div>
                    </div>
                </CuttingsBox>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Typography.Title level={3} style={{ color: '#fff', padding: '10px' }}>Cutting # {cuttingIndex}</Typography.Title>
                    {handleData(currentCutting).map((cutting, index) => <><MeanIndicator key={index} title={cutting.field} value={cutting.value.mean.toFixed(2)} std={cutting.value.std.toFixed(2)} min={cutting.value.min.toFixed(2)} max={cutting.value.max.toFixed(2)} style={{ minWidth: '250px' }}></MeanIndicator></>)}
                    <Indicator title={'Lag Depth'} value={cuttings ? cuttings.lag_depth.toFixed(2) : '0.00'} style={{ minWidth: '250px' }}></Indicator>
                </div>
            </StyledSectionRow>
        </AggregatedDataProvider>
    )
}