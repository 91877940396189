import React, { useState, useEffect, useRef, createRef } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Header, HeaderItem, Indicator, Body, Content } from './styles'

const Tabs = ({
  headerAlign,
  width,
  background,
  tabs,
  initialSelected,
  selectedIndex,
  children
}) => {
  const [internSelected, setSelected] = useState(initialSelected)
  const selected = selectedIndex ? selectedIndex : internSelected
  const [indicatorWidth, setIndicatorWidth] = useState(0)
  const _headerItemRef = useRef(tabs.map(() => createRef()))
  const _indicatorLeft = _headerItemRef.current
    .slice(0, selected)
    .reduce((acc, item) => acc + item.current.offsetWidth, 0);

  if (!tabs.length) return null;

  useEffect(() => {
    setIndicatorWidth(_headerItemRef.current[selected].current.offsetWidth);
  }, [selected]);

  return (
    <Wrapper width={width} background={background}>
      <Header>
        {tabs.map((item, index) => (
          <HeaderItem
            ref={_headerItemRef.current[index]}
            key={index}
            headerAlign={headerAlign}
            onClick={() => typeof selectedIndex === 'number' ? null : setSelected(index)}
            aria-selected={selected === index}
            disabled={typeof selectedIndex === 'number'}
          >
            {item}
          </HeaderItem>
        ))}
        <Indicator
          headerAlign={headerAlign}
          left={_indicatorLeft}
          width={indicatorWidth}
          itemsqnt={tabs.length}
          selected={selected}
        />
      </Header>
      <Body>
        {children.map((child, index) => {
          if (selected === index) return <Content key={index}>{child}</Content>;
        })}
      </Body>
    </Wrapper>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string.isRequired),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  width: PropTypes.string,
  background: PropTypes.string,
  initialSelected: PropTypes.number,
  headerAlign: PropTypes.oneOf(['left', 'right'])
};

Tabs.defaultProps = {
  tabs: [],
  width: 'auto',
  background: 'transparent',
  initialSelected: 0
};

export default Tabs;
