import React from "react";
import styled from "styled-components";
import { Button } from "antd";

export const Header = styled.header`
    display: flex;
    justify-content: space-between; 
    width: 100%;
    color: #fff;
    padding: 20px;
`


export const CustomizeButton = styled(Button)`
    background: #2B2936;
    border: none;
    color: #fff;
`