import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import zone from './zone';
import wells from './wells';
import crossplots from './crossplots';
import experiments from './experiments';
import models from './models';

const rootReducer = combineReducers({
  zone,
  wells,
  crossplots,
  experiments,
  models,
  routing: routerReducer
});

export default rootReducer;
