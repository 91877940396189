import React from "react"
import { Wrapper, StyledInput, StyledLabel } from "./styles"

const Input = ({ id, label, placeholder, width, ...props }) => {
    return (
        <Wrapper>
            <StyledLabel htmlFor={id}>{label}</StyledLabel>
            <StyledInput id={id} placeholder={placeholder} />
        </Wrapper>
    )
}

export default Input
