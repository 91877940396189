import * as actions from '../constants/experiments';
import { fetchExperiments, fetchOneExperiment } from '../api/experiments';

export const receiveExperiments = experiments => ({
  type: actions.LIST_EXPERIMENTS,
  experiments
  // experiments: experiments.map(( name ) => ({ name })),
});

export const receiveOneExperiments = (experiment, id) => ({
  type: actions.LIST_ONE_EXPERIMENT,
  experiment,
  id,
});

export const listExperiments = (afterSuccess) => {
  return async (dispatch) => {
    try {
      const response = await fetchExperiments();
      dispatch(receiveExperiments(response.data));
    } catch (e) {
      console.log('error listExperiments', e)
    } finally {
      afterSuccess();
    }
  };
};

export const listOneExperiments = (name, afterSuccess) => {
  return async (dispatch, getState) => {
    const experiments = getState().experiments.items;
    const currentExperiment = experiments[name];
    try {
      const response = await fetchOneExperiment(name);
      dispatch(receiveOneExperiments(response.data, currentExperiment.experiment_id));
    } catch (e) {
      console.log('error listExperiments', e)
    } finally {
      afterSuccess();
    }
  };
};