import React from 'react';
import logo from '../../images/logogeowellex.svg';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Input from '../Input';
import Button from '../Button';
import Spinner from '../Spinner';
import { Formik, Form, ErrorMessage, setFieldError } from 'formik';
import './style.scss';

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .max(8, 'use only eight characters')
    .required('Required'),
  confirmPassword: Yup.string()
    .max(8, 'use only eight characters')
    .required('Required')
 });

const NewPassword = ({
  onSubmitNewPassword, errorNewPassword, backToLogin, isSubmited,
}) => {
  return(
    <div className="container-forgot">
    <Formik
      validationSchema={PasswordSchema}
      initialValues = {{
        password: '',
        confirmPassword: '',
      }}
      enableReinitialize
      onSubmit={onSubmitNewPassword}
      render={({
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <Form onSubmit={handleSubmit} className="form-password">
          <h1 className="title-forgot">Please enter your new password</h1>
          {errorNewPassword && (
            <p style={{
              color: 'red', fontSize: '14px', textAlign: 'center',
            }}>
              {errorNewPassword} 
            </p>
          )}
          <p>{isSubmitting}</p>
          {isSubmited && (
            <Alert
              message="password updated successfully"
              type="success" className="alert"
              showIcon
            />
          )}
          <Input 
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            text="text"
            name="password"
            id="password"
            placeholder="New password"
          />
          <ErrorMessage name="password">{msg => 
            <div className="error-text">
               <Alert message={msg} banner />
            </div>
          }
          </ErrorMessage>
          <Input 
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            text="text"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Confirm password"
          />
          <ErrorMessage name="confirmPassword">{msg => 
            <div className="error-text">
               <Alert message={msg} banner />
            </div>
          }
          </ErrorMessage>
          <Button
            type="submit"
            disabled={isSubmitting}
          > 
            <span style={{ fontSize: '15px' }}>Set a new password</span>
          </Button> 
        </Form>
      )}
    />
      <Link
        onClick={backToLogin}
        style={{
          fontSize: '15px', color: 'white',
          display: 'flex'
        }}
        to="/"
      >
        Back to Sing In
      </Link>
      <hr/>
    </div>
  );
}

export default NewPassword;