import React from 'react';
import Modal from 'react-modal';
import { modalStyleMachineLearning } from '../../utils';
import Models from '../../Models/Train';

import './style.scss';

const TrainModelModal = ({ isOpen, onClose, openModalWellPhases, closeModalWellPhases }) => {
  return (
    <Modal
      isOpen={isOpen}
      openModalWellPhases={openModalWellPhases}
      closeModalWellPhases={closeModalWellPhases}
      style={{
        ...modalStyleMachineLearning,
        height: 'calc(100vh - 100px)',
      }}
      onRequestClose={onClose}
    >
      {/* <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 60, position: 'relative' }} className="experiment-container"> */}
      <Models closeModal={onClose} openModalWellPhases={openModalWellPhases} closeModalWellPhases={closeModalWellPhases} />
      {/* </div> */}
    </Modal>
  );
};

export default TrainModelModal;