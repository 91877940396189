import { replaceCommaToDot } from '../utils';
/*
 // JS
  import LasConverter from './lib/LasConverter';
 
  uploadFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function() {
      const lines = this.result;

      const JSONData = new LasConverter({
        lines,
        name: file.name,
        size: file.size,
      }).getJSON();

      console.log('JSONDATA ----->', JSONData);
    };

    reader.readAsText(file);
  }

 // HTML
  <input
    id="lasFile"
    type="file"
    onChange={this.uploadFile}
  />
*/

const removeNoAnsciValues = sentence => sentence.replace(/[^\x20-\x7E]/g, '');

const sectionsTypes = {
  '~V': 'version',
  '~W': 'well',
  '~C': 'curve',
  '~P': 'parameter',
  '~O': 'other',
  '~A': 'data'
};

function getMnem(line) {
  let mnem = null;
  const mnemEnd = line.search(/\./);
  if (line && mnemEnd !== 0 && !line.includes('~')) {
    mnem = line.slice(0, mnemEnd).trim();
    if (mnem && mnem.includes('.')) {
      const [currentMnem] = mnem.split('.');
      mnem = currentMnem;
    }
  }
  return { mnem, mnemEnd };
}

function getUnit(line) {
  const dotIndex = line.search(/\./);
  const unitStart = dotIndex + 1;
  const unitEnd = line.slice(unitStart).search(/ /);
  let unit = line.slice(unitStart, unitStart + unitEnd);
  const hasDots = unit.search(/:/);
  if (hasDots && unit.split(':') && unit.split(':')[0]) {
    unit = unit.split(':')[0].trim();
  }
  return { unit, unitEnd };
}

function getValueAndDesc(line, unitEnd) {
  let value = '';
  const valueStart = unitEnd;
  if (unitEnd) {
    // eslint-disable-next-line no-unused-vars
    value = line.slice(valueStart);
  }

  let [formatedValue, description] = line.slice(unitEnd).split(/\s\s:/);
  if (line.slice(unitEnd).split(/\s\s:/).length === 1 && formatedValue.search(':') === 0) {
    description = formatedValue.replace(':', '');
    formatedValue = '';
  }

  return {
    v: formatedValue ? removeNoAnsciValues(formatedValue.trim()) : '',
    desc: description ? removeNoAnsciValues(description.trim()) : ''
  };
}

const ONE_PPM = 10000;

const shouldConvertToPercentage = (v) =>
  ['c1', 'c2', 'c3', 'c4', 'c5', 'nc4', 'nc5'].includes(v);

const ppmToPercentage = (value) => value / ONE_PPM;

class LasConverter {
  constructor(file) {
    this.supported_version = 2.0;
    this.errors = [];
    this.file = file;
    this.JSONData = null;

    this.init();
  }

  supportedVersion = () => {
    return `Supported LAS Version : ${this.supported_version}`;
  }

  init = () => {
    this.readFile();
  }

  // eslint-disable-next-line consistent-return
  readFile = () => {
    let section = '';
    const rules = [
      'version',
      'well',
      'parameter',
      'curve',
      'other'
    ];

    const lines = this.file.lines.split(/\n/);

    /*
    * Coverage the case when the data rows is just one line because the file was copied 
    * from a excell file and there is not \n character, I need to split it to work well.
    * The fix is walk through the row and split all values by empty space,
    * after it we need to cut echa row with the same total of curves registered
    * such as: if we have 21 curves, each row will be splited after add 21 values in data string
    * TODO: Improving the description, this was in an troubleshooting
    */
    const content = { data: {} };
    const curveColumns = [];

    // Think in a worst length
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      let hasUnit = false;

      // Read the sections
      if (line.includes('~')) {
        section = this.getCurrentSection(line);

        if (rules.includes(section)) {
          content[section] = {};
        }
      }

      // Prevent to read the commented lines
      if (line.search('#') === -1 && section !== 'data') {
        let description = '';
        let value = '';

        // ----------MNEM
        // eslint-disable-next-line prefer-const
        let { mnem, mnemEnd } = getMnem(line);

        // ----------All UNIT cases
        const { unit, unitEnd } = getUnit(line);
        if (unitEnd > 0) {
          hasUnit = true;
        }

        // ----------Get the curves the name columns of each curve
        if (section === 'curve' && mnem && !curveColumns.includes(mnem)) {
          curveColumns.push(mnem);
          mnem = mnem
            .replace('main','')
            .trim()
            .replace(' ', '_')
            .toLowerCase();

          // TODO refactor - ppm to percentage
          if (shouldConvertToPercentage(mnem)) {
            content.data[`${mnem}_percentage`] = [];
          }

          content.data[mnem] = [];
        }

        // ---------VERSION-------------
        // Check the version
        // VERS.               2.00 : CWLS Log ASCII Standard - Version 2.00
        if (section === 'version' && mnem === 'VERS') {
          if (!line.includes('2')) {
            this.errors.push('Invalid version this version is not supported!');
          }
        }

        if (['curve', 'well', 'parameter', 'other', 'version'].includes(section) && line[0] !== "~") {
          const { v, desc } = getValueAndDesc(
            line.slice(mnemEnd + 1).trim(),
            unitEnd,
            mnemEnd
          );
          value = v;
          description = desc;
        }

        if (mnem && section) {
          content[section][mnem] = {
            value: removeNoAnsciValues(value),
            unit: hasUnit ? unit : '',
            description: removeNoAnsciValues(description)
          };
          hasUnit = false;
        }
      }

      if (section === 'data') {
        if (!line.includes('~A')) {
          // Organize the data in each array position
          let dataLine = line.split(/(\s+)/).filter(str => !!str && str !== '~A' && str !== '' && str.trim().length);
          if (dataLine.length === 1) {
            dataLine = dataLine[0].split(/(\s+)/).filter(str => str.trim().length);
          }
          // prevent to broken with invalid .las file without curve or data
          if (content && !content.curve) {
            return 'Is not a valid .las. \n Try to check if there is curves and data headers';
          }

          const columns = Object.keys(content.curve);
          for(let dataCount = 0; dataCount < dataLine.length; dataCount++) {
            let currentColumn = columns[dataCount];
            if (currentColumn) {
              const currentStr = currentColumn.replace('main','');
              currentColumn = currentStr ? currentStr.trim().replace(' ', '_').toLowerCase() : currentStr.replace(' ', '_').toLowerCase();
              const columnData = content.data[currentColumn];
              const data = dataLine[dataCount];
              if (!columns.includes(data) && Array.isArray(columnData)) {
                if (data) {
                  const value = replaceCommaToDot(data);
                  columnData.push(value);

                  // Calculations of data
                  if (shouldConvertToPercentage(currentColumn)) {
                    const gasInPercentage = ppmToPercentage(value);
                    content.data[`${currentColumn}_percentage`].push(gasInPercentage);
                  }
                }
              }
            }
          }
        }
      }
    }
    this.JSONData = content;
  }

  // eslint-disable-next-line consistent-return
  getCurrentSection = (line) => {
    const sectionsCodes = Object.keys(sectionsTypes);
    for(let i = 0; i < sectionsCodes.length; i++) {
      const currentCode = sectionsCodes[i];
      const currentSection = line.search(currentCode);
      if (currentSection >= 0) {
        return sectionsTypes[currentCode];
      }
    }
  }

  getJSON = () => {
    return this.JSONData;
  }
}

// Converter
// .init()
// .hasValidExtension()
// .readFile()
// .getJSON()

export default LasConverter;
