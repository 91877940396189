import React, { useContext, useEffect, useState } from "react";
import Fieldset from "../../Fieldset";
import Input from "../../Input";
import InputNumber from "../../InputNumber";
import InputRow from "../../InputRow";
import { Button, Icon, Switch, Tooltip } from "antd";
import { AddButton } from "../../Button/styles";
import SelectedItems from "../../SelectedItems";

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';

import { bitParametersInitialValues, bitParametersSchema } from "./payload";
import { SettingsFormContext } from "..";
import useHydraulicsContext from "../../../../new/Hydraulics/useHydraulics";

const BitParametersForm = ({ nextStep, previousStep, dataBits, setDataBits }) => {
    const methods = useForm({
        defaultValues: dataBits ? dataBits : bitParametersInitialValues,
        resolver: yupResolver(bitParametersSchema),
    });

    const { handleSubmit, watch, setValue, getValues, control, formState: { errors, isSubmitSuccessful } } = methods;

    const { fields, append, remove, insert } = useFieldArray({
        control,
        name: 'jets', // unique name for your Field Array
    });
    const { onCancel, bitParametersDetails, setBitParametersDetails } = useContext(SettingsFormContext);
    const { unitsConfigDetails } = useHydraulicsContext()

    function goToNextStep(data) {
        setDataBits(data)
        setBitParametersDetails(data)
        if (data != null) {
            nextStep(2)
        }
    }

    function AddNewJet() {
        const values = getValues(['num_jets', 'jet_diam'])
        if (values != null) {
            append({
                num_jets: values[0],
                jet_diam: values[1]
            })
        }
    }

    function getBitSizeFromLastSection(e) {
        if (e && e.preventDefault) { e.preventDefault(); }

        let section_length = watch('phases').length
        let last_section_type = getValues(`phases.${section_length - 1}.section_type`)

        if (last_section_type == 'Open Hole') {
            let last_diam = getValues(`phases.${section_length - 1}.casing_diam`)
            setValue('bit_size', last_diam)
        }

    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(goToNextStep)}>
                    <Fieldset label={'Well Geometry'}>
                        <InputRow name={'phases'} control={control} errors={errors.phases} />
                    </Fieldset>
                    <Fieldset label={'Bit Parameters'}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', gap: '8px' }}>
                            <Input
                                label={'Bit Name'}
                                name={`bit_name`}
                                control={control}
                            />
                            <div style={{ display: 'flex', alignItems: 'flex-end', gap: '8px', width: '100%' }}>
                                <InputNumber
                                    label={'Bit Size'}
                                    name={`bit_size`}
                                    control={control}
                                    width={'90%'}
                                    unit={unitsConfigDetails.radius_unit}
                                    onFocus={() => getBitSizeFromLastSection()}
                                />
                                <Input
                                    label={'Bit Nº'}
                                    name={`bit_number`}
                                    control={control}
                                    width={'10%'}
                                />
                            </div>
                            <Input
                                label={'Bit S N'}
                                name={`bit_sn`}
                                control={control}
                            />
                            <Input
                                label={'Bit Manufacturer'}
                                name={`bit_manufacturer`}
                                control={control}
                            />
                        </div>
                    </Fieldset>
                    <Fieldset label={'Bit Jets Parameters'}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', gap: '8px' }}>
                            <InputNumber
                                label={'Num Jets'}
                                name={`num_jets`}
                                control={control}
                                width={'15%'}
                            />
                            <InputNumber
                                label={'Jet Diameter'}
                                name={`jet_diam`}
                                control={control}
                                unit={unitsConfigDetails.jetDiameter_unit}
                                style={{ width: '100%' }}
                            />
                            <AddButton
                                htmlType={'button'}
                                onClick={AddNewJet}
                            >+ Add Jets</AddButton>
                        </div>
                        <span style={{ margin: '5px', color: '#B4B1C2' }}>Selected Jets:</span>
                        <SelectedItems data={Object.keys(fields).map((item, index) => ({ id: item, field: `Jet ${Number(item) + 1}`, index: index }))} remove={remove}></SelectedItems>
                        {errors.jets ? <span style={{ color: 'red', margin: '2px' }}>{errors.jets.message}</span> : null}
                    </Fieldset>
                    <Fieldset label={'Casing Depth'}>
                        <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
                            <InputNumber
                                label={'Casing Shoe - Measured Depth'}
                                unit={unitsConfigDetails.depth_unit}
                                style={{ width: '100%' }}
                                name={'casing_depth'}
                                control={control}
                                errors={errors.casing_depth}

                            />
                            <InputNumber
                                label={'Casing Shoe - TVD'}
                                unit={unitsConfigDetails.depth_unit}
                                name={'casing_tvd'}
                                style={{ width: '100%' }}
                                control={control}
                                errors={errors.casing_tvd}
                            />
                        </div>
                    </Fieldset>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            htmlType={'button'}
                            onClick={onCancel}
                            style={{ color: 'red' }}
                        >
                            Cancel
                        </Button>
                        <div>
                            <Button
                                type="link"
                                onClick={() => previousStep(0)}
                                style={{ color: '#fff' }}
                            >
                                Back
                            </Button>
                            <Button
                                type="primary"
                                htmlType={'submit'}
                            >Next</Button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default BitParametersForm;