import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledTabs = styled(Radio.Group)({
    "&.ant-radio-group": {
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        '&:focus, &:active': {
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
        },

        ".ant-radio-button-wrapper": {
            background: '#16151F',
            border: 'none',
            padding: '0 15px',
            height: '30px',
            lineHeight: '30px',
            textAlign: 'justify',
            color: '#B4B1C2',
            boxShadow: 'none',

            fontWeight: '600',

            '&:hover': {
                color: '#FAF5FF',
            },

            '&::before': {
                display: 'none'
            },
            "&:first-child": {
                borderRadius: '64px 0 0 64px',
                padding: 0,
            },
            "&:last-child": {
                borderRadius: '0 64px 64px 0',
                padding: 0,
            },
            "& span:not([class*='ant-radio-button ant-radio-button-checked']):not([class*='ant-radio-button-inner'])": {
                padding: '6px 15px',
                borderRadius: '64px',
            }
        },
        ".ant-radio-button-wrapper-checked": {
            color: "#FAF5FF",
            '&:hover': {
                color: '#FAF5FF',
            },
            "& span:not([class*='ant-radio-button ant-radio-button-checked']):not([class*='ant-radio-button-inner'])": {
                background: '#565365',
            }

        }
    }
})