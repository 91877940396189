import React from 'react';
import classNames from 'classnames';

const ArrowLine = ({
  text, textColor, className,
  fillColor
}) => (
  <div
    className={classNames('arrow-line-wrapper', className)}
  >
    <div
      className="arrow-line-container"
    >
      <svg
        ersion="1.1"
        viewBox="0 0 100 100"
        enableBBackground="new 0 0 100 100"
        className="arrow-line-arrow"
        fill={fillColor}
      >
        <g>
          <path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"/>
        </g>
      </svg>
      <span
        style={{ color: textColor }}
      >
        {text}
      </span>
      <svg
        version="1.1"
        className="arrow-line"
        fill={fillColor}
      >
        <line
          x1="5"
          y1="21"
          x2="148"
          y2="21"
          strokeWidth="2"
          stroke={fillColor}
        />
      </svg>
    </div>
  </div>
);

ArrowLine.defaultProps = {
  textColor: 'red',
  fillColor: 'red'
};

export default ArrowLine;

