import React from 'react';
import Spinner from '../Spinner';

const ProcessingSpinner = ({spinnerText}) => (
  <div className="spin-dxc">
    <Spinner classes="spinner--dxc"/>
    <span className="message-dxc">{spinnerText}</span>
  </div>
);

export default ProcessingSpinner;