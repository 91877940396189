import React from "react"
import { Figure, StyledIcons } from "./styles"
import icons from './../../../assets/icons'
const Icon = ({ width, height, name, alt, color }) => {

  return (
    <Figure width={width} height={height}>
      <StyledIcons src={icons[name]} alt={alt} color={color} />
    </Figure>
  )
}
export default Icon