import React, { Component } from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import classNames from 'classnames';
import Rectangle from '../Rectangle';
import './style.scss';

Exporting(Highcharts);

class FluidTyping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true
    };
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();
  }

  // @TODO Refactor it
  shouldComponentUpdate(nextProps) {
    if (nextProps.dataWithDepth.length) {
      this.chart.series[0].update({
        data: nextProps.dataWithDepth
      }, true);
      this.chart.redraw();
    }
    return false;
  }

  createScatterPlot = () => {
    const { id, yScale, xScale } = this.props;
    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        marginTop: 60
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        ...xScale,
        allowDecimals: true,
        title: {
          enabled: true,
          text: `<b>${xScale.title}</b>`,
          y: -40
        },
        min: 0,
        max: 30,
        opposite: true,
        startOnTick: true,
        endOnTick: true,
        type: '',
        showLastLabel: true,
        tickInterval: 10,
        minorTickInterval: 1,
        pointStart: 0,
        tickPosition: 'outside',
        gridLineWidth: 0,
        labels: {
          formatter: () => ''
        }
      },
      yAxis: {
        ...yScale,
        allowDecimals: true,
        title: {
          text: yScale.title
        },
        lineWidth: 1,
        lineColor: '#ccd6eb',
        startOnTick: true,
        endOnTick: true,
        type: '',
        showLastLabel: true,
        reversed: true,
        gridLineWidth: 1
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x} cm, {point.y} kg'
          }
        }
      },
      tooltip: {
        enabled: false
      },
      series: [{
        name: ' ',
        color: 'rgba(223, 83, 83, .5)',
        data: [],
        marker: {
          fillColor: 'rgba(45, 52, 54, 0.7)',
          symbol: 'circle'
        }
      }, {
        name: ' ',
        color: 'rgba(119, 152, 191, 0.5)',
        data: [],
        marker: {
          fillColor: 'rgba(231, 76, 60, 0.7)',
          symbol: 'circle'
        }
      }]
    });
  }

  render() {
    const {
      id, withMinorGridLines, className, deactivateLegend
    } = this.props;
    const { isVisible } = this.state;
    return (
      <div
        className="scatter-general fluid-type-container"
        style={{
          display: isVisible ? 'flex' : 'none'
        }}
      >
        {/* {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config"
              onClick={openConfigureScale}
            />
          )
        } */}
        <div className="scatter-fluid-title">
          <span className="oil-title">OIL</span>
          <span className="transition-title">TRANSITION</span>
          <span className="gas-title">GAS</span>
        </div>
        <Rectangle
          fillColor="#d1dabc"
          strokeColor="#718656"
          rectWidth={79.5}
          rectHeight={639.5}
          viewBox="unset"
          svgWidth={200}
          svgHeight={900}
          rotate={0}
          containerHeight="unset"
          containerWidth="unset"
          containerLeft={77}
          containerTop={71}
        />
        <Rectangle
          fillColor="#f4c094"
          strokeColor="#ed713e"
          rectWidth={
            // + 0.5 because it needs to overlay the two side borders
            79.5 + 0.5
          }
          rectHeight={639.5}
          viewBox="unset"
          svgWidth={200}
          svgHeight={900}
          rotate={0}
          containerHeight="unset"
          containerWidth="unset"
          containerLeft={
            // + 79.5 because of the first bar
            // - 0.25 because it needs to overlay the left border
            77 + 79.5 - 0.25
          }
          containerTop={71}
        />
        <Rectangle
          fillColor="#f1a7a7"
          strokeColor="#f15555"
          rectWidth={
            // + 0.5 because it needs to overlay the two side borders
            // + 0.5 because it needs to be below the last vertical reference line
            79.5 + 0.5 + 0.5
          }
          rectHeight={639.5}
          viewBox="unset"
          svgWidth={200}
          svgHeight={900}
          rotate={0}
          containerHeight="unset"
          containerWidth="unset"
          containerLeft={
            // + 79.5 because of the first bar
            // + 79.5 because of the second bar
            // - 0.25 because it needs to overlay the left border
            77 + 79.5 + 79.5 - 0.25
          }
          containerTop={71}
        />
        <div
          className={classNames(
            className,
            { 'reset-minor-grids': !withMinorGridLines },
            { 'deactivate-legend': deactivateLegend }
          )}
          id={id}
          style={{
            paddingTop: '12px',
            minWidth: '326px',
            height: '725px',
            maxWidth: '330px',
            flex: 1
          }} />
      </div>
    );
  }
}

FluidTyping.defaultProps = {
  withMinorGridLines: false,
  deactivateLegend: true,
  className: 'scatter-plot',
  title: 'Fluid Typing'
};

export default FluidTyping;
