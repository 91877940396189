/**
 * fn: distanceConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for distance converter
 */
export const distanceConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'm'
  switch (unitIn) {
    case 'ft':
      result = val / 3.2808;
      break;
    case 'yd':
      result = val / 1.0936;
      break;
    case 'in':
      result = val / 39.3701;
      break;
    default:
      break;
  }

  // Convert 'm' in 'unitOut'
  switch (unitOut) {
    case 'ft':
      result *= 3.2808;
      break;
    case 'yd':
      result *= 1.0936;
      break;
    case 'in':
      result *= 39.3701;
      break;
    default:
      break;
  }
  return result;
};

/**
 * fn: massConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for mass converter
 */
export const massConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'kg'
  switch (unitIn) {
    case 'lb':
      result = val / 2.20462;
      break;
    case 'klb':
      result = val / 0.00220462;
      break;
    case 'ton':
      result = val / 0.001;
      break;
    default:
      break;
  }

  // Convert 'kg' in 'unitOut'
  switch (unitOut) {
    case 'lb':
      result *= 2.20462;
      break;
    case 'klb':
      result *= 0.00220462;
      break;
    case 'ton':
      result *= 0.001;
      break;
    default:
      break;
  }
  return result;
};

/**
 * fn: pressureConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for pressure converter
 */
export const pressureConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'kPa'
  switch (unitIn) {
    case 'psi':
      result = val / 0.145038;
      break;
    case 'Pa':
      result = val / 1000;
      break;
    case 'kgf/cm²':
      result = val / 0.010197;
      break;
    case 'bar':
      result = val / 0.01;
      break;
    default:
      break;
  }

  // Convert 'kPa' in 'unitOut'
  switch (unitOut) {
    case 'psi':
      result *= 0.145038;
      break;
    case 'Pa':
      result *= 1000;
      break;
    case 'kgf/cm²':
      result *= 0.010197;
      break;
    case 'bar':
      result *= 0.01;
      break;
    default:
      break;
  }
  return result;
};

/**
 * fn: rpmConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for rpm converter
 */
export const rpmConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'RPM'
  switch (unitIn) {
    case 'RPS':
      result = val / 0.016667;
      break;
    default:
      break;
  }

  // Convert 'RPM' in 'unitOut'
  switch (unitOut) {
    case 'RPS':
      result *= 0.016667;
      break;
    default:
      break;
  }
  return result;
};

/**
 * fn: torqueConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for torque converter
 */
export const torqueConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'N.m'
  switch (unitIn) {
    case 'lbf.ft':
      result = val / 0.737562;
      break;
    case 'kN.m':
      result = val / 0.001;
      break;
    default:
      break;
  }

  // Convert 'kg' in 'unitOut'
  switch (unitOut) {
    case 'lbf.ft':
      result *= 0.737562;
      break;
    case 'kN.m':
      result *= 0.001;
      break;
    default:
      break;
  }
  return result;
};

/**
 * fn: densityConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for density converter
 */
export const densityConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'kg/m³'
  switch (unitIn) {
    case 'ppg':
      result = val / 0.008345;
      break;
    case 'g/cm³':
      result = val / 0.001;
      break;
    case 'lb/ft³':
      result = val / 0.062428;
      break;
    default:
      break;
  }

  // Convert 'kg/m³' in 'unitOut'
  switch (unitOut) {
    case 'ppg':
      result *= 0.008345;
      break;
    case 'g/cm³':
      result *= 0.001;
      break;
    case 'lb/ft³':
      result *= 0.062428;
      break;
    default:
      break;
  }
  return result;
};

/**
 * fn: concentConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for concentration converter
 */
export const concentConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'kg/m³'
  switch (unitIn) {
    case 'ppm':
      result = val / 1000;
      break;
    case 'mg/L':
      result = val / 1000;
      break;
    case 'kg/L':
      result = val / 0.001;
      break;
    default:
      break;
  }

  // Convert 'kg/m³' in 'unitOut'
  switch (unitOut) {
    case 'ppm':
      result *= 1000;
      break;
    case 'mg/L':
      result *= 1000;
      break;
    case 'kg/L':
      result *= 0.001;
      break;
    default:
      break;
  }
  return result;
};

/**
 * fn: velocConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for velocity converter
 */
export const velocConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'm/s'
  switch (unitIn) {
    case 'min/m':
      result = (1 / val) * 0.016667;
      break;
    case 'km/h':
      result = val / 3.6;
      break;
    case 'ft/s':
      result = val / 3.28084;
      break;
    case 'ft/h':
      result = val / 11811;
      break;
    default:
      break;
  }

  // Convert 'm/s' in 'unitOut'
  switch (unitOut) {
    case 'min/m':
      result = (1 / result) * 0.016667;
      break;
    case 'km/h':
      result *= 3.6;
      break;
    case 'ft/s':
      result *= 3.28084;
      break;
    case 'ft/h':
      result *= 11811;
      break;
    default:
      break;
  }
  return result;
};

/**
 * fn: flowConverter
 * @param {number} val - input value number that need to be converted
 * @param {string} unitIn - input unit type
 * @param {string} unitOut - output unit type
 * @return {number} - returns a number with result for flow converter
 */
export const flowConverter = (val, unitIn, unitOut) => {
  let result = val;

  // Convert 'unitIn' in 'm³/s'
  switch (unitIn) {
    case 'gal/min':
      result = val / 15850.3;
      break;
    case 'gal/s':
      result = val / 264.172;
      break;
    case 'L/s':
      result = val / 1000;
      break;
    default:
      break;
  }

  // Convert 'm³/s' in 'unitOut'
  switch (unitOut) {
    case 'gal/min':
      result *= 15850.3;
      break;
    case 'gal/s':
      result *= 264.172;
      break;
    case 'L/s':
      result *= 1000;
      break;
    default:
      break;
  }
  return result;
};