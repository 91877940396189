import * as yup from "yup";

export function updateUnits(data) {
    const unitsStructure = {
        depth_unit: data.length_unit,
        pressure_unit: 'psi',
        radius_unit: 'inch',
        fluidDensity_unit: 'ppg',
        solidsDensity_unit: 'g/cm³',
        jetDiameter_unit: 'inch/32',
        sphericity_unit: '%'
    }

    return unitsStructure;
}

export const UnitParametersInitalValues = {

    depth_unit: 'ft',
    pressure_unit: 'psi',
    radius_unit: 'inch',
    fluidDensity_unit: 'ppg',
    solidsDensity_unit: 'g/cm³',
    jetDiameter_unit: 'inch/32',
    sphericity_unit: '%'
}

export const depthUnits = ['ft', 'm'];
export const pressureUnits = ['psi', 'pa', 'bar'];
export const radiusUnits = ['inch', 'mm'];

