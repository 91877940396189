import React from 'react'
import { StyledButton } from './styles'
import Icon from '../Icon'


const SelectedItem = ({ children, remove, index }) => (
    <StyledButton htmlType={'button'}>
        <span>{children}</span>
        <a key={index} role={'button'} onClick={() => remove(index)}><Icon name="close" style={{ marginBottom: '0px' }} width={8} height={8} /></a>
    </StyledButton>
)
const SelectedItems = ({ data, remove }) => {

    if (!data.length) return null
    return (
        <div style={{ display: 'flex' }}>
            {data.map(({ id, field, index }) => (
                <SelectedItem key={id} index={index} remove={remove}>{field}</SelectedItem>

            ))}
        </div>
    )
}
export default SelectedItems