import Highcharts from 'highcharts';
import _get from 'lodash.get';
import hexRgb from 'hex-rgb';

/*
*
* Zones 
* --------------------------
* How it works
* 1. after to create a chart we neet to register the (instance)
* 2. when the chart receive data this is updated we are free to see through shouldComponentUpdate
*    (one zone is applyed over the chart just when there are data)
* 3. we need to check when there are some stored zones and load getting the zones
*    associated to the current selected (Well)
* 4. the user is free to open the Swipe component input the data on the form
*    and create a zone and dispatch to store it on redux
* 5. perform createZones to create or updateZones to apply the changes
*/

/*
* generatePlotLine
* This function will generate a scheme to a plotLine to
* represent a zone within the chart
*/
export const generatePlotLine = (
  id, value, width = 1, color, name
) => ({
  value,
  width,
  color,
  id,
  label: {
    text: name,
    y: 20,
    strokWidth: 6,
    color: '#000',
    style: {
      color: '#000',
      fontSize: 16
    }
  },
  zIndex: 500
});

export const allCharts = {
  instances: {
    gasComposition: null
  }
};

/*
* createZones
* This function will apply the zones
*/

// eslint-disable-next-line no-shadow
export const applyZoneToChart = (axis, zone, chartName, instance, thereIsName, generatePlotLine) => {
  const {
    id, topX, width, color, name, bottomX
  } = zone;
  const textName = thereIsName ? name : '';
  const startId = `start-${id}`;
  const endId = `end-${id}`;
  const zoneLineStart = generatePlotLine(
    startId, topX, width,
    color, textName
  );
  const zoneLineEnd = generatePlotLine(
    endId, bottomX, width,
    color, ''
  );
  axis.addPlotLine(zoneLineStart);
  axis.addPlotLine(zoneLineEnd);

  const rgbColor = hexRgb(color);

  const plotBand = {
    from: topX,
    to: bottomX,
    color: `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, 0.12)`,
    id: startId,
    zIndex: 20
  };
  axis.addPlotBand(plotBand);

  // eslint-disable-next-line no-use-before-define
  const chart = instance || allCharts.instances[chartName];
  chart.redraw();
};

export const updateChartZone = (chartInstance, axis, zone) => {
  axis.removePlotLine(zone.id);
  applyZoneToChart(chartInstance, axis, zone, generatePlotLine);
};

export const registerInstance = (chartId, chartInstance) => {
  allCharts.instances[chartId] = chartInstance;
};

export const unregisterInstance = chartId => {
  allCharts.instances[chartId] = null;
};

export const chartsToApplyZones = [
  'rop',
  'total-gas',
  'chromatography',
  'gas-composition',
  'balance-ratio',
  'reason-chart',
  'isotope-s13'
];

// TODO generalize this for loop
export const applyZoneToAllCharts = zone => {
  let i = 0;
  let chart = null;
  for (i; i < Highcharts.charts.length; i++) {
    chart = Highcharts.charts[i];
    const chartId = _get(chart, 'renderTo.id', false);
    if (
      _get(chart, 'xAxis[0].setExtremes', false) &&
      chartsToApplyZones.includes(chartId)
    ) {
      const thereIsName = i === 2;
      applyZoneToChart(
        chart.xAxis[0],
        zone,
        chartId,
        chart,
        thereIsName,
        generatePlotLine
      );
    }
  }
};

// TODO generalize this for loop refactor
export const removeAllZones = (zoneId) => {
  let i = 0;
  let chart = null;
 
  for (i = 1; i < Highcharts.charts.length; i++) {
    chart = Highcharts.charts[i];
    const chartId = _get(chart, 'renderTo.id', false);
    if (
      _get(chart, 'xAxis[0].setExtremes', false) &&
      chartsToApplyZones.includes(chartId)
    ) {
      const plotStartId = `start-${zoneId}`;
      chart.xAxis[0].removePlotLine(plotStartId);
      chart.xAxis[0].removePlotLine(`end-${zoneId}`);
      chart.xAxis[0].removePlotBand(plotStartId);
      chart.redraw();
    }
  }
};

export const findDepthIndexValueToApplyInPlotBand = (
  depthValue, data
) => {
  // eslint-disable-next-line array-callback-return
  return data.find(value => {
    const resultDepth = String(value.y).split('.');
    const [currentDepth] = resultDepth;
    if (currentDepth === depthValue) {
      return depthValue;
    }
    return undefined;
  });
};


