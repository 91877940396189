import axios from 'axios';
import store from 'store';
import { getGateway } from './gateway';

const GATEWAY = getGateway();
const URL = `${GATEWAY}/inwell-data`;

export const filterWellsByData = async (data) => {
    // lower data and replace space with _
    data = data.toLowerCase().replace(' ', '_');
    const resource = `${URL}/well?group_logs=True&filter_by_data=${data}`;
    const response = await axios.get(
        resource,
        {
            headers: {
                'access-token': store.get('automud-token'),
                'Content-type': 'application/json'
            }
        }
    );
    return response;
}

export const filterCommonMnemonics = async (wells) => {
    const resource =  `${URL}/well/filter-common-mnemonics`;
    const response = await axios.post(
        resource,
        { wells },
        {
            headers: {
                'access-token': store.get('automud-token'),
                'Content-type': 'application/json'
            }
        }
    );
    return response;
}