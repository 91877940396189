import styled, { css } from 'styled-components'
import { Input } from 'antd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  div {
    display: flex;
    width: 100%;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
export const StyledLabel = styled.label`
    margin-left: 5px;
    color: #B4B1C2;
`
export const StyledInput = styled(Input)`
    border: none;
    &.ant-input{
    background-color: #211F2B;
    cursor: text;
    color: #fff
  }
`
