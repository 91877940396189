import React from 'react';

import {
  Button,
  Steps
} from 'antd';

import ButtonCloseModal from '../ButtonCloseModal';
import Spinner from '../Spinner';

const { Step } = Steps;

const CreateWellModal = ({
  current, steps, closeWell,
  curvesKeys, processing, submitBitSize,
  goPrevBitSizes, currentWell, wellphases, prev,
  next, startProcess
}) => {
  return (
    <>
      <div>
        <Steps current={current} data-cy="home__steps">
          {steps.map(item => (
            <Step
              style={{ color: 'white', paddingRight: '1rem' }}
              key={item.title}
              title={item.title}
            />
          ))}
        </Steps>
        <ButtonCloseModal
          closeWell={closeWell}
        />
      </div>
      <div className="steps-content">
        {current < steps.length && steps[current].content(
          curvesKeys,
          processing,
          current,
          submitBitSize,
          goPrevBitSizes,
          closeWell,
          steps,
          currentWell ? currentWell.bitSize : null,
          wellphases
        )}
        {steps.length - 1 && (
          <div>
            {processing && (
              <div className="charts-container__spinner">
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="steps-action">
        {current < steps.length && current !== 1 && (
          <Button
            style={{ marginLeft: 8 }}
            onClick={closeWell}
            className="cy-btn-cancel"
          >
            Cancel
          </Button>
        )}
        {current > 0 && !processing && current !== 1 && (
          <Button
            style={{ marginLeft: 8 }}
            onClick={prev}
            className="cy-btn-previous"
          >
            Previous
          </Button>
        )}
        {(current === 0 || current !== 1 && current !== 2) && (
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            className="cy-btn-next"
            onClick={next}
          >
            Next
          </Button>
        )}
        {current === 2 && current !== 1 && (
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            onClick={startProcess}
            className="cy-btn-apply"
            disabled={processing}
          >
            Apply
          </Button>
        )}
      </div>
    </>
  );
};

export default CreateWellModal;
