/* eslint-disable */
class EditorBoxArea {
  constructor() {
    this.elements = [];
  }

  deactivateRotate = () => {
  }

  activateRotate = () => {
  }

  activateResize = () => {
  }

  deactivateResize = () => {
  }

  register = () => {
  }

  remove = () => {
  }

  removeLast = () => {
  }
}

export const ADD_BOX = 'ADD_BOX';
export const REMOVE_BOX = 'REMOVE_BOX';

class LogManager {
  constructor() {
    this.historic = [];
  }

  registerAction = (action) => {
    this.historic.push(action);
  }

  undoLastAction = () => {
    // Access the last action
    const lastAction = this.historic[0];
    // Execute the cb to remove or do something
    lastAction.undo();
    // Remove this from the historic
    this.historic.shift();
  }
}

const Logs = new LogManager();
export { Logs };

export default EditorBoxArea;

