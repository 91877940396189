/*
* Queue
* Is a singleton module to help us enqueue the things
* this simulate not all, but some behaviours as a 
* queue class.
* */
class Queue {
  constructor() {
    this.stack = [];
  }

  getStack = () => {
    return this.stack;
  }

  getSize = () => {
    return this.stack.length;
  }

  enqueue = (data) => {
    this.stack.push(data);
  }

  // remove the last
  dequeue = () => {
    this.stack.pop();
    const len = this.stack.length;
    return this.stack[len - 1];
  }

  getLast = () => {
    const [last] = this.stack.slice(-1);
    return last;
  }
}

const ZoomQueue = new Queue;

export default ZoomQueue;

