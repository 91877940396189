import React from "react";
import { Header, CustomizeButton } from './styles.js'
import TabsGroup from '../TabsGroup';
import Icon from '../Icon';
import { Tooltip, Typography } from 'antd'

const { Title } = Typography;

const HeaderHydraulics = ({ ...props }) => {
    const items = [
        { page: 'Hydraulics' },
        { page: 'Cuttings' }
    ]
    return (
        <>
            <Header>
                <Title level={2} style={{ color: '#fff' }}  >Hydraulics</Title>
                <TabsGroup items={items} page={props.currentPage} setPage={props.setCurrentPage}></TabsGroup>
                <div>
                    <CustomizeButton style={{ marginRight: '10px' }} onClick={props.onBackHome} icon={'left'}>Return</CustomizeButton>
                    <Tooltip title="Change Units" placement="bottom">
                        <CustomizeButton style={{ marginRight: '10px' }} onClick={props.openUnitsConfigModal} icon={'setting'} />
                    </Tooltip>
                    <CustomizeButton onClick={props.openSettingsFormModal} icon={'edit'}>Customize</CustomizeButton>
                </div>
            </Header>
        </>
    )
}
export default HeaderHydraulics;