import * as ACTIONS from '../constants/experiments';
import { createReducer } from './createReducer';

const initialState = {
  items: {},
  item: null,
  nameExperiment: '',
};

function listExperiments(state, action) {
  const { experiments } = action;
  return {
    ...state,
    items: experiments,
  };
}

function listOneExperiment(state, action) {
  const { experiment, id } = action;
  const updatedExperiments = Object.keys(state.items || {}).reduce((acc, experimentKey) => {
    if (state.items[experimentKey].experiment_id === id)  {
      acc[experimentKey] = {
        ...state.items[experimentKey],
        details: {
          ...experiment,
        }
      };
    } else {
      acc[experimentKey] = {
        ...state.items[experimentKey],
      };
    }
    return acc;
  }, {}); 
  return {
    ...state,
    items: updatedExperiments,
  };
}

export default createReducer(initialState, {
  [ACTIONS.LIST_EXPERIMENTS]: listExperiments,
  [ACTIONS.LIST_ONE_EXPERIMENT]: listOneExperiment,
});
