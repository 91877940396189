import React, { useState } from "react"
import { Wrapper, StyledInputNumber, StyledLabel, Unit, ActionButtons, IncreaseButton, DecreaseButton, ActionArea } from "./styles"
import { Icon, Popover } from "antd"
import { useController, useFormContext } from "react-hook-form"
import ErrorPopover from "../ErrorPopover"

const InputNumber = ({ id, label, unit, name, placeholder, width, control, errors, ...props }) => {

    const { field } = useController({ name, control })
    const { setValue, getValues } = useFormContext();
    const [visible, setVisible] = useState();

    const decrease = (e) => {
        if (e && e.preventDefault) { e.preventDefault(); }
        let val = getValues(name)
        if (field.value > 0) {
            setValue(name, Number(val) - 1)
        }
    }
    const increase = (e) => {
        if (e && e.preventDefault) { e.preventDefault(); }
        let val = getValues(name)
        setValue(name, Number(val) + 1)
    }

    return (
        <>
            {errors ?
                <ErrorPopover
                    content={errors ? <span style={{ color: '#fff', margin: '2px' }}>{errors.message.message || errors.message}</span> : null}
                    style={{ backgroundColor: '#333', width: 200 }}
                >
                    <Wrapper style={{ width: width }}>
                        <StyledLabel htmlFor={id}>{label}</StyledLabel>
                        <StyledInputNumber type={'number'} value={field.value} id={id} onChange={field.onChange} onBlur={field.onBlur} onFocus={props.onFocus} aria-invalid={errors ? "true" : "false"} placeholder={0} />
                        <ActionArea>
                            <Unit className="unit">{unit}</Unit>
                            <ActionButtons>
                                <IncreaseButton
                                    type={'button'}
                                    onClick={(e) => increase(e)}>
                                    <Icon type="up" twoToneColor={'#B4B1C2'} style={{ fontSize: '8px' }} />
                                </IncreaseButton>
                                <DecreaseButton
                                    type={'button'}
                                    onClick={(e) => decrease(e)}>
                                    < Icon type="down" twoToneColor={'#B4B1C2'} style={{ fontSize: '8px' }} />
                                </DecreaseButton>
                            </ActionButtons>
                        </ActionArea>

                    </Wrapper>
                </ErrorPopover>
                :
                <Wrapper style={{ width: width }}>
                    <StyledLabel htmlFor={id}>{label}</StyledLabel>
                    <StyledInputNumber type={'number'} value={field.value} id={id} onChange={field.onChange} onBlur={field.onBlur} onFocus={props.onFocus} aria-invalid={errors ? "true" : "false"} placeholder={0} />
                    <ActionArea>
                        <Unit className="unit">{unit}</Unit>
                        <ActionButtons>
                            <IncreaseButton
                                type={'button'}
                                onClick={(e) => increase(e)}>
                                <Icon type="up" twoToneColor={'#B4B1C2'} style={{ fontSize: '8px' }} />
                            </IncreaseButton>
                            <DecreaseButton
                                type={'button'}
                                onClick={(e) => decrease(e)}>
                                < Icon type="down" twoToneColor={'#B4B1C2'} style={{ fontSize: '8px' }} />
                            </DecreaseButton>
                        </ActionButtons>
                    </ActionArea>
                </Wrapper>
            }
        </>
    )
}

export default InputNumber