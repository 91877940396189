export default {
	primary: {
		50: "#E8FEE9",
		100: "#C6FBC9",
		200: "#9CF8A5",
		300: "#66F47C",
		400: "#0FEF59",
		500: "#00EA33",
		600: "#00D82A",
		700: "#55B92A",
		
	},
	dark: {
		100: "#F4FAFA",
		200: "#EEF3F3",
		300: "#E5EAEA",
		400: "#B2B7B7",
		500: "#6B6879",
		600: "#6A6F6F",
		800: "rgb(29, 28, 28)",
		900: "#211F2B",
		950: "#272727", 
	},
	support: {
		white: 'white'
	}
}
