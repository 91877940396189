import React from "react"

import { Figure, StyledImage } from "./styles"
import images from '../../assets'

const Image = ({ width, height, name, alt, color, ...props }) => (
  <Figure width={width} height={height}>
    <StyledImage src={images[name]} alt={alt} color={color} {...props} />
  </Figure>
)

export default Image