import React, { useContext, useEffect } from "react";
import store from 'store';
import useHydraulicsContext from "../../../../new/Hydraulics/useHydraulics";
import Fieldset from "../../Fieldset";
import InputNumber from "../../InputNumber";
import List from "../../List";
import { Button, Select } from "antd";

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import {
    fluidParametersSchema,
    convertToPayloadStructure,
    fluidParametersInitalValues,
    correlationChoose,
    hydraulicModelChoose,
    surfacePressureLossType
} from "./payload";

import { SettingsFormContext } from "..";

const { Option } = Select

const FluidParametersForm = ({ previousStep, dataFluids, setDataFluids }) => {
    const { unitsConfigDetails } = useHydraulicsContext();

    const methods = useForm({
        defaultValues: dataFluids ? dataFluids : fluidParametersInitalValues,
        resolver: yupResolver(fluidParametersSchema),
    });
    const { handleSubmit, control, getValues, formState: { errors } } = methods



    const { onCancel, wellPhasesDetails, bitParametersDetails, fluidParametersDetails, setFluidParametersDetails } = useContext(SettingsFormContext)
    const { setPayload, wellInfo, fetchHydraulics, response } = useHydraulicsContext()



    useEffect(() => {
        if (wellPhasesDetails && fluidParametersDetails) {
            const payloadStructure = convertToPayloadStructure(wellPhasesDetails, bitParametersDetails, fluidParametersDetails, wellInfo, unitsConfigDetails)
            fetchHydraulics(payloadStructure)
            setPayload(payloadStructure)
        }
    }, [fluidParametersDetails]);

    function createFluidParameters(data) {
        setDataFluids(data)
        setFluidParametersDetails(data)
        onCancel()
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(createFluidParameters)}>
                    <Fieldset label={'Fluid Parameters'}>
                        <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
                            <InputNumber
                                label={'Fluid Density'}
                                name={'flu_dens'}
                                unit={unitsConfigDetails.fluidDensity_unit}
                                style={{ width: '100%' }}
                                control={control}
                                errors={errors.flu_dens}
                            />
                        </div>
                    </Fieldset>
                    <Fieldset label={'Rheometer Readings'}>
                        <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
                            <InputNumber
                                label={'R600'}
                                name={'r600'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.r600} />

                            <InputNumber
                                label={'R300'}
                                name={'r300'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.r300} />
                            <InputNumber
                                label={'R200'}
                                name={'r200'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.r200} />
                            <InputNumber
                                label={'R100'}
                                name={'r100'}
                                style={{ width: '100%' }}
                                errors={errors.r100} />
                            <InputNumber
                                label={'R6'}
                                name={'r6'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.r6} />
                            <InputNumber
                                label={'R3'}
                                name={'r3'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.r3} />

                        </div>
                    </Fieldset>
                    <Fieldset label={'Hydraulic Model Setup'}>
                        <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', gap: '8px' }}>
                            <List
                                label={'Hydraulic Model Selection'}
                                name={'hydraulic_model'}
                                control={control}
                                options={hydraulicModelChoose}
                                errors={errors.hydraulic_model} >
                                {hydraulicModelChoose && hydraulicModelChoose.map((value) => <Option key={value} value={value}>{value}</Option>)}
                            </List>

                            <List
                                defaultValue={'Moore'}
                                label={'Hydraulic Lag Time Correlation'}
                                name={'correlation'}
                                control={control}
                                options={correlationChoose}
                                errors={errors.correlation} >
                                {correlationChoose && correlationChoose.map((value) => <Option key={value} value={value}>{value}</Option>)}
                            </List>

                        </div>
                    </Fieldset>
                    <Fieldset label={'Pressure Parameters'}>
                        <div>
                            <List
                                label={'Surface Pressure Loss - Type'}
                                name={'case_surf_press_loss'}
                                control={control}
                                style={{ width: '100%' }}
                                placeholder="Enter the pressure loss rating of the rig surface equipment used."
                                errors={errors.case_surf_press_loss} >
                                
                                {surfacePressureLossType && surfacePressureLossType.map((value, index) => <Option key={value} value={index + 1}>{value}</Option>)}
                            </List>
                        </div>
                        <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
                            <InputNumber
                                label={'Choke Pressure Loss'}
                                unit={unitsConfigDetails.pressure_unit}
                                name={'ch_press_loss'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.ch_press_loss} />
                            <InputNumber
                                label={'Surface Casing  Pressure Loss'}
                                unit={unitsConfigDetails.pressure_unit}
                                name={'cs_press_loss'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.cs_press_loss} />
                            <InputNumber
                                label={'Downhole Tools  Pressure Loss'}
                                unit={unitsConfigDetails.pressure_unit}
                                name={'tool_press_loss'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.tool_press_loss} />
                        </div>
                    </Fieldset>
                    <Fieldset label={'Cuttings Parameters'}>
                        <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
                            <InputNumber
                                label={'Solids Density'}
                                unit={unitsConfigDetails.solidsDensity_unit}
                                name={'solids_dens'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.solids_dens} />
                            <InputNumber
                                label={'Solids Diameter'}
                                unit={unitsConfigDetails.radius_unit}
                                name={'solids_diam'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.solids_diam} />
                            <InputNumber
                                label={'Sphericity (0-100%)'}
                                unit={unitsConfigDetails.sphericity_unit}
                                name={'spher'}
                                control={control}
                                style={{ width: '100%' }}
                                errors={errors.spher} />
                        </div>
                    </Fieldset>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            htmlType={'button'}
                            onClick={onCancel}
                            style={{ color: 'red' }}
                        >
                            Cancel
                        </Button>
                        <div>
                            <Button
                                type="link"
                                onClick={() => previousStep(1)}
                                style={{ color: '#fff' }}
                            >
                                Back
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    )
}

export default FluidParametersForm;