import styled from 'styled-components'
import { variant, space, flexbox } from 'styled-system'

export const ButtonRAW = styled.button`
  ${space}
  ${flexbox}
  border-radius: 8px;
  border: none;
  padding: .5rem 1rem;
  ${({ theme }) => variant({ variants: theme.buttons })};

  &:not([disabled]) {
    cursor: pointer;
  }
`
