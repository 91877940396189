export function calculateWetness(
  c1Composition, c2Composition, c3Composition,
  c4Composition, c5Composition
) {
  const sumC1ToC5 = c1Composition + c2Composition + c3Composition + c4Composition + c5Composition;
  const wetness = !sumC1ToC5 ? 0 : (100 - ((c1Composition / sumC1ToC5) * 100));
  return wetness;
}

export function calculateBalanceRatio(
  c1Composition, c2Composition, c3Composition,
  c4Composition, c5Composition
) {
  const sumC3C4C5 = (c3Composition + c4Composition + c5Composition);
  const ballance = !sumC3C4C5 ? 0 : (c1Composition + c2Composition) / sumC3C4C5;
  return ballance;
}

export function calculateCharacterRatio(
  c3Composition, c4Composition, c5Composition
) {
  const sumC4C5 = c4Composition + c5Composition;
  const characterRatio = (sumC4C5 === 0 || c3Composition === 0) ? 0 : (sumC4C5 / c3Composition);
  return characterRatio;
}

export function calculateBitSize(bitSize) {
  const [left, right] = String(bitSize).split(' ');

  let bitSizeValue = Number(left);

  if (right && right.includes('/')) {
    // eslint-disable-next-line no-eval
    bitSizeValue += eval(right);
  }

  return bitSizeValue || 0;
};

/**
 *  Formula
 *  https://gitlab.com/geowellex/wellwatcher/well-gold/uploads/73277adce7fa9710f2c96c74e3d5977d/image.png
 */
export function calculateC1Normalized(
  bitSize = 0, rop = 0, c1PPM = 0, flow = 0
) {
  // TODO be able to migrate or accept the constant to represent ft and meters
  const constantToNormalize = 3.81;
  const x = constantToNormalize * c1PPM * flow;
  const bitSizeValue = calculateBitSize(bitSize);
  // eslint-disable-next-line no-restricted-properties
  const y = rop * Math.pow(bitSizeValue, 2);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(x) || isNaN(y)) {
    return null;
  }
  return  x / y;
}
