import React from "react";
import { Typography } from 'antd'
import { StyledSection, HydraulicsFlow, PressureGridItems } from "../styles";
import Indicator from '../../../components/Hydraulics/Indicator'

import useHydraulicsContext from "../useHydraulics";
import { handleData } from "../hydraulicsUtils";

export const FlowAnalysis = ({ pressureDetails }) => {
    const { isLoading, correlation } = useHydraulicsContext()

    return (
        <StyledSection>
            <Typography.Title level={3} style={{ color: '#fff', padding: '20px' }}>Flow Analysis</Typography.Title>
            <HydraulicsFlow>
                <PressureGridItems>
                    {handleData(correlation).map((indicator) => <Indicator key={indicator.field} title={indicator.field} value={indicator.value.toFixed(2)} style={{ minWidth: '250px' }} />)}
                </PressureGridItems>
            </HydraulicsFlow>
        </StyledSection>
    )
}