import React, { useState } from "react"
import  { Select } from "antd"
import Input from "../Input"
import InputNumber from "../InputNumber"
import Icon from "../Icon"
import { AddButton, DeleteRow, StyledDisabledInput, StyledInputRow } from "./styles"
import { useFieldArray, useFormContext } from "react-hook-form"
import useHydraulicsContext from "../../../new/Hydraulics/useHydraulics"
import { sectionTypeOptions } from "../SettingsFormModal/SettingsForm/payload"
import List from "../List"

const { Option } = Select;

const InputRow = ({ id, label, placeholder, control, errors, name, width, ...props }) => {

    const { unitsConfigDetails } = useHydraulicsContext()
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "phases", // unique name for your Field Array
    });
    const { setValue, getValues } = useFormContext();

    const inputs = [{
        field: 'top',
        name: 'top_ecd',
        label: 'Top',
        unit: unitsConfigDetails.depth_unit
    },
    {
        field: 'bottom',
        name: 'bottom_ecd',
        label: 'Bottom',
        unit: unitsConfigDetails.depth_unit
    },
    {
        field: 'diam',
        name: 'casing_diam',
        label: 'Diameter',
        unit: unitsConfigDetails.radius_unit
    }]

    function addNewPhase(phases) {
        if (phases) {
            let length = phases.length;
            let prev_bottom_ecd = getValues(`phases.${length - 1}.bottom_ecd`)
            append({
                "top_ecd": prev_bottom_ecd,
                "bottom_ecd": null,
            })
        }

    }

    return (
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', gap: '8px' }}>
            {fields.map((field, index) => {
                return (
                    <StyledInputRow>
                        <StyledDisabledInput key={field.id} name={`phases.${index}`} placeholder={`Section ${index + 1}`} disabled={true} style={{ backgroundColor: 'transparent' }} />
                        <List
                            label={'Type'}
                            name={`phases.${index}.section_type`}
                            control={control}
                            options={sectionTypeOptions}
                            //errors={errors.section_type} 
                        >
                            {sectionTypeOptions && sectionTypeOptions.map((value) => <Option key={value} value={value}>{value}</Option>)}
                        </List>
                        {inputs.map(({ field, label, unit, name }) =>
                            <InputNumber
                                id={field.index}
                                key={name}
                                placeholder={0}
                                label={label}
                                unit={unit}
                                name={`phases.${index}.${name}`}
                                control={control}
                                errors={errors && errors[index] ? errors[index][name] : ''}

                            />
                        )}

                        <DeleteRow type={'button'} onClick={() => remove(index)}>
                            <Icon name={'trash'} width={'20'} height={'20'} margin={'.5em'}></Icon>
                        </DeleteRow>
                    </StyledInputRow >
                )
            })}
            <AddButton onClick={() => addNewPhase(fields)}
            >
                + Add Section
            </AddButton>
            {errors ? <span style={{ color: 'red', margin: '2px' }}>{errors.message}</span> : null}
        </div >
    )
}

export default InputRow