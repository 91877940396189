import React, { useState, useEffect, Fragment, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field,
} from 'formik';
import * as crossPlotsActions from './actions/crossplots';
import _get from 'lodash.get';
import { Input, Button,  Drawer,  Select, Switch } from 'antd';
import { Form as AntForm } from 'antd';
import store from 'store';
import Legend from './components/Legend';
import QualityChart from './components/QualityChart';
import ScatterPlotC1C45 from './components/ScatterPlot/ScatterPlotC1C45';
import ScatterPlotC1 from './components/ScatterPlot/ScatterPlotC1C3';
import ScatterPlotC1ByC2 from './components/ScatterPlot/ScatterPlotC1ByC2';
import ScatterPlotC1C2ByC3 from './components/ScatterPlot/ScatterPlotC1C2ByC3';
import VerticalScatterplotC25 from './components/ScatterPlot/VerticalScatterPlotc25';
import VerticalScatterPlotC1C2 from './components/ScatterPlot/VerticalScatterPlotC1C2';
import C1Normalize from './components/Normalize/C1Normalize';
import ScatterPlotWetness from './components/ScatterPlot/ScatterPlotWetness';
import FluidTyping from './components/FluidTyping';
import IcByNc from './components/IcByNc';
import C2iC4 from './components/C2iC4';
import ScatterPlotC2ByC3 from './components/C2ByC3';
import { getUnit } from './utils';
import './styles/app.scss';

const { Option } = Select;

const CustomSwitchComponent = ({
  field,
  setFieldValue,
  setFieldTouched,
}) => {
  return (
    <Switch
      {...field}
      defaultChecked
      onChange={(checked) => {
        setFieldValue('isVisible', checked);
        setFieldTouched('isVisible', true)
      }}
    />
  );
};

// TODO LEO should move the whole logic to redux
// we need to turn this component a stateless and separate
// this logic to store it temporarily on the indexDB, otherwhise
// when the user to click to save it permanently
// this will be associated with the config of each chart, and also
// to remind of use useMemo hook in each cahrt to avoid unexpected re-render
const Crossplots = ({ match }) => {
  const dispatch = useDispatch();
  const crossPlotState = useSelector(state => state.crossplots);

  const [currentChart, setChart] = useState();
  const [visible, setVisible] = useState(false);
  const [scattersConfig, setConfig] = useState();
  const [chartType, setCurrentType] = useState();
  const [ranges, setRanges] = useState(null);
  const [pointsInsideZones, setZones] = useState([]);
  const [currentUnit, setUnit] = useState(getUnit());
  const [initialValues, setInitialValues] = useState({
    yMin: '',
    yMax: '',
    yTitle: '',
    yAxisType: '',
    xMin: '',
    xMax: '',
    xTitle: '',
    xAxisType: '',
    isVisible: true,
  });

  useEffect(() => {
    const [id, type] = match.params.id.split('--');
    const queryData = store.get('querys')[id];
    if (queryData) {
      setConfig({ ...queryData, id });
    }
    setChart(type);
    setUnit(getUnit());

  }, [match]);

  const titles = {
    balanceRatioToggle: 'Fluid Typing Checks',
    C1C2SF: 'Geochemistry',
  };

  const toggleDrawer = (type) => () => {
    setCurrentType(type);
    if (scattersConfig && type) {
      const windowConfig = store.get('querys')[scattersConfig.id];
      const chartConfig = windowConfig.scales[type];
      setInitialValues({
        yMin: chartConfig.y.min,
        yMax: chartConfig.y.max,
        yTitle: chartConfig.y.title,
        yAxisType: chartConfig.y.yAxisType,
        xMin: chartConfig.x.min,
        xMax: chartConfig.x.max,
        xTitle: chartConfig.x.title,
        xAxisType: chartConfig.x.xAxisType,
        isVisible: chartConfig.isVisible,
      });
    }
    setVisible(!visible);
  };

  const closeDrawer = () => {
    setCurrentType(null);
    setVisible(false);
  };

  /*
   * This function receive the submit from the form
   * and will update on localStorage in a specific query
   * the config of a specific cross plot contained on this query
   * This function has the purporse as well to dispatch the new values
   * with the new config to apply in each crossplot
   * We will get the values from the title
   */
  const changeScatterConfig = (values, actions) => {
    // Update the localStorage
    const { id, scales } = scattersConfig;
    const updatedQuery = { ...scattersConfig, };
    const { yMin, yMax, yTitle, yAxisType, xMin, xMax, xTitle, xAxisType } = values;
    updatedQuery.scales[chartType] = {
      y: { min: Number(yMin), max: Number(yMax), title: yTitle, yAxisType, },
      x: { min: Number(xMin), max: Number(xMax), title: xTitle, xAxisType, },
      isVisible: values.isVisible,
    };

    const oldQueys = store.get('querys');
    store.set('querys', {
      ...oldQueys,
      [id]: updatedQuery,
    });

    // Dispatch It
    const changeConfig = new CustomEvent(
      `${id}-${chartType}`, {
        detail: {
          chartType,
          config: updatedQuery.scales[chartType],
          id,
        },
      },
    );
    document.dispatchEvent(changeConfig);
    // Hide the Drawer
    setVisible(!visible);
  };

  const receivePoints = (pWithDepth) => {
    dispatch(
      crossPlotsActions.receiveFluidTypeValues(pWithDepth)
    );
  };

  const depthFluidType = _get(scattersConfig, 'depth');
  const maxDepthFluidType = depthFluidType ? depthFluidType.slice(-1)[0] : 0;
  const minFluidType = _get(scattersConfig, 'depth[0]');
  const xScaleFluidType = _get(
    scattersConfig,
    'scales.chart1.x',
    { min: 0, max: 1000, title: 'Fluid Typing',  xAxisType: 'linear', }
  );

  const wetnessDataWithDepth = _get(scattersConfig, 'data.chart3.itemsWithDepth', []);
  const wetnessData = _get(scattersConfig, 'data.chart3.items', []);
  const C1C2C3DataWithDepth = _get(scattersConfig, 'data.chart2.itemsWithDepth', []);
  const C1C2C3Data = _get(scattersConfig, 'data.chart2.items', []);
  const ScatterPlotC1C2Data = _get(scattersConfig, 'data.chart1.items', []);
  
  return (
    <div className="scatters-plot">
      <Drawer
        title="Edit Chart"
        placement="right"
        closable={false}
        onClose={toggleDrawer}
        visible={visible}
        width="320"
        destroyOnClose
      >
        <Formik
          initialValues={initialValues}
          onSubmit={changeScatterConfig}
          enableReinitialize
          render={({
            handleChange,
            handleBlur,
            handleFocus,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              {/*
              <AntForm.Item label="Is Visible" style={{ display: 'flex' }}>
                <Field
                  name="isVisible"
                  id="isVisible"
                  component={CustomSwitchComponent}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              </AntForm.Item>
              */}
              <AntForm.Item
                label="yMin"
                style={{ display: 'flex' }}
              >
                <Input
                  placeholder={initialValues.yMin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="yMin"
                  name="yMin"
                />
              </AntForm.Item>
              <AntForm.Item label="yMax" style={{ display: 'flex' }}>
                <Input
                  placeholder={initialValues.yMax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="yMax"
                  name="yMax"
                />
              </AntForm.Item>
              <AntForm.Item label="xMin" style={{ display: 'flex' }}>
                <Input
                  placeholder={initialValues.xMin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="xMin"
                  name="xMin"
                />
              </AntForm.Item>
              <AntForm.Item label="xMax" style={{ display: 'flex' }}>
                <Input
                  placeholder={initialValues.xMax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="xMax"
                  name="xMax"
                />
              </AntForm.Item>
              <AntForm.Item
                label="Vertical Title"
                style={{ display: 'flex' }}
              >
                <Input
                  placeholder={initialValues.yTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="yTitle"
                  name="yTitle"
                />
              </AntForm.Item>
              <AntForm.Item label="Horizontal Title" style={{ display: 'flex' }}>
                <Input
                  placeholder={initialValues.xTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="xTitle"
                  name="xTitle"
                />
              </AntForm.Item>
              {/*
              <AntForm.Item label="y Type of axis" style={{ display: 'flex' }}>
                <Select
                  showSearch
                  name="yAxisType"
                  id="yAxisType"
                  style={{ width: 140 }}
                  defaultValue={initialValues.yAxisType}
                  placeholder={initialValues.yAxisType}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                >
                  <Option value="linear">Linear</Option>
                  <Option value="logarithmic">Logarithmic</Option>
                </Select>
              </AntForm.Item>
              <AntForm.Item label="x Type of axis" style={{ display: 'flex' }}>
                <Select
                  showSearch
                  name="xAxisType"
                  id="xAxisType"
                  defaultValue={initialValues.xAxisType}
                  style={{ width: 140 }}
                  placeholder={initialValues.xAxisType}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                >
                  <Option value="linear">Linear</Option>
                  <Option value="logarithmic">Logarithmic</Option>
                </Select>
              </AntForm.Item>
              */}
              <AntForm.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={closeDrawer} style={{ marginRight: '0.5rem', }}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Apply
                </Button>
              </AntForm.Item>
            </form>
          )}
        />
      </Drawer>
      {/*
        {isProcessing && (
          <p>Loading...</p>
        )}
      */}
      {currentChart && (
        <>
          {scattersConfig && scattersConfig.name ?
            <div className="scatter-title-zone">
              <h3 className="title-zone">{scattersConfig && scattersConfig.name}</h3>
            </div>
          : null}
          <div className="scatter-title-wrapper scatter-title-margin">
            <div className="scatter-title-box">
              <h3 className="scatters-title">
                {titles[currentChart]}
              </h3>
              {'  '}
              {scattersConfig && (
                <h3 className="scatters-range">
                  {`Min: ${scattersConfig.minLabel} - Max: ${scattersConfig.maxLabel}`}
                </h3>
              )}
            </div>
            <div className="scatters-legends">
              <Legend
                name="oil"
                label="OIL"
              />
              <Legend
                name="transition"
                color="#f4c094"
                label="TRANSITION"
              />
              <Legend
                name="gas"
                color="#f1a7a7"
                label="GAS"
              />
            </div>
          </div>
        </>
      )}
      {currentChart && currentChart === 'balanceRatioToggle' && (
        <Fragment>
          <div class='all-plot'>
          <div className="vertical-plot">
            {/*
            <FluidTyping
              id={'fluid-typing'}
              key={'fluid-typing'}
              yScale={
                {
                  min: minFluidType,
                  max: maxDepthFluidType,
                  title: ` MD Depth ( ${currentUnit} )  `,
                  yAxisType: 'linear',
                }
              }
              xScale={xScaleFluidType}
              openConfigureScale={toggleDrawer('chart1')}
              idListener={scattersConfig && scattersConfig.id}
              data={crossPlotState.points}
              dataWithDepth={crossPlotState.points}
            />
            */}
          </div>
          <div className="scatter-group">
            <ScatterPlotC1C2ByC3
              id={'scatter-c1-c3'}
              key={'scatter-c1-c3'}
              yScale={
                scattersConfig &&
                scattersConfig.scales &&
                  scattersConfig.scales.chart2 &&
                  scattersConfig.scales.chart2.y
                  ? scattersConfig.scales.chart2.y
                  : { min: 0.0001, max: 100, title: '(C4+C5) / (C1+C2)', yAxisType: 'logarithmic', }
              }
              xScale={
                scattersConfig &&
                scattersConfig.scales
                  && scattersConfig.scales.chart2
                  && scattersConfig.scales.chart2.x
                  ? scattersConfig.scales.chart2.x
                  : { min: 0.1, max: 1000, title: '(C1+C2) / C3', xAxisType: 'logarithmic' }
              }
              openConfigureScale={toggleDrawer('chart2')}
              idListener={scattersConfig && scattersConfig.id}
              receivePointsIsideZone={receivePoints}
              data={C1C2C3Data}
              dataWithDepth={C1C2C3DataWithDepth}
            />
            <ScatterPlotWetness
              id= "crossplot-wetness"
              yScale={
                scattersConfig &&
                scattersConfig.scales &&
                scattersConfig.scales.chart3 &&
                scattersConfig.scales.chart3.y
              }
              xScale={
                scattersConfig &&
                scattersConfig.scales &&
                scattersConfig.scales.chart3 &&
                scattersConfig.scales.chart3.x
              }
              openConfigureScale={toggleDrawer('chart3')}
              idListener={scattersConfig && scattersConfig.id}
              receivePointsIsideZone={receivePoints}
              dataWithDepth={wetnessDataWithDepth}
              data={wetnessData}
            />
            <ScatterPlotC1
              id={'scatter-c1'}
              key={'scatter-c1'}
              yScale={
                scattersConfig &&
                scattersConfig.scales &&
                scattersConfig.scales.chart4 &&
                scattersConfig.scales.chart4.y
              }
              xScale={
                scattersConfig &&
                scattersConfig.scales &&
                scattersConfig.scales.chart4 &&
                scattersConfig.scales.chart4.x
              }
              openConfigureScale={toggleDrawer('chart4')}
              idListener={scattersConfig && scattersConfig.id}
              receivePointsIsideZone={receivePoints}
              dataWithDepth={
                scattersConfig &&
                scattersConfig.data &&
                scattersConfig.data.chart4 &&
                scattersConfig.data.chart4.itemsWithDepth
                  ? scattersConfig.data.chart4.itemsWithDepth : []
              }
              data={
                scattersConfig &&
                scattersConfig.data &&
                scattersConfig.data.chart4.items ?
                scattersConfig.data.chart4.items : []
              }
            />
            <ScatterPlotC1C45
              id={'c1-c45'}
              key={'c1-c45'}
              yScale={
                scattersConfig &&
                scattersConfig.data &&
                scattersConfig.scales.chart5 &&
                scattersConfig.scales.chart5.y
              }
              xScale={
                scattersConfig &&
                scattersConfig.data &&
                scattersConfig.scales.chart5 &&
                scattersConfig.scales.chart5.x
              }
              openConfigureScale={toggleDrawer('chart5')}
              idListener={scattersConfig && scattersConfig.id}
              receivePointsIsideZone={receivePoints}
              dataWithDepth={
                scattersConfig &&
                scattersConfig.data &&
                scattersConfig.data.chart5 &&
                scattersConfig.data.chart5.itemsWithDepth ? scattersConfig.data.chart5.itemsWithDepth : []
              }
              data={
                scattersConfig &&
                scattersConfig.data &&
                scattersConfig.data.chart5 &&
                scattersConfig.data.chart5.items ? scattersConfig.data.chart5.items : []
              }
            />
          </div>
          </div>
        </Fragment>
      )}
      {/* Geochemistry */}  
      {currentChart && currentChart === 'C1C2SF' && (
        <>
          <div className="wrapper-vertical-plot">
            <div className="wrapper-c1-c2">
              <div className="vertical-plot">
                <div className="wrapper-scatter-plot-c1c2">
                  <span className="title-vertical-c1byc2-oil">OIL CHARGE</span>
                  <span className="title-vertical-c1byc2-gas">GAS CHARGE</span>
                  {/* <span className="title-vertical-c1byc2">C1/C2 (MS)</span> */}
                  <VerticalScatterPlotC1C2 
                    id={'c1-c2'}
                    key={'c1-c2'}
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart1 &&
                        scattersConfig.scales.chart1.y
                        ? scattersConfig.scales.chart1.y
                        : { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear', }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart1
                        && scattersConfig.scales.chart1.x
                        ? scattersConfig.scales.chart1.x
                        : { min: 0, max: 20, title: 'C1/C2', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    data={ScatterPlotC1C2Data}
                    openConfigureScale={toggleDrawer('chart1')}
                  />
                </div>
                <div className="wrapper-scatterplot-c25">
                {/* <span className="title-vertical-c-25">SF C2S</span> */}
                <span className="title-vertical-c-5-oil">OIL CHARGE</span>
                <span className="title-vertical-c-5-gas">GAS CHARGE</span>
                  <VerticalScatterplotC25
                    id={'c-25'}
                    key={'c-25'}
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart2 &&
                        scattersConfig.scales.chart2.y
                        ? scattersConfig.scales.chart2.y
                        : { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear', }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart2
                        && scattersConfig.scales.chart2.x
                        ? scattersConfig.scales.chart2.x
                        : { min: 0, max: 4, title: 'SF C2-5 (ppm)', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    data={scattersConfig && scattersConfig.data && scattersConfig.data.chart1.items ? scattersConfig.data.chart1.items : []}
                    openConfigureScale={toggleDrawer('chart2')}
                  />
                </div>
              </div>
              <div className="scatter-group plot-c1-c2-sf">
                <ScatterPlotC1ByC2
                  id={'scatter-c1-by-c2'}
                  key={'scatter-c1-by-c2'}
                  yScale={
                    scattersConfig &&
                    scattersConfig.scales &&
                      scattersConfig.scales.chart3 &&
                      scattersConfig.scales.chart3.y
                      ? scattersConfig.scales.chart3.y
                      : { min: 1000, max: 7000, title: 'SF C2-5 (ppm)', yAxisType: 'linear', }
                  }
                  xScale={
                    scattersConfig &&
                    scattersConfig.scales
                      && scattersConfig.scales.chart3
                      && scattersConfig.scales.chart3.x
                      ? scattersConfig.scales.chart3.x
                      : { min: 0, max: 40, title: 'C1/C2', xAxisType: 'linear' }
                  }
                  idListener={scattersConfig && scattersConfig.id}
                  data={scattersConfig && scattersConfig.data && scattersConfig.data.chart3.items ? scattersConfig.data.chart3.items : []}
                  openConfigureScale={toggleDrawer('chart3')}
                />
              </div>
            </div>
            <br />
            <div className="scatter-title-wrapper">
              <div className="scatter-title-box">
                <h3 className="scatters-title">
                  Biodegradation
                </h3>
                {'  '}
                {scattersConfig && (
                  <h3 className="scatters-range">
                    {`Min: ${scattersConfig.minLabel} - Max: ${scattersConfig.maxLabel}`}
                  </h3>
                )}
              </div>
            </div>
            <div className="wrapper-ic4-ic5-c2">
              <div className="vertical-plot">
                <div className="wraper-ic4-nc4">
                  <IcByNc
                    id={'ic4-nc4'}
                    key={'ic4-nc4'}
                    title="iC4/nC4"
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart10 &&
                        scattersConfig.scales.chart10.y
                        ? scattersConfig.scales.chart10.y
                        : { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear' }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart10
                        && scattersConfig.scales.chart10.x
                        ? scattersConfig.scales.chart10.x
                        : { min: 0, max: 5, title: 'iC4/nC4', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    nameChart={'chart10'}
                    data={scattersConfig &&
                      scattersConfig.data &&
                      scattersConfig.data.chart10 ? scattersConfig.data.chart10 : []}
                    openConfigureScale={toggleDrawer('chart10')}
                  />
                </div>
                <div className="wrapper-ic5-nc5">
                  <IcByNc
                    id={'ic5-nc5'}
                    key={'ic5-nc5'}
                    title="iC5/nC5"
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart9 &&
                        scattersConfig.scales.chart9.y
                        ? scattersConfig.scales.chart9.y
                        : { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear' }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart9
                        && scattersConfig.scales.chart9.x
                        ? scattersConfig.scales.chart9.x
                        : { min: 0, max: 5, title: 'iC5/nC5', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    nameChart={'chart9'}
                    data={
                      scattersConfig &&
                      scattersConfig.data &&
                      scattersConfig.data.chart9 ? scattersConfig.data.chart9 : []}
                    openConfigureScale={toggleDrawer('chart9')}
                  />
                </div>
              </div>
              <div className="scatter-group plot-c1-c2-sf d-column wrapper-c2-ic4-c2-by-c3 ">
                <div className="wrapper-c2-ic4">
                  <C2iC4
                    data={
                      scattersConfig &&
                      scattersConfig.data &&
                      scattersConfig.data.chart8 ? scattersConfig.data.chart8.items : []}
                    id={'c2-ic4'}
                    key={'c2-ic4'}
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart8 &&
                        scattersConfig.scales.chart8.y
                        ? scattersConfig.scales.chart8.y
                        : { min: 1, max: 10, title: 'C2/C3', yAxisType: 'linear' }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart8
                        && scattersConfig.scales.chart8.x
                        ? scattersConfig.scales.chart8.x
                        : { min: 0, max: 80, title: 'C2/iC4', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    openConfigureScale={toggleDrawer('chart8')}
                  />
                </div>
                <div className="wrapper-c2-c3">
                  <ScatterPlotC2ByC3
                    data={scattersConfig && scattersConfig.data && scattersConfig.data.chart7 ? scattersConfig.data.chart7.items : []}
                    id={'c2-by-c3'}
                    key={'c2-by-c3'}
                    yScale={
                      scattersConfig &&
                      scattersConfig.scales &&
                        scattersConfig.scales.chart7 &&
                        scattersConfig.scales.chart7.y
                        ? scattersConfig.scales.chart7.y
                        : { min: 1, max: 100, title: 'C1/C2', yAxisType: 'linear' }
                    }
                    xScale={
                      scattersConfig &&
                      scattersConfig.scales
                        && scattersConfig.scales.chart7
                        && scattersConfig.scales.chart7.x
                        ? scattersConfig.scales.chart7.x
                        : { min: 0, max: 9, title: 'C2/C3', xAxisType: 'linear' }
                    }
                    idListener={scattersConfig && scattersConfig.id}
                    openConfigureScale={toggleDrawer('chart7')}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Crossplots;
