import React, { useState, useEffect } from 'react'

import { HydraulicsProvider } from './useHydraulics'

import { Container } from './styles'
import HeaderHydraulics from '../../components/Hydraulics/HeaderHydraulics'
import SettingsFormModal from '../../components/Hydraulics/SettingsFormModal'
import UnitsConfigModal from '../../components/Hydraulics/UnitsConfigModal'
import { PressureLoss } from './PressureLoss'
import { FlowAnalysis } from './FlowAnalysis'
import { Cuttings } from './Cuttings'


const Hydraulics = ({ onBackHome, ...props }) => {
    const [state, setState] = useState({
        isOpenSettingsFormModal: true,
        isOpenUnitsConfigModal: false
    });

    const [currentPage, setCurrentPage] = useState(0);

    const showSettingsFormModal = () => {
        setState({
            isOpenSettingsFormModal: true
        });
    };

    const showUnitsConfigModal = () => {
        setState({
            isOpenUnitsConfigModal: true
        });
    };

    const handleOk = e => {
        setState({
            isOpenSettingsFormModal: false,
            isOpenUnitsConfigModal: false,
        });
    };

    const handleCancel = e => {
        setState({
            isOpenSettingsFormModal: false,
            isOpenUnitsConfigModal: false,
        });
    };

    const pages = [
        (<>
            <PressureLoss />
            <FlowAnalysis />
        </>),
        (<Cuttings wells={props.wells}
            afterSelect={props.afterSelect}
            unit={props.unit}
            min={props.min}
            max={props.maxDepth}
            onAfter={props.onAfter}
            depth={props.depth}
            lithoAutomudData={props.lithoAutomudData}
        ></Cuttings>)
    ]

    return (
        <HydraulicsProvider>
            <Container>
                <SettingsFormModal onOK={handleOk}
                    onCancel={handleCancel}
                    visible={state.isOpenSettingsFormModal}
                    openUnitsConfigModal={showUnitsConfigModal}
                >
                </SettingsFormModal>
                <UnitsConfigModal onOK={handleOk}
                    onCancel={handleCancel}
                    visible={state.isOpenUnitsConfigModal}
                >
                </UnitsConfigModal>
                <HeaderHydraulics
                    onBackHome={onBackHome}
                    openSettingsFormModal={showSettingsFormModal}
                    openUnitsConfigModal={showUnitsConfigModal}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                >
                </HeaderHydraulics>
                {pages[currentPage]}
            </Container >
        </HydraulicsProvider>
    )
}

export default Hydraulics;
