import React from 'react';
import Card from '../Card';
import classNames from 'classnames';

const BigCard = ({ description, value, unit, classes, type, lastCard }) => {
  return (
    <Card classes={{
      customContainer: classes.customClass
    }}>
      <div
        className={
          classNames(
            'big-card__container',
            {
              [type]: !!type,
              [lastCard]: !!lastCard
            }
          )
        }
      >
      <div className="big-card__content">
        <div className="big-card__description">
          {description}
        </div>
        <div className="big-card__value">
          <span className="big-card__unit">{unit}</span>
          <span className="big-card__text">{value}</span>
        </div>
      </div>
      </div>
    </Card>
  );
};

BigCard.defaultProps = {
  classes: {
    customClass: 'card--mini',
    raised: 'raised'
  },
  value: 'N/A',
  raised: false
};

export default BigCard;
