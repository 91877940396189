const bitSizesInFloat = {
  '6 7/8': 6.875,
  '6 3/4': 6.75,
  '6 1/8': 6.125,
  '7 7/8': 7.875,
  '8 3/4': 8.75,
  '8 1/2': 8.5,
  '8 3/8': 8.375,
  '9 1/2': 9.5,
  '10 5/8': 625,
  '12 1/4': 12.25,
  '13 1/2': 13.5,
  '16': 16,
  '17 1/2': 17.5 
};

export function generateBitSizeCurve(lastDepth, bitSizes, stepAcquisition) {
  const depthGenerator = (stop, step = 1) =>
    new Array(stop).fill(null).map((_, index)=> index + step);

  const depth = depthGenerator(lastDepth, stepAcquisition);

  // Values from the Well Phases form
  const top_values = bitSizes.map(b => b.top);
  const bottom_values =  bitSizes.map(b => b.bottom);
  const bit_size = bitSizes.map(b => bitSizesInFloat[b.bitSize]);

  // Define "bit_size_array" with the last value from "bit_size" 
  let bit_size_array = new Array(depth.length).fill(bit_size[bit_size.length - 1]);

  // Update "bit_size_array" values based on the top and bottom phases and bit sizes
  for (let i = 0; i < top_values.length; i++) {
    const startIndex = depth.findIndex((d) => d >= top_values[i]);
    bit_size_array.fill(bit_size[i], startIndex, bit_size_array.length);
  }

  return bit_size_array;
}

/***
  -How to perform this function

  generateBitSizeCurve(
    2000, //depth total 
    [ 
        { top: 0, bottom: 1000, bitSize: '6 1/8' },
        { top: 1001, bottom: 2000, bitSize: '16'}
    ],
    1
  )
*/