import React from "react"
import { ButtonRAW } from "./styles"

const Button = ({ children, variant = 'primary', ...props }) => (
  <ButtonRAW variant={props.disabled || variant} {...props}>
    {children}
  </ButtonRAW>
)                                                                                                            

export default Button
