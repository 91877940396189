import React from 'react';
import classNames from 'classnames';

function Input({
  type, name, placeholder, className, onChange, ...props
}) {
  return (
    <input
      {...props}
      className={classNames(
        'input',
        className,
      )}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}

Input.defaultProps = {
  type: '',
  name: '',
  placeholder: ''
};

export default Input;
