import React from 'react';
import classNames from 'classnames';

const Nav = ({
  children, className
}) => (
  <div className={classNames(className)}>
    <nav className="nav">
      {children}
    </nav>
  </div>
);

export default Nav;
