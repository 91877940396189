import React, { Component } from 'react';
import store from 'store';
import Highcharts from 'highcharts';
import classNames from 'classnames';
import { Button } from 'antd';
import { createPlotLine } from  '../../utils';
import Input from '../Input';

class C1Normalize extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();
  }

  shouldComponentUpdate(nextProps) {

    const { plotLineMin, plotLineMax } = nextProps;
    if (
      plotLineMin !== this.props.plotLineMin ||
      plotLineMax !== this.props.plotLineMax
    ) {
      createPlotLine(
        plotLineMin,
        plotLineMax,
        this.chart,
      );
    }
    
    if (nextProps.minDepth ||
        nextProps.depthTop !== this.props.minDepth ||
        this.props.depthTop || nextProps.maxDepth ||
        nextProps.depthBottom !== this.props.maxDepth ||
        this.props.depthBottom) {
   
      const labels = nextProps.depthBottom ? ({
        labels: {
          formatter: function() {
            if(this.value <= nextProps.depthBottom){
              return this.value;
            } else {
              return nextProps.depthBottom;
            }
          }
        }
      }) : {};

      this.chart.yAxis[0].update({
        min: nextProps.depthTop ? nextProps.depthTop : nextProps.minDepth,
        max: nextProps.depthBottom ? nextProps.depthBottom : nextProps.maxDepth,
        pointStart: nextProps.depthTop,
        tickInterval: 10,
        ...labels,
      });
      this.chart.redraw();
      return true;
    }
     return false; 
  }

  onChangeSlider = ({ target: { name, value }}) => {
    const chartName = 'c1Normalize'
    this.setState({
      [name]: value
    }, () => {
      createPlotLine(
        this.state.plotLineMin,
        this.state.plotLineMax,
        this.chart,
        chartName
      );
    });
  }

  saveData = () => {
    const { plotLineMin, plotLineMax } = this.state;
  
    store.set('plot-lines-normalize', {
      [this.props.wellId]: {
        c1Normalize: { min: plotLineMin, max: plotLineMax } }
    });
  }

  createScatterPlot = () => {
    const { id, data, } = this.props;
    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter',
        backgroundColor: '#ffffff'
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false,
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        title: {
          enabled: true,
          text: '<b>C1 Normalized (ppm)</b>',
        },
        type: 'logarithmic',
        opposite: true,
        pointStart: 1,
        tickStart: 1,
        showLastLabel: true,
        showFirstLabel: true,
        min: 1,
        max: 1000000
      },
      yAxis: {
        title: {
          text: '<b>C1 Normalized (ppm)</b>',
        },
        allowDecimals: true,
        lineColor: '#ccd6eb',
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        showFirstLabel: true,
        reversed: true,
        gridLineWidth: 1
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: '#b55700',
        borderWidth: 0,
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: '{point.x} cm, {point.y} kg'
          }
        }
      },
      tooltip: {
        enabled: false,
      },
      series: [{
          name: ' ',
          color: 'rgba(223, 83, 83, .5)',
          data: [],
          marker: {
            fillColor: 'rgba(45, 52, 54, 0.7)',
            symbol: 'circle',
            radius: 2.5,
          }
      }, {
          name: ' ',
          color: 'rgba(119, 152, 191, 0.5)',
          data: [],
          marker: {
            fillColor: 'rgba(231, 76, 60, 0.7)',
            symbol: 'circle',
          }
      }]
    });
  }

  render() {
    const {
      id, withMinorGridLines, className, deactivateLegend, setTypeChart, maxDepth, minDepth
    } = this.props;

    return (
      <div className="normalize-containe">
        <Button 
          type="primary"
          shape="circle"
          icon="setting"
          className="settings-normalize-chart"
          onClick={setTypeChart('normalize')}
        />
        <div className="scatter-general">
          <div
            className={classNames(
              className,
              { 'reset-minor-grids': !withMinorGridLines },
              { 'deactivate-legend': deactivateLegend }
            )}
            id={id}
            style={{
            paddingTop: '12px',
            height: '600px',
            width: '340px',
            flex: 1
          }}>
          </div>
        </div>
      </div>
    );
  }
}

C1Normalize.defaultProps = {
  withMinorGridLines: false,
  deactivateLegend: true,
  className: 'scatter-plot',
};

export default C1Normalize;
