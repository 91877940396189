import React, { Component } from 'react';
import Highcharts from 'highcharts';
import { Button } from 'antd';
import Exporting from 'highcharts/modules/exporting';
import classNames from 'classnames';
import {
  calculateCoordinates
} from '../../../utils/CalculatingCoordinatesArea';
import {
  filterToAvoidZeroXAxis, filterToAvoidZeroYAxis,
  getPointsC1C2ByC3Zones
} from '../../../utils';
import { drawPath } from '../../../utils/Svg';

Exporting(Highcharts);

/*
 X – (C1+C2)/C3   Y – (C4+C5)/(C1+C2)

Oil Zone (1-2-3-4)
1-2: y = 8E-05x2,5917
2-3: y = 68,463x-1,803
3-4: y = 128,05x2,6936
4-1: y = 0,5621x-1,815

Gas Zone (5-6-7-8)
5-6: y = 1,4569x-1,712
6-7: y = 5E-11x2,8419
7-8: y = 126,13x-1,697
8-5: y = 6E-06x2,694

Transition Superior
y = 0,0167
Min: x = 18,25
Max: x = 42,75

Transition Inferior
y = 0,1486
Min: x = 7,855
Max: x = 19
*/

// 3-4: y = 128,05x2,6936
// 4-1: y = 0,5621x-1,815
const P4 = calculateCoordinates(
  128.05,
  2.6936,
  0.5621,
  -1.815
);

// 2-3: y = 68,463x-1,803
// 3-4: y = 128,05x2,6936
const P3 = calculateCoordinates(
  68.463,
  -1.803,
  128.05,
  2.6936
);

// 1-2: y = 8E-05x2,5917
// 4-1: y = 0,5621x-1,815
const P1 = calculateCoordinates(
  8 * (1 / (Math.pow(10, 5))),
  2.5917,
  0.5621,
  -1.815
);

// 1-2: y = 8E-05x2,5917
// 2-3: y = 68,463x-1,803
const P2 = calculateCoordinates(
  8 * (1 / (Math.pow(10, 5))),
  2.5917,
  68.463,
  -1.803
);

// Gas Zone (5-6-7-8)
// 5-6: y = 1,4569x-1,712
// 6-7: y = 5E-11x2,8419
// 7-8: y = 126,13x-1,697
// 8-5: y = 6E-06x2,694
const P5 = calculateCoordinates(
  1.4569,
  -1.712,
  6 * (1 / (Math.pow(10, 6))),
  2.694
);

// 5-6: y = 1,4569x-1,712
// 6-7: y = 5E-11x2,8419
const P6 = calculateCoordinates(
  1.4569,
  -1.712,
  5 * (1 / (Math.pow(10, 11))),
  2.8419
);

// 6-7: y = 5E-11x2,8419
// 7-8: y = 126,13x-1,697
const P7 = calculateCoordinates(
  5 * (1 / (Math.pow(10, 11))),
  2.8419,
  126.13,
  -1.697
);

// 7-8: y = 126,13x-1,697
// 8-5: y = 6E-06x2,694
const P8 = calculateCoordinates(
  126.13,
  -1.697,
  6 * (1 / Math.pow(10, 6)),
  2.694
);

// Transition Upper 
// y = 0,0167
// Min: x = 18,25
// Max: x = 42,75
const upperMin = { x: 18.25, y: 0.1486 };
const upperMax = { x: 42.75, y: 0.1486 };

// y = 0,1486
// Min: x = 7,855
// Max: x = 19
const bottomMin = { x: 7.855, y: 0.0167 };
const bottomMax = { x: 19, y: 0.0167 };

const drawOil = chart => drawPath(
  P1.x, P1.y,
  P2.x, P2.y,
  P3.x, P3.y,
  P4.x, P4.y,
  chart,
  {
    'fill': '#d1dabc',
    'stroke': '#718656',
    'stroke-width': 2,
    'opacity': '0.5'
  },
  true
);

const drawGas = (chart) => drawPath(
  P5.x, P5.y,
  P6.x, P6.y,
  P7.x, P7.y,
  P8.x, P8.y,
  chart,
  {
    'fill': '#f1a7a7',
    'stroke': '#f15555',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

const drawTransition = (chart) => drawPath(
  bottomMin.x, bottomMin.y,
  bottomMax.x, bottomMax.y,
  upperMax.x, upperMax.y,
  upperMin.x, upperMin.y,
  chart,
  {
    'fill': '#f4c094',
    'stroke': '#ed713e',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

/*
 * C1C2ByC2 normalized
 * CJ5: C1+C2 / C3 
 * CI5: C4 + C5 / C1+C2
 * =IF(AND(CJ5<=28,4;CJ5>=12,6;CI5<=0,1;CI5>=0,0225)=TRUE;20;IF(CJ5<=15;10;30))
 */
const C1C2C3Normalized = (c1, c2, c3, c4) => {
  const CJ5 = c1 + c2 / c3;
  const CI5 = c4 + c4 / c1 + c2;
  if (CJ5 <= 28.4 && CJ5 >= 12.6 && CI5 <= 0.1 && CI5 >= 0.0225) {
    return 20;
  }
  if (CJ5 <= 15) {
    return 10;
  } 
  return 30;
  
};

class ScatterPlotC1C3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true
    };
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();
    this.drawOil = () => drawOil(this.chart);
    this.drawGas = () => drawGas(this.chart);
    this.drawTransition = () => drawTransition(this.chart);

    // Oil
    this.chart.oil = this.drawOil();
    // Gas area
    this.chart.gas = this.drawGas();
    // Transition area
    this.chart.transition = this.drawTransition();

    // TODO leo needs to refactor it
    const { idListener } = this.props;
    document.addEventListener(`${idListener}-chart2`, ({ detail }) => {
      // TODO review it to update the areas after resize
      const { config } = detail;
      if (this.chart.yAxis && this.chart.yAxis[0]) {
        this.chart.yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (this.chart.xAxis && this.chart.xAxis[0]) {
        this.chart.xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }

      this.chart.oil.destroy();
      this.chart.oil = this.drawOil();

      this.chart.gas.destroy();
      this.chart.gas = this.drawGas();

      this.chart.transition.destroy();
      this.chart.transition= this.drawTransition();

      this.setState(() => ({
        isVisible: config.isVisible
      }));
    });
  }

  createScatterPlot = () => {
    const {
      id, xScale, yScale,
      data, dataWithDepth, receivePointsIsideZone
    } = this.props;
    const filteredToAvoidZeroX = filterToAvoidZeroXAxis(data);
    const filteredToAvoidZeroData = filterToAvoidZeroYAxis(filteredToAvoidZeroX);

    const pointsWithinZones = getPointsC1C2ByC3Zones(
      filteredToAvoidZeroData,
      { x: 7.5 , y: 0.0145 },
      { x: 22.5, y: 0.25 },
      { x: 0.87, y: 88 },
      { x: 0.3, y: 5 },
      P5, P6, P7, P8,
      bottomMin, bottomMax, upperMin, upperMax
    );

    if (pointsWithinZones) {
      const filteredValues = this.shouldFilterValuesInsideZones(
        dataWithDepth,
        pointsWithinZones
      );
      receivePointsIsideZone(filteredValues);
    }

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter'
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        startOnTick: true,
        endOnTick: true,
        ...xScale,
        title: {
          enabled: true,
          text: xScale.title
        },
        type: 'logarithmic',
        minorTickInterval: 0.01,
        gridLineWidth: 0,
        allowDecimals: true
      },
      yAxis: {
        lineWidth: 1,
        lineColor: '#ccd6eb',
        minorTickInterval: 0.0001,
        ...yScale,
        title: {
          text: yScale.title
        },
        allowDecimals: true,
        type: 'logarithmic',
        gridLineWidth: 0
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: '#fff',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 3,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: 'x: {point.x}, y: {point.y}',
            enabled: false
          }
        }
      },
      tooltip: {
        enabled: false
      },
      series: [
        {
          name: ' ',
          color: 'rgba(231, 76, 60, 0.5)',
          data: filteredToAvoidZeroData,
          marker: {
            fillColor: 'rgba(231, 76, 60, 0.5)',
            symbol: 'circle',
            radius: 2
          }
        }
      ]
    });
  }

  shouldFilterValuesInsideZones = (dataWithDepth, resultInterestedArea) => {
    const filteredPoints = dataWithDepth.reduce((acc, currentValue) => {
      resultInterestedArea.oil.reduce((acc2, oilPoint) => {
        if (
          JSON.stringify(oilPoint) === JSON.stringify(currentValue.item)
        ) {
          const { depth, c1, c2, c3, c4, c5 } = currentValue;
          const normalizedX = C1C2C3Normalized(c1, c2, c3, c4, c5);
          acc.push([normalizedX, depth]);
        }
        return acc2;
      }, []);
      resultInterestedArea.gas.reduce((acc3, gasPoint) => {
        if (
          JSON.stringify(gasPoint) === JSON.stringify(currentValue.item)
        ) {
          const { depth, c1, c2, c3, c4, c5 } = currentValue;
          const normalizedX = C1C2C3Normalized(c1, c2, c3, c4, c5);
          acc.push([normalizedX, depth]);
        }
        return acc3;
      }, []);
      resultInterestedArea.transition.reduce((acc4, transitionPoint) => {
        if (
          JSON.stringify(transitionPoint) === JSON.stringify(currentValue.item)
        ) {
          const { depth, c1, c2, c3, c4, c5 } = currentValue;
          const normalizedX = C1C2C3Normalized(c1, c2, c3, c4, c5);
          acc.push([normalizedX, depth]);
        }
        return acc4;
      }, []);
      return acc;
    }, []);
    return filteredPoints;
  }

  render() {
    const {
      id, withMinorGridLines, className, deactivateLegend,
      openConfigureScale
    } = this.props;
    const { isVisible } = this.state;
    return (
      <div
        className="scatter-general c1-c2-by-c3-container"
        style={{
          width: '400px',
          display: isVisible ? 'flex' : 'none',
          marginLeft: '1rem',
          marginBottom: '14px',
          alignItems: 'center'
        }}
      >
        {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config"
              onClick={openConfigureScale}
            />
          )
        }
        <div
          className={classNames(
            className,
            { 'reset-minor-grids': !withMinorGridLines },
            { 'deactivate-legend': deactivateLegend }
          )}
          id={id}
          style={{
            width: '400px',
            height: '360px',
            flex: 1
          }}
        />
      </div>
    );
  }
}

ScatterPlotC1C3.defaultProps = {
  withMinorGridLines: false,
  deactivateLegend: true,
  className: 'scatter-plot'
};

export default ScatterPlotC1C3;

