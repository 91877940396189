import axios from 'axios';
import store from 'store';
import { getGateway } from './gateway';

const GATEWAY = getGateway();
const URL = `${GATEWAY}/mlflow-training`;

export const trainModel = async (data) => {
    const resource = `${URL}/vertex-training`;
    const response = await axios.post(
        resource,
        data,
        {
            headers: {
                'access-token': store.get('automud-token'),
                'Content-type': 'application/json'
            }
        }
    );
    return response;
}