import React from 'react';
import Clipboard from '../Clipboard';
import './style.scss';

const ClipBoardWrapper = ({ children }) => <div className="clipboard__wrapper">{children}</div>;

const BitSizeClipboard = ({ rangeTop, rangeBottom, bottom, top }) => (
  <div className="clipboard__container">
    <ClipBoardWrapper>
      <Clipboard
        value={rangeTop}
        defaultValue={top}
        clipboardTarget="topValue"
      />
    </ClipBoardWrapper>
    <ClipBoardWrapper>
      <Clipboard
        value={rangeBottom}
        defaultValue={bottom}
        clipboardTarget="bottomValue"
      />
    </ClipBoardWrapper>
  </div>
);

export default BitSizeClipboard;
