import React, { useState } from 'react';
import {
  Button, Input, Radio
} from 'antd';
import closeIcon from '../../images/cancel.png';

const ConflictWell = ({
  closeWell, stepModal, wellName
}) => {
  const [conflictingType, setConflictType] = useState('update');
  const [newWell, setNewWell] = useState();

  const onChangeRadio = ({ target: { value } }) => {
    setConflictType(value);
  };

  const onChangeNewWell = (event) => {
    setNewWell(event.target.value);
  };

  return (
    <>
      <div className="wrapper-conflicting-data">
        <h1 data-cy="title-conflict-data" className="conflicting-data-title">
          Conflicting data
        </h1>
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            marginLeft: '60px',
            cursor: 'pointer',
            outline: 'none'
          }}
          onClick={closeWell}
        >
          <img src={closeIcon} alt="close"/>
        </button>
      </div>
      <div className="home__modal-container ">
        <div className="home__modal-form-group">
          <div className="conflicting-data">
            <p
              className="conflicting-data__description">
              A well with the name
              <span className="conflicting-data__las-name">{`"${wellName}"`}</span>
              already exists. To continue you can:
            </p>
            <div>
              <Radio.Group
                className="form-radio"
                name="radiogroup"
                onChange={onChangeRadio}
                value={conflictingType}
              >
                <Radio
                  className="form-radio__option"
                  value="update"
                  data-cy="update-well"
                >
                  Update the well, but <strong>ALL DATA</strong> will be overwritten.
                </Radio>
                <Radio
                  className="form-radio__option"
                  value='create'
                  data-cy="new-well"
                >
                  Create a new well with a different name.
                </Radio>
              </Radio.Group>
            </div>
            {conflictingType === 'create' ? (
              <div className="conflicting-data__well-name">
                <label
                  htmlFor="well-new"
                >
                  Well:
                </label>
                <Input
                  type="text"
                  name="well-name"
                  placeholder="well name"
                  onChange={onChangeNewWell}
                  data-cy="well-name"
                />
              </div>
            ) : (
              <div className="conflicting-data__well-name conflicting-data__well-name--disable">
                <label
                  htmlFor="well-new"
                >
                  Well:
                </label>
                <Input
                  type="text"
                  name="well-name"
                  placeholder="well name"
                  onChange={onChangeNewWell}
                  disabled
                />
              </div>
            )}
          </div>
        </div>
        <div className="steps-action">
          <Button
            style={{ marginLeft: 8 }}
            onClick={closeWell}
            className="btn-cancel-conflict-data"
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            className="btn-next-conflict-data"
            onClick={() => stepModal(conflictingType, newWell)}
            data-cy="btn-conflict-data"
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConflictWell;
