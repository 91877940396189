import React, {
  memo,
  useEffect,
  useRef,
  useState
} from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import {
  applyDefaultZoomToChart,
  toggleCurves
} from 'lib/charts';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import store from 'store';
import {
  defaultCrossHair,
  displayZoomBtn,
  formattingMin,
  getToolTipValues,
  propagateZoom,
  syncronizeTooltip
} from 'utils';

import ChartHeader from './ChartHeader';
import {
  createDefaultConfig,
  getDefaultSeries
} from './config';

Exporting(Highcharts);

const TOGGLE_AI_GR = 'toggle-aiGr-curve';
const TOGGLE_GAMMA_RAY = 'toggle-gr-curve';

const AI_GR = 'AI-GR';
const GAMMA_RAY = 'GR';

const SYNCHRONIZED_EVENT = 'sincronize-selected';

const minus = y => x => x - y;
const getMinSelection = minus(200);
const getMaxSelection = minus(100);

const GammaRay = ({
  id,
  selectedWell,
}) => {
  const zoomRefId = useRef(null);
  const [chart, setChart] = useState(null);
  const wells = useSelector(state => state.wells);
  const { currentWell } = wells;
  const series = useState({
    0: true,
    1: true,
    2: true,
    3: true
  });

  useEffect(() => {
    let timeoutId = undefined;
    if (currentWell) {
      const aiGr = get(currentWell, 'aiGammaRay', []);
      const gammaRay = get(currentWell, 'gamma_ray', []);
      const depth = currentWell.depth || [];
      const scales = store.get('configScales');
      const grConfig = createDefaultConfig(
        scales,
        displayZoomBtn,
        propagateZoom,
        getToolTipValues,
        getDefaultSeries,
        defaultCrossHair,
        aiGr,
        gammaRay,
        depth
      );
      const maxDepth = depth.length - 1;
      const chartInstance = Highcharts.chart(id, grConfig);

      if (chartInstance) {
        setChart(chartInstance);
        zoomRefId.current = applyDefaultZoomToChart(chartInstance, maxDepth);

        syncronizeTooltip(`#${id}`);
      }
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    }
  }, [wells && currentWell]);

  // useEffect(() => {
  //   const depth = currentWell && currentWell.depth || [];
  //   if (currentWell && currentWell.selectedWell != currentWellSelected && depth && depth.length) {
  //     setCurrentWellSelected(currentWell.selectedWell);
  //     chart.destroy();

  //     const mse = currentWell.mse ? currentWell.mse : [];
  //     const wob = currentWell.wob ? currentWell.wob : [];
  //     const rop = currentWell.rop ? currentWell.rop : [];
  //     const scales = store.get('configScales');
  //     const totalGasConfig = createDefaultConfig(
  //       scales,
  //       displayZoomBtn,
  //       propagateZoom,
  //       getToolTipValues,
  //       getDefaultSeries,
  //       defaultCrossHair,
  //       mse,
  //       wob,
  //       rop,
  //       depth
  //     );
  //     const maxDepth = depth.length-1;
  //     const chartInstance = Highcharts.chart(id, totalGasConfig);
  //     if (chartInstance) {
  //       setChart(chartInstance);
  //       zoomRefId.current = applyDefaultZoomToChart(chartInstance, maxDepth);
  //       syncronizeTooltip(`#${id}`);
  //     }
  //   }
  // }, [currentWell && currentWell.selectedWell, currentWell && currentWell.depth]);

  useEffect(() => {
    let timeoutId = undefined;
    if (currentWell && currentWell.depth && chart) {
      timeoutId = setTimeout(() => {
        const depth = currentWell.depth || [];
        if (
          depth && depth.length
          && (chart
          && chart.xAxis !== undefined
        )
        ) {
          chart.xAxis[0].setExtremes(depth.length - 200 || 0, depth.length);
        }
      }, 200);

      if (selectedWell && chart.axes != undefined) {
        const gammaRay = get(currentWell, 'gamma_ray', []);
        const aiGammaRay = get(currentWell, 'aiGammaRay', []);
        chart.update({
          series: getDefaultSeries(aiGammaRay, gammaRay)
        });
        chart.redraw();
      }
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }, [
    currentWell && currentWell.aiGammaRay,
    currentWell && currentWell.gamma_ray,
    currentWell && currentWell.depth,
    selectedWell,
    chart
  ]);

  useEffect(() => {
    const setSeries = series[1];
    const genericToggle = ({ detail: { index } }) => {
      toggleCurves(chart, setSeries, index);
    };

    const updateSetings = (chartInstance, indexSerie) => ({ detail }) => {
      if (chartInstance.yAxis && chartInstance.yAxis[indexSerie]) {
        chartInstance.yAxis[indexSerie].update(
          {
            min: formattingMin(detail.min),
            max: detail.max
          },
          true
        );
      }
      if (chartInstance.series && chartInstance.series[indexSerie]) {
        const serie = chartInstance.series[indexSerie];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        });
        serie.redraw();
      }
    };

    const synchronizeSelectedDepth = event => {
      const { depth } = this.props;
  
      let minValue = depth && depth.slice(0) ? depth.slice(0)[0] : 0;
      let maxValue = depth && depth.slice(-1) ? depth.slice(-1)[0] : 0;

      chart.xAxis[0].setExtremes(minValue, maxValue, false, false);
      chart.redraw();
    };

    if (chart) {
      document.addEventListener(TOGGLE_AI_GR, genericToggle);
      document.addEventListener(TOGGLE_GAMMA_RAY, genericToggle);

      document.addEventListener(SYNCHRONIZED_EVENT, synchronizeSelectedDepth);

      document.addEventListener('aiGrPrediction', updateSetings(chart, 2));
      document.addEventListener('gammaRay', updateSetings(chart, 0));
      document.addEventListener('gammaRay', updateSetings(chart, 1));
    }

    return () => {
      document.removeEventListener(TOGGLE_AI_GR, genericToggle);
      document.removeEventListener(TOGGLE_GAMMA_RAY, genericToggle);

      document.removeEventListener(
        SYNCHRONIZED_EVENT,
        synchronizeSelectedDepth
      );

      document.removeEventListener('aiGrPrediction', updateSetings);
      document.removeEventListener('gammaRay', updateSetings);

      clearTimeout(zoomRefId);
    };
  }, [series, chart]);

  return (
    <ChartContainer className="total-gas-container">
      <ChartHeader />
      <div
        id={id}
        style={{
          height: 'calc(100% - 132px)'
        }}
        data-cy="chart-gr"
      />
    </ChartContainer>
  );
};

GammaRay.defaultProps = {
  id: 'gamma_ray',
  parentContainer: '.charts-container'
};

export default memo(GammaRay);
