import colors from './colors'

export default {
  primary: {
    color: colors.dark['950'],
    bg: colors.primary['700'],
    '&:hover': {
      backgroundColor: colors.primary['600']
    },
  },
  secondary: {
    color: colors.dark['900'],
    bg: colors.dark['600'],
    light: {
      color: colors.dark['600'],
      bg: colors.primary['100'],
    },
    dark: {
      color: colors.dark['100'],
      bg: colors.primary['900'],
    }
  },
  link: {
    color: colors.dark['100'],
    bg: 'transparent'
  },
  outlined: {
    color: colors.primary['700'],
    border: '1px solid',
    borderColor: colors.primary['700'],
    bg: 'transparent',
  },
  disabled: {
    color: colors.dark['600'],
    bg: colors.dark['900'],
    fontSize: 12,
  }
}
