import React, { useEffect, useState, createContext, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { listExperiments } from 'actions/experiments'

import { filterWellsByData, filterCommonMnemonics } from 'api/inwell.js'

const initialState = {
  isLoading: false,
  setIsLoading: () => undefined,
}

export const ExperimentContext = createContext(initialState)

export const ExperimentProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [trainingData, setTrainingData] = useState({})
  const [description, setDescription] = useState('')
  const [wells, setWells] = useState([])
  const [logName, setLogName] = useState(null)
  const [curve, setCurve] = useState(null)
  const [selectedCurves, setSelectedCurves] = useState([])
  const [availableLogs, setAvailableLogs] = useState({
    "logs": [],
    "logsList": [],
    "currentLog": "",
    "currentLogCurves": []
  })
  const [selectedWells, setSelectedWells] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [hyperparameterTuning, setHyperparameterTuning] = useState('')
  const [dataImputation, setDataImputation] = useState({'imputation_type': '', 'imputation_value': ''})
  const [scalingType, setScalingType] = useState('')
  const experiments = useSelector((state) => state.experiments)
  const dispatch = useDispatch()

  const fetchExperiments = () => {
    setIsLoading(true)
    dispatch(listExperiments(() => setIsLoading(false)));
  }
  
  useEffect(() => {
    fetchExperiments()
  }, [])

  useEffect(() => {
    setTrainingData({ ...trainingData, ['train_columns']: selectedCurves })
  }, [selectedCurves])

  useEffect(() => {
    console.log('Training Data:', trainingData)
  }, [trainingData])

  const handleModelOutput = (item) => {

    try {
      filterWellsByData(item.label).then((response) => {
        const { wells } = response.data || []

        setWells(wells)
      })
    }
    catch (error) {
      console.log('error', error)
    }
  }

  const handleTrainingModel = (field, value) => {
    if (field === 'target_column') {
      const newTrainingData = {
        ['target_column']: value.label === 'Gamma Ray' ? 'LOG #1: gamma_ray' : 'interpreted_lithology',
        ['curve_type']: value.label.toLowerCase().replace(/\s/g, '_'),
        ['model_type']: value.label === 'Interpreted Lithology' ? 'Classifier' : 'Regressor',
        ['task']: value.label === 'Interpreted Lithology' ? 'classification' : 'regression'
      }
    
      setTrainingData({ ...trainingData, ...newTrainingData })
    } else {
      setTrainingData({ ...trainingData, [field]: value.label }) 
    }

    if (field === 'target_column') {
      handleModelOutput(value)
    }
  }

  const handleChange = (field, e) => {

    if (field === 'target_column') {
      const newTrainingData = {
        ['target_column']: value.label === 'Gamma Ray' ? 'LOG #1: gamma_ray' : 'interpreted_lithology',
        ['curve_type']: value.label.toLowerCase().replace(/\s/g, '_'),
        ['model_type']: value.label === 'Interpreted Lithology' ? 'Classifier' : 'Regressor',
        ['task']: value.label === 'Interpreted Lithology' ? 'classification' : 'regression'
      }
      setTrainingData({ ...trainingData, ...newTrainingData })
    } else {
      setTrainingData({ ...trainingData, [field]: e }) 
    }

    if (field === 'target_column') {
      handleModelOutput(value)
    }
  }

  const handleDescription = (item) => {
    setDescription(item)
    setTrainingData({ ...trainingData, ['experiment_description']: item.target.value })
  }

  const handleSelectedWells = (selectedWell) => {
    const hasSelectedWell = selectedWells.find((selected) => selected.uidWell === selectedWell.id)
    const selectedWellParsed = wells.find((well) => well.uidWell === selectedWell.id)
    
    if (hasSelectedWell) {
      setSelectedWells(selectedWells.filter((selected) => selected.uidWell !== selectedWell.id))
    } else {
      setSelectedWells([...selectedWells, selectedWellParsed])
    }

  }

  const handleConfirm = async () => {
    const response = await filterCommonMnemonics(selectedWells)

    const availableMnemonics = response.data.available_mnemonics
    const logsList = Object.keys(availableMnemonics).map(log => ({ label: log, id: log }))
    const firstLog = Object.keys(availableMnemonics)[0]
    const firstLogCurves = availableMnemonics[firstLog].map(log => ({ label: log, id: log }))


    setAvailableLogs({
      "logs": availableMnemonics,
      "logsList": logsList,
      "currentLog": firstLog,
      "currentLogCurves": firstLogCurves
    })

    const filteredLogList = response.data.filtered_log_list
    const dataSettings = {
      ['data']: filteredLogList,
      ['data_provider']: 'Inwell'
    }

    setTrainingData({ ...trainingData, ...dataSettings })
  }

  const addSelectedCurve = (field, value) => {
    setSelectedCurves({ ...selectedCurves, [field]: [...(selectedCurves[field] || []), value] })
  }

  const removeSelectedCurve = ({ id }) => {
    const splittedSelectedCurve = id.split(': ')
    const field = splittedSelectedCurve[0]
    const value = splittedSelectedCurve[1]

    setSelectedCurves({ ...selectedCurves, [field]: selectedCurves[field].filter(curve => curve !== value) })
  }

  const parseShowCurves = () => {
    const availableCurves = Object.keys(selectedCurves).filter((logName) => selectedCurves[logName].length)
    const parsedData = availableCurves
      .map((logName) => {
        return selectedCurves[logName]
          .map((curve) => ({
            id: `${logName}: ${curve}`,
            field: `${logName}: ${curve}`
          }))
    }).flat()

    return parsedData
  }

  useEffect(() => {
    if (scalingType === '') {
      setTrainingData({ ...trainingData, ['data_scaler']: null })
    }
    else {
      setTrainingData({ ...trainingData, ['data_scaler']: { 'scaling_type': scalingType } })
    }
  }, [scalingType])

  const handleScalingType = (item) => {
    console.log('handle scalying type', scalingType)
    setScalingType(item)
  }

  useEffect(() => {
    setTrainingData({ ...trainingData, ['data_imputation']: dataImputation })
  }, [dataImputation])

  

  const handleImputationType = (item) => {
    setDataImputation({ ...dataImputation, ['imputation_type']: item })
  }

  const handleImputationValue = (item) => {
    setDataImputation({ ...dataImputation, ['imputation_value']: item })
  }

  useEffect(() => {
    if (hyperparameterTuning === '') {
      setTrainingData({ ...trainingData, ['tuning_method']: 'Basic' })
    }
    else {
      setTrainingData({ ...trainingData, ['tuning_method']: hyperparameterTuning })
    }
  }, [hyperparameterTuning])

  const handleHyperparameterTuning = (item) => {
    console.log('handle Hyperparameter Tuning', hyperparameterTuning)
    setHyperparameterTuning(item)
    setTrainingData({ ...trainingData, ['tuning_method']: hyperparameterTuning })
  }
  

  return (
    <ExperimentContext.Provider value={{
      isLoading,
      isValid,
      experiments: experiments.items,
      wells,
      logName,
      curve,
      curves: parseShowCurves(),
      trainingData,
      description,
      hyperparameterTuning,
      availableLogs,
      selectedWells,
      selectedCurves,
      parseShowCurves,
      setLogName,
      setIsValid,
      setCurve,
      setAvailableLogs,
      setScalingType,
      handleTrainingModel,
      handleChange,
      handleDescription,
      handleConfirm,
      handleSelectedWells,
      addSelectedCurve,
      removeSelectedCurve,
      handleHyperparameterTuning,
      handleScalingType, 
      handleImputationType, 
      handleImputationValue
    }}>
      {children}
    </ExperimentContext.Provider>
  )
}

const useExperimentsContext = () => {
  const context = useContext(ExperimentContext)

  if (context === undefined) {
    throw new Error('useExperiment must be used within a ExperimentProvider')
  }

  return context
}

export default useExperimentsContext