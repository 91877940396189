/*
* @fn: calculateCoordinatesArea
* @params: a, b, c, d
*
* @description:
* These functions are extremly important to discover the x and y values
* when we change the scales of each crossplots.
* We don't have the value of x and y we have just the equations provided
* by Repsol, they gave us the equations and we are able to 
* calculate and know the real values.
*  
* How to calculate it?
* There are equations with the format A * ln(x) + B
* the intersection of two segments give us a point, that are edges of chart
* to help identify on the edge (geometry this is called an edge or line segment
* that meets two).
*
* We need to know the (x) that
* solves two equations simultaneously,
* therefore an intersection are something like this.
*
* A * ln(x) + B = y0
* C * ln(x) + D = y1
*
* if intercept the point X such that
* A * ln(x) + B = C * ln(x) + D
*
* using simple algebrism we define that:
* ln(x) = (D - B) / (A - C)
*
* The solution of these equations give us the point (X) of uma vertex 
* Note: ln() function means natural logarithm
*
* EQUATIONS OF THE WH/BH chart
* Oil Zone (1-2-3-4)
* 1-2: y = 11,064ln(x) + 4,5848
* 2-3: y = -22,4ln(x) + 75,407
* 3-4: y = 11,064ln(x) + 106,92
* 4-1: y = -22,11ln(x) + 53,198
*
* Gas Zone (5-6-7-8)
* 5-6: y = -0,748ln(x) + 4,5932
* 6-7: y = 117,03ln(x) - 718,82
* 7-8: y = -2,079ln(x) + 30,58
* 8-5: y = 117,98ln(x) - 280,09
*
*
* Transition Superior:
* y = -5,938ln(x) + 40,567
* Min: x = 8,3
* Max: x = 13,3
*
* Transition Inferior:
* y = -19,31ln(x) + 49,093
* Min: x = 4,33
* Max: x = 11
*
* Example to discover the Point 1:
* We need to have the equations from point 1 to 2 and from point 4 - 1,
* the point one is in commonw with theses two segments.
*
* Ex:
* 1-2: y = 11,064ln(x) + 4,5848
* 4-1: y = -22,11ln(x) + 53,198
* Following: A * ln(x) + B = C * ln(x) + D
* 
* A = 11,064
* B = 4,5848
* C = -22,11
* D = 53,198
*
* aux = (d - b) / (a - c);
* x = Math.exp(aux);
* y = a * Math.log(x) + b;
*/

/*
 * @fn: calculateCoordinatesWithLn
 * @desription: This function should calculate a x and y using a Natural Logarithm
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @param {number} d
 * return {Object} x and y value
 */
export const calculateCoordinatesWithLn = (a, b, c, d) => {
  const aux = (d - b) / (a - c);
  const x = Math.exp(aux);
  const y = a * Math.log(x) + b;

  return { x, y };
};

export const calculateCoordinates = (a, a2, b, b2) => {
  const aux = Math.log10(a / b) / (b2 - a2);
  // eslint-disable-next-line no-restricted-properties
  const x = Math.pow(10, aux);
  // eslint-disable-next-line no-restricted-properties
  const y = b * Math.pow(x, b2);

  return { x, y };
};

export const calculateCoordinatesOfExponential = (a, a2, b, b2) => {
  const x = Math.log(a / b) / (b2 - a2);
  const y = a * Math.exp((a2 * x));

  return { x, y };
};

export const calculateTransitionByX = (x, a1, a2) => {
  // eslint-disable-next-line no-restricted-properties
  const y = a1 * Math.pow(x, a2);
  return { x, y };
};

export const calculateTransitionExponential = (x, a1, a2) => {
  const y = a1 * Math.exp((a2 * x));
  return { x, y };
};
