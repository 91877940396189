import reduce from 'lodash.reduce';
import {
  ADD_ZONE, DELETE_ZONE, UPDATE_ZONE, RECEIVE_ZONES,
  DELETE_ALL
} from '../constants/zone';
import { createReducer } from './createReducer';

/*
* Zone Scheme
* 
* This is a format of each object within items
*
* const zone = {
*   id: '45',
*   wellId: 'a5g6e89w',
*   cutOffsIds: ['a6v6', 'h4f6'],
*   name: 'zone1', // value displayed on the zone 
*   color: '#000',
*   top: 100,
*   bottom: 200,
*   borderColor: '#000',
*   borderWidth: 2,
*   chartToApply: 'gasComposition' || 'all', // chartID or allCharts
*   status: 'active',
* };
*/

const initialState = {
  items: {}
};

function addZone(state, action) {
  const { zone } = action;
  return {
    ...state,
    items: {
      ...state.items,
      [zone.id]: zone
    }
  };
}

function deleteAll() {
  return { items: {} };
}

function deleteZone(state, action) {
  const { zoneId } = action;
  const items = reduce(state.items, (acc, zone) => {
    if (zone.id !== zoneId) {
      acc[zone.id] = zone;
    }
    return acc;
  }, {});
  return {
    ...state,
    items
  };
}

function updateZone(state, action) {
  const { zone } = action;
  return {
    ...state,
    items: {
      ...state.items,
      [zone.id]: zone
    }
  };
}

function receiveZones(state, action) {
  const { zones } = action;
  return {
    ...state,
    items: {
      ...zones
    }
  };
}

export default createReducer(initialState, {
  [ADD_ZONE]: addZone,
  [DELETE_ZONE]: deleteZone,
  [DELETE_ALL]: deleteAll,
  [UPDATE_ZONE]: updateZone,
  [RECEIVE_ZONES]: receiveZones
});
