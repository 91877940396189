import React from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import open from 'assets/open-screen.svg';
import exit from 'assets/exit-screen.svg';

const FullScreenMode = (({ children }) => {
  const handle = useFullScreenHandle();

  const onClickHandler = handle.active ? handle.exit : handle.enter;
  const onChangeImg = handle.active ? exit : open;

  return(
    <FullScreen handle={handle}>
      <div className="wrapper-full-screen">
        <button
          type="button"
          onClick={onClickHandler}
          className="wrapper-full-screen__btn"
        >
          <img src={onChangeImg} alt="exit screen" />
        </button>
      </div>
      {children}
    </FullScreen>
  );
});

export default FullScreenMode;