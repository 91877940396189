import React, { useState } from 'react';
import { Radio } from 'antd'
import { StyledTabs } from './style'

function TabsGroup({ ...props }) {
    const [mode, setMode] = useState(0);

    const handleModeChange = (e) => {
        props.setPage(e.target.value);
        setMode(e.target.value);
    };
    return (

        <>
            <StyledTabs defaultValue={mode} style={{ marginBottom: 8 }}>
                {props.items.map((item, index) => <Radio.Button onClick={handleModeChange} value={index} key={index}>{item.page}</Radio.Button>)}
            </StyledTabs>
        </>
    )
}
export default TabsGroup;