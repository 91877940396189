import React from 'react';
import store from 'store';
import { setOnIndexDB, findOnIndexDB, } from '../../utils';

const ResetSystem = ({ history }) => {
  const wipeData = () => {
    findOnIndexDB('wells').then(wells => {
      setOnIndexDB('wells', {});

      store.set('logged', false);
      store.set('well-token', '');
      history.push('/');
    });
  };

  return (
    <button
      style={{ margin: '20px', padding: '8px' }}
      onClick={wipeData}
      value="reset data"
    >
      reset data
    </button>
  );
};

export default ResetSystem;

