import styled from 'styled-components';
import { Input } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 10px;
  transition: all ease-in-out .25s;
  div {
    display: flex;
    width: 100%;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`
export const StyledLabel = styled.label`
    margin-left: 5px;
    color: #B4B1C2;
`

export const StyledInputNumber = styled(Input)`
    border: none;
    
    &.ant-input{
        background-color: #211F2B;
        cursor: text;
        color: #fff
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; 
    }

    &[type=number] {
        -moz-appearance:textfield;
    }

`
export const ActionButtons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
`

export const ActionArea = styled.span`
    position: absolute;
    top: 1.5rem;
    right: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

`

export const Unit = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #B4B1C2;
    margin-right: 6px;
`

export const IncreaseButton = styled.button`
  background: transparent;
  line-height: 12px;
  border: none;
  width: 12px;
  height: 12px;
  i svg{
    transition: all ease-in-out .2s;
  }
  &:hover {
    i svg{
        transform: scale(1.5);
        color: #B4B1C2;
    }
  }
  &:focus{     
    outline: none;
    border: none;
  } 
`
export const DecreaseButton = styled.button`
  background: transparent;
  line-height: 12px;
  border: none;
  width: 12px;
  height: 12px;
  i svg{
    transition: all ease-in-out .2s;
  }
  &:hover {
    i svg{
        transform: scale(1.5);
        color: #B4B1C2;
    }
  }
  &:focus{     
    outline: none;
    border: none;
  }
`