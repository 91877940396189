import styled from 'styled-components'

export const Value = styled.span`
  flex: 1;
`

export const ValueWrapper = styled.div(({ theme }) => `
  display: flex;
  align-items: center;
  background-color: ${theme.colors.dark['950']};
  padding: .76rem;
  border-radius: 5px;
  color: ${theme.colors.support.white};
  
  &:not(disabled) {
    cursor: pointer;
  }
`)

