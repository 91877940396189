import React from "react"
import { CardTitle, CardValue, CardUnit, CardSubUnits, CardSubValues, StyledCard } from "./styles"

const MeanIndicator = ({ id, title, value, std, min, max, unit, ...props }) => {
    return (
        <StyledCard>
            <CardTitle>{title}</CardTitle>
            <div>
                <CardUnit>mean</CardUnit>
                <CardValue>{value}</CardValue>
            </div>
            <div>
                <CardSubUnits> std </CardSubUnits>
                <CardSubValues>{std}</CardSubValues>
            </div>
            <div>
                <CardSubUnits>min</CardSubUnits>
                <CardSubValues>{min}</CardSubValues>
            </div>
            <div>
                <CardSubUnits>max</CardSubUnits>
                <CardSubValues>{max}</CardSubValues>
            </div>
        </StyledCard>
    )
}

export default MeanIndicator
