import React from "react";
import styled from 'styled-components'

export const ColorLegend = styled.div`
    border-radius: 100%;
    width: 12px;
    height: 12px;
    background-color: ${(props) => props.color};
    margin-right: 5px;
`

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-Content: between;
    borderRadius: 8px;
`

export const CardContent = styled.div`
    padding: 10px;
    display: grid;
`

export const Value = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
`

export const Text = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #B4B1C2;
    flex: none;
    order: 0;
    flex-grow: 0;
`

const PieChartLegend = ({color, title, value}) => {

    return (
        <>
            <Card>
                <ColorLegend color={color}></ColorLegend>
                <CardContent>
                    <Text>{title}</Text>
                    <Value>{value.toFixed(1) + '%'}</Value>
                </CardContent>
            </Card>
        </ >
    )
}
export default PieChartLegend;