import styled from "styled-components";

export const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 4px;
    
    max-height: 200px;
    flex-basis: 200px;
    flex-grow: 1;
    border-radius: 16px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.04) 100%);
    transition: all ease-in-out .3s;
    &:hover{
        background: #565365;
    }
    cursor: pointer;
    `
export const CardTitle = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 1vw;

    color: #B4B1C2;
`

export const CardValue = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 1.8vw;

    color: #FAF5FF;

    margin-right: 5px;
`

export const CardUnit = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 0.9vw;

    color: #B4B1C2;
`