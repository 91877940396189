import React from 'react';
import { Row, Col, Carousel } from 'antd';
import image1 from '../../images/carousel-start/img-01.svg';
import image2 from '../../images/carousel-start/img-02.svg';
import image3 from '../../images/carousel-start/img-03.svg';

const SlideStart = () => (
  <div className="carousel-start">
    <Carousel autoplay autoplaySpeed={4800}>
      <div className="carousel-start__item">
        <img src={image3} className="carousel-start__item-image" alt=""/>

        <Row type="flex" justify="center" align="middle">
          <Col span={18}>
            <span className="carousel-start__item-title">
              AUTOMUD: Advanced analysis of Mud Logging in real time
            </span>
          </Col>
        </Row>
      </div>

      <div className="carousel-start__item">
        <img src={image1} className="carousel-start__item-image" alt=""/>

        <Row type="flex" justify="center" align="middle">
          <Col span={18}>
            <span className="carousel-start__item-title">
            Advanced models for calculating and analyzing drilling data
            </span>
          </Col>
        </Row>
      </div>

      <div className="carousel-start__item">
        <img src={image2} className="carousel-start__item-image" alt=""/>

        <Row type="flex" justify="center" align="middle">
          <Col span={18}>
            <span className="carousel-start__item-title">
            Obtain precise data about rheology and hydraulics, geopressure and interpreted lithology for your wells
            </span>
          </Col>
        </Row>
      </div>
    </Carousel>
  </div>
);

export default SlideStart;
