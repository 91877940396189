/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { getEthenenAndDepth, findOnIndexDB } from '../../utils';
import ChartContainer from '../Shared/ChartContainer';
import RenderChart from './RenderChart';
import { relativeEtheneChart, defaultConfigAxis } from './chart';
import Settings from './Settings';
import './style.scss';

Exporting(Highcharts);
const createChart = (
  config,
  id
) => {
  const chart = new Highcharts.chart(id, {
    ...config,
    chart: {
      ...config.chart
    }
  });
  return chart;
};

const updateAxisCofig = ( chart, yMin, yMax, xMin, xMax) => {
  chart.yAxis[0].update({
    min: yMin,
    max: yMax
  }, true);
  chart.xAxis[0].update({
    min: xMin,
    max: xMax
  }, true);
  chart.redraw();
};

const RelativeEthene = ({ match }) => {
  const [visible, setVisible] = useState(false);
  const [scattersConfig, setConfig] = useState();
  const [chart, setChart] = useState(null);
  const [currentChart, setCurrentChart] = useState();
  const [etheneConfig, setEtheneConfig] = useState(defaultConfigAxis);

  useEffect(() => {
    const [id, type] = match.params.id.split('--');
  }, [match]);

  useEffect(() => {
    const [id, type] = match.params.id.split('--');

    (async () => {
      const wells = await findOnIndexDB('wells');
      const currentWell = wells.find(well => well.wellId === id); 
      const dataEthene = getEthenenAndDepth(
        currentWell.depth,
        currentWell.c2,
        currentWell.c2s,
        currentWell.c2h6,
        currentWell.c2h4
      );
      const config = relativeEtheneChart(dataEthene);
      const chartInstance = createChart(config, 'relative-ethene');
      setChart(chartInstance);
    })();

  }, []);

  const onSubmitEthene = (values) => {
    const { yMin, yMax, xMin, xMax } = values;
    setEtheneConfig({
      ...etheneConfig,
      yMin,
      yMax,
      xMin,
      xMax
    });
    updateAxisCofig(chart, yMin, yMax, xMin, xMax);
  };

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  return (
    <div className="scatters-ethene">
      <div className="scatter-header-ethene">
        <div className="scatter-info-ethene">
          <h3 className="scatter-title-ethene">
            Relative Ethene
          </h3>
          {scattersConfig && (
            <h3 className="scatters-ethene-range">
              {`Min: ${scattersConfig.minLabel} - Max: ${scattersConfig.maxLabel}`}
            </h3>
          )}
        </div>
      </div>
      <Settings
        onClose={onClose}
        visible={visible}
        currentScaleConfig={etheneConfig}
        onSubmitEthene={onSubmitEthene}
      />
      <div className="ethene-container">
        <ChartContainer>
          {/* <div className="wrapper-button-ethene">
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-ethene"
              onClick={showDrawer}
            />
          </div> */}
          <RenderChart id="relative-ethene"/>
        </ChartContainer>
      </div>
    </div>
  );
}

export default RelativeEthene;
