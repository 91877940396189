import React, {
  useEffect,
  useState
} from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Card from '../Card';

const NOT_AVAILABLE = 'N/A';

const RopBigCard = ({ description, unit, classes, type, lastCard, selectedWell  }) => {
  const wells = useSelector(state => state.wells);
  const [rop, setState] = useState(NOT_AVAILABLE);

  useEffect(() => {
    const { currentWell, selectedDepthIndex } = wells;
    if (currentWell && selectedDepthIndex >= 0) {
      const index = selectedDepthIndex || 0;
      const ropValue = currentWell && currentWell.rop && currentWell.rop[index]
        ? currentWell.rop[index].toFixed(0) : 'N/A';
      setState(ropValue);
    }
  }, [wells.selectedDepthIndex]);

  return (
    <Card classes={{
      customContainer: classes.customClass
    }}>
      <div
        className={
          classNames(
            'big-card__container',
            {
              [type]: !!type,
              [lastCard]: !!lastCard
            }
          )
        }
      >
      <div className="big-card__content">
        <div className="big-card__description">
          {description}
        </div>
        <div className="big-card__value">
          <span className="big-card__unit">{unit}</span>
          <span className="big-card__text">{rop}</span>
        </div>
      </div>
      </div>
    </Card>
  );
};

RopBigCard.defaultProps = {
  classes: {
    customClass: 'card--mini',
    raised: 'raised'
  },
  value: 'N/A',
  raised: false
};

export default RopBigCard;
