import React from 'react';
import classNames from 'classnames';

const Wrapper = className => ({ children, ...props }) => (
  <div {...props} className={classNames(className)}>
    {children}
  </div>
);

export default Wrapper;
