import React from 'react';

import classes from 'classnames';
import Modal from 'react-modal';

// import { useAuth } from '../../hooks/useAuth';
import removeIcon from '../../assets/close.svg';
import inWellFile from '../../assets/inWellFile.svg';
import cloudGeowellexIcon from '../../assets/cloud-geowellex.svg';
import { modalStyleInWell } from '../../utils';

const Title = ({ title, className, classTitle }) => (
  <h3 className={classes(
    'title-row',
    {
      [className]: !!className,
      [classTitle]: !!classTitle
    }
  )}
  >
    {title}
  </h3>
);

Title.defaultProps = {
  classTitle: 'modal-scale__title'
};

export const DataSourceModal = ({ uploadLithoFile, isOpen, uploadFile, changeInWellTableModal, onCloseModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        ...modalStyleInWell,
        overflowX: 'hidden',
      }}
      onRequestClose={onCloseModal}
    >
      <div style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <img
          onClick={onCloseModal}
          src={removeIcon}
          className="modal-scale__close"
          data-cy="close-modal"
          alt="close modal"
        />
      </div>
      <div
        className="modal-scale__form"
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h2 style={{ color: '#fff', fontSize: 20 }}>Select Data Source</h2>   
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 45 }}>
          <div onClick={uploadFile} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', width: 220 }}>
            <img src={inWellFile} width="50" height="50" />
            <span>Import File</span>
          </div>
          {/* <div onClick={uploadLithoFile} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', width: 220 }}>
            <img src={inWellFile} width="50" height="50" />
            <span>Import Lithology</span>
          </div> */}
          <div onClick={changeInWellTableModal} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center', width: 220, opacity: 1 }}>
            <img src={cloudGeowellexIcon} width="50" height="50" />
          {/* checkInWellIsAllowed()  */}
          {/* <div onClick={true? changeInWellTableModal : () => {}} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center', width: 220, opacity: true ? 1 : 0.5 }}> */}
            <span>Cloud Connection</span>
          </div>

          {/* </div> */}
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 90 }}>
          <Button style={{ background: '#333', color: '#fff', width: 120, height: 40 }}>Cancel</Button>
          <Button type="primary" style={{ width: 120, height: 40 }}>Next</Button>
        </div> */}
      </div>
    </Modal>
  );
};

export default DataSourceModal;
