import React from "react"
import { Wrapper, InputRAW, InputWrapper } from "./styles"

import Label from '../Text'

const Input = ({ id, label, width, rightElement, ...props }) => {
  return(
    <Wrapper>
      {label && <Label as="label" htmlFor={id} variant="label">{label}</Label>}
      <InputWrapper>
        <InputRAW {...{ id, ...props }} />
        {rightElement && rightElement()}
      </InputWrapper>
    </Wrapper>
  )
}

export default Input