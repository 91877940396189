import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from './store';
import { createBrowserHistory } from 'history';
import Modal from 'react-modal';
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter
} from 'react-router-dom';
import { Redirect } from 'react-router';
import store from 'store';
import { ThemeProvider } from 'styled-components';
import Home from './screens/Home';
import Crossplots from './Crossplots';
import RelativeEthene from './components/RelativeEthene';
import UpdatePassword from './components/UpdatePassword';
import LoginPage from './screens/LoginPage';
import ResetSystem from './components/ResetSystem';
import Models from './Models/Train';

import theme from './theme'
import Hydraulics from './new/Hydraulics';
import RegisterCard from './Models/Register/ExperimentCard';

// import Hydraulics from './new/Hydraulics';

const history = createBrowserHistory();

const storeReducer = configureStore();

Modal.setAppElement('#root');

function RouterComponent() {
  return (
    <Provider store={storeReducer}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <div style={{ width: '100%' }}>
            <Route path="/" exact history={history} component={LoginPage} />
            <PrivateRoute history={history} path="/home" component={Home} />
            <PrivateRoute
              history={history}
              path="/crossplots/:id"
              component={Crossplots}
            />
            <PrivateRoute
              history={history}
              path="/relative-ethene/:id"
              component={RelativeEthene}
            />
            <Route path="/updatepassword/:id/" component={UpdatePassword} />
            <Route path="/reset" component={ResetSystem} />
            <Route path="/new-registered-card" component={RegisterCard} />
            <PrivateRoute
              exact
              history={history}
              path="/hydraulics"
              component={Hydraulics}
            />
            {/* <Route component={NotFound} /> */}
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

const PrivateRoute = ({ component: Component, history, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return store.get('logged') ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);

export default RouterComponent;
