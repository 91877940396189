import React from 'react';
import Modal from 'react-modal';
import CreateGeopress from './createGeopress';
import { modalStyleCreateWell } from 'utils';
import BitSizeClipboard from 'components/BitSizeClipboard';
import BitSizeForm from 'components/BitSize/BitSizeForm';
import exitImage from 'images/exit-gray.svg';

const GeopressureModal = (props) => {
  const {
    isOpen, closeModal, current,
    closeWell, curvesKeys, processing,
    submitBitSize, goPrevBitSizes, currentWell,
    prev, next, startProcess, rangeTop,
    rangeBottom, top, bottom,
    selectMnemnonics,
    errorCurves,
    onChangeOverburden,
    activeOverburden,
    refactorConstants,
    sensorOptions, wellName
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      style={modalStyleCreateWell}
    >
      <div className="geopress-modal">
        <div className="header-geopress">
          <span>Geopressions Config</span>
          <img
            className="close-geopress-modal"
            onClick={closeModal}
            src={exitImage}
            alt="close modal"
          />
        </div>
        <CreateGeopress
          current={current}
          closeWell={closeWell}
          curvesKeys={curvesKeys}
          processing={processing}
          submitBitSize={submitBitSize}
          goPrevBitSizes={goPrevBitSizes}
          currentWell={currentWell}
          prev={prev}
          next={next}
          startProcess={startProcess}
          rangeTop={rangeTop}
          rangeBottom={rangeBottom}
          top={top}
          bottom={bottom}
          selectMnemnonics={selectMnemnonics}
          errorCurves={errorCurves}
          onChangeOverburden={onChangeOverburden}
          activeOverburden={activeOverburden}
          refactorConstants={refactorConstants}
          sensorOptions={sensorOptions}
        />
      </div>
    </Modal>
  );
}

export default GeopressureModal;
