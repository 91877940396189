import close from './close.svg'
import trash from './trash.svg'
import chevronUp from './chevron-up.svg'
import chevronDown from './chevron-down.svg'
import edit from './edit-2.svg'

const icons = {
    close,
    trash,
    chevronUp,
    chevronDown,
    edit
}
export default icons