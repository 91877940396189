import React, {
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import { ABBREVS_ROCKS_NAMES } from 'utils/constants';
import { importAll } from 'utils';


const legendRockImages = importAll(require.context('../../../../images/patterns', false, /\.(png|jpe?g|svg)$/))

const ChartHeader = ({ lithoData, title, depth }) => {
  const wells = useSelector(state => state.wells);
  const [rockValues, setRockValues] = useState([]);

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0 && wells.selectedDepthIndex != null) {
      const rockNames = Object.keys(lithoData);
      const lithologyData = rockNames.map(rockName => {
        const currentRockData = lithoData[rockName];

        const valueByDepth = wells
          && wells.selectedDepthIndex != -1
          && currentRockData
          && currentRockData[wells.selectedDepthIndex] ? currentRockData[wells.selectedDepthIndex] : 0;
          
        return {
          name: ABBREVS_ROCKS_NAMES[rockName],
          value: valueByDepth ? valueByDepth : 0,
          abbreviation: rockName
        };
      });

      setRockValues(lithologyData);
    }
  }, [wells]);

  const getTooltipValues = (tooltipValues) => {
    if (tooltipValues.length >= 5) {
     return (
        <div style={{ display: 'flex', fontSize: 11, }}>
          <div style={{
              width: 140,
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {
              tooltipValues.slice(0, 5).reduce((acc, rockValue) => {
                if (rockValue.value) {
                  acc.push(
                    <div style={{ display: 'inline-flex', alignItems: 'center', color: '#000' }}>
                    {/* <div className="real-time-litho-tooltip"> */}
                    <span style={{ marginRight: '5px'}}>
                        <img src={legendRockImages[`${rockValue.name}.png`]} 
                          width={'15px'} 
                          height={'15px'} 
                          style={{ transform: 'rotate(90deg)'}}
                        />
                    </span>
                      {rockValue.abbreviation}: {rockValue.value.toFixed(2)}%
                    {/* </div> */}
                    </div>
                  );
                }
                return acc;
              }, [])
            }
          </div>
          <div style={{
            width: 140,
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            {
              tooltipValues.slice(5, tooltipValues.length).reduce((acc, rockValue) => {
                if (rockValue.value) {
                  acc.push(
                    <div style={{ display: 'inline-flex', alignItems: 'center', color: '#000' }}>
                      <span style={{ marginRight: '5px'}}>
                        <img src={legendRockImages[`${rockValue.name}.png`]} 
                          width={'15px'} 
                          height={'15px'}
                          style={{ transform: 'rotate(90deg)'}}
                        />
                      </span>
                        {rockValue.abbreviation}: {rockValue.value.toFixed(2)}%
                      {/* </div> */}
                    </div>
                  );
                }
                return acc;
              }, [])
            }
          </div>
        </div>
      );
    };

    return tooltipValues.reduce((acc, rockValue) => {
      if (rockValue.value) {
        acc.push(
          <div className="real-time-litho-tooltip">
            {rockValue.abbreviation}: {Math.round(rockValue.value)}%
          </div>
        );
      }
      return acc;
    }, []);
  }

  let rockTooltipValues = getTooltipValues(rockValues);

  return (
    <div
      className="real-time-litho-header"
    >
      <div className="real-time-litho-title">
        {title}
      </div>
      {rockValues.length ? rockTooltipValues : null}
      <div className="real-time-litho-scales">
        <div className='real-time-litho-scales-numbers'>
          <span>0</span>
          <span>100</span>
        </div>
      </div>
    </div>
  );
}

export default ChartHeader;
