import React, { forwardRef } from 'react';
import classNames from 'classnames';

const Card = forwardRef((props) => {
  const {
    classes, styles, children, raised, other
  } = props;
  return (
    <div
      className={classNames({
        [classes.container]: !!classes.container,
        [classes.customContainer]: !!classes.customContainer,
        [classes.raised]: !!raised
      })}
      style={styles}
      {...other}
    >
      {children}
    </div>
  );
});

Card.defaultProps = {
  classes: {
    container: 'card',
    raised: 'raised'
  },
  raised: false
};

export default Card;
