import React, { Component } from 'react';
import { Row, Col } from 'antd';
import axios from 'axios';

import { withRouter } from "react-router";
import store from 'store';
import AOS from 'aos';
import ReactSvg from 'react-svg';
import { errorNotification } from 'utils';
import { DEFAULT_SCALES } from 'utils/constants';
import SlideStart from 'components/SlideStart';
import Copyright from 'components/Copyright';
import LoginForm from 'components/LoginForm';
import { doAuthenticate } from 'api';
import { loginAuth, getUserInfo } from 'api/auth';

import LogoWellexGold from 'images/logo-wellex-automud.svg';

const indexDBFeatureDetection = (error, success) => {
  if (!('indexedDB' in window)) {
    console.warn('IndexedDB not supported');
    errorNotification(
      'Please update your browser, this application does not support this version!',
      ''
    );
    error();
  } else {
    success();
  }
};

class LoginPage extends Component {
  state = {
    loading: false,
    errorLogin: '',
    errorForgot: '',
    isSubmited: false
  }

  componentDidMount() {
    const configScales = store.get('configScales');
    if (!configScales) {
      store.set('configScales', DEFAULT_SCALES);
    }

    AOS.init({
      disable: () => {
        const minHeight = 750;
        return window.innerHeight < minHeight;
      }
    });

    document.addEventListener('logout', this.wipeUser);
  }

  componentWillUnmount() {
    document.removeEventListener('logout', this.wipeUser);
  }

  wipeUser = () => {
    store.set('logged', false);
    store.remove('automud-token');
  }

  isAuthenticated = (username) => {
    const { history } = this.props;
    store.set('logged', true);
    store.set('well-user', username);
    this.setState({ loading: false });
    history.push('/home');
  }

  doLogin = async (values, actions) => {

    actions.setSubmitting(true);
    this.setState({ loading: true });
    indexDBFeatureDetection(
      () => {
        actions.setSubmitting(false);
      },
      async () => {
        const { username, password } = values;

        try {
          const tokenResponse = await loginAuth({ username, password });

          const token = store.get('automud-token');
          store.set('automud-token', tokenResponse.data.access_token);
          store.set('email', username);
          axios.defaults.headers.common['Authorization'] = tokenResponse.data.access_token;

          const userInfoData = await getUserInfo(values.username, values.password);
          if (userInfoData.data.sub) {
            store.set('email', userInfoData.data.sub);
          }

          store.set('preferred_username', values.username);

          this.isAuthenticated(username);
          actions.setSubmitting(false);
        } catch (error) {
          console.log('LOGIN ERROR:', error);
          this.setState({
            errorLogin: error && error.response && error.response.data ? error.response.data.error : error,
            loading: false
          });
          actions.setSubmitting(false);
        }
      });
  }

  forgotPassword = () =>{
    
    /*
      const {email} = values;
        Api.post('/recoverpassword/', {
          email,
        }).then((response) => {
          actions.setSubmitting(false);
          actions.setErros(null);
          this.setState({
            errorForgot: '',
            isSubmited: true
          });
        }).catch((error) => {
          if(error && error.response && error.response.data){
            const errorData = error.response.data[0].result;
            actions.setSubmitting(false);
            this.setState({
              errorForgot: errorData
            });
          } else {
            this.setState({
              errorForgot: '',
              isSubmited: true
            });
          }
        });
    */

    
  }


  render() {
    const { errorLogin, errorForgot, isSubmited, loading } = this.state;

    return (
      <div className="login-page">
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} md={14} lg={10} className="login-page__section-form">
            <Row type="flex" justify="center" align="middle">
              <Col xs={22} sm={22} md={16} lg={18} xl={14} >
                <div className="login-page__header">
                  <div 
                    className="login-page__header-image" 
                    data-aos="fade-down"
                    data-aos-delay="300"
                    data-aos-duration="500"
                    data-aos-easing="ease"
                  >
                    <ReactSvg 
                      src={LogoWellexGold} 
                      className="login-page__header-image-logo"
                    />
                  </div>
                  <div 
                    data-aos="fade-left"
                    data-aos-delay="350"
                    data-aos-duration="550"
                    data-aos-easing="ease"
                  >
                    <h1 className="login-page__header-title">Welcome!</h1>
                    <p className="login-page__header-subtitle">Log in to your account</p>
                  </div>
                </div>
                
                <LoginForm
                  onSubmitLogin={this.doLogin}
                  errorLogin={errorLogin}
                  errorForgot={errorForgot}
                  onSubmitForgot={this.forgotPassword}
                  isSubmited={isSubmited}
                  loading={loading}
                />
                <Copyright />
              </Col>

            </Row>
          </Col>
          <Col xs={0} sm={0} md={10} lg={14} className="login-page__section-carousel">
            <SlideStart />
          </Col>
        </Row>
      </div>
    );
  }

}

export default withRouter(LoginPage);
