import React from "react"
import { Form } from "antd";
import { StyledFieldSet } from "./styles";


const Fieldset = ({ id, label, ...props }) => {
    return (
        <>

            <StyledFieldSet id={id}>
                <legend>{label}</legend>
                {props.children}
            </StyledFieldSet>

        </>
    )
}
export default Fieldset;