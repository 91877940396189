import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle(({ theme }) => `
&::-webkit-scrollbar {
  width: 14px;
}

&::-webkit-scrollbar-track {
  background: ${theme.colors.dark['400']};
}

&::-webkit-scrollbar-thumb {
  background: ${theme.colors.dark['600']};

  &:hover {
    background: ${theme.colors.dark['950']};
  }
}
`)

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgb(29, 28, 28);
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(29, 28, 28);
  padding: 2rem 4rem;
  width: 100%;
`
