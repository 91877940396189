import styled from 'styled-components'

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center; 
  color: #B4B1C2;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  background: #211F2B;
  padding: .2rem .5rem;
  span {
    padding: 0 .2rem;
    margin: .1rem;
  }
  &:not(:first-child) {
    margin-left: .5rem;
  }
  figure{
    margin: 0 0 1em;
  }
`