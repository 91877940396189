import React from 'react';
import './style.scss';
import Spinner from "../Spinner";

const Loading = ({ customStyles }) => {
  return (
    <div
      className="modal-models"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 300,
        ...(customStyles || {}),
      }}
    >
      <div
        className="loading__spinner"
        style={{ marginRight: '4px', marginTop: '4px' }}
      >
        <Spinner classes="spinner--small" />
      </div>
      <p style={{ marginTop: 8, color: '#fff' }}>Loading...</p>
    </div>
  );
}

export default Loading