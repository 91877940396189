import React, { useEffect, useState } from 'react'

import { fetchExperiments, fetchOneExperiment } from 'api/experiments'

const usePredictedExperiments = () => {
  const [isLoading, setLoading] = useState(false)
  const [experimentIsLoading, setExperimentIsLoading] = useState(false)
  const [experiments, setExperiments] = useState(null)

  useEffect(() => {
    const getExperiments = async () => {
      try {
        setLoading(true)
          const { data } = await fetchExperiments()
          const experimentsName = Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: null }) , {})

          setExperiments(experimentsName)
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message)
        }
      } finally {
        setLoading(false)
      }
    }

    getExperiments()
  }, [fetchExperiments])

  const getExperiment = async (name) => {
    try {
      setExperimentIsLoading(true)
      const { data } = await fetchOneExperiment(name)
      const experimentData = Object.keys(data).reduce((acc, experimentKey) => [...acc, data[experimentKey]], [])

      return experimentData
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
      }
    } finally {
      setExperimentIsLoading(false)
    }
  } 

  return {
    isLoading,
    experimentIsLoading,
    experiments,
    getExperiment
  }
}

export default usePredictedExperiments