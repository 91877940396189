import axios from 'axios';
import store from 'store';
import { getGateway } from './gateway';

const GATEWAY = getGateway();
const URL = `${GATEWAY}/mlflow-metadata`;

export const fetchExperiments = async () => {
  const resource = `${URL}/experiments/info`;
  const response = await axios.get(
    resource,
    {
      headers: {
        'access-token': store.get('automud-token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const fetchOneExperiment = async (experimentName) => {
  const resource = `${URL}/experiments/${experimentName}/runs`;
  const response = await axios.get(
    resource,
    {
      headers: {
        'access-token': store.get('automud-token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};
