import get from 'lodash.get';
import uniqBy from 'lodash.uniqby';

import * as ACTIONS from '../constants/wells';
import { createReducer } from './createReducer';

const initialState = {
  items: [],
  currentWell: null,
  selectedWell: -1,
  depthData: [],
  wellOptions: [],
  selectedDepthIndex: null,
  beforeWell: null,
  trendLines: null,
  calculatingGeopressure: false
};

const generateWellOption = (wellName, wellId) => ({
  label: wellName || '',
  value: wellId
});

function filterWell(state, action) {
  const { wellId } = action;
  const filteredWell = state.items.find(well => {
    if (well.wellId === wellId || well.wellInfo && well.wellInfo.wellId === wellId) {
      return true;
    }
    return false;
  });
  return {
    ...state,
    selectedWell: get(filteredWell, 'wellId', -1),
    currentWell: filteredWell || null,
    wellOptions: filteredWell ? state.wellOptions : [],
    depthData: filteredWell ? state.depthData : [],
    beforeWell: filteredWell || null
  };
}

function receiveWell(state, action) {
  let { well } = action;
  let wellId = '';

  if (well.wellId) {
    wellId = well.wellId;
  }
  if (well.wellInfo && well.wellInfo.wellId) {
    wellId = well.wellInfo.wellId;
  }

  const currentWell = state.items.find(w => w.wellId === wellId || w.wellId === well.wellId);
  const wellName = well.wellInfo && well.wellInfo.wellName
    ? well.wellInfo.wellName : well.nameWell;

  let wellOptions = state.wellOptions;

  let items = state.items;
  if (currentWell) {
    items = items.map(w => {
      if (w.wellId === wellId) {
        return { ...w, ...well, ...well.curves };
      }
      return w;
    });
  } else {
    const wellOption = generateWellOption(wellName, wellId);
    wellOptions = [...wellOptions, wellOption];
  }

  let lithoData = {};
  if (state.selectedWell !== wellId) {
    lithoData = { realtimeLithology: null, lithologyScore: null, aiGammaRay: null };
  }

  return {
    ...state,
    items,
    selectedDepthIndex: 0,
    wellOptions,
    currentWell: { ...well, ...lithoData },
    selectedWell: wellId,
    currentWellKey: wellId,
    beforeWell: { ...well, ...well.curves },
  };
}

function receiveWells(state, action) {
  const { wells } = action;
  const wellOptions = wells.map(well => {
    const wellName = get(well, 'name', well.id);
    const wellOption = generateWellOption(wellName, well.id);
    return { ...wellOption, wellId: well.id, wellName: well.name };
  });
  const formattedOptions = wells.map(w => ({ ...w, wellId: w.id, wellName: w.name }));
  return {
    ...state,
    items: formattedOptions,
    wellOptions,
    wellsWithPathFile: formattedOptions,
  };
}

function deleteWell(state, action) {
  const { wellId } = action;
  const items = state.items.filter(well => well.wellId !== wellId);
  const wellOptions = state.wellOptions.filter(well => well.value !== wellId);
  return {
    ...state,
    selectedWell: -1,
    currentWellKey: null,
    depthData: [],
    currentWell: null
  };
}

function receiveDepthData(state, action) {
  return {
    ...state,
    depthData: action.data
  };
}

function updateWell(state, action) {
  const { well } = action;
  const items = state.items.map(item => item.id === state.selectedWell ? { ...item, ...well } : item);
  const currentWell = items.find(currentWell => currentWell.id === well.id);

  const wellOptions = state.wellOptions.map(item => item.value === well.id
    ? generateWellOption(currentWell.name, currentWell.id) : item
  );

  console.log(`updateWell `, {
    currentWell: well,
    items,
    newOptions: state.wellOptions,
    wellOptions,
  });
  return {
    ...state,
    items,
    currentWell: well,
    beforeWell: well,
    wellOptions
  };
}

function receiveSelectedDepthIndex(state, action) {
  return {
    ...state,
    selectedDepthIndex: action.index
  };
}

function receiveTrendLinesValue(state, action) {
  return {
    ...state,
    trendLines: action.trendLines
  };
}

function startCalculateGeopressure(state, action) {
  return {
    ...state,
    calculatingGeopressure: true 
  };
}

function stopCalculateGeopressure(state, action) {
  return {
    ...state,
    calculatingGeopressure: false 
  };
}

function receiveRealtimeLithology(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, realtimeLithology: action.data },
    beforeWell: { ...state.beforeWell, realtimeLithology: action.data }
  };
}

function receiveMLPorosity(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, mlPorosity: action.data },
    beforeWell: { ...state.beforeWell, mlPorosity: action.data }
  };
}

function receiveLithologyScore(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, lithologyScore: action.data },
    beforeWell: { ...state.beforeWell, lithologyScore: action.data }
  };
}

function receiveAiGrPrediction(state, action) {
  return {
    ...state,
    currentWell: { ...state.currentWell, aiGammaRay: action.data },
    beforeWell: { ...state.currentWell, aiGammaRay: action.data } 
  };
}


export default createReducer(initialState, {
  [ACTIONS.FILTER_WELL]: filterWell,
  [ACTIONS.RECEIVE_WELL]: receiveWell,
  [ACTIONS.RECEIVE_WELLS]: receiveWells,
  [ACTIONS.DELETE_WELL]: deleteWell,
  [ACTIONS.REGISTER_DEPTH_DATA]: receiveDepthData,
  [ACTIONS.UPDATE_WELL]: updateWell,
  [ACTIONS.RECEIVE_SELECTED_INDEX]: receiveSelectedDepthIndex,
  [ACTIONS.RECEIVE_TRENDLINES_VALUE]: receiveTrendLinesValue,
  [ACTIONS.START_CALCULATE_GEOPRESSURE]: startCalculateGeopressure, 
  [ACTIONS.STOP_CALCULATE_GEOPRESSURE]: stopCalculateGeopressure,
  [ACTIONS.RECEIVE_REALTIME_LITHOLOGY]: receiveRealtimeLithology,
  [ACTIONS.RECEIVE_LITHOLOGY_SCORE]: receiveLithologyScore,
  [ACTIONS.RECEIVE_AI_GAMMA_RAY]: receiveAiGrPrediction,
  [ACTIONS.RECEIVE_ML_POROSITY]: receiveMLPorosity
});
