import React, { useState, useEffect } from "react";
import { Steps } from "antd";

import WellPhasesForm from "./WellPhasesForm";
import FluidParametersForm from "./FluidParametersForm";
import BitParametersForm from "./BitParametersForm";

const { Step } = Steps;

const SettingsForm = () => {
    const [currentStep, setCurrentStep] = useState(0)
    const [phasesFormData, setPhasesFormData] = useState()
    const [bitsFormData, setBitsFormData] = useState()
    const [fluidsFormData, setFluidsFormData] = useState()


    function goToPreviousStep(step) {
        setCurrentStep(step)
    }

    const forms = [
        <WellPhasesForm nextStep={setCurrentStep} dataPhases={phasesFormData} setDataPhases={setPhasesFormData} />,
        <BitParametersForm nextStep={setCurrentStep} previousStep={goToPreviousStep} dataBits={bitsFormData} setDataBits={setBitsFormData} />,
        <FluidParametersForm previousStep={goToPreviousStep} dataFluids={fluidsFormData} setDataFluids={setFluidsFormData} />
    ]


    return (
        <>
            <Steps current={currentStep} style={{ padding: '20px' }}>
                <Step title={'Drill String'}></Step>
                <Step title={'Well Geometry'}></Step>
                <Step title={'Fluid Parameters'}></Step>
            </Steps >
            {forms[currentStep]}
        </>
    )
}

export default SettingsForm;