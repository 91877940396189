export const GAS_COMP_DEFAULT = 'gasComposition';
export const GAS_COMP_C1 = 'gasCompositionC1';
export const GAS_COMP_C2 = 'gasCompositionC2';
export const GAS_COMP_C3 = 'gasCompositionC3';
export const GAS_COMP_C4 = 'gasCompositionC4';
export const GAS_COMP_C5 = 'gasCompositionC5';

export const ROCK_SHALE = 'ROCK_SHALE';
export const ROCK_BRECCIA = 'ROCK_BRECCIA';
export const ROCK_MARL = 'ROCK_MARL';

export const CHROMA_C1 = 'chromatographyC1';
export const CHROMA_C2 = 'chromatographyC2';
export const CHROMA_C3 = 'chromatographyC3';
export const CHROMA_IC4 = 'chromatographyIc4';
export const CHROMA_NC4 = 'chromatographyNc4';
export const CHROMA_NC5 = 'chromatographyNc5';
export const CHROMA_IC5 = 'chromatographyIc5';

export const UPDATE = 'update';
export const CREATE = 'create';

export const GEOPRESSURE_COLORS = {
  pp: '#f08c34',
  rft: '#54a652',
  mw: '#989898',
  ecd: '#55a856',
  fg: '#ea4a44',
  rftDark: '#5c5c5c',
  obg: '#333333'
};

export const scales = {
  rop: {
    min: 0,
    max: 60,
    color: '#27E03D',
    line: '#27E03D',
    title: 'ROP',
    titleModal: 'ROP'
  },
  gasRatio: {
    min: 0,
    max: 60,
    color: '#9600ff',
    title: 'GR',
    titleModal: 'GAMA RAY'
  },
  mse: {
    min: 0,
    max: 60,
    color: '#020202',
    line: '#020202',
    title: 'MSE',
    titleModal: 'MSE'
  },
  totalGas: {
    min: 0.1,
    max: 100000,
    color: '#020202',
    line: '#020202',
    title: 'Total Gas (units)',
    titleModal: 'Total Gas'
  },
  totalCarbon: {
    min: 0.1,
    max: 100000,
    color: '#8200F4',
    title: 'Total Carbon',
    titleModal: 'Total Carbon'
  },
  c1Normalized: {
    min: 0.1,
    max: 10000,
    color: '#FF0000',
    title: 'C1 Normalized',
    titleModal: 'C1 Normalized'
  },
  chromatography: {
    min: 0.1,
    max: 100000,
    color: '#8a221b'
  },
  mse: {
    min: 0,
    max: 60,
    color: '#020202',
    line: '#020202',
    title: 'MSE',
    titleModal: 'MSE'
  },
  wob: {
    min: 0,
    max: 60,
    color: '#8200F4',
    title: 'WOB',
    titleModal: 'WOB'
  },
  aiGrPrediction: {
    min: 0,
    max: 100,
    color: '#E12FEA',
    title: 'AI GR Prediction',
    titleModal: 'AI GR Prediction'
  },
  mlPorosity: {
    min: 0,
    max: 100,
    color: '#272BFF',
    title: 'ML Porosity',
    titleModal: 'ML Porosity'
  },
  gammaRay: {
    min: 0,
    max: 100,
    color: '#0F2CBA',
    line: '#0F2CBA',
    title: 'Gamma Ray',
    titleModal: 'Gamma Ray'
  },
  c1Normalized: {
    min: 0,
    max: 60,
    color: '#27E03D',
    line: '#27E03D',
    title: 'ROP',
    titleModal: 'ROP'
  },
  gradient: {
    min: 0,
    max: 5000,
    title: 'Gradient',
    titleModal: 'Gradient',
    ...GEOPRESSURE_COLORS
  },
  chromatography: {
    min: 0.1,
    max: 100000,
    color: '#8a221b'
  },
  [CHROMA_C1]: {
    min: 0.1,
    max: 100000,
    color: '#FF0000',
    title: 'C1',
    titleModal: 'C1'
  },
  [CHROMA_C2]: {
    min: 0.1,
    max: 100000,
    color: '#00C420',
    title: 'C2',
    titleModal: 'C2'
  },
  [CHROMA_C3]: {
    min: 0.1,
    max: 100000,
    color: '#0F2CBA',
    title: 'C3',
    titleModal: 'C3'
  },
  [CHROMA_IC4]: {
    min: 0.1,
    max: 100000,
    color: '#E12FEA',
    title: 'IC4',
    titleModal: 'IC4'
  },
  [CHROMA_NC4]: {
    min: 0.1,
    max: 100000,
    color: '#B0015B',
    title: 'NC4',
    titleModal: 'NC4'
  },
  [CHROMA_IC5]: {
    min: 0.1,
    max: 100000,
    color: '#9A3BFF',
    title: 'iC5',
    titleModal: 'IC5'
  },
  [CHROMA_NC5]: {
    min: 0.1,
    max: 100000,
    color: '#97E9FC',
    title: 'nC5',
    titleModal: 'NC5'
  },
  gasComposition: {
    min: 0.01,
    max: 100,
    title: 'C1',
    titleModal: 'C1',
    color: '#000'
  },
  rockClaystone: {
    min: 0,
    max: 100,
    color: '#ff8003',
    title: 'Rock Claystone',
    titleModal: 'Rock Claystone'
  },
  rockCarbonateShale: {
    min: 0,
    max: 100,
    color: '#ff8003',
    title: 'Carbonate Shale',
    titleModal: 'Carbonate Shale'
  },
  rockChalk: {
    min: 0,
    max: 100,
    color: '#AAB7B8',
    title: 'Rock chalk',
    titleModal: 'Rock chalk'
  },
  rockDiabase: {
    min: 0,
    max: 100,
    color: '#AAB7B8',
    title: 'rock Diabase',
    titleModal: 'rock Diabase'
  },
  rockLime: {
    min: 0,
    max: 100,
    color: '#AAB7B8',
    title: 'Rock Lime',
    titleModal: 'Rock Lime'
  },
  rockHalite: {
    min: 0,
    max: 100,
    color: '#bfbfbf',
    title: 'Rock Halite',
    titleModal: 'Rock Halite'
  },
  rockMarl: {
    min: 0,
    max: 100,
    title: 'Rock Marl',
    titleModal: 'Rock Marl',
    color: '#c6c6c6'
  },
  rockGypsum: {
    min: 0,
    max: 100,
    title: 'Rock Halite',
    titleModal: 'Rock Halite',
    color: '#AAB7B8'
  },
  rockAnhydrite: {
    min: 0,
    max: 100,
    title: 'Rock Anhydrite',
    titleModal: 'Rock Anhydrite',
    color: '#ff0342'
  },
  rockCalcarenite: {
    min: 0,
    max: 100,
    title: 'Rock Calcarenite',
    titleModal: 'Rock Calcarenite',
    color: '#0303ff'
  },
  rockCalcilutite: {
    min: 0,
    max: 100,
    title: 'Rock Calcilutite',
    titleModal: 'Rock Calcilutite',
    color: '#80ffff'
  },
  rockSandstone: {
    min: 0,
    max: 100,
    title: 'Rock Sandstone',
    titleModal: 'Rock Sandstone',
    color: '#ffff03'
  },
  rockSiltstone: {
    min: 0,
    max: 100,
    title: 'Rock Siltstone',
    titleModal: 'Rock Siltstone',
    color: '#410505'
  },
  rockShale: {
    min: 0,
    max: 100,
    color: '#038003',
    title: 'Rock Shale',
    titleModal: 'Rock Shale'
  },
  rockTuf: {
    min: 0,
    max: 100,
    color: '#038003',
    title: 'Rock Tuf',
    titleModal: 'Rock Tuf'
  },
  rockSilexite: {
    min: 0,
    max: 100,
    color: '#ccc',
    title: 'Rock Silexite',
    titleModal: 'Rock Silexite'
  },
  characterRatio: {
    min: 0.1,
    max: 10,
    color: '#26b5e8',
    title: 'Cha. Ratio',
    titleModal: 'Character Ratio'
  },
  balanceRatio: {
    min: 0.1,
    max: 100,
    color: '#652C8F',
    title: 'Bal. Ratio',
    titleModal: 'Balance Ratio'
  },
  wetnessRatio: {
    min: 0.1,
    max: 100,
    color: '#009244',
    title: 'Wet. Ratio',
    titleModal: 'Wetness Ratio'
  },
  meanScore: {
    min: 0,
    max: 100,
    color: '#000',
    title: 'Mean Score',
    titleModal: 'Mean Score'
  },
  standardDeviation: {
    min: 0,
    max: 100,
    color: '#ee3232',
    title: 'Standard Deviation',
    titleModal: 'Standard Deviation'
  },
  dxc: {
    min: 0,
    max: 100,
    color: '#3d90f5',
    title: 'DXC',
    titleModal: 'DXC'
  },
  dxcNormal: {
    min: 0,
    max: 100,
    color: '#fab01b',
    title: 'DXC Normal',
    titleModal: 'DXC Normal'
  },
  ic4Nc4: {
    min: 0,
    max: 2,
    color: '#652C8F',
    title: 'ic4/Nc4',
    titleModal: 'ic4/Nc4'
  },
  ic5Nc5: {
    min: 0,
    max: 10,
    color: '#F6911C',
    title: 'iC5/nC5',
    titleModal: 'iC5/nC5'
  },
  c1c2: {
    min: 0,
    max: 10,
    color: '#EA008A',
    title: 'C1/C2',
    titleModal: 'C1/C2'
  },
  slowFactor: {
    min: 0,
    max: 50,
    color: '#BC1E2D',
    xclassName: 'sfC1ByC2',
    title: 'Slope Factor',
    titleModal: 'Slow Factor'
  },
  isotope: {
    min: -100,
    max: 50,
    color: '#006738',
    title: 'δ13C ()',
    titleModal: 'Isotopes'
  },
  [GAS_COMP_C1]: {
    min: 0,
    max: 100,
    color: '#FF0000',
    title: 'C1',
    titleModal: 'C1'
  },
  [GAS_COMP_C2]: {
    min: 0,
    max: 100,
    color: '#00C420',
    title: 'C2',
    titleModal: 'C2'
  },
  [GAS_COMP_C3]: {
    min: 0,
    max: 100,
    color: '#0F2CBA',
    title: 'C3',
    titleModal: 'C3'
  },
  [GAS_COMP_C4]: {
    min: 0,
    max: 100,
    color: '#E12FEA',
    title: 'C4',
    titleModal: 'C4'
  },
  [GAS_COMP_C5]: {
    min: 0,
    max: 100,
    color: '#020202',
    title: 'C5',
    titleModal: 'C5'
  },
  ...GEOPRESSURE_COLORS
};

export const DEFAULT_SCALES = Object.freeze(scales);

const white = '#fff';

export const COLORS = {
  color1: white
};

export const defaultCurveKeys = {
  depth: null,
  flowppms: null,
  rop: null,
  gamma_ray: null,
  gas_ratio: null,
  total_gas: null,
  c1: null,
  c2: null,
  c3: null,
  c4: null,
  ic4: null,
  c5: null,
  ic5: null,
  isotope: null,
  c1Byc2: null,
  ic4BynC4: null,
  ic5BynC5: null,
  wetnessRatioData: null,
  balanceRatioData: null,
  characterRatioData: null,
  c1Composition: null,
  c2Composition: null,
  c3Composition: null,
  nc4Composition: null,
  nc5Composition: null,
  gor: null,
  api: null,
  pp: null,
  rft: null,
  mw: null,
  ecd: null,
  fg: null,
  rft: null,
  obg: null,
  torque: null,
  rpm: null,
  azimuth: null,
  wob: null,
  inclination: null,
};

export const defaultCurveKeysSensorType = {
  c2h4: null,
  c2h6: null,
  c2s: null
};

// Add a pre-list with a lot of mnemonic or curve keys here
export const defaultMnemonics = {
  depth: [
    'depth_tvd',
    'tvd',
    'tvd_depth',
    'md',
    'total_depth',
    'depth',
    'depth_md'
  ],
  flow: ['flow', 'flowin', 'flowout', 'flowinout', 'flowin/ou', 'flwpmps'],
  rop: ['rop', 'rop_min', 'rop/min'],
  gamma_ray: ['g_ray', 'gamma_ray', 'gammaRay', 'gamma_r', 'gr', 'GR'],
  total_gas: [
    't_gas',
    'total_gas',
    'gast',
    'gast_t',
    'gas_total',
    'gastotal',
    'totalgas',
    'gastchr'
  ],
  c1: ['c1'],
  c2: ['c2'],
  c3: ['c3'],
  c4: ['c4', 'C4'],
  c5: ['c5', 'C5'],
  nc4: ['nc4'],
  nc5: ['nc5'],
  ic4: ['ic4', 'isotpec4'],
  ic5: ['ic5', 'isotpec5'],
  torque: ['torque'],
  rpm: ['rpm'],
  azimuth: ['azimuth'],
  wob: ['wob'],
  inclination: ['inclination'],
};

export const defaultMnemonicsSensorType = {
  c2h4: ['c2h4'],
  c2h6: ['c2h6'],
  c2s: ['c2s']
};

export const geopressureDefaultCurves = {
  depth: null,
  rop: null,
  wob: null,
  ecd: null,
  rpm: null,
  overburdenGradient: null,
  normalPressureGradient: null,
  waterDepth: null,
  density: null,
  mw: null
};

export const defaultMnemonicsGeopress = {
  depth: [
    'depth_tvd',
    'dept',
    'tvd',
    'tvd_depth',
    'md',
    'total_depth',
    'depth',
    'depth_md',
    'water_depth',
    'density'
  ],
  rop: ['rop', 'rop_min', 'rop/min'],
  wob: ['wob'],
  ecd: ['ecd'],
  rpm: ['rpm', 'rps'],
  overburdenGradient: ['overburden_gradient', 'obg'],
  mw: ['mw', 'mud_weight', 'mw_in', 'mw_out']
};

export const defaultCurveKeysDensity = {
  density: null
};

export const defaultMnemonicsDensity = {
  density: ['density']
};

export const ABBREV_LITHOLOGY_SCORES = {
  SHALE: 'SHL',
  GYPSUM: 'GYP',
  HALITE: 'HAL',
  DIABASE: 'DIA',
  SILEXITE: 'SXT',
  ANHYDRITE: 'AND',
  SANDSTONE: 'SDS',
  SILTSTONE: 'STS',
  CALCARENITE: 'CRE',
  CALCILUTITE: 'CLU',
  METASEDIMENT: 'MSD',
  TUF: 'TUF',
  AGT: 'AGT',
  CHLK: 'CHLK',
  CARB_SHL: 'CARB_SHL'
};

export const ABBREVS_ROCKS_NAMES = {
  'SXT': 'rock_silexite',
  'XST': 'rock_schist',
  'QZT': 'rock_quartzite',
  'MSD': 'rock_metasediment',
  'MNI': 'rock_ui_metam',
  'GNA': 'rock_gnaiss',
  'FLT': 'rock_fillite',
  'ARS': 'rock_slate',
  'TUF': 'rock_tuff',
  'INI': 'rock_ui_igneous',
  'GRN': 'rock_granite',
  'DIA': 'rock_diabase',
  'EMB': 'rock_basement',
  'BAS': 'rock_basalt',
  'TQD': 'rock_taquidrite',
  'SLV': 'rock_sivinite',
  'SNI': 'rock_ui_salt',
  'HAL': 'rock_halite',
  'GYP': 'rock_gypsum',
  'CRN': 'rock_carnallite',
  'AND': 'rock_anhydrite',
  'DOL': 'rock_dolomite',
  'COQ': 'rock_coquina',
  'CRU': 'rock_calcirrudite',
  'CRE': 'rock_calcarenite',
  'CSI': 'rock_calcisiltite',
  'CLU': 'rock_calcilutite',
  'MRL': 'rock_marl',
  'DMT': 'rock_diamictite',
  'CGL': 'rock_conglomerate',
  'BRC': 'rock_breccia',
  'SDS': 'rock_sandstone',
  'STS': 'rock_siltstone',
  'SHL': 'rock_shale',
  'AGT': 'rock_claystone',
  'LIME': 'rock_limestone',
  'CHLK': 'rock_chalk',
  'CARB_SHL': 'rock_carbonate_shale',
  'CARB_SHALE': 'rock_carbonate_shale'
};

export const ABBREVS_ROCKS = {
  rock_silexite: 'SXT',
  rock_schist: 'XST',
  rock_quartzite: 'QZT',
  rock_metasediment: 'MSD',
  rock_ui_metam: 'MNI',
  rock_gnaiss: 'GNA',
  rock_fillite: 'FLT',
  rock_slate: 'ARS',
  rock_tuff: 'TUF',
  rock_ui_igneous: 'INI',
  rock_granite: 'GRN',
  rock_diabase: 'DIA',
  rock_basement: 'EMB',
  rock_basalt: 'BAS',
  rock_taquidrite: 'TQD',
  rock_sivinite: 'SLV',
  rock_ui_salt: 'SNI',
  rock_halite: 'HAL',
  rock_gypsum: 'GYP',
  rock_carnallite: 'CRN',
  rock_anhydrite: 'AND',
  rock_dolomite: 'DOL',
  rock_coquina: 'COQ',
  rock_calcirrudite: 'CRU',
  rock_calcarenite: 'CRE',
  rock_calcisiltite: 'CSI',
  rock_calcilutite: 'CLU',
  rock_marl: 'MRL',
  rock_diamictite: 'DMT',
  rock_conglomerate: 'CGL',
  rock_breccia: 'BRC',
  rock_sandstone: 'SDS',
  rock_siltstone: 'STS',
  rock_shale: 'SHL',
  rock_claystone: 'AGT',
  rock_limestone: 'LIME',
  rock_chalk: 'CHLK',
  rock_carbonate_shale: 'CARB_SHL'
};

export const rocks = [
  'rock_silexite',
  'rock_schist',
  'rock_quartzite',
  'rock_metasediment',
  'rock_ui_metam',
  'rock_gnaiss',
  'rock_fillite',
  'rock_slate',
  'rock_tuff',
  'rock_ui_igneous',
  'rock_granite',
  'rock_diabase',
  'rock_basement',
  'rock_basalt',
  'rock_taquidrite',
  'rock_sivinite',
  'rock_ui_salt',
  'rock_halite',
  'rock_gypsum',
  'rock_carnallite',
  'rock_anhydrite',
  'rock_dolomite',
  'rock_coquina',
  'rock_calcirrudite',
  'rock_calcarenite',
  'rock_calcisiltite',
  'rock_calcilutite',
  'rock_limestone',
  'rock_marl',
  'rock_diamictite',
  'rock_conglomerate',
  'rock_breccia',
  'rock_sandstone',
  'rock_siltstone',
  'rock_shale',
  'rock_claystone',
  'rock_carbonate_shale',
  'rock_chalk'
];
