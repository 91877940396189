import React from 'react';
import classNames from 'classnames';

const Legend = ({
  name, label
}) => (
  <div className="legend__container">
    <div
      className={classNames(
        'legend__box',
        { [`legend--${name}`]: !!name }
      )}
    />
    <div className="legend__label">
      {label}
    </div>
  </div>
);

export default Legend;
