import React, {
  useEffect,
  useState
} from 'react';

import classes from 'classnames';
import Modal from 'react-modal';

import removeIcon from '../../assets/close.svg';
import { modalStyle } from '../../utils';
import ScalesConfig from '../ScalesConfig';
import GeopressureForm from '../ScalesConfig/GeopressureForm';

const Title = ({ title, className, classTitle }) => (
  <h3 className={classes(
    'title-row',
    {
      [className]: !!className,
      [classTitle]: !!classTitle
    }
  )}
  >
    {title}
  </h3>
);

Title.defaultProps = {
  classTitle: 'modal-scale__title'
};

const ConfigModal = () => {
  const [modalState, setModal] = useState({
    typeOfTitle: null,
    modalIsOpen: false
  });

  const closeModal = () => {
    setModal({ modalIsOpen: false, typeOfTitle: null });
  };

  useEffect(() => {
    const openConfigChart = ({ detail }) => {
      setModal(() => {
        return {
          typeOfTitle: detail.type,
          modalIsOpen: true
        };
      });
    };
    document.addEventListener('openConfigModal', openConfigChart);
    return () => {
      document.removeEventListener('openConfigModal', openConfigChart);
    };
  }, []);

  const { typeOfTitle, modalIsOpen } = modalState;

  return (
    <>
      {
        typeOfTitle === 'gradient' 
        ? <Modal
            isOpen={modalIsOpen}
            style={modalStyle}
            onRequestClose={closeModal}
          >
            <div className="modal-scale__header">
              <Title title="Scale settings" />
              <img
                onClick={closeModal}
                src={removeIcon}
                className="modal-scale__close"
                data-cy="close-modal"
                alt="close modal"
              />
            </div>
            <div
            className="modal-scale__form"
          >
            {typeOfTitle && (
              <GeopressureForm typeOfTitle={typeOfTitle} onClose={closeModal} />
            )}
          </div>
          </Modal>
        : <Modal
            isOpen={modalIsOpen}
            style={modalStyle}
            onRequestClose={closeModal}
          >
          <div
            className="modal-scale__header"
          >
            <Title title="Scale settings" />
            <img
              onClick={closeModal}
              src={removeIcon}
              className="modal-scale__close"
              data-cy="close-modal"
              alt="close modal"
            />
          </div>
          <div
            className="modal-scale__form"
          >
            {typeOfTitle && (
              <ScalesConfig
                typeOfTitle={typeOfTitle}
                onClose={closeModal}
              />
            )}
          </div>
        </Modal>
      } 
    </>
  );
};

export default ConfigModal;
