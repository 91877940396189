import React, { useState, useEffect } from "react";
import BitWell from "../../../assets/bit-well.png";

import { ChartContainer, Title } from './styles'
import './tooltip.scss';
import { Tooltip } from "antd";

import useAggregatedDataContext from "../../../new/Hydraulics/Cuttings/useAggregatedData";

const TotalDepthCuttings = ({ setCurrentCutting, currentCutting, setCuttingIndex, ...props }) => {
    const [cuttingsInfo, setCuttingsInfo] = useState([])
    const { aggregatedSamples } = useAggregatedDataContext();

    const ShowCuttingsDetails = (index) => {
        if (cuttingsInfo) {
            setCuttingIndex(index + 1)
            setCurrentCutting({
                'Original Depth': {
                    "mean": cuttingsInfo[index].original.mean,
                    "std": cuttingsInfo[index].original.std,
                    "min": cuttingsInfo[index].original.min,
                    "max": cuttingsInfo[index].original.max
                },
                'Current Depth': {
                    "mean": cuttingsInfo[index].current.mean,
                    "std": cuttingsInfo[index].current.std,
                    "min": cuttingsInfo[index].current.min,
                    "max": cuttingsInfo[index].current.max
                }
            })
        }
    };

    useEffect(() => {
        if (aggregatedSamples) {
            setCuttingsInfo(aggregatedSamples);
        }
    }, [aggregatedSamples])

    return (
        <>
            <ChartContainer>
                <Title>Total Depth</Title>

                <img width={90} height={'100%'} src={BitWell} />
                {cuttingsInfo.map((item, index) => {
                    const top = ((item.current.mean / props.max[props.max.length - 1]) * 98);
                    return (
                        <>
                            <Tooltip onMouseEnter={() => ShowCuttingsDetails(index)} value={index} placement={'right'} title={'cuttings #' + (index + 1)}>
                                <div className='cutting-pointer' style={{ position: 'absolute', top: `${top}%`, width: '10px', height: '10px', borderRadius: '50px', cursor: 'pointer' }}></div>
                            </Tooltip>
                        </>);
                })}
            </ChartContainer>
        </>
    )
}
export default TotalDepthCuttings;