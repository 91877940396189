import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ZoomQueue from 'utils/Queue';
import { applyResetZoom, applyResetZoomGeopression } from 'utils';

import './style.scss';

const ZoomButton = ({ min, max, depth, geopressureDepth }) => {
  const [visible, setVisible] = useState(false);
  const [isGeopressure, setIsGeopressure] = useState(false);

  useEffect(() => {
    document.addEventListener('zoom-in', ({detail}) => {
      const {visible, geopressure} = detail;
      setVisible(visible);
      setIsGeopressure(geopressure);
    });
  }, []);

  const resetZoom = () => {
    const last = ZoomQueue.dequeue();
    let newMin = 0;
    let newMax = 0;
    let newMinDepth = 0;
    let newMaxDepth = 0;

    if (last) {
      newMin = last.min; // last undo
      newMax = last.max;
      newMinDepth = last.minDepth;
      newMaxDepth = last.maxDepth;
    } else {
      if (isGeopressure && geopressureDepth) {
        newMin = geopressureDepth[0];
        newMax = geopressureDepth[geopressureDepth.lenght-1];
      } else {
        newMinDepth = depth[min];
        newMaxDepth = depth[max];
        newMin = min; // from state
        newMax = max;
      }
    }

    if (isGeopressure) {
      applyResetZoomGeopression(newMin, newMax);
    } else {
      applyResetZoom(newMin, newMax, newMinDepth, newMaxDepth);
    }

    if (ZoomQueue.getSize() <= 0) {
      setVisible(false);
    }
  };

  // style={{ display: visible ? 'flex' : 'none', zIndex: 200 }}
  return(
    <div className="zoom__container">
      <button
        type="button"
        className={classNames(
          'btn-zoom',
          {
            'zoom-visible': visible,
            'zoom-hidden': !visible
          }
        )}
        onClick={resetZoom}
      >
        Reset Zoom
      </button>
    </div>
  );
};

export default ZoomButton;

