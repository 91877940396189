import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Drawer, Input, Button } from 'antd';
import { ErrorMessage } from '../../utils';
import './style.scss';

const axisSchema = Yup.object().shape({
  yMin: Yup.number().required('Required'),
  yMax: Yup.number().required('Required'),
  xMin: Yup.number().required('Required'),
  xMax: Yup.number().required('Required')
});

const Settings = ({onClose, visible, currentScaleConfig, onSubmitEthene}) => {
  const { yMin, yMax, xMin, xMax } = currentScaleConfig;
  const formik = useFormik({
    initialValues: {
      yMin,
      yMax,
      xMin,
      xMax
    },
    onSubmit: onSubmitEthene,
    validationSchema: axisSchema
  });

  const { handleSubmit, handleChange, values, errors } = formik;

  return(
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={385}
      className="wrapper-drawer"
    >
      <div className="settings-ethene-title">Edit Chart</div>
      <form onSubmit={handleSubmit}>
        <div className="wrapper-form-ethene">
          <div className="wrapper-fields-ethene">
            <label className="labels-ethene">yMin:</label>
            <Input
              name="yMin"
              type="number"
              onChange={handleChange}
              value={values.yMin}
            />
          </div>
          <div className="wrapper-error-ethene">
            {errors && errors.yMin && <ErrorMessage message={errors.yMin} />}
          </div>
          <div className="wrapper-fields-ethene">
            <label className="labels-ethene">yMax:</label>
            <Input
              name="yMax"
              type="number"
              onChange={handleChange}
              value={values.yMax}
            />
          </div>
          <div className="wrapper-error-ethene">
            {errors && errors.yMax && <ErrorMessage message={errors.yMax} />}
          </div>
          <div className="wrapper-fields-ethene">
            <label className="labels-ethene">xMin:</label>
            <Input
              name="xMin"
              type="number"
              onChange={handleChange}
              value={values.xMin}
            />
          </div>
          <div className="wrapper-error-ethene">
            {errors && errors.xMin && <ErrorMessage message={errors.xMin} />}
          </div>
          <div className="wrapper-fields-ethene">
            <label className="labels-ethene">xMax:</label>
            <Input
              name="xMax"
              type="number"
              onChange={handleChange}
              value={values.xMax}
            />
          </div>
          <div className="wrapper-error-ethene">
            {errors && errors.xMax && <ErrorMessage message={errors.xMax} />}
          </div>
        </div>
        <div className="wrapper-ethene-buttons">
          <div className="ethene-btn-cancel">
            <Button onClick={onClose}>Cancel</Button>
          </div>
          <div className="button-apply">
            <Button
              htmlType="submit"
              type="primary"
            >
              Apply
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
}

export default Settings;