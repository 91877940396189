export const formatLabels = data =>
  (currentItem) => parseFloat(data[currentItem.value]).toFixed();

const COLORS = {
  serieColor: '#eeeeee',
  black: '#000',
  transparentLine: 'rgba(255,255,255,0)',
  white: '#fff',
  gray: '#333'
};

export const getDefaultSerie = data => [{
  name: '',
  inverted: true,
  type: 'spline',
  lineWidth: 0,
  strokeWidth: 0,
  yAxis: 0,
  data,
  startOnTick: true,
  endOnTick: true,
  allowDecimals: true,
  showFirstLabel: false,
  showLastLabel: false,
  tickPosition: 'inside',
  lineColor: COLORS.transparentLine,
  tickColor: COLORS.black,
  tickLength: 7,
  tickPixelInterval: 100,
  tickInterval: 100,
  minorTickInterval: 30000,
  dashStyle: ' ',
  showinlegend: false,
  color: COLORS.serieColor,
  enablemousetracking: false,
  marker: {
    states: {
      hover: {
        enabled: false
      }
    }
  }
}];

export const getConfig = (data, zoomType) => ({
  series: getDefaultSerie(data),
  chart: {
    marginLeft: 5,
    zoomType,
    inverted: true,
    width: 100,
    alignTicks: false,
    resetZoomButton: {
      position: { x: 0, y: -50 }
    },
    margin: [4, 0, 4, 0],
    plotBackgroundColor: COLORS.white
  },
  title: ' ',
  subtitle: {
    text: '  ',
    align: 'left'
  },
  credits: {
    enabled: false
  },
  exporting: { enabled: false },
  xAxis: [
    {
      tickInterval: 1000,
      tickPosition: 'inside',
      tickColor: COLORS.gray,
      tickWidth: 1,
      lineColor: COLORS.gray,
      lineWidth: 2,
      labels: {
        x: 14,
        y: 4,
        align: 'left',
        formatter: formatLabels(data)
      },
      linkedTo: 0,
      startOnTick: true,
      endOnTick: true,
      allowDecimals: true,
      showFirstLabel: true,
      showLastLabel: true,
      gridLineColor: COLORS.gray
    },
    {
      // config of the RIGHT ticks and label
      tickInterval: 500,
      tickPosition: 'outinside',
      tickColor: COLORS.gray,
      tickWidth: 1,
      lineColor: COLORS.gray,
      left: 97,
      lineWidth: 2,
      labels: {
        x: -10,
        y: 4,
        align: 'right',
        formatter: formatLabels(data)
      },
      linkedTo: 0,
      allowDecimals: true,
      showFirstLabel: true,
      showLastLabel: true
    }
  ],
  yAxis: [
    {
      minorTickInterval: 50000,
      tickInterval: 100000,
      min: 0,
      max: 2,
      gridLineColor: COLORS.gray,
      gridLineWidth: 0,
      labels: {
        format: ' ',
        style: {
          color: COLORS.gray
        }
      },
      title: {
        text: '',
        y: -28,
        style: {
          marginBottom: 10,
          color: COLORS.gray
        }
      },
      offset: -40,
      opposite: true,
      allowDecimals: true
    },
    {
      gridLineWidth: 0,
      title: {
        text: ' ',
        style: {
          color: COLORS.black
        }
      },
      labels: {
        format: '',
        style: {
          color: COLORS.black
        },
        allowDecimals: true
      }
    },
    {
      gridLineWidth: 0,
      title: {
        text: ' ',
        style: {
          color: COLORS.gray
        }
      },
      labels: {
        format: '',
        style: {
          color: COLORS.gray
        }
      },
      opposite: true,
      allowDecimals: true
    }
  ],
  legend: {
    layout: 'vertical',
    align: 'left',
    x: 90,
    verticalAlign: 'top',
    y: -10,
    floating: true
  }
});
