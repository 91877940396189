import React, { createContext, useState } from 'react';
import { Button, Modal } from 'antd';
import UnitsConfig from './UnitsConfig';
import styled from "styled-components";

export const StyledModal = styled(Modal)`
    &.ant-modal > .ant-modal-content{
       
        color: #fff;
        border: none;
        background-color: #16151F;
        max-height: 90%;
        overflow-y:scroll;

        & .ant-modal-header{
            color: #fff;
            border: none;
            background: #16151F;
            padding-top: 30px;
        }
        & .ant-modal-title{
            color: #fff;
            font-size: 36px;
        }
        & .ant-modal-close{
            top: 5px;
            color: #fff;
 
        }

    }
    
`;

export const UnitsConfigContext = createContext({})


function UnitsConfigModal({ onCancel, ...props }) {


    return (
        <UnitsConfigContext.Provider value={{
            onCancel,
        }}>
            <StyledModal {...props}
                title="Units Configuration"
                footer={''}
                onCancel={onCancel}
            >
                <UnitsConfig onCancel={onCancel} dataUnits={props.unitsConfigDetails} setDataUnits={props.setUnitsConfigDetails} />
            </StyledModal >
        </UnitsConfigContext.Provider>
    )
}


export default UnitsConfigModal;