import Papa from 'papaparse';
import keys from 'lodash.keys';
import reduce from 'lodash.reduce';
import { getObjectValues } from '.';

export const csvNames = {
  depth: 'depth',
  wob: 'wob',
  rop: 'rop',
  mse: 'mse',
  realTimeLithology: 'realTimeLithology',
  lithology: 'lithology',
  standardDeviation: 'standardDeviation',
  meanScore: 'meanScore',
  shaleLithologyScore: 'shaleLithologyScore'
};

const fluidColumns = getObjectValues(csvNames);

export const crossPlotsKeysToSynchronizeWithDepth = [
  ...fluidColumns,
];

export const fieldsToCsv = [
  // 'nameWell',
  // 'uid',
  // 'startIndex',
  // 'endIndex',
  // 'isActive',
  // 'indexUnit',
  'depth',
  'c1',
  'c2',
  'c3',
  'c4',
  'c5',
  'nc4',
  'nc5',
  'ic4',
  'ic5',
  'flow',
  'wob',
  'gamma_ray',
  'rpm',
  'rop',
  'torque',
  'total_gas',
  'inclination',
  'aiGammaRay',
  'azimuth',
  'realtime_litho_AGT',
  'realtime_litho_CARB_SHL',
  'realtime_litho_CHLK',
  'realtime_litho_LIME',
  'realtime_litho_MRL',
  'realtime_litho_SDS',
  'realtime_litho_SHL',
  'realtime_litho_STS',
  'realtime_litho_TUF',

  'litho_score_AGT',
  'litho_score_CARB_SHL',
  'litho_score_CHLK',
  'litho_score_LIME',
  'litho_score_MRL',
  'litho_score_SDS',
  'litho_score_SHL',
  'litho_score_STS',
  'litho_score_TUF',
];

export const aliasesToCsv = {
  // 'nameWell': 'nameWell',
  // 'uid': 'uid',
  // 'startIndex': 'startIndex',
  // 'endIndex': 'endIndex',
  // 'isActive': 'isActive',
  // 'indexUnit': 'indexUnit',
  'depth': 'depth',
  'c1': 'c1',
  'c2': 'c2',
  'c3': 'c3',
  'c4': 'c4',
  'c5': 'c5',
  'nc4': 'nc4',
  'nc5': 'nc5',
  'ic4': 'ic4',
  'ic5': 'ic5',
  'flow': 'flow',
  'wob': 'wob',
  'gamma_ray': 'gamma_ray',
  'rpm': 'rpm',
  'rop': 'rop',
  'torque': 'torque',
  'total_gas': 'total_gas',
  'inclination': 'inclination',
  'aiGammaRay': 'aiGammaRay',
  'azimuth': 'azimuth',
  'realtime_litho_AGT': 'realtime_litho_AGT',
  'realtime_litho_CARB_SHL': 'realtime_litho_CARB_SHL',
  'realtime_litho_CHLK': 'realtime_litho_CHLK',
  'realtime_litho_LIME': 'realtime_litho_LIME',
  'realtime_litho_MRL': 'realtime_litho_MRL',
  'realtime_litho_SDS': 'realtime_litho_SDS',
  'realtime_litho_SHL': 'realtime_litho_SHL',
  'realtime_litho_STS': 'realtime_litho_STS',
  'realtime_litho_TUF': 'realtime_litho_TUF',

  'litho_score_AGT': 'litho_score_AGT',
  'litho_score_CARB_SHL': 'litho_score_CARB_SHL',
  'litho_score_CHLK': 'litho_score_CHLK',
  'litho_score_LIME': 'litho_score_LIME',
  'litho_score_MRL': 'litho_score_MRL',
  'litho_score_SDS': 'litho_score_SDS',
  'litho_score_SHL': 'litho_score_SHL',
  'litho_score_STS': 'litho_score_STS',
  'litho_score_TUF': 'litho_score_TUF'
};

/* createRowsToCsv
 * @description: Should access the values and to create an object with
 * columns to parse and generate a CSV.
 * @param {Object} well - a well json.
 * @param {Array} fieldsToCsv - represents each chart curve showed on the
 * page. This curve key is stored within an well on redux.
 * @param {Object} aliasesKeys - a map to access and replace the current key
 *  this is a kind of formatter to avoid display
 *  some variables names with camel case for example
 *  the user should read Slope Factor instead of
 *  slopeFactor.
 * @param {Array} keysToRelateWithDepth receive what keys need to be checked
 *                and related this value just if depth is the same. This is
 *                necessary because the crossplots value are not sorted.
 * @return {Array} - This represent each line of the well
 *  such as: [{ c1: 10, c2: 4, depth: 10 }, ...].
 */
export const createRowsToCsv = (
  well, fieldsToMap, aliasesKeys, keysToRelateWithDepth
) => {
  return reduce(well.depth, (acc, depthValue, index) => {
    const currentLine = {};
    fieldsToMap.forEach((key) => {
      if (keys(aliasesKeys).includes(key)) {
        const currentKey = aliasesKeys[key];
        currentLine[currentKey] = well[key] && well[key][index];
      } else if (
        depthValue && keysToRelateWithDepth.includes(key)
      ) {
        // TODO refactor it
        // this filter and relate each crossplot point with depth
        const crossPlotData = well[key];
        for(let i = 0; i <= crossPlotData.length; i++) {
          const currentCrossPlotValue = crossPlotData[i];
          if (currentCrossPlotValue
            && currentCrossPlotValue.depth
            && currentCrossPlotValue.depth === depthValue
          ) {
            currentLine[key] = currentCrossPlotValue.item;
          }
        }
      } else if (!keysToRelateWithDepth.includes(key)) {
        currentLine[key] = well[key] && well[key][index];
      }
    })
    acc.push(currentLine);
    return acc;
  }, []);
};


export const csvToJSON = (lines, worker) => {
  return new Promise((resolve) => {
    Papa.parse(lines, {
      worker,
      complete: (results) => {
        const csvData = { valid: true, data: null, errors: [] };
        if (results.errors.length) {
          csvData.valid = false;
          csvData.errors = results.errors;
        }
        csvData.data = results.data;
        resolve(csvData);
      }
    });
  });
};

