import './style.scss';

/* eslint-disable */
import React, {
  useEffect,
  useState
} from 'react';

import * as wellActions from 'actions/wells';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import {
  connect,
  useDispatch
} from 'react-redux';
import { bindActionCreators } from 'redux';
import store from 'store';

import { receiveSelectedDepthIndex } from '../../../actions/wells';
import {
  defaultCrossHair,
  disableDefaultZoom,
  displayZoomBtn,
  getToolTipValues,
  getUnit,
  propagateZoom,
  syncronizeTooltip,
} from '../../../utils';
import { DEFAULT_SCALES } from '../../../utils/constants';

Exporting(Highcharts);

const GRAY = '#333';

const DepthWithUnit = ({ unit }) => (
  <>
    <span>Selected</span>
    <span className="selected-depth__header-with-unit">
      {`Depth (${unit || ''})`}
    </span>
  </>
);

const DepthValue = ({ selectedDepth, unit, criticalSelected }) => {
  return (
    <div>
      <div>
        <span>{`${selectedDepth ? selectedDepth.toFixed(2) : ''} ${unit}`}</span>
      </div>
    </div>
  );
}

const updatedHeader = {
  scales: store.get('configScales') || DEFAULT_SCALES,
  selectedDepth: 0,
  unit: getUnit()
};

const initialHeader = {
  selectedDepth: 0,
  unit: getUnit()
};


const HeaderChart = React.memo(({ unit, selectedDepthIndex }) => {
  const dispatch = useDispatch();

  const configScales = store.get('configScales');
  // --- Intital State
  const [headerState, setHeaderState] = useState({
    ...initialHeader,
    scales: configScales || DEFAULT_SCALES
  });

  useEffect(() => {
    setHeaderState({
      scales: configScales
    });

    // TODO leo needs replace it using observer pattern
    const selectedDepthListener = ({ detail }) => {
      const { depth } = detail;
      if (depth) {
        updatedHeader.selectedDepth = depth;
        setHeaderState({
          ...headerState,
          ...updatedHeader
        });
      }
    };
    document.addEventListener('selected-depth', selectedDepthListener);

    const tooltipListener = ({ detail }) => {
      const { tooltipValues } = detail;
      const { x, y } = tooltipValues[0];
      const currentDepthValue = y;
      const currentDepthIndex = x;
      dispatch(receiveSelectedDepthIndex(currentDepthIndex));
      updatedHeader.selectedDepth = currentDepthValue;

      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('tooltip-values', tooltipListener);

    return () => {
      document.removeEventListener('tooltip-values', tooltipListener);
      document.removeEventListener('selected-depth', selectedDepthListener);
    };
  }, []);

  useEffect(() => {
    setHeaderState({
      unit
    });
  }, [unit]);

  const { selectedDepth } = headerState;

  let criticalCurvesHeader = null;

  return (
    <div className="charts-header__selected-depth">
      <div>
        {selectedDepth
        ? <DepthValue selectedDepth={selectedDepth} unit={unit} />
        : <DepthWithUnit unit={unit} />}
      </div>
    </div>
  );
});

const getDefaultSerie = (data) => ({
  name: '',
  inverted: true,
  lineWidth: 0,
  opacity: 0,
  strokeWidth: 0,
  color: 'rgba(0, 0, 0, 5.0)',
  yAxis: 0,
  data,
  startOnTick: true,
  endOnTick: true,
  allowDecimals: true,
  tickPosition: 'inside',
  tickColor: '#000',
  marker: {
    enabled: false
  },
  dashStyle: ' '
});

class SelectedDepth extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      data: [],
      selectedId: 0,
      plotBands: [],
      scatters: []
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.createSeriesChart();

    syncronizeTooltip(`#${id}`);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps && nextProps.data &&
      nextProps.data.length !== this.props.data.length
      || nextProps.selectedWell !== this.props.selectedWell
      || this.props.beforeWell.depth.length !== nextProps.currentWell.depth.length
    ) {
      this.chart.update({
        series: [getDefaultSerie(nextProps.data)]
      });

      const min = nextProps.data && nextProps.data.length ? nextProps.data.length - 200 : 0;
      const max = nextProps.data && nextProps.data.length ? nextProps.data.length: 0;
      this.chart.xAxis[0].removePlotBandOrLine();
      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(min, max);
      }, 200);
      return true;
    }

    if (this.props.currentWell !== nextProps.selectedWell) {
      this.chart.xAxis[0].removePlotBandOrLine();
      this.chart.xAxis[1].removePlotBandOrLine();
      this.chart.redraw();
    }

    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  createSeriesChart = () => {
    const { id, data } = this.props;
    
    this.chart = Highcharts.chart(id, {
      chart: {
        zoomType: 'x',
        type: 'spline',
        inverted: true,
        width: 92,
        alignTicks: false,
        marginLeft: 5,
        margin: [4, 0, 2, 0],
        plotBackgroundColor: 'rgba(0,0,0,0)',
        events: {
          selection: (event) => {
            displayZoomBtn();
            propagateZoom(event);
          }
        },
        ...disableDefaultZoom
      },
      plotOptions: {
        series: {
          showInLegend: false,
          color: '#eeeeee',
          enableMouseTracking: false,
          marker: {
            states: {
              hover: {
                enabled: false
              }
            }
          }
        }
      },
      title: ' ',
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      exporting: { enabled: false },
      xAxis: [
        {
          minorGridLineWidth: 0,
          minorTickLength: 2,
          minorTickWidth: 1,
          minPadding: 0,
          maxPadding: 0,
          offset: 5,
          opposite: true,
          tickPosition: 'inside',
          tickColor: GRAY,
          tickWidth: 1,
          lineColor: GRAY,
          lineWidth: 3,
          labels: {
            x: -90,
            y: 4,
            align: 'left',
            formatter: currentItem => {
              const value = this.props.data[currentItem.value];
              return value ? value.toFixed() : '';
            }
          },
          allowDecimals: true,
          gridLineColor: '#fff'
        },
        {
          tickInterval: 20,
          tickPosition: 'outinside',
          tickColor: GRAY,
          tickWidth: 1,
          lineColor: GRAY,
          left: 100,
          lineWidth: 7,
          labels: {
            x: -15,
            y: 4,
            align: 'right',
            formatter: currentItem => {
              const value = this.props.data[currentItem.value];
              return value ? value.toFixed() : '';
            }
          },
          linkedTo: 0,
          allowDecimals: true
        }
      ],
      yAxis: [
        {
          min: 0,
          gridLineColor: GRAY,
          gridLineWidth: 0,
          labels: {
            format: ' ',
            style: {
              color: Highcharts.getOptions().colors[2]
            }
          },
          title: {
            text: '',
            y: -58,
            style: {
              marginBottom: 10,
              color: GRAY
            }
          }
        }
      ],
      tooltip: {
        ...defaultCrossHair,
        shared: true,
        formatter: getToolTipValues('selected-depth')
      },
      backgroundColor: 'rgba(255,255,255,0.95)',
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 70,
        verticalAlign: 'top',
        y: -10,
        floating: true,
        backgroundColor: 'rgba(255,255,255,0.25)'
      },
      series: [getDefaultSerie(data)]
    });
  }

  render() {
    const { id, unit } = this.props;

    return (
      <div className="chart__box-container">
        <HeaderChart
          unit={unit} 
          selectedDepthIndex={this.props.wells.selectedDepthIndex}
        />
        <div
          id={id}
          style={{
            width: '98px',
            height: 'calc(100% - 128px)'
          }}
        />
      </div>
    );
  }
}

SelectedDepth.defaultProps = {
  zoomType: 'x',
  parentContainer: '.charts-container',
  id: 'selected-depth'
};

// export default React.memo(SelectedDepth);

const mapStateToProps = ({ zone, wells }, state) => ({
  zone,
  wells
});

const mapDispatchToProps = dispatch => ({
  wellAction: bindActionCreators(wellActions, dispatch),
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedDepth);

