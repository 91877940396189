import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Inter', sans-serif;

  background-color: #16151F;
  width: 100%;
  min-height: 100vh;
`

export const StyledSection = styled.section`
  padding: 20px;
  display: inline-block;
  min-height: calc(50vh - 3rem);
  width: 100%;
  flex-direction: column;
  overflow: hidden; 
`
export const StyledSectionRow = styled.section`
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 85%;
  flex-direction: row;
  overflow: hidden; 
  padding: 20px;
`

export const HydraulicsPressure = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 968px) {
    flex-direction: column;
  }
`
export const HydraulicsFlow = styled.div`
  display: flex;
  height: calc(50% - 20rem);
`

export const ChartBox = styled.div`
  display: flex;
  flex: 1;
  padding: 24px;
  gap: 4px;
  margin: 10px;
  height: 100%;
  max-height: 500px;
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.04) 100%);
  @media (max-width: 1235px) {
    flex-direction: column;
  }
`

export const CuttingsBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
  gap: 4px;
  margin: 10px;
  justify-content: flex-start;
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.04) 100%);
  @media (max-width: 1235px) {
    flex-direction: column;
  }
`

export const PressureGridItems = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  flex: 1,
  gap: '15px',
  height: '100%',
  maxHeight: '18rem',
  margin: '10px'
})