import React from 'react';
import classNames from 'classnames';

function Button({
  block, type = 'button', name, size,
  text, btnType, className,
  onClick, children, disabled,
  ...props
}) {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      {...props}
      type={type}
      name={name}
      size={size}
      disabled={disabled}
      className={classNames(
        'btn btn--primary',
        {
          [className]: className,
          [block]: block
        }
      )}
      onClick={onClick}
    >
      {text || children}
    </button>
  );
}

Button.defaultProps = {
  block: '',
  type: 'button',
  name: '',
  size: 'md',
  text: '',
  btnType: '',
  className: ''
};

export default Button;
