import React from "react"
import { Input } from '.'

const TextArea = (props) => (
  <Input
    as="textarea"
    rows="3"
    {...props}
  />
);

export default TextArea
