import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';

Exporting(Highcharts);
HighchartsMore(Highcharts);

/*
<QualityChart id={'Quality-chart'} />
*/

class QualityChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();
  }

  createScatterPlot = () => {
    const { id } = this.props;

    this.chart = Highcharts.chart(id, {
      chart: {
        plotBorderWidth: 1
      },
      title: {
        text: `<b> QA / QC </b>`,
        style: {
          fill: '#666666',
          fontWeight: 'bold',
          color: '#666666',
          fontSize: '15px'
        }
      },
      credits: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'mol %',
          style: {
            fontSize: '13px'
          }
        },
        zoomEnabled: true,
        minorGridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickLength: 10,
        minorTickWidth: 0,
        type: 'logarithmic'
      },
      xAxis: {
        zoomEnable: false
      },
      series: [
        {
          name: '6128-6136 m MD',
          data: [100, 8, 2, 0.8, 0.5],
          type: 'line',
          color: '#FF0000',
          marker: {
            enabled: false
          }
        },
        {
          name: 'Sagitario-1 (6148m)',
          data: [70, 12, 9, 5, 3],
          type: 'scatter',
          marker: {
            symbol: 'circle',
            fillColor: '#e8f000',
            lineWidth: 0.8,
            lineColor: '#000000',
            radius: 6
          }
        },
        {
          name: 'Temperature error',
          type: 'errorbar',
          data: [[100,70], [8, 6], [2, 1], [0.8, 0.3], [0.8, 0.3]],
          color: '#212020',
          tooltip: {
            pointFormat: '(error range: {point.low}-{point.high}°C)<br/>'
          }
        }
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    });
  }

  render() {
    const { id } = this.props;
    return (
      <div id={id}/>
    );
  }
}

export default QualityChart;
