import close from './close.svg'
import arrowUp from './arrow-up.png'
import arrowDown from './arrow-down.png'

const images = {
  close,
  arrowDown,
  arrowUp
}

export default images