import React from 'react'
import { Container } from './styles.js'
import { Box, Text, Input, HR, Select } from '../../../components/index.js'
import useExperiments from '../../../hooks/useExperiments.js'

const Training = () => {
  const {
    handleHyperparameterTuning,
    setScalingType,
    setImputationType,
    setImputationValue,
    handleScalingType, 
    handleImputationType, 
    handleImputationValue
  } = useExperiments()

  return (
    <Container>
      <Text variant="subtitle">Handle Missing Values</Text>
      <Box
        display="flex"
        alignItems="center"
        mt="0.5rem"
      >
        <Select
          label='Imputation Type'
          options={[{ label: 'Constant', id: 1 }, { label: 'Mean', id: 2 }, { label: 'Median', id: 3 }, { label: 'Most Frequent', id: 4 }]}
          onSelect={({ label }) => handleImputationType(label)}
        />
        <Box ml="0.5rem">
          <Input type="number" label="Imputation Value" onChange={(e) => handleImputationValue(e.target.value)} />
        </Box>
      </Box>
      <HR />
      <Text variant="title">Advanced Settings </Text>
      <Box
        mt="1.5rem"
        mb="0.5rem">
        <Text variant="subtitle">Data Scaler</Text>
      </Box>
      <Text variant="paragraph">If selected data will be automatically scaled and normalized.</Text>
      <Box mt="0.75rem" mb="1.5rem" >
        <Select
          label='Scaling Type'
          options={[{ label: 'Standard', id: 1 }, { label: 'Robust', id: 2 }, { label: 'Min Max 0 to 1', id: 3 }, { label: 'Min Max -1 to 1', id: 4 }]}
          onSelect={({ label }) => handleScalingType(label)}
        />
      </Box>
      <Box mb="0.5rem">
        <Text variant="subtitle">Hyperparameter Tuning</Text>
      </Box>
      <Text variant="paragraph">If selected, the model use either a more robust or advanced method to
        find the best possible model<br /> given the other settings and input data.</Text>
      <Box mt="0.75rem" mb="1.5rem">
        <Select
          label='Hyperparameter Tuning Type'
          options={[{ label: 'Robust', id: 1 }, { label: 'Advanced', id: 2 }]}
          onSelect={({ label }) => handleHyperparameterTuning(label)}
        />
      </Box>
    </Container>
  )
}

export default Training