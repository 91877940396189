import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { useFormik } from 'formik';
import classes from 'classnames';
import { CompactPicker } from 'react-color';
import { message } from 'antd';
import { DEFAULT_SCALES } from '../../utils/constants';
import Wrapper from '../Wrapper';

const LOG_CURVES = [
  'chromatographyC1',
  'chromatographyC2',
  'chromatographyC3',
  'chromatographyIc4',
  'chromatographyIc5',
  'chromatographyNc4',
  'chromatographyNc5'
];

const validLogValues = [
  0.0001,
  0.001,
  0.01,
  0.1,
  1,
  10,
  100,
  1000,
  10000,
  100000,
  1000000,
  10000000
];

export const updateChart = (event, data) => {
  console.log(`updateChart ==>`, { event, data});
  const updateScales = new CustomEvent(event, {
    detail: data
  });
  document.dispatchEvent(updateScales);
};

const onCommitScalesChanges = (values, curveName) => {
  const prevConfig = store.get('prevConfig');
  if (prevConfig) {
    store.set('configScales', prevConfig);
    store.set('wasCommitedConfig', 'true');
    store.remove('prevConfig');
  }

  updateStorage(curveName, values);
  updateChart(curveName, values);
  const scalesConfig = store.get('configScales') || {};
  store.set('configScales', {
    ...scalesConfig,
    [curveName]: { ...scalesConfig[values], ...values }
  });
  message.success('New scale settings successfully applied!');
};

const onRemoveScalesChanges = () => {
  const prevConfig = store.get('prevConfig');
  if (prevConfig) {
    store.remove('prevConfig');
  }
};

export const updateStorage = (curveName, newValues) => {
  const scalesConfig = store.get('configScales');
  const tempConfig = store.get('prevConfig') || {};

  store.set('prevConfig', {
    ...scalesConfig,
    ...tempConfig,
    [curveName]: newValues
  });
};

export const checkGasComposition = values => {
  const errors = {};

  if (values.min < 0 || values.min > 100) {
    errors.min = true;
    message.error('Invalid value');
    return errors;
  }
  if (values.max > 100 || values.max < 0) {
    errors.max = true;
    message.error('Invalid value');
    return errors;
  }
  return errors;
};

export const logValidate = (typeOfCurve, values, curvesTocheck) => {
  const errors = {};
  const messageError = 'Empty value';

  const isLogCurve = curvesTocheck.includes(typeOfCurve);
  if (values.min === undefined) {
    errors.min = true;
    message.error(messageError);
    return errors;
  }
  if (isLogCurve && !validLogValues.includes(values.min)) {
    errors.min = true;
    message.error('Invalid min value out of the logarithmic scale.');
  }
  if (values.max === undefined) {
    errors.max = true;
    message.error(messageError);
    return errors;
  }
  if (isLogCurve && !validLogValues.includes(values.max)) {
    errors.max = true;
    message.error('Invalid max value out of the logarithmic scale.');
  }
  return errors;
};

export const validate = (typeOfCurve, values, curvesTocheck) => {
  if (typeOfCurve.includes('gasComposition')) {
    return checkGasComposition(values);
  }
  return logValidate(typeOfCurve, values, curvesTocheck);
};

export const updateOnLocalStorage = (
  typeOfCurve,
  curvesTocheck,
  onClose,
  validateInputs,
  updateLocalStorage,
  updateScalesChart
) => (values, { setErrors }) => {
  const errors = validateInputs(typeOfCurve, values, curvesTocheck);
  console.log(`scale ==>`, typeOfCurve, values);
  if (!Object.keys(errors).length) {
    updateLocalStorage(typeOfCurve, values);
    updateScalesChart(typeOfCurve, values);

    const config = store.get('configScales');
    onClose();
  } else {
    setErrors(errors);
  }
};

export const restoreData = (
  curveName,
  defaultConfig,
  setFieldValue,
  updateLocalStorage,
  updateScalesChart
) => () => {
  setFieldValue('min', defaultConfig.min);
  setFieldValue('max', defaultConfig.max);
  setFieldValue('color', defaultConfig.color);

  updateScalesChart(curveName, defaultConfig);
  updateLocalStorage(curveName, defaultConfig);
  onRemoveScalesChanges();
  message.success('Scale settings successfully restored!');
};

const Field = ({ children }) => (
  <div className="form-scale__wrapper-input">
    <div className="form-scale__box-input">{children}</div>
  </div>
);

const Label = ({ id, text, className }) => (
  <label
    htmlFor={id}
    className={classes('form-scale__label', { [className]: !!className })}
  >
    {text}
  </label>
);

const InputConfig = ({ name, onChange, value, className }) => (
  <input
    className={classes('form-scale__input', {
      [className]: !!className
    })}
    type="number"
    name={name}
    id={name}
    onChange={onChange}
    value={value}
    data-cy={`modal-input-${name}`}
  />
);

const ColorSelector = ({
  handleClickColor,
  handleBlurColor,
  handleChangeColor,
  color,
  activeColor
}) => (
  <>
    <div
      onClick={handleClickColor}
      className="form-scale__wrapper-color"
      style={{ backgroundColor: color }}
      data-cy="scale-color"
    />
    {activeColor ? (
      <div className="form-scale__tooltip-color">
        <div
          className="form-scale__close-tooltip"
          onClick={handleBlurColor}
          data-cy="close-color"
        />
        <div className="form-scale__picker">
          <CompactPicker
            color={color}
            onChange={handleChangeColor}
            onBlur={handleChangeColor}
          />
        </div>
      </div>
    ) : null}
  </>
);

const WrapperActions = Wrapper('form-scale__group-buttons');
const WrapperSaveButtons = Wrapper('form-scale__group-save-buttons');

const ConfigActions = ({ onClose, onReset, applyConfigOnChart, curve, values }) => {
  return (
    <WrapperActions>
      <button
        onClick={onReset}
        type="button"
        className="form-scale__restore-button"
        data-cy="reset-config"
      >
        Restore Defaults
      </button>
      <WrapperSaveButtons>
        <button onClick={() => {
          onCommitScalesChanges(values, curve);
          onClose();
          onRemoveScalesChanges();
        }} type="button" htmlType="button" className="form-scale__ok-button">
          ok
        </button>
        <button
          onClick={applyConfigOnChart}
          type="submit"
          className="form-scale__save-button"
          data-cy="apply-config"
        >
          Apply
        </button>
      </WrapperSaveButtons>
    </WrapperActions>
  );
};

const Title = ({ title, className }) => (
  <h3 className={classes('title-row', { [className]: !!className })}>
    {title}
  </h3>
);

const SubTitle = ({ title }) => (
  <Title title={title} className="form-scale__subtitle" />
);

const ScalesConfig = ({ typeOfTitle, onClose }) => {
  const wells = useSelector(state => state.wells);

  const tempConfig = store.get('prevConfig');
  const configScales = store.get('configScales');

  const [currentCurveConfig, setcurrentCurveConfig] = useState();

  const [modalState, setModal] = useState(false);
  const [activeColor, setActiveColor] = useState(false);

  const openModal = () => setModal(true);

  useEffect(() => {
    const thereIsConfigCommited = store.get('wasCommitedConfig');
    if (thereIsConfigCommited === 'true') {
      store.remove('prevConfig');
      setcurrentCurveConfig(configScales);
      store.set('wasCommitedConfig', 'false');
    }
  }, [store.get('wasCommitedConfig')]);

  useEffect(() => {
    if (!wells.selectedWell) {
      setcurrentCurveConfig(store.get('configScales'));
      store.remove('prevConfig');
      store.set('wasCommitedConfig', 'false');
    }
    if (!wells.currentWell) {
      setcurrentCurveConfig(store.get('configScales'));
      store.remove('prevConfig');
      store.set('wasCommitedConfig', 'false');
    }
  }, [wells && wells.selectedWell]);
  
  useEffect(() => {
    if (tempConfig && !currentCurveConfig) {
      setcurrentCurveConfig({
        ...tempConfig,
      });
      return;
    }
    if (configScales && !currentCurveConfig) {
      setcurrentCurveConfig({
        ...configScales,
      });
      return;
    }
    if (
      configScales && currentCurveConfig &&
      configScales.min !== currentCurveConfig.min ||
      configScales.max !== currentCurveConfig.max ||
      configScales.color !== currentCurveConfig.color
    ) {
      setcurrentCurveConfig({
        ...configScales,
      });
      return;
    }
    if (!tempConfig && !configScales && !currentCurveConfig) {
      setcurrentCurveConfig({
        ...DEFAULT_SCALES[typeOfTitle]
      });
    }
  }, [tempConfig, configScales])

  useEffect(() => {
    const wipeConfig = () => {
      store.remove('prevConfig');
    }
    document.addEventListener('unload', wipeConfig);
    return () => {
      document.removeEventListener('unload', wipeConfig);
    };
  }, []);

  const onSubmit = updateOnLocalStorage(
    typeOfTitle,
    LOG_CURVES,
    onClose,
    validate,
    updateStorage,
    updateChart
  );

  const scaleConfig = currentCurveConfig && typeOfTitle ? currentCurveConfig[typeOfTitle] : {
    min: DEFAULT_SCALES[typeOfTitle].min,
    max: DEFAULT_SCALES[typeOfTitle].max,
    color: DEFAULT_SCALES[typeOfTitle].color,
    titleModal: DEFAULT_SCALES[typeOfTitle].titleModal,
    title: DEFAULT_SCALES[typeOfTitle].title
  };

  const formik = useFormik({
    initialValues: {
      min: scaleConfig.min,
      max: scaleConfig.max,
      color: scaleConfig.color
    },
    enableReinitialize: true,
    onSubmit
  });

  const updateResetStorage = () => {
    store.set('configScales', { ...DEFAULT_SCALES, [typeOfTitle]: DEFAULT_SCALES[typeOfTitle] });
  };

  const onReset = restoreData(
    typeOfTitle,
    DEFAULT_SCALES[typeOfTitle],
    formik.setFieldValue,
    updateResetStorage,
    updateChart
  );

  const { handleSubmit, handleChange, values } = formik;

  const handleClickColor = () => setActiveColor(true);
  const handleBlurColor = () => setActiveColor(false);

  const handleChangeColor = ({ hex }) => {
    handleChange({
      target: {
        value: hex,
        name: 'color'
      }
    });
  };

  return (
    <div className="form-scale">
      <form onSubmit={handleSubmit}>
        <SubTitle title={DEFAULT_SCALES[typeOfTitle].titleModal} />
          <div className="form-scale__wrapper-inputs">
            <Field>
              <Label id="min" text="Minimum" />
              <InputConfig
                name="min"
                id="min"
                onChange={handleChange}
                value={values.min}
              />
            </Field>
            <Field>
              <Label id="max" text="Maximum" />
              <InputConfig
                name="max"
                id="max"
                onChange={handleChange}
                value={values.max}
              />
            </Field>
            {values.color && (
              <Field>
                <Label id="color" text="Color" />
                <ColorSelector
                  handleClickColor={handleClickColor}
                  handleBlurColor={handleBlurColor}
                  handleChangeColor={handleChangeColor}
                  color={values.color}
                  activeColor={activeColor}
                />
              </Field>
            )}
          </div>
        <ConfigActions
          values={formik.values}
          curve={typeOfTitle}
          onReset={onReset}
          onClose={onClose}
          openModal={openModal}
        />
        {/*
        <ConfirmActions
          modalState={modalState}
          confirmSettings={modalStyleConfirmSettings}
        />
        */}
      </form>
    </div>
  );
};

export default ScalesConfig;
