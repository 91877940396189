import * as yup from "yup";
import { distanceConverter, pressureConverter } from "./UnitConverterFormula";

export function convertToPayloadStructure(wellPhases, bitParameters, fluidParameters, wellInfo, units) {

    let phases_length = bitParameters.phases.length - 1;
    const payloadStructure = {
        "hydraulic_model": fluidParameters.hydraulic_model,
        "correlation": fluidParameters.correlation,
        "user_settings": {
            "bit_diam": bitParameters.bit_size,
            "case_surf_press_loss": fluidParameters.case_surf_press_loss,
            "ch_press_loss": pressureConverter(fluidParameters.ch_press_loss, units.pressure_unit, 'psi'),
            "cs_press_loss": pressureConverter(fluidParameters.cs_press_loss, units.pressure_unit, 'psi'),
            "flu_dens": pressureConverter(fluidParameters.flu_dens, units.pressure_unit, 'psi'),

            "list_hole_diam": bitParameters.phases.map(({ casing_diam }) => casing_diam),
            "list_pipe_ref": bitParameters.phases.map(() => "D"),
            "list_sec_length": bitParameters.phases.map(({ top_ecd, bottom_ecd }) => distanceConverter(Number(bottom_ecd - top_ecd), units.depth_unit, 'ft')),
            "list_inner_pipe": bitParameters.phases.map(() => {
                const pipes_length = wellPhases.pipes.length;
                let inner_pipe = wellPhases.pipes[pipes_length - 1].diam_in;
                return inner_pipe;
            }),
            "list_outside_pipe": bitParameters.phases.map(() => {
                const pipes_length = wellPhases.pipes.length;
                let outside_pipe = wellPhases.pipes[pipes_length - 1].diam_out;
                return outside_pipe;
            }),

            "list_jet_diam": bitParameters.jets.flatMap(obj => Array(obj.num_jets).fill(obj.jet_diam)),

            "r100": fluidParameters.r100,
            "r200": fluidParameters.r200,
            "r3": fluidParameters.r3,
            "r300": fluidParameters.r300,
            "r6": fluidParameters.r6,
            "r600": fluidParameters.r600,

            "sapata_md": distanceConverter(bitParameters.casing_depth, units.depth_unit, 'ft'),
            "sapata_tvd": distanceConverter(bitParameters.casing_tvd, units.depth_unit, 'ft'),
            "solids_dens": fluidParameters.solids_dens,
            "tool_press_loss": fluidParameters.tool_press_loss,
            "solids_diam": fluidParameters.solids_diam,
            "spher": (fluidParameters.spher / 100)
        },

        "well_info": {
            "uid": wellInfo.uid,
            "uid_well": wellInfo.uidWell,
            "uid_wellbore": wellInfo.uidWellbore
        }
    }

    return payloadStructure;
}

export const wellPhasesInitialValues = {

    pipes: [{
        "pipe_type": null,
        "diam_in": null,
        "diam_out": null,
        "length": null
    }],


}

export const bitParametersInitialValues = {
    phases: [{
        "top_ecd": null,
        "bottom_ecd": null,
        diam: null,
    }],
    // ----- Bit Parameters -------
    bit_name: null,
    bit_manufacturer: null,
    bit_size: null,
    num_jets: null,
    jet_diam: null,
    jets: [],
    // ----- Casing Depth -------
    casing_depth: null,
    casing_tvd: null,

}

export const fluidParametersInitalValues = {


    // ----- Fluid Parameters -------
    flu_dens: null,

    // ----- Reometer Readings -------
    r600: null,
    r300: null,
    r100: null,
    r200: null,
    r3: null,
    r6: null,

    // ----- Hydraulic Model Setup ------
    hydraulic_model: "Bingham",
    correlation: "Moore",

    // ----- Pressure Parameters ------
    case_surf_press_loss: null,
    ch_press_loss: 0,
    cs_press_loss: 0,
    tool_press_loss: 0,

    // ----- Cuttings Parameters ------
    solids_dens: null,
    solids_diam: null,
    spher: null
}

export const correlationChoose = ["Chien", "Hopkins", "Moore", "Walker"];
export const hydraulicModelChoose = ["Power", "Bingham"];
export const surfacePressureLossType = ["Type I", "Type II", "Type III", "Type IV", "Type V"];
export const bitSizeOptions = [
    '6 7/8',
    '6 3/4',
    '6 1/8',
    '7 7/8',
    '8 3/4',
    '8 1/2',
    '8 3/8',
    '9 1/2',
    '10 5/8',
    '12 1/4',
    '13 1/2',
    '16',
    '17 1/2'
];

export const casingDiamOptions = [
    '4 1/2',
    '5',
    '5 1/2',
    '7',
    '7 5/8',
    '9 5/8',
    '10 3/4',
    '13 3/8',
    '20'
];

export const sectionTypeOptions = ["Casing", "Open Hole", "Liner", "Riser"];
export const pipeTypeOptions = ["Drill Pipe", "Drill Collar", "Heavy Weight"];


export const wellPhasesSchema = yup.object({
    //------------ Drill Pipe -----------------
    pipes: yup.array(yup.object({
        "pipe_type": yup.string(),
        "diam_in": yup.number()
            .positive({ message: 'must be greater than 0!' })
            .when('diam_out', (diam_out, schema) => {
                return schema.test({
                    test: diam_in => !!diam_out && diam_in < diam_out,
                    message: `the value of Internal Diameter must be lower than Outside Diameter!`
                })
            })    
            .required({ message: 'this field is required!' }),
        "diam_out": yup.number()
            .positive({ message: 'must be greater than 0!' })
            .required({ message: 'this field is required!' }),
        "length": yup.number().nullable()
    })).min(1),

}).required()

export const fluidParametersSchema = yup.object({

    // ----- Fluid Parameters -------
    flu_dens: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .required({ message: 'this field is required!' }),

    // ----- Reometer Readings -------
    r600: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .required({ message: 'this field is required!' }),
    r300: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .when('r600', (r600, schema) => {
            return schema.test({
                test: r300 => !!r600 && r300 < r600,
                message: `the value of R300 must be lower than R600!`
            })
        })
        .required({ message: 'this field is required!' }),
    r100: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .when('r200', (r200, schema) => {
            return schema.test({
                test: r100 => !!r200 && r100 < r200,
                message: `the value of R100 must be lower than R200!`
            })
        })
        .nullable()
        .optional(),
    r200: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .when('r300', (r300, schema) => {
            return schema.test({
                test: r200 => !!r300 && r200 < r300,
                message: `the value of R200 must be lower than R300!`
            })
        })
        .nullable()
        .optional(),
    r3: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .when('r6', (r6, schema) => {
            return schema.test({
                test: r3 => !!r6 && r3 < r6,
                message: `the value of R3 must be lower than R6!`
            })
        })
        .nullable()
        .optional(),
    r6: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .when('r100', (r100, schema) => {
            return schema.test({
                test: r6 => !!r100 && r6 < r100,
                message: `the value of R6 must be lower than R100!`
            })
        })
        .nullable()
        .optional(),
    // ----- Hydraulic Model Setup ------    
    hydraulic_model: yup.string()
        .required({ message: 'this field is required!' }),
    correlation: yup.string()
        .required({ message: 'this field is required!' }),

    // ----- Pressure Parameters ------
    case_surf_press_loss: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .integer().max(5)
        .required({ message: 'this field is required!' }),
    ch_press_loss: yup.number()
        .required({ message: 'this field is required!' }),
    cs_press_loss: yup.number()
        .required({ message: 'this field is required!' }),
    tool_press_loss: yup.number()
        .required({ message: 'this field is required!' }),

    // ----- Cuttings Parameters ------
    solids_dens: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .when('flu_dens', (flu_dens, schema) => {
            return schema.test({
                test: solids_dens => !!flu_dens && solids_dens > (flu_dens * 0.12),
                message: `the value of Solid Density must be greater than ${(flu_dens * 0.12)}!`
            })
        })
        .required({ message: 'this field is required!' }),
    solids_diam: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .required({ message: 'this field is required!' }),
    spher: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .required({ message: 'this field is required!' })
}).required();


export const bitParametersSchema = yup.object({
    phases: yup.array(yup.object({
        "section_type": yup.string(),
        "top_ecd": yup.number()
            .required({ message: 'this field is required!' }),
        "bottom_ecd": yup.number()
            .positive({ message: 'must be greater than 0!' })
            .required({ message: 'this field is required!' }),
        "casing_diam": yup.number({ message: "this must be a number, if that phase don't have casing diameter fill with 0!" }),
    })).min(1),

    // ----- Bit Parameters -------
    bit_name: yup.string().nullable(),
    bit_size: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .required({ message: 'this field is required!' }),
    bit_number: yup.string().nullable(),
    bit_sn: yup.string().nullable(),
    bit_manufacturer: yup.string().nullable(),


    num_jets: yup.number(),
    jet_diam: yup.number(),

    jets: yup.array(yup.object({
        num_jets: yup.number()
            .positive({ message: 'must be greater than 0!' })
            .required({ message: 'this field is required!' }),
        jet_diam: yup.number()
            .positive({ message: 'must be greater than 0!' })
            .required({ message: 'this field is required!' }),
    })).min(1),
    // ----- Casing Depth -------

    casing_depth: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .required({ message: 'this field is required!' }),
    casing_tvd: yup.number()
        .positive({ message: 'must be greater than 0!' })
        .required({ message: 'this field is required!' }),

}).required()