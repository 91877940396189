import styled from 'styled-components';

export const Title = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    color: #333;
`;

export const ChartContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 12px;
    margin-top: 90px;
    position: relative;
    width: 150px;
    pointer-events: all;


    // NOTE:
    // This is required to avoid get other event 
    // dispatched by other child element
    // this will update the header values
    pointer-events: all;
`;

export const CuttingPointer = styled.div`
    &::before{
        content: '';
        width: 10px;
        height: 10px;
        background-color: #6B6879;
        &:hover{
            background-color: #55B92A;
        }
    }
`;