import axios from 'axios';
import store from 'store';
import { getGateway } from './gateway';

const GATEWAY = getGateway();
const URL = `${GATEWAY}/mlflow-metadata`;
const stage = 'Production'; // Staging Production
const ML_SERVING = `${GATEWAY}/mlflow-serving`;
const ML_REGISTRY = `${GATEWAY}/mlflow-registry`;

export const fetchModels = async () => {
  const resource = `${URL}/models/registered/info`;
  const response = await axios.get(
    resource,
    {
      headers: {
        'access-token': store.get('automud-token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const fetchOneModel = async (name) => {
  const resource = `${URL}/models/registered/${name}/${stage}/info`;
  const response = await axios.get(
    resource,
    {
      headers: {
        'access-token': store.get('automud-token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const predictLithology = async (modelName, data) => {
  const resource = `${ML_SERVING}/models/registered/${modelName}/${stage}/predict`;
  const response = await axios.post(
    resource,
    { data },
    {
      headers: {
        'access-token': store.get('automud-token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const predictLithologyScores = async (modelName, data) => {
  const resource = `${ML_SERVING}/models/registered/${modelName}/${stage}/predict-probabilities`;
  const response = await axios.post(
    resource,
    { data },
    {
      headers: {
        'access-token': store.get('automud-token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const fetchRunOneMetadata = async (runId) => {
  const resource = `${URL}/runs/${runId}/info`;
  const response = await axios.get(
    resource,
    {
      headers: {
        'access-token': store.get('automud-token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const deleteModel = async (modelName) => {
  const resource = `${ML_REGISTRY}/registry/delete/${modelName}`;
  const response = await axios.delete(
    resource,
    {
      headers: {
        'access-token': store.get('automud-token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
}

export const addModel = async (body) => {
  const resource = `${ML_REGISTRY}/registry/register`;
  const response = await axios.post(
      resource,
      body,
      {
          headers: {
              'access-token': store.get('automud-token'),
              'Content-type': 'application/json'
          }
      }
  );
  return response;
}