import React, { useState } from "react"
import Input from "../../../Input"
import InputNumber from "../../../InputNumber"
import Icon from "../../../Icon"
import { AddButton, DeleteRow, StyledDisabledInput, StyledInputDrillString } from "./styles"
import { useFieldArray, useFormContext } from "react-hook-form"
import useHydraulicsContext from "../../../../../new/Hydraulics/useHydraulics"
import { pipeTypeOptions } from "../../../SettingsFormModal/SettingsForm/payload"
import List from "../../../List"

const InputDrillString = ({ id, label, placeholder, control, errors, name, width, ...props }) => {

    const { unitsConfigDetails } = useHydraulicsContext()
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "pipes", // unique name for your Field Array
    });
    const { setValue, getValues } = useFormContext();

    const inputs = [{
        field: 'diam_in',
        name: 'diam_in',
        label: 'Internal Diameter (ID)',
        unit: unitsConfigDetails.radius_unit
    },
    {
        field: 'diam_out',
        name: 'diam_out',
        label: 'Outside Diameter (OD)',
        unit: unitsConfigDetails.radius_unit
    },
    {
        field: 'length',
        name: 'length',
        label: 'Length',
        unit: unitsConfigDetails.depth_unit
    }]

    function addNewPipe(pipes) {
        if (pipes) {
            append({
                "diam_in": null,
                "diam_out": null,
                "length": null
            })
        }

    }

    return (
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', gap: '8px' }}>
            {fields.map((field, index) => {
                return (
                    <StyledInputDrillString>
                        <List
                            label={'Type'}
                            name={`pipes.${index}.pipe_type`}
                            control={control}
                            options={pipeTypeOptions}
                            defaultValue={'Drill Pipe'}
                            //errors={errors.pipe_type} 
                        >
                            {pipeTypeOptions && pipeTypeOptions.map((value) => <Option key={value} value={value}>{value}</Option>)}
                        </List>
                        {inputs.map(({ field, label, unit, name }) =>
                            <InputNumber
                                id={field.index}
                                key={name}
                                placeholder={0}
                                label={label}
                                unit={unit}
                                name={`pipes.${index}.${name}`}
                                control={control}
                                errors={errors && errors[index] ? errors[index][name] : ''}

                            />
                        )}

                        <DeleteRow type={'button'} onClick={() => remove(index)}>
                            <Icon  name="trash" width="20" height="20" margin=".5em"></Icon>
                        </DeleteRow>
                    </StyledInputDrillString >
                )
            })}
            <AddButton onClick={() => addNewPipe(fields)}
            >
                + Add Pipe
            </AddButton>
            {errors ? <span style={{ color: 'red', margin: '2px' }}>{errors.message}</span> : null}
        </div >
    )
}

export default InputDrillString;