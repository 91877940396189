const drawSvgPath = (leftPadding) => (
  x1, y1, x2, y2, x3, y3, x4, y4,
  chart, config
) => {
  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];

  const x1Px = xAxis.toPixels(x1);
  const y1Px = yAxis.toPixels(y1);

  const x2Px = xAxis.toPixels(x2);
  const y2Px = yAxis.toPixels(y2);

  const x3Px = xAxis.toPixels(x3);
  const y3Px = yAxis.toPixels(y3);

  const x4Px = xAxis.toPixels(x4);
  const y4Px = yAxis.toPixels(y4);

  return chart.renderer.path([
    'M',
    (leftPadding ? x1Px + leftPadding : x1Px),
    y1Px,
    'L', x2Px, y2Px,
    'L', x3Px, y3Px,
    'L', x4Px, y4Px,
    'Z'
  ]).attr({
    zIndex: 200,
    ...config
  }).add();
};

export const drawPathWithPadding = (padding) =>
  (...args) => drawSvgPath(padding)(...args);

export const drawPath = (...args) => drawSvgPath(0)(...args);
