import {
  calculateWetness, calculateBalanceRatio, calculateCharacterRatio
} from './GasesCalculations';

/* eslint no-new-func: 0 */
const fnWithExpressions = (filterFnKey, expressions) => new Function(
  filterFnKey,
  `return ${expressions || filterFnKey}`
);

const identity = x => x;

const callbacksToApply = {
  c1: {
    calculateC1PPM: identity,
    calculateC1Composition: identity,
    calculateCharacter: identity,
    calculateBalance: identity,
    calculateWetness: identity,
    calculateC1ByC2: identity
  }
};

const seriesMap = {
  c1: {
    instanceName: 'chromatography',
    indexSerie: 1
  },
  c2: {
    instanceName: 'chromatography',
    indexSerie: 2
  },
  c3: {
    instanceName: 'chromatography',
    indexSerie: 3
  },
  ic4: {
    instanceName: 'chromatography',
    indexSerie: 4
  },
  nc4: {
    instanceName: 'chromatography',
    indexSerie: 5
  },
  ic5: {
    instanceName: 'chromatography',
    indexSerie: 6
  },
  nc5: {
    instanceName: 'chromatography',
    indexSerie: 7
  },
  c1Composition: {
    instanceName: 'gasComposition',
    indexSerie: 1
  },
  c2Composition: {
    instanceName: 'gasComposition',
    indexSerie: 2
  },
  c3Composition: {
    instanceName: 'gasComposition',
    indexSerie: 2
  },
  nc4Composition: {
    instanceName: 'gasComposition',
    indexSerie: 4
  },
  nc5Composition: {
    instanceName: 'gasComposition',
    indexSerie: 5
  },
  character: {
    instanceName: 'characterRatio',
    indexSerie: 1
  },
  balance: {
    instanceName: 'characterRatio',
    indexSerie: 2
  },
  wetness: {
    instanceName: 'characterRatio',
    indexSerie: 3
  }
};

const characterMap = [
  {
    instanceName: 'characterRatio',
    indexSerie: 1,
    processedDataName: 'character'
  },
  {
    instanceName: 'characterRatio',
    indexSerie: 2,
    processedDataName: 'balance'
  },
  {
    instanceName: 'characterRatio',
    indexSerie: 3,
    processedDataName: 'wetness'
  }
];

const ic4ByNc4Map = {
  instanceName: 'slopeFactor',
  indexSerie: 4,
  processedDataName: 'ic4BynC4'
};

const ic5ByNc5Map = {
  instanceName: 'slopeFactor',
  indexSerie: 3,
  processedDataName: 'ic5BynC5'
};

const slopeAndCharacter = [
  {
    instanceName: 'slopeFactor',
    indexSerie: 2,
    processedDataName: 'c1ByC2'
  },
  {
    instanceName: 'slopeFactor',
    indexSerie: 1,
    processedDataName: 'slopeFactor'
  },
  ...characterMap
];

const allRelatedCharts = [
  {
    instanceName: 'chromatography',
    indexSerie: 1,
    processedDataName: 'c1'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 2,
    processedDataName: 'c2'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 3,
    processedDataName: 'c3'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 4,
    processedDataName: 'ic4'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 5,
    processedDataName: 'nc4'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 6,
    processedDataName: 'ic5'
  },
  {
    instanceName: 'chromatography',
    indexSerie: 7,
    processedDataName: 'nc5'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 1,
    processedDataName: 'c1Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 1,
    processedDataName: 'c1Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 2,
    processedDataName: 'c2Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 3,
    processedDataName: 'c3Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 4,
    processedDataName: 'c4Composition'
  },
  {
    instanceName: 'gasComposition',
    indexSerie: 5,
    processedDataName: 'c5Composition'
  },
  ic4ByNc4Map,
  ic5ByNc5Map,
  ...slopeAndCharacter
];

const generateC15ToProcess = gasKey => () => (currentWell, filter) => {
  const { data } = currentWell;
  const {
    c1, c1Composition, c2, c2Composition,
    c3, c3Composition, nc4Composition, nc5Composition,
    ic4BynC4, ic5BynC5, nc4, nc5,
    slowFactor
  } = data;
  const { filterFnKey, expressions } = filter;

  let key = gasKey;
  if (key === 'balance' || key === 'character' || key === 'wetness') {
    key += 'RatioData';
  }

  if (['ic4', 'ic5'].includes(key)) {
    key += 'Normal';
  }

  return data[key].reduce((acc, currentGas, index) => {
    const fnToValidatePredicate = fnWithExpressions(filterFnKey, expressions);
    const currentGasValue = data[key][index];
    const isValid = fnToValidatePredicate(currentGasValue);

    if (isValid) {
      // C1 composition 
      const currentC1 = c1[index];
      const c1Comp = c1Composition[index];
      const c2Comp = c2Composition[index];
      const c3Comp = c3Composition[index];
      const c4Comp = nc4Composition[index];
      const c5Comp = nc5Composition[index];

      // Character, balance, wetness
      const character = calculateCharacterRatio(
        c1Comp, c2Comp, c3Comp, c4Comp, c5Comp
      );
      const balance = calculateBalanceRatio(
        c1Comp, c2Comp, c3Comp, c4Comp, c5Comp
      );
      const wetness = calculateWetness(
        c1Comp, c2Comp, c3Comp, c4Comp, c5Comp
      );

      acc.c1.push(currentC1);
      acc.c1Composition.push(c1Comp);

      const currentC2 = c2[index];
      acc.c2.push(currentC2);
      acc.c2Composition.push(c2Comp);

      const currentC3 = c3[index];
      acc.c3.push(currentC3);
      acc.c3Composition.push(c3Comp);

      const currentNC4 = nc4[index];
      const currentIC4 = nc4[index];
      acc.nc4.push(currentNC4);
      acc.ic4.push(currentIC4);
      acc.c4Composition.push(c4Comp);

      const currentNC5 = nc5[index];
      acc.nc5.push(currentNC5);

      const currentNormalC5 = nc5[index];
      acc.ic5.push(currentNormalC5);
      acc.c5Composition.push(c5Comp);

      const currentSlope = slowFactor[index];
      acc.slopeFactor.push(currentSlope);

      acc.c1ByC2.push(c1Comp / c2Comp)
      acc.wetness.push(wetness);
      acc.balance.push(balance);
      acc.character.push(character);

      const currentIc4 = ic4BynC4[index];
      const currentIc5 = ic5BynC5[index];
      acc.ic4BynC4.push(currentIc4);
      acc.ic5BynC5.push(currentIc5);
    } else {
      acc.c1ByC2.push(null);
      acc.wetness.push(null);
      acc.balance.push(null);
      acc.character.push(null);
      acc.ic4BynC4.push(null);
      acc.ic5BynC5.push(null);
      acc.c1.push(null);
      acc.c2.push(null);
      acc.c3.push(null);
      acc.nc4.push(null);
      acc.ic4.push(null);
      acc.ic5.push(null);
      acc.nc5.push(null);
      acc.c1Composition.push(null);
      acc.c2Composition.push(null);
      acc.c3Composition.push(null);
      acc.c4Composition.push(null);
      acc.c5Composition.push(null);
      acc.slopeFactor.push(null);
    }
    return acc;
  }, {
    c1: [],
    c2: [],
    c3: [],
    nc4: [],
    ic4: [],
    nc5: [],
    ic5: [],
    c1Composition: [],
    c2Composition: [],
    c3Composition: [],
    c4Composition: [],
    c5Composition: [],
    c1ByC2: [],
    wetness: [],
    balance: [],
    character: [],
    ic4BynC4: [],
    ic5BynC5: [],
    slopeFactor: []
  });
};

/*
 * CurveRelation
 * this is a simple map to access curves that rely on c1 value wich 
 * needs to be processed again after apply the filter
 *
 * IMPORTANT: each curve that relies of c1, c2, c3, c4, c5
 * for example gas composition, character, wetness, balance
 * we gonna walking through each item and store these indexes
 * to access and apply these values to others charts, because
 * all values are already calculated.
 *
 * When we are apply cutoffs to curves in PPM, c1, c2, c3, c4, c5
 * this curve should recalculate everything that relies
 */
const curveRelations = {
  /*
   * This array has all information of each related chart that will be affected
   * by the c1Composition filter what means that they will display the data
   * */
  c1Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c1Composition')
  },
  c2Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c2Composition')
  },
  c3Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c3Composition')
  },
  nc4Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('nc4Composition')
  },
  nc5Composition: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('nc5Composition')
  },
  c1: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c1')
  },
  c2: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c2')
  },
  c3: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('c3')
  },
  nc4: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('nc4')
  },
  ic4: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('ic4')
  },
  nc5: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('nc5')
  },
  ic5: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('ic5')
  },
  balance: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('balance')
  },
  character: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('character')
  },
  wetness: {
    relatedChartInData: allRelatedCharts,
    fnToProcess: generateC15ToProcess('wetness')
  }
};

/*
 * generateResetFilter
 */
const generateResetFilter = (curveName, curveRelationsMap) => {
  const filter = { curveName, filterFnKey: curveName };
  const relatedChartsFn = curveRelationsMap[filter.curveName];
  return { ...filter, ...relatedChartsFn };
};

const checkCurvesToReset = (oldFilters, newFilters) => {
  const curvesFromFilters = newFilters && newFilters.map(filter => filter.curveName);
  // Whether there is filter but this is not on the new filters reset it
  return oldFilters.reduce((acc, curveName, index) => {
    if (curveName && curvesFromFilters && !curvesFromFilters.includes(curveName)) {
      acc.toReset.push(curveName);
      acc.newFilters.splice(index, 1);
    }
    return acc;
  }, { newFilters: [...oldFilters], toReset:[] });
};

/**
 * FilterProcessor - Has the purpose to manipulate and propagate each filter applyed to the curve
 * C1 relations
 * C1 PPM is used on
 * C1 composition
 *  Character
 *  Wetness
 *
 *  When this some kind of this curve is related between them receive cutoff, this should process
 *  the data again and also needs to update the charts.
 * 
 * Create a util class to recalculate and return the array of values that are associated
 * this class should propagate the change to the redux
 * update the global state
 * store this cutt offs on the indexDB
 *  
 * Be able to process and unprocess
 *
 */
class CutOffProcessor {
  // store the necessary things to apply and propagate all filters to each curve
  constructor() {
    this.instances = {};
    this.currentWell = null;
    this.updateWellAction = null;
    this.dispatch = null;
    this.actions = null;
    this.filter = {};
    this.data = null;
    this.origData = null;
    this.filtersCurves = [];
  }

  registerInstances = (chartName, instance) => {
    this.instances[chartName] = instance;
  }

  registerDispatch = dispatch => {
    this.dipatch = dispatch;
  }

  registerDefaultData = data => {
    this.data = data;
    this.origData = data;
  }

  undoFilter = curveName => {
    this.filter = generateResetFilter(curveName, curveRelations);
    this.getFnsFromAllRelatedChart(curveName, true);
  }

  // create filter by curveName
  registerFilters = (wellId, filters) => {
    // Reset curves
    const curvesToReset = checkCurvesToReset(this.filtersCurves, filters);
    curvesToReset.toReset.reduce((acc, curve) => {
      this.undoFilter(curve);
      return acc;
    }, []);

    // Removing old reseted curves
    this.filtersCurves = curvesToReset.newFilters;

    // Apply the filters
    /* eslint-disable */
    for(const filterKey in filters) {
      const filter = filters[filterKey];
      const { curveName } = filter;
      const relatedChartsFn = curveRelations[curveName];
      this.filter = { ...filter, ...relatedChartsFn };
      this.addCurves(curveName);
      this.getFnsFromAllRelatedChart(curveName);
    }
  }

  addCurves = curveName => {
    if (!this.filtersCurves.includes(curveName)) {
      this.filtersCurves.push(curveName);
    }
  }

  // should access all curve relation and process this data 
  getFnsFromAllRelatedChart = (curveName, shouldReset = false) => {
    const currentRelationCurve = curveRelations[curveName];
    const toProcess = currentRelationCurve.fnToProcess;
    const callbackList = callbacksToApply[curveName];
    const fnToCalculateAllValues = toProcess(callbackList);
    this.processAllData(curveName, fnToCalculateAllValues, shouldReset);
  }

  // Access the last data processed walk through each filter array of each related chart and curve and process data
  // should get all data related with the current curve and should execute the calculation
  // Get the base value C1 in PPM, apply cutoffs and reuse it on other places
  processAllData = (curveName, fnToProcessAllData, shouldReset) => {
    const dataToProcess = shouldReset ? this.origData : this.data;
    const processedData = fnToProcessAllData(dataToProcess, this.filter);
    console.log(`PROCESS LOG ${curveName}-->`, processedData);
    this.updateSeries(curveName, processedData);
  }

  // should receive the data and apply
  updateSeries = (curveName, data) => {
    const { instances } = this;
    const currentCurveData = data[curveName];
    const { instanceName, indexSerie } = seriesMap[curveName];
    const chart = instances[instanceName];
    chart.series[indexSerie].update({
      data: currentCurveData
    });

    // Update all charts that relies from the the current curve
    // -access all instance
    // -get the config series and index
    // -walking through a loop to update it
    const relatedConfigCharts = curveRelations[curveName].relatedChartInData;
    relatedConfigCharts.forEach(currentConfig => {
      /* eslint no-shadow: 0 */
      const { instanceName, indexSerie, processedDataName } = currentConfig;
      const instanceChart = this.instances[instanceName];
      const currentConfigData = data[processedDataName];
      instanceChart.series[indexSerie].update({
        data: currentConfigData
      });
      instanceChart.redraw();
    });

    this.storeOnRedux();
  }

  connectToRedux = (updateWellAction, currentWell) => {
    this.updateWellAction = updateWellAction;
    this.currentWell = currentWell;
  }

  storeOnRedux = () => {
    this.updateWellAction(this.currentWell);
  }
}

export default new CutOffProcessor();

