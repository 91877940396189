import store from 'store';
import { disableDots } from 'utils';

export const createDefaultConfig = (
  scales,
  displayZoomBtn,
  propagateZoom,
  getToolTipValues,
  getDefaultSeries,
  defaultCrossHair,
  mse,
  wob,
  rop,
  depth
) => ({
  chart: {
    inverted: true,
    width: 160,
    marginTop: 0,
    margin: 0,
    zoomType: 'x',
    events: {
      selection: event => {
        displayZoomBtn();
        propagateZoom(event, depth);
      }
    },
    animation: false,
    shadow: false
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    allowDecimals: false,
    labels: {
      enabled: false
    },
    tickPosition: 'inside',
    tickColor: '#fff',
    gridLineColor: '#a4a4a4',
    gridLineWidth: 1,
    minorTickInterval: 1,
    zIndex: 800
  },
  yAxis: [
    {
      title: {
        text: ''
      },
      padding: 0,
      margin: 0,
      // minorGridLineWidth: 1,
      // minorGridLineColor: '#E0E0E0',
      tickStart: 0,
      // type: 'linear',
      minorTickInterval: 10,
      tickInterval: 10,
      min: scales.rop.min || 1,
      max: scales.rop.max || 100,
      color: 'rgba(0, 0, 0, 0)',
      index: 0,
      gridLineWidth: 1,
      zIndex: 10,
      step: true,
      allowDecimals: true,
      labels: {
        enabled: false
      }
    },
    // {
    //   title: {
    //     text: ''
    //   },
    //   type: 'linear',
    //   minorTickInterval: 10,
    //   tickInterval: 10,
    //   min: scales.mse.min || 0.1,
    //   max: scales.mse.max || 100,
    //   gridLineColor: 'rgba(0, 0, 0, 0)',
    //   color: scales.mse.color,
    //   index: 1,
    //   allowDecimals: true,
    //   labels: {
    //     enabled: false
    //   },
    //   gridLineWidth: 1,
    //   minorGridLineWidth: 1,
    //   minorGridLineColor: 'rgba(0, 0, 0, 0)',
    //   zIndex: 10,
    //   step: true
    // },
    {
      type: 'linear',
      minorTickInterval: 10,
      tickInterval: 10,
      title: {
        text: ''
      },
      min: scales.wob.min || 0.1,
      max: scales.wob.max || 100,
      color: scales.wob.color,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      index: 1,
      allowDecimals: true,
      labels: {
        enabled: false
      },
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      zIndex: 10,
      step: true
    },
    {
      type: 'area',
      // minorTickInterval: 10,
      // tickInterval: 10,
      title: {
        text: ''
      },
      min: scales.rop.min || 0.1,
      max: scales.rop.max || 100,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      color: scales.rop.color,
      index: 2,
      allowDecimals: true,
      labels: {
        enabled: false
      },
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      zIndex: 10,
      step: true
    }
  ],
  plotOptions: {
    series: {
      marker: {
        enabled: false
      },
      enableMouseTracking: false,
      showInLegend: false,
      hover: false
    }
  },
  exporting: { enabled: false },
  tooltip: {
    shared: true,
    ...defaultCrossHair,
    formatter: getToolTipValues('mse')
  },
  series: getDefaultSeries(mse, wob, rop)
});

export const getDefaultSeries = (mseData, wobData, ropData) => {
 const { mse, wob, rop } = store.get('configScales');
  return [
    {
      type: 'spline',
      ...disableDots,
      name: 'totalPlaceholder',
      data: wobData,
      color: '#fff',
      min: mse.min,
      max: mse.max,
      index: 0,
      yAxis: 0,
      allowDecimals: true
    },
    // {
    //   type: 'spline',
    //   ...disableDots,
    //   name: 'mse',
    //   data: mseData,
    //   color: mse.color,
    //   lineColor: mse.color,
    //   lineWidth: 1.3,
    //   min: mse.min,
    //   max: mse.max,
    //   index: 1,
    //   yAxis: 1,
    //   fillOpacity: 0,
    //   allowDecimals: true
    // },
    {
      type: 'spline',
      ...disableDots,
      name: 'wob',
      data: wobData,
      color: wob.color,
      lineColor: wob.color,
      lineWidth: 1.3,
      min: wob.min,
      max: wob.max,
      index: 1,
      yAxis: 1,
      allowDecimals: true
    },
    {
      crosshair: true,
      fillOpacity: 0.5,
      name: 'rop',
      type: 'area',
      data: ropData,
      lineColor: rop.color,
      color: rop.color,
      yAxis: 2,
      zIndex: 2,
      index: 2,
      step: true,
      lineWidth: 0.8,
      shadow: false,
      ...disableDots
    },
  ];
};
